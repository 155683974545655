import { Component, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { compare } from 'src/app/shared/shared-functions';
import { TiposDeEntregaService } from 'src/app/layout/administrador/configuracion/tipos-de-entrega/tipos-de-entrega.service';
import { TipoEntregaDTO } from 'src/app/ModelDTO/DTO/tipoEntrega.DTO';
import { TipoDevolucionEntrega } from '@api/enums/tipo-devolucion-entrega.enum';
import { TipoUnidadDevolucion } from '@api/enums/tipo-unidad-devolucion.enum';

enum ColumnNames {
  Nombre = 'Nombre',
  TiempoDevolucion = 'TiempoDevolucion',
  DiasPrevioAviso = 'DiasPrevioAviso',
}
@Component({
  selector: 'app-entregas-empleado',
  templateUrl: './entregas-empleado.component.html',
  styleUrls: ['./entregas-empleado.component.scss'],
})
export class EntregasEmpleadoComponent {
  @Input()
  public set idPersonal(value: number) {
    this.pIdPersonal = value;
    this.actualizarEntregas();
  }

  public posibleEntregas: TipoEntregaDTO[];
  public readonly ColumnNames = ColumnNames;
  public displayedColumns: string[] = [ColumnNames.Nombre, ColumnNames.TiempoDevolucion, ColumnNames.DiasPrevioAviso];
  public mostrarDias = false;
  private pIdPersonal: number;

  constructor(
    private tipoEntregas: TiposDeEntregaService,
    private translate: TranslateService,
  ) {}

  public sortData(sort: Sort): void {
    const data = this.posibleEntregas.slice();
    if (!sort.active || !sort.direction?.length) {
      this.posibleEntregas = data;
      return;
    }

    this.posibleEntregas = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNames.Nombre:
          return compare(a.nombre, b.nombre, isAsc);
        case ColumnNames.TiempoDevolucion:
          return compare(a.tiempoDevolucion, b.tiempoDevolucion, isAsc);
        case ColumnNames.DiasPrevioAviso:
          return compare(a.diasPrevioAviso, b.diasPrevioAviso, isAsc);
        default:
          return 0;
      }
    });
  }

  public obtenerFechaDevolucion(tipoEntrega: TipoEntregaDTO): string {
    if (
      tipoEntrega.tipoDevolucion === TipoDevolucionEntrega.Sin_devolucion ||
      tipoEntrega.tipoDevolucion === TipoDevolucionEntrega.No_definido
    ) {
      return '';
    }

    if (tipoEntrega.tipoDevolucion === TipoDevolucionEntrega.Reposicion) {
      return this.translate.instant('DELIVERIES.WITH_REPLACEMENT');
    }

    return `${tipoEntrega.tiempoDevolucion} ${this.translate.instant(
      `ENUM.CONTRACT_TYPES.TYPE_UNIT_DURATION_MODALITY.${TipoUnidadDevolucion[tipoEntrega.unidadDevolucion]}`,
    )}`;
  }

  private async actualizarEntregas(): Promise<void> {
    if (this.pIdPersonal > 0) {
      this.posibleEntregas = await this.tipoEntregas.obtenerEntregasPorPersonal(this.pIdPersonal);
    }
  }
}
