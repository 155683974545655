<div id="top-container" class="d-flex flex-column flex-grow-1">
  <ng-container *ngIf="puestoSeleccionado">
    <div class="puesto-overlay" (click)="verDetallePuesto(undefined)">
      <mat-card class="m-3">
        <mat-card-content>
          <div class="d-flex flew-row justify-content-between align-items-center">
            <h5 class="gray-color my-2">{{ 'PROFILE.JOB_DESCRIPTION' | translate }}</h5>
            <span class="dialog-close-icon material-symbols-outlined" (click)="verDetallePuesto(undefined)"> close </span>
          </div>
          <h5 class="primary-color my-2">{{ puestoSeleccionado?.cargo?.nombre }}</h5>
          <quill-view-html class="mat-drawer-content my-0 break-word" [content]="puestoSeleccionado?.cargo?.descripcion | emptyParagraph" />
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
  <div class="example-sidenav-content">
    <form #contratacionForm="ngForm">
      <!--Info contractual, puesto y bancaria -->
      <h4 class="primary-color">{{ 'PROFILE.CONTRACT_INFORMATION' | translate }}</h4>
      <div class="d-flex flex-row flex-wrap gap-2">
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label> {{ 'PROFILE.FILE' | translate }} </mat-label>
          <input
            [disabled]="!modoEdicion || !modoEmpresa"
            #nroLegajo
            matInput
            id="nroLegajo"
            name="nroLegajo"
            type="text"
            [(ngModel)]="personalModel.Legajo.legajo"
          />
        </mat-form-field>
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'EMPLOYEES.CONTRACT_TYPE' | translate }}</mat-label>
          <mat-select
            [disabled]="!modoEdicion || !modoEmpresa"
            #selCont
            id="selCont"
            name="selCont"
            [(ngModel)]="personalModel.LegajoModel.Modalidad"
            (ngModelChange)="updateEndDate($event)"
            id="modalidad"
            name="modalidad"
            [compareWith]="compareId"
          >
            <mat-option *ngFor="let modalidad of modalidades" [value]="modalidad">
              {{ modalidadString(modalidad) | splitBeauty: '_' }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'GENERAL.OFFICE' | translate }}</mat-label>
          <mat-select
            [disabled]="!modoEdicion || !modoEmpresa"
            [(ngModel)]="personalModel.Oficina"
            id="ofi"
            name="ofi"
            [compareWith]="compareId"
          >
            <mat-option *ngFor="let oficina of oficinas" [value]="oficina">{{ oficina.nombre }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>{{ 'GENERAL.INTERN_NUMBER' | translate }}</mat-label>
          <input
            [disabled]="!modoEdicion || !modoEmpresa"
            #internNumber
            type="number"
            max="99999"
            matInput
            id="internNumber"
            name="internNumber"
            [(ngModel)]="personalModel.Legajo.internNumber"
          />
        </mat-form-field>
      </div>
      <div *ngIf="!hideContractualInformation" class="d-flex flex-row flex-wrap gap-2">
        <neo-date-selector
          appearance="outline"
          #fecD
          class="flex-fill"
          formClass="w-100"
          [label]="'PROFILE.START_CONTRACT' | translate"
          id="fecD"
          [required]="true"
          [min]="personalModel?.FechaNacimiento"
          [disabled]="!modoEdicion || !modoEmpresa"
          [form]="contratacionForm"
          [(date)]="personalModel?.Legajo.fechaIngreso"
          (dateChange)="onDateChange($event)"
          [onlyValidDate]="true"
          [onReturn]="fecH"
          [infoDate]="personalModel.Legajo.antiguedad | antiguedad"
        >
        </neo-date-selector>

        <neo-date-selector
          appearance="outline"
          #fecH
          class="flex-fill"
          formClass="w-100"
          [label]="'PROFILE.END_CONTRACT' | translate"
          id="fecH"
          [min]="personalModel?.Legajo.fechaIngreso"
          [disabled]="!modoEdicion || !modoEmpresa"
          [form]="contratacionForm"
          [(date)]="personalModel?.Legajo.fechaSalida"
        >
        </neo-date-selector>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>{{ 'EMPLOYEES.PAYMENT_TYPE' | translate }}</mat-label>
          <mat-select
            [disabled]="!modoEdicion || !modoEmpresa"
            id="paymentModality"
            name="paymentModality"
            [(ngModel)]="personalModel?.Legajo.paymentModality"
          >
            <mat-option
              *ngFor="let modality of paymentModalityTypes | arrayTranslate: 'ENUM.PAYMENT_MODALITY'"
              [value]="paymentModalityEnum[modality.value]"
            >
              {{ modality.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'BUSINESS_NAME.TITLE_SINGULAR' | translate }}</mat-label>
          <mat-select
            name="businessName"
            [disabled]="!modoEdicion || !modoEmpresa"
            [(ngModel)]="personalModel.Legajo.businessName"
            [compareWith]="compareId"
          >
            <mat-option [value]="null"> {{ 'BUSINESS_NAME.NOT_ASSIGNED' | translate }} </mat-option>

            <ng-container *ngIf="modoEmpresa">
              <mat-option *ngFor="let businessName of businessNames$ | async" [value]="businessName"> {{ businessName.name }} </mat-option>
            </ng-container>

            <!--
              en modo colaborador no traigo el listado porque no tiene permiso para obtenerlo
              solo muestro el que tiene asignado
            -->
            <ng-container *ngIf="!modoEmpresa && personalModel.Legajo.businessName?.id > 0">
              <mat-option [value]="personalModel.Legajo.businessName">
                {{ personalModel.Legajo.businessName.name }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex flex-row flex-wrap gap-2">
        <mat-form-field appearance="outline" class="flex-fill" *ngIfFeatureFlag="'featureSalary'">
          <mat-label>{{ 'PROFILE.GROSS_SALARY' | translate }}</mat-label>
          <input
            [disabled]="!modoEdicion || !modoEmpresa"
            #sueldo
            matInput
            id="sueldo"
            name="sueldo"
            [(ngModel)]="personalModel.Legajo.sueldoBruto"
            currencyMask
            [options]="{ prefix: '$ ', suffix: '', thousands: '.', decimal: ',', allowNegative: false }"
          />
        </mat-form-field>
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'GENERAL.CURRENCY' | translate }}</mat-label>
          <mat-select
            [disabled]="!modoEdicion || !modoEmpresa"
            [compareWith]="compareId"
            [(ngModel)]="personalModel.Legajo.currency"
            name="currency"
          >
            <mat-option [disabled]="true">
              <ngx-mat-select-search
                [labelGenerator]="currencyLabelGetter"
                [list]="currencies$ | async"
                (filtered)="filteredCurrencies = $event"
              >
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let currency of filteredCurrencies" [value]="currency">
              ({{ currency.display }}) {{ currency.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-selector-categoria
          class="flex-fill"
          [(categoria)]="categoriaSelected"
          [(subcategoria)]="subcategoriaSelected"
          [disabled]="!modoEdicion || !modoEmpresa"
          (categoriaChange)="onCategoriaChange($event)"
          (subcategoriaChange)="onSubcategoriaChange($event)"
        >
        </app-selector-categoria>
      </div>
      <div class="d-flex flex-row flex-wrap gap-2">
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>{{ 'GENERAL.HR_AREA' | translate }}</mat-label>
          <mat-select
            [compareWith]="compareId"
            [disabled]="!modoEdicion || !modoEmpresa"
            id="rhArea"
            name="rhArea"
            [(ngModel)]="personalModel?.Legajo.hrArea"
          >
            <mat-option *ngFor="let area of areas$ | async" [value]="area">{{ area.nombre }} </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container *ngIfFeatureFlag="countryFeatureHayGrade">
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'PROFILE.HAY_GRADE' | translate }} </mat-label>
            <input
              [disabled]="!modoEdicion || !modoEmpresa"
              [matTooltip]="'PROFILE.HAY_GRADE_INFORMATION' | translate"
              matInput
              name="hayGrade"
              type="text"
              [(ngModel)]="personalModel.Legajo.hayGrade"
            />
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'PROFILE.GRADE_PERCENTAGE' | translate }} </mat-label>
            <input
              [disabled]="!modoEdicion || !modoEmpresa"
              matInput
              name="gradePercentage"
              type="text"
              [(ngModel)]="personalModel.Legajo.hayGradePercentage"
            />
          </mat-form-field>
        </ng-container>
        <app-autocomplete-service-selector
          class="flex-fill"
          name="healthInsurance"
          [label]="'PROFILE.HEALTH_INSURANCE' | translate"
          [datasource]="healthInsuranceSearch"
          [(ngModel)]="personalModel.Legajo.healthInsuranceCompany"
          [displayFn]="displayFnHealthInsurance"
          [disabled]="!modoEdicion || !modoEmpresa"
        ></app-autocomplete-service-selector>
      </div>
    </form>
    <div class="d-flex flex-column">
      <h4 class="primary-color">{{ 'WORK_DAY.WORK_DAY_INFORMATION' | translate }}</h4>
      <div class="d-flex flex-row flex-wrap gap-2">
        <mat-form-field
          [matTooltip]="'WORK_DAY.DISABLED_MODALITY_TOOLTIP' | translate"
          [matTooltipDisabled]="!disabledWorkMode"
          class="w-33 w-100-md"
          appearance="outline"
        >
          <mat-label>{{ 'GENERAL.FORMS.MODALITY' | translate }}</mat-label>
          <mat-select [disabled]="disabledWorkMode" name="workLocationType" [(ngModel)]="personalModel.Legajo.modoTrabajo">
            <mat-option
              *ngFor="let workLocationType of workLocationTypes | arrayTranslate: 'ENUM.RECRUITMENT.WORK_LOCATION_TYPE'"
              [value]="workLocationEnum[workLocationType.value]"
            >
              {{ workLocationType.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex flex-row flex-wrap gap-2">
        <ng-container *ngIf="personalModel.Legajo.workDays.length">
          <table mat-table [dataSource]="personalModel.Legajo.workDays" class="hidden-sm" multiTemplateDataRows>
            <ng-container [matColumnDef]="ColumnNamesWorkDays.Name">
              <th mat-header-cell *matHeaderCellDef>{{ 'GENERAL.FORMS.NAME' | translate }}</th>
              <td mat-cell *matCellDef="let value">
                {{ value.name }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="ColumnNamesWorkDays.Total">
              <th mat-header-cell *matHeaderCellDef>{{ 'TIME_RANGE.' + ColumnNamesWorkDays.Total | translate }}</th>
              <td mat-cell *matCellDef="let value">
                {{ value.totalWorkDay | timepad }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="ColumnNamesWorkDays.Computed">
              <th mat-header-cell *matHeaderCellDef>{{ 'TIME_RANGE.' + ColumnNamesWorkDays.Computed | translate }}</th>
              <td mat-cell *matCellDef="let value">
                {{ value.totalComputedWorkDay | timepad }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="ColumnNamesWorkDays.Expand">
              <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
              <td mat-cell *matCellDef="let value">
                <button mat-icon-button (click)="expandRowOnClick(value); $event.stopPropagation()">
                  <mat-icon fontSet="material-symbols-outlined" *ngIf="expandedElement !== value">keyboard_arrow_down</mat-icon>
                  <mat-icon fontSet="material-symbols-outlined" *ngIf="expandedElement === value">keyboard_arrow_up</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let value" [attr.colspan]="displayedColumnsWorkDays.length">
                <div class="element-detail-expandable flex-column" [@detailExpand]="value === expandedElement ? 'expanded' : 'collapsed'">
                  <p>
                    {{ 'GENERAL.FORMS.MODALITY' | translate }}:
                    <strong>
                      <span>{{ workLocationEnum[value.workingMode] | enumTranslate: 'ENUM.RECRUITMENT.WORK_LOCATION_TYPE' }}</span>
                    </strong>
                  </p>
                  <p>
                    {{ 'GENERAL.DAYS' | translate }}:
                    <strong>
                      <span>{{ selectedDaysList(value) }}</span>
                    </strong>
                  </p>
                  <p>
                    {{ 'TIME_RANGE.TITLE' | translate }}:
                    <strong>
                      <span> {{ selectedTimeRanges(value.ranges) }} </span>
                    </strong>
                  </p>
                </div>
              </td>
            </ng-container>
            <ng-container [matColumnDef]="ColumnNamesWorkDays.Actions">
              <th mat-header-cell *matHeaderCellDef>{{ 'GENERAL.ACTION' | translate }}</th>
              <td mat-cell *matCellDef="let value">
                <button
                  color="danger"
                  mat-icon-button
                  class="mr-2"
                  [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)"
                  (click)="removeWorkDay(value)"
                >
                  <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsWorkDays"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsWorkDays" class="element-row-expandable"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row-expandable"></tr>
          </table>
          <!--VISTA MOBILE-->
          <div class="hidden-lg w-100 gap-2">
            <mat-card class="no-shadow mb-2 border-form" *ngFor="let workDay of personalModel.Legajo.workDays">
              <mat-card-content>
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <div class="d-flex flex-column">
                    <h5 class="primary-color m-0 mb-1">{{ workDay.name }}</h5>
                  </div>
                  <div class="text-right pt-1" *ngIf="modoEmpresa">
                    <button
                      color="danger"
                      mat-icon-button
                      (click)="removeWorkDay(workDay)"
                      class="mr-2"
                      [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)"
                    >
                      <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <small> {{ 'TIME_RANGE.DURATION' | translate }}: {{ workDay.totalWorkDay | timepad }} </small>
                  <small> {{ 'TIME_RANGE.COMPUTED_DURATION' | translate }}: {{ workDay.totalComputedWorkDay | timepad }} </small>
                  <small>
                    {{ 'GENERAL.DAYS' | translate }}:
                    <span>
                      {{ selectedDaysList(workDay) }}
                    </span>
                  </small>
                  <small>
                    {{ 'TIME_RANGE.TITLE' | translate }}:
                    <span> {{ selectedTimeRanges(workDay.ranges) }} </span>
                  </small>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </ng-container>
        <mat-expansion-panel
          *ngIf="modoEmpresa"
          [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)"
          class="mb-2 no-shadow w-100 mat-expansion-panel-fixed"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
              {{ 'WORK_DAY.TITLE_FORM_NEW' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form #workDayForm="ngForm">
            <div class="d-flex flex-row flex-wrap gap-2 align-items-center">
              <mat-form-field class="flex-fill" appearance="outline">
                <mat-label>{{ 'WORK_DAY.TITLE' | translate }}</mat-label>
                <mat-select [disabled]="!modoEdicion || !modoEmpresa" [(ngModel)]="newWorkDay" name="workDay" [compareWith]="compareId">
                  <mat-option *ngFor="let workDay of workDays$ | async" [value]="workDay">
                    {{ workDay.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <div class="gap-2">
                <button [disabled]="!modoEdicion" mat-flat-button color="primary" (click)="addWorkDay(newWorkDay)">
                  {{ 'GENERAL.ADD' | translate }}
                </button>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap gap-2">
      <h4 class="primary-color">
        {{ 'PROFILE.JOB_INFORMATION' | translate }}
      </h4>
    </div>
    <div class="d-flex flex-row flex-wrap gap-2">
      <ng-container *ngIf="personalModel.Legajo.puestos.length > 0; else sinPuestos">
        <table mat-table [dataSource]="personalModel.Legajo.puestos" class="noShadow hidden-sm" matSort (matSortChange)="sortData($event)">
          <ng-container [matColumnDef]="ColumnNamesPuestos.Area">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.AREA' | translate }}</th>
            <td mat-cell *matCellDef="let puesto">{{ puesto.area.nombre }}</td>
          </ng-container>
          <ng-container [matColumnDef]="ColumnNamesPuestos.Sector">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.SECTOR' | translate }}</th>
            <td mat-cell *matCellDef="let puesto">{{ puesto.sector.nombre }}</td>
          </ng-container>
          <ng-container [matColumnDef]="ColumnNamesPuestos.Cargo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PROFILE.POSITION' | translate }}</th>
            <td mat-cell *matCellDef="let puesto">
              <div class="d-flex align-items-center">
                {{ puesto.cargo.nombre }}
                <button mat-icon-button color="primary" (click)="verDetallePuesto(puesto)">
                  <mat-icon fontSet="material-symbols-outlined">info</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>
          <ng-container [matColumnDef]="ColumnNamesPuestos.Supervisor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.SUPERVISOR' | translate }}</th>
            <td mat-cell *matCellDef="let puesto">
              {{ puesto.superior ? puesto.superior.nombreCompleto : '' }}
            </td>
          </ng-container>
          <ng-container [matColumnDef]="ColumnNamesPuestos.Accion">
            <th mat-header-cell *matHeaderCellDef>{{ 'GENERAL.ACTION' | translate }}</th>
            <td mat-cell *matCellDef="let puesto">
              <button mat-icon-button [matMenuTriggerFor]="positionMenu">
                <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
              </button>
              <mat-menu #positionMenu="matMenu">
                <button mat-menu-item (click)="editarPuesto(puesto)" [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)">
                  {{ 'GENERAL.EDIT' | translate }}
                </button>
                <button mat-menu-item (click)="switchPositionVisibility(puesto)" [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)">
                  {{ positionVisibilityMode(puesto) | translate }}
                </button>
                <button
                  mat-menu-item
                  [command]="eliminarPuestoCmd"
                  [commandValue]="puesto"
                  [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)"
                >
                  {{ 'GENERAL.DELETE' | translate }}
                </button>
                <button mat-menu-item [command]="creatJobCertificateCmd" [commandValue]="puesto">
                  {{ 'PROFILE.LABOR_SECTION.CREATE_CERTIFICATE' | translate }}
                </button>
              </mat-menu>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsPuestos"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsPuestos"></tr>
        </table>

        <!--VISTA MOBILE-->
        <div class="hidden-lg w-100 gap-2">
          <mat-card class="no-shadow mb-2 border-form" *ngFor="let puesto of personalModel.Legajo.puestos">
            <mat-card-content>
              <div class="d-flex flex-row justify-content-between align-items-center">
                <div class="d-flex flex-column">
                  <h5 class="primary-color m-0 mb-1">{{ 'PROFILE.AREA' | translate }} - {{ puesto.area.nombre }}</h5>
                  <small class="color-gray-light mt-n1">{{ 'GENERAL.SECTOR' | translate }} - {{ puesto.sector.nombre }}</small>
                </div>
                <div class="text-right pt-1" *ngIf="modoEmpresa">
                  <button
                    color="danger"
                    mat-icon-button
                    [command]="eliminarPuestoCmd"
                    [commandValue]="puesto"
                    class="mr-2"
                    [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)"
                  >
                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                  </button>
                  <button
                    color="primary"
                    mat-icon-button
                    (click)="editarPuesto(puesto)"
                    [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)"
                  >
                    <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
                  </button>
                </div>
              </div>
              <div class="d-flex flex-column">
                <small
                  >{{ 'PROFILE.POSITION' | translate }}: {{ puesto.cargo.nombre }}
                  <button mat-icon-button color="primary" (click)="verDetallePuesto(puesto)">
                    <mat-icon fontSet="material-symbols-outlined">info</mat-icon>
                  </button></small
                >
                <small>{{ 'GENERAL.SUPERVISOR' | translate }}: {{ puesto.superior ? puesto.superior.nombreCompleto : '' }}</small>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
      <ng-template #sinPuestos>
        <app-warning-card [mensaje]="'PROFILE.NO_POSITION_ASSIGNED'" class="w-100"></app-warning-card>
      </ng-template>
      <mat-expansion-panel
        (afterCollapse)="reiniciarPuesto()"
        #panelPuesto
        *ngIf="modoEmpresa"
        [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)"
        class="mb-2 no-shadow w-100 mat-expansion-panel-fixed"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon fontSet="material-symbols-outlined" *ngIf="puesto.Id === 0; else addIcon">add</mat-icon>
            <ng-template #addIcon>
              <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
            </ng-template>
            {{ puesto.Id === 0 ? 'Agregar' : 'Edición' }} puesto
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form #puestoForm="ngForm">
          <div class="d-flex flex-column">
            <div class="d-flex flex-row flex-wrap gap-2">
              <app-selector-area
                #areaSelector
                class="flex-fill"
                [disabled]="!modoEdicion || !modoEmpresa"
                [(area)]="puesto.Area"
                [(sector)]="puesto.Sector"
                [opcionTodosArea]="false"
                [opcionTodosSector]="false"
              >
              </app-selector-area>
              <neo-completer-mat
                appearance="outline"
                class="flex-fill"
                [style.z-index]="401"
                [name]="'completer-cargo'"
                [datasource]="cargoSearch"
                [formFieldClass]="'w-100'"
                [minSearchLength]="0"
                label="Cargo"
                [autoHighlight]="true"
                [fillHighlighted]="false"
                [disabled]="!modoEdicion || !modoEmpresa || !areaSelector.area?.id"
                [(ngModel)]="puesto.Cargo"
                [clearUnselected]="true"
                [textNoResults]="'PROFILE.NO_POSITION_FOUND' | translate"
                [openOnFocus]="true"
                [required]="true"
                (ngModelChange)="consultarSuperiorCmd.execute(puesto)"
              >
              </neo-completer-mat>
              <div class="d-flex flex-row align-items-center flex-fill gap-2">
                <neo-completer-mat
                  appearance="outline"
                  class="flex-fill"
                  [name]="'completer-user'"
                  [datasource]="personalSearch"
                  [formFieldClass]="'w-100'"
                  [minSearchLength]="3"
                  label="Supervisor"
                  [autoHighlight]="true"
                  [fillHighlighted]="false"
                  [disabled]="!modoEdicion || (modoEdicion && !modoEmpresa)"
                  [(ngModel)]="puesto.Superior"
                  [clearUnselected]="true"
                  [textNoResults]="'GENERAL.NO_EMPLOYEE_FOUND' | translate"
                >
                </neo-completer-mat>
              </div>
            </div>
            <div class="d-flex justify-content-end gap-2">
              <button class="mr-2" [disabled]="!modoEdicion" mat-flat-button (click)="reiniciarPuesto()">
                {{ 'GENERAL.CANCEL' | translate }}
              </button>
              <button [disabled]="!modoEdicion" mat-flat-button color="primary" (click)="agregarPuesto()">
                {{ puesto.Id === 0 ? ('GENERAL.ADD' | translate) : ('GENERAL.UPDATE' | translate) }}
              </button>
            </div>
          </div>
        </form>
      </mat-expansion-panel>
    </div>

    <form #bancoForm="ngForm">
      <h4 class="primary-color">{{ 'PROFILE.BANK_INFORMATION' | translate }}</h4>
      <div class="d-flex flex-row flex-wrap gap-2">
        <neo-completer-mat
          appearance="outline"
          [required]="true"
          class="d-flex flex-grow-1"
          name="completer-banco"
          [datasource]="bancosSearch"
          [openOnFocus]="true"
          [formFieldClass]="'w-100'"
          [minSearchLength]="3"
          [label]="'PROFILE.BANKING_ENTITY' | translate"
          [autoHighlight]="true"
          [fillHighlighted]="false"
          [disabled]="!modoEdicion"
          [(ngModel)]="personalModel.Banco"
          [clearUnselected]="true"
          [textNoResults]="'PROFILE.NO_BANK_FOUND' | translate"
        >
        </neo-completer-mat>
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'EMPLOYEES.BANK_ACCOUNT_TYPE' | translate }}</mat-label>
          <mat-select
            [disabled]="!modoEdicion"
            [compareWith]="compareId"
            [(ngModel)]="personalModel.BankAccountType"
            name="bankAccountType"
            [required]="modoEdicion && modoEmpresa && personalModel.Alias?.length > 0"
          >
            <mat-option *ngFor="let accountType of accountTypes$ | async" [value]="accountType"> {{ accountType.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>{{ 'PROFILE.BANK_ID_CODE' | translate }}</mat-label>
          <input
            [disabled]="!modoEdicion"
            #nroCuenta="ngModel"
            matInput
            id="nroCuenta"
            name="nroCuenta"
            [(ngModel)]="personalModel.NroCuenta"
            [pattern]="bankAccountPattern"
          />
          <mat-hint>
            {{
              personalModel.NroCuenta?.length !== 1
                ? ('GENERAL.CHARS_ENTERED' | translate: { cant: personalModel.NroCuenta.length })
                : ('GENERAL.CHAR_ENTERED' | translate)
            }}
          </mat-hint>
          <mat-error *ngIf="nroCuenta.errors?.pattern">{{ 'GENERAL.INVALID_CHAR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="flex-fill">
          <mat-label>{{ 'PROFILE.ALIAS' | translate }}</mat-label>
          <input
            [disabled]="!modoEdicion"
            #alias
            matInput
            id="alias"
            name="alias"
            [(ngModel)]="personalModel.Alias"
            [maxLength]="20"
            [minlength]="6"
          />
          <mat-error *ngIf="!$any(alias).valid">{{ 'PROFILE.CONTROL_ALIAS' | translate: { cant: 6 } }}</mat-error>
        </mat-form-field>
      </div>
    </form>
    <h4 class="primary-color">{{ 'PROFILE.BENEFITS_AND_SALARY_SUPPLEMENTS' | translate }}</h4>
    <mat-accordion class="mb-2">
      <mat-expansion-panel class="no-shadow">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ 'PROFILE.ABSENCE_BENEFITS' | translate }} </mat-panel-title>
          <mat-panel-description class="hidden-sm"> {{ 'PROFILE.CHECK_REMOVE_ADD_BENEFITS' | translate }} </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="mt-2">
          <form #beneficiosAusenciaForm="ngForm">
            <div class="d-flex flex-row flex-wrap gap-2" *ngIf="modoEdicion && modoEmpresa">
              <mat-form-field class="flex-fill" appearance="outline">
                <mat-label>{{ 'PROFILE.ABSENCE_TYPE' | translate }}</mat-label>
                <mat-select [(ngModel)]="nuevoBeneficioAusencia.ausencia" id="tipo" name="tipo" required>
                  <mat-optgroup
                    *ngFor="let item of ausenciaXConvenio | keyvalue"
                    [label]="nombreConvenio(item.value?.length ? item.value[0].convenio : undefined)"
                  >
                    <mat-option *ngFor="let tipoAusencia of item.value" [value]="tipoAusencia">{{ tipoAusencia?.nombre }}</mat-option>
                  </mat-optgroup>
                </mat-select>
              </mat-form-field>
              <neo-date-selector
                appearance="outline"
                class="flex-fill"
                formClass="w-100"
                [label]="'GENERAL.SINCE' | translate"
                id="fecDA"
                [required]="true"
                [(date)]="nuevoBeneficioAusencia.fechaDesde"
                [form]="beneficiosAusenciaForm"
              >
              </neo-date-selector>
            </div>
            <div class="d-flex flex-row flex-wrap gap-2 align-items-center" *ngIf="modoEdicion && modoEmpresa">
              <mat-form-field class="flex-fill" appearance="outline">
                <mat-label>{{ 'GENERAL.OBSERVATION' | translate }}</mat-label>
                <input #obsBenAus matInput type="text" id="obsBenAus" name="obsBenAus" [(ngModel)]="nuevoBeneficioAusencia.observacion" />
              </mat-form-field>
              <div class="gap-2">
                <button type="button" mat-raised-button color="primary" [command]="agregarBeneficioAusenciaCmd">
                  {{ 'GENERAL.ADD' | translate }}
                </button>
              </div>
            </div>
          </form>
          <table mat-table [dataSource]="personalModel.LegajoModel.BeneficiosAusencia" matSort (matSortChange)="sortData($event)">
            <ng-container [matColumnDef]="ColumnNamesBeneficiosAusencia.Ausencia">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PROFILE.ABSENCE_TYPE' | translate }}</th>
              <td mat-cell *matCellDef="let beneficioAusencia">
                {{ beneficioAusencia.ausencia.nombre }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="ColumnNamesBeneficiosAusencia.ObservacionAusencia">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.OBSERVATION' | translate }}</th>
              <td mat-cell *matCellDef="let beneficioAusencia">
                {{ beneficioAusencia.observacion }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="ColumnNamesBeneficiosAusencia.DesdeAusencia">
              <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.SINCE' | translate }}</th>
              <td class="text-right" mat-cell *matCellDef="let beneficioAusencia">
                {{ beneficioAusencia.fechaDesde | ngxdate: 'shortDateFull' }}
              </td>
            </ng-container>
            <ng-container matColumnDef=" ">
              <th class="text-right" mat-header-cell *matHeaderCellDef></th>
              <td class="text-right" mat-cell *matCellDef="let beneficioAusencia">
                <div class="group-buttons">
                  <button
                    type="button"
                    mat-stroked-button
                    color="danger"
                    [command]="eliminarBeneficioAusenciaCmd"
                    [commandValue]="beneficioAusencia"
                    *ngIf="modoEdicion && modoEmpresa"
                  >
                    {{ 'GENERAL.REMOVE' | translate }}
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsBeneficiosAusencia"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsBeneficiosAusencia"></tr>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- OTROS BENEFICIOS -->
    <mat-accordion class="mb-2">
      <mat-expansion-panel class="no-shadow">
        <mat-expansion-panel-header>
          <mat-panel-title> {{ 'PROFILE.OTHER_BENEFITS' | translate }} </mat-panel-title>
          <mat-panel-description class="hidden-sm"> {{ 'PROFILE.CHECK_REMOVE_ADD_BENEFITS' | translate }} </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="mt-2">
          <form #beneficiosForm="ngForm">
            <div class="d-flex flex-row flex-wrap gap-2" *ngIf="modoEdicion && modoEmpresa">
              <mat-form-field class="flex-fill" appearance="outline">
                <mat-label>{{ 'PROFILE.BENEFIT_TYPE' | translate }}</mat-label>
                <mat-select
                  [(ngModel)]="nuevoBeneficio.beneficio"
                  class="form-control"
                  id="catSelect"
                  name="catSelect"
                  [compareWith]="compareId"
                  required
                >
                  <mat-option *ngFor="let ben of beneficiosPersonales" [value]="ben">{{ ben.nombre }} </mat-option>
                </mat-select>
              </mat-form-field>
              <neo-date-selector
                appearance="outline"
                class="flex-fill"
                formClass="w-100"
                [label]="'GENERAL.SINCE' | translate"
                id="fecD"
                [required]="true"
                [(date)]="nuevoBeneficio.fechaDesde"
                [form]="beneficiosForm"
              >
              </neo-date-selector>
            </div>
            <div class="d-flex flex-row flex-wrap gap-2 align-items-center" *ngIf="modoEdicion && modoEmpresa">
              <mat-form-field class="flex-fill" appearance="outline">
                <mat-label> {{ 'GENERAL.OBSERVATION' | translate }} </mat-label>
                <input #obsBen matInput type="text" id="obsBen" name="obsBen" [(ngModel)]="nuevoBeneficio.observacion" />
              </mat-form-field>
              <div class="gap-2">
                <button type="button" mat-raised-button color="primary" [command]="agregarBeneficioCmd">
                  {{ 'GENERAL.ADD' | translate }}
                </button>
              </div>
            </div>
          </form>
          <table mat-table [dataSource]="personalModel.LegajoModel.Beneficios" matSort (matSortChange)="sortData($event)">
            <ng-container [matColumnDef]="ColumnNamesBeneficios.Beneficio">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.BENEFIT' | translate }}</th>
              <td mat-cell *matCellDef="let beneficio">
                {{ beneficio.beneficio.nombre }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="ColumnNamesBeneficios.Observacion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.OBSERVATION' | translate }}</th>
              <td mat-cell *matCellDef="let beneficio">
                {{ beneficio.observacion }}
              </td>
            </ng-container>
            <ng-container [matColumnDef]="ColumnNamesBeneficios.Desde">
              <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.SINCE' | translate }}</th>
              <td class="text-right" mat-cell *matCellDef="let beneficio">
                {{ beneficio.fechaDesde | ngxdate: 'shortDateFull' }}
              </td>
            </ng-container>
            <ng-container matColumnDef=" ">
              <th class="text-right" mat-header-cell *matHeaderCellDef></th>
              <td class="text-right" mat-cell *matCellDef="let beneficio">
                <div class="group-buttons">
                  <button
                    type="button"
                    mat-stroked-button
                    color="danger"
                    [command]="eliminarBeneficioCmd"
                    [commandValue]="beneficio"
                    *ngIf="modoEdicion && modoEmpresa"
                  >
                    {{ 'GENERAL.REMOVE' | translate }}
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsBeneficios"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsBeneficios"></tr>
          </table>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <form #representacionForm="ngForm">
      <h4 class="primary-color">
        {{ 'PROFILE.GUILD_REPRESENTATION' | translate }}
        <span *ngIf="personalModel.Legajo.categoriaContratacion?.convenio?.id"
          >({{ personalModel.Legajo.categoriaContratacion.convenio.nombreCompleto }})</span
        >
      </h4>
      <div class="d-flex flex-row align-items-center flex-wrap gap-2">
        <mat-checkbox
          class="gap-2"
          [disabled]="!modoEdicion || !modoEmpresa"
          color="primary"
          [(ngModel)]="personalModel.LegajoModel.RepresentanteGremial"
          id="esR"
          name="esR"
          [onReturn]="fechairg"
        >
          {{ 'PROFILE.GUILD_REPRESENTATIVE' | translate }}
        </mat-checkbox>
        <neo-date-selector
          appearance="outline"
          #fechairg
          class="flex-fill"
          formClass="w-100"
          [label]="'PROFILE.START_REPRESENTATION' | translate"
          [min]="personalModel?.FechaNacimiento"
          [disabled]="!modoEdicion || !modoEmpresa || !personalModel.LegajoModel.RepresentanteGremial"
          id="fechairg"
          [(date)]="personalModel.LegajoModel.InicioRepresentacionGremial"
          [onReturn]="fechafrg"
          [form]="representacionForm"
        >
        </neo-date-selector>
        <neo-date-selector
          appearance="outline"
          #fechafrg
          class="flex-fill"
          formClass="w-100"
          [label]="'PROFILE.END_REPRESENTATION' | translate"
          id="fechafrg"
          [disabled]="!modoEdicion || !modoEmpresa || !personalModel.LegajoModel.RepresentanteGremial"
          [form]="representacionForm"
          [(date)]="personalModel.LegajoModel.FinRepresentacionGremial"
        >
        </neo-date-selector>
      </div>
    </form>
  </div>
</div>
