import { BehaviorSubject } from 'rxjs';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { Component, OnInit } from '@angular/core';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

import { MatTabChangeEvent } from '@angular/material/tabs';
import { PersonalLegajoServiceBackend } from 'src/app/ServiceBackend/personalLegajo.ServiceBackend';
import { ReferralsServiceBackend } from 'src/app/ServiceBackend/referrals.ServiceBackend';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { urlWebPage } from 'src/app/shared/constants';
import { SalesCrmDTO } from 'src/app/ModelDTO/DTO/salesCrm.DTO';
import { SalesCrmStatus } from 'src/app/ModelDTO/DTO/salesCrmStatus.ENUM';
import { MatDialogRef } from '@angular/material/dialog';
import { ReferidoDTO } from 'src/app/ModelDTO/DTO/referido.DTO';

enum ColumnNames {
  Date = 'Fecha',
  Name = 'Nombre',
  Company = 'Empresa',
  DemoReward = 'Demo',
  Reward = 'Recompensa',
  Status = 'Estado',
}

@Component({
  selector: 'app-referred-modal',
  templateUrl: './referred-modal.component.html',
  styleUrls: ['./referred-modal.component.scss'],
})
export class ReferredModalComponent implements OnInit {
  public readonly ReferralPrice = '60.000';
  public readonly urlWebPage = urlWebPage;
  public selectedTab = 0;
  public referrals: SalesCrmDTO[] = [];
  public loading = false;
  public readonly ColumnNames = ColumnNames;
  public displayedColumns: string[] = [ColumnNames.Date, ColumnNames.Name, ColumnNames.Status, ColumnNames.DemoReward, ColumnNames.Reward];
  public readonly SalesCrmStatus = SalesCrmStatus;

  public referredForm = new UntypedFormGroup({
    personalEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    referredName: new UntypedFormControl('', [Validators.required]),
    referredEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    referredPhone: new UntypedFormControl('', [Validators.required]),
    referredCompany: new UntypedFormControl('', [Validators.required]),
  });
  public referredCmd: ICommand = new Command(() => this.sendReference(), new BehaviorSubject(true), true);

  public get potentialAmount(): number {
    return this.referrals
      .map((item) => {
        if (item.status === SalesCrmStatus.PENDING) {
          return item.refersAmount + item.refersDemoAmount;
        }
        if (item.status === SalesCrmStatus.DEMO_DONE) {
          return item.refersAmount;
        }
        return 0;
      })
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  public get totalAmount(): number {
    return this.referrals
      .map((item) => {
        if (item.status === SalesCrmStatus.DEMO_DONE || item.status === SalesCrmStatus.DEMO_DONE_LOST) {
          return item.refersDemoAmount;
        }

        if (item.status === SalesCrmStatus.WON) {
          return item.refersAmount + item.refersDemoAmount;
        }

        return 0;
      })
      .reduce((partialSum, a) => partialSum + a, 0);
  }

  constructor(
    public headerService: HeaderAppService,
    private dialogRef: MatDialogRef<ReferredModalComponent>,
    private personalLegajoService: PersonalLegajoServiceBackend,
    private snackBar: SnackBarService,
    private referralsServiceBackend: ReferralsServiceBackend,
  ) {}

  public ngOnInit(): void {
    this.loadReferrals();

    this.referredForm.patchValue({
      personalEmail: this.headerService.userLogged.email,
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public tabChange($event: MatTabChangeEvent): void {
    this.selectedTab = $event.index;
  }

  private async sendReference(): Promise<void> {
    const res = await this.personalLegajoService.insertPersonalLegajosReferir(this.saveReference());
    if (res) {
      this.snackBar.showInfo('Referencia realizada con éxito');
      this.dialogRef.close();
    }
  }

  private loadReferrals(): void {
    this.referralsServiceBackend.getReferrals().subscribe((referrals) => {
      this.referrals = referrals;
    });
  }

  private saveReference(): ReferidoDTO {
    const referredDTO: ReferidoDTO = new ReferidoDTO();
    referredDTO.emailUsuario = this.referredForm.value.personalEmail;
    referredDTO.nombreCompleto = this.referredForm.value.referredName;
    referredDTO.email = this.referredForm.value.referredEmail;
    referredDTO.telefono = this.referredForm.value.referredPhone;
    referredDTO.empresa = this.referredForm.value.referredCompany;
    return referredDTO;
  }
}
