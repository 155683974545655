import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PerfilEmpresaService } from 'src/app/layout/administrador/configuracion/perfil-empresa/perfil-empresa.service';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { LocalidadSearchService } from 'src/app/shared/services/search/localidad-search.service';
import { compare, compareDtoId, domicilioGoogleMaps } from 'src/app/shared/shared-functions';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { FamiliarDTO } from 'src/app/ModelDTO/DTO/familiar.DTO';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { RelacionFamiliar } from '@api/enums/relacion-familiar.enum';
import { FamiliarModelDTO } from 'src/app/ModelDTO/familiar.ModelDTO';

enum ColumnNames {
  Nombre = 'nombre',
  Parentesco = 'parentesco',
  Nacimiento = 'nacimiento',
  Dni = 'dni',
  Acargo = 'acargo',
  Discapacidad = 'discapacidad',
  Pep = 'pep',
  Comentario = 'comentario',
  Accion = 'accion',
}
@Component({
  selector: 'app-datos-familiares',
  templateUrl: './datos-familiares.component.html',
  styleUrls: ['./datos-familiares.component.scss'],
})
export class DatosFamiliaresComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('familiarForm', { static: true }) public familiaForm: NgForm;

  @ViewChild(MatExpansionPanel, { static: true })
  public panelFamiliar: MatExpansionPanel;

  @Input() public personalModel: PersonalModelDTO;
  @Input() public modoEdicion = false;
  @Input() public modoEmpresa = false;

  public familiar: FamiliarDTO;
  public paises: Array<PaisDTO>;
  public pais: PaisDTO;
  public readonly ColumnNames = ColumnNames;
  public readonly compareId = compareDtoId;
  public columns = [
    ColumnNames.Nombre,
    ColumnNames.Parentesco,
    ColumnNames.Nacimiento,
    ColumnNames.Dni,
    ColumnNames.Acargo,
    ColumnNames.Discapacidad,
    ColumnNames.Pep,
    ColumnNames.Comentario,
    ColumnNames.Accion,
  ];
  public dataSource = [];

  public relaciones: Array<string>;

  public relacionFamiliar = RelacionFamiliar;
  public domicilioGoogleMaps = domicilioGoogleMaps;
  public eliminarCmd: ICommand = new Command((value) => this.eliminar(value), new BehaviorSubject(true), true);
  public buscarFamiliarAfipCmd: ICommand = new Command(() => this.buscarFamiliarEnAfip(), new BehaviorSubject(true), true);
  public fechaNacMenorCmd: ICommand = new Command(() => this.evaluarSiEsMenorDeEdad(), new BehaviorSubject(true), true);
  public country: PaisDTO;

  private subs = new Subscription();

  constructor(
    public localidadSearch: LocalidadSearchService,
    public neoModalService: NgxNeoModalMatService,
    private perfilService: PerfilEmpleadoSharedService,
    private perfilEmpresaService: PerfilEmpresaService,
  ) {
    this.nuevoFamiliar();
    this.relaciones = FamiliarModelDTO.getRelacionFamiliar();
    this.relaciones.shift();
    this.paises = new Array<PaisDTO>();
  }

  public async ngOnInit(): Promise<void> {
    this.paises = await this.perfilService.obtenerPaises();
    this.subs.add(
      this.perfilEmpresaService.company$.subscribe((company) => {
        this.country = company?.pais;
      }),
    );
  }

  public ngOnChanges(): void {
    this.dataSource = [...this.personalModel.Familiares];
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  public sortData(sort: Sort): void {
    const data = this.personalModel.Familiares.slice();
    if (!sort.active || !sort.direction?.length) {
      this.personalModel.Familiares = data;
      return;
    }

    this.personalModel.Familiares = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNames.Nombre:
          return compare(a.nombreCompleto, b.nombreCompleto, isAsc);
        case ColumnNames.Dni:
          return compare(a.dni, b.dni, isAsc);
        case ColumnNames.Parentesco:
          return compare(this.relacionFamiliar[a.relacion], this.relacionFamiliar[b.relacion], isAsc);
        case ColumnNames.Nacimiento:
          return compare(a.fechaNacimiento?.getTime(), b.fechaNacimiento?.getTime(), isAsc);
        case ColumnNames.Discapacidad:
          return compare(a.discapacidad, b.discapacidad, isAsc);
        case ColumnNames.Acargo:
          return compare(a.aCargo, b.aCargo, isAsc);
        case ColumnNames.Pep:
          return compare(a.pep, b.pep, isAsc);
        default:
          return 0;
      }
    });
  }

  public editar(familiar: FamiliarDTO): void {
    const familiarClone = new FamiliarDTO();
    familiarClone.PrepareDTO(familiar);
    this.familiar = familiarClone;
    if (this.familiar.domicilio?.localidad?.provincia?.pais?.id > 0) {
      this.pais = this.paises.find((x) => x.id === this.familiar.domicilio.localidad.provincia.pais.id);
    }
    if (!this.familiar.dni) {
      this.familiar.dni = '';
    }
    this.panelFamiliar.open();
  }

  public async eliminar(familiar: FamiliarDTO): Promise<void> {
    const res = await this.neoModalService.decision('PROFILE.FAMILY.DECISION_DELETE_FAMILY_MEMBER');
    if (res.ButtonResponse === AlertButton.Accept) {
      this.personalModel.Familiares = this.personalModel.Familiares.filter((x) => x.dni !== familiar.dni);
    }
    this.reiniciarFamiliar();
  }

  public reiniciarFamiliar(): void {
    this.nuevoFamiliar();
    this.panelFamiliar.close();
  }

  public async agregarFamiliar(familiar: FamiliarDTO): Promise<void> {
    if (this.familiaForm.valid) {
      const hoy = new Date();
      if (familiar.fechaNacimiento > hoy) {
        await this.neoModalService.warning('PROFILE.BIRTH_DATE_CONTROL');
        return;
      }
      const familiarExistente = this.personalModel.Familiares.find((x) => x.dni === this.familiar.dni);
      if (familiarExistente) {
        const updatedFamily = new FamiliarDTO();
        updatedFamily.PrepareDTO(this.familiar);
        this.personalModel.Familiares = this.personalModel.Familiares.map((family) =>
          family.dni === updatedFamily.dni ? updatedFamily : family,
        );
      } else {
        this.familiar.id = (new Date().getTime() & 0x7fffffff) * -1; // truncado a 32bits
        this.personalModel.Familiares = [...this.personalModel.Familiares, this.familiar];
      }
      this.reiniciarFamiliar();
    } else {
      await this.neoModalService.warning('GENERAL.COMPLETE_MANDATORY_DATA');
    }
  }

  public nuevoFamiliar(): void {
    this.familiar = new FamiliarDTO();
    this.familiar.dni = '';
    this.familiar.aCargo = true;
  }

  public paisChanged(pais: PaisDTO): void {
    this.localidadSearch.pais = pais;
  }

  private async buscarFamiliarEnAfip(): Promise<void> {
    if (this.familiar.dni) {
      const res = await this.perfilService.obtenerPersonalBusquedaAfipDNI(this.familiar.dni);
      if (res) {
        const familyMember = new FamiliarDTO();
        familyMember.PrepareDTO(res);
        familyMember.nombreCompleto = `${res.nombre} ${res.apellido}`;
        this.familiar = familyMember;
        this.evaluarSiEsMenorDeEdad();
      } else {
        await this.neoModalService.warning(
          `No se encontraron en AFIP datos con el DNI ${this.familiar.dni}. Cargue manualmente los datos del colaborador.`,
        );
      }
    }
  }

  private evaluarSiEsMenorDeEdad(): void {
    const familiarDTO = this.familiar;
    const today = new Date();
    const tiempoMili = today.getTime() - familiarDTO.fechaNacimiento.getTime();
    const edad = Math.trunc(tiempoMili / (1000 * 60 * 60 * 24 * 365));
    if (edad < 18) {
      this.familiar.aCargo = true;
    } else {
      this.familiar.aCargo = false;
    }
  }
}
