/* eslint-disable no-console */
import { PlatformLocation } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import BlotFormatter from '@enzedonline/quill-blot-formatter2';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import Quill from 'quill';
import { Subscription, filter, map, mergeMap, pairwise, startWith, take } from 'rxjs';
import { DetalleEmpleadoModalComponent } from 'src/app/layout/administrador/colaboradores/nomina/detalle-empleado-modal/detalle-empleado-modal.component';
import {
  companyFeatureAppUpdateReminder,
  companyFeatureWebPushNotifications,
} from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { FCM } from 'src/app/shared/localStorageConstants';
import { NotificationHandlerService } from 'src/app/shared/services/notification-handler.service';
import { WebPushService } from 'src/app/shared/services/web-push.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';
import { NativePushNotificationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/native-push-notification.service';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';
import { UserServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/backend/user.ServiceBackend';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authRequest.DTO';
import { TypeSocial } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/typeSocial.ENUM';
import { PushService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/push/push.service';
import PlainClipboard from 'src/app/lib/plain-clipboard';
import { PosthogService } from 'src/app/lib/posthog.service';
import { HeaderAppService } from './core/header/header-app.service';
import { GlobalSettingsService } from './layout/administrador/configuracion/configuracion-sistema/global-settings.service';
import { PerfilEmpresaService } from './layout/administrador/configuracion/perfil-empresa/perfil-empresa.service';
import { colors } from './shared/colors';
import { appStore, playStore, urlWeb } from './shared/constants';
import { AlertButton } from './shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from './shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { MAT_ICONS } from './shared/mat-icons.constants';
import { ZendeskService } from './shared/zendesk/zendesk.service';
import { WebSocketService } from './shared/services/web-socket.service';

// eslint-disable-next-line no-var
declare var universalLinks: any; // For typecript compilation

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  private subs = new Subscription();
  private modalNavigationWait = false;
  private logged = false;
  private firstNavigaiton: boolean;

  constructor(
    private authenticationService: AuthenticationService,
    private nativePushNotificationService: NativePushNotificationService,
    private pushService: PushService,
    private websocketService: WebSocketService,
    private headerService: HeaderAppService,
    private cordovaService: CordovaService,
    private userService: UserServiceBackend,
    private empresaService: PerfilEmpresaService,
    private swUpdate: SwUpdate,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private location: PlatformLocation,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private featureFlagService: FeatureFlagService,
    private notificationHandlerService: NotificationHandlerService,
    private webPushService: WebPushService,
    private gtmService: GoogleTagManagerService,
    private neoModalService: NgxNeoModalMatService,
    private translateService: TranslateService,
    private adapter: DateAdapter<any>,
    private zendeskService: ZendeskService,
    private globalSettingsService: GlobalSettingsService,
    // keep this import to init config
    private posthogService: PosthogService,
  ) {
    this.subs.add(
      this.authenticationService.loggedEvent$.subscribe(async (authResponseDTO) => {
        // This event occurs on a new Sign In or on a Page Refresh
        if (authResponseDTO) {
          await this.headerService.initializeActualUser();
          this.empresaService.loadCompanyFromStorage();
          this.logged = true;
          this.featureFlagService.loadFlagsFromLocalStorage();
          this.headerService.loadUserEntity();
          this.globalSettingsService.initSettings();
        } else {
          this.logged = false;
        }
      }),
    );

    this.router.events
      .pipe(
        filter((x) => x instanceof NavigationEnd), // filter out only the navigation end event
        startWith(null), // set an initial value
        pairwise(), // emit both the previous and current value
        take(2),
      )
      .subscribe((event) => {
        this.firstNavigaiton = event[0] === null;
      });

    this.subs.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map((route) => {
            let result = route;
            while (result.firstChild) result = result.firstChild;
            return result;
          }),
          filter((route) => route.outlet === 'primary'),
          mergeMap((route) => route.data),
        )
        .subscribe((event) => this.headerService.setPageTitle(event.title ?? '')),
    );

    this.subs.add(
      this.router.events.subscribe((event: NavigationEnd | NavigationStart) => {
        if (event instanceof NavigationStart) {
          this.modalNavigationWait = true;
        } else if (event instanceof NavigationEnd) {
          if (environment.production) {
            const gtmTag = {
              event: 'page',
              pageName: event.url,
            };
            this.gtmService
              .pushTag(gtmTag)
              .then()
              .catch(() => {});
          }

          this.modalNavigationWait = false;
          this.checkUserModalOpen();
          if (event.url !== event.urlAfterRedirects) {
            // we have to do this to prevent back navigation on paste url
            this.checkModalBack();
          }
        }
      }),
    );

    // The tenantId param is useful for people that receive links and have access to different companies
    this.activatedRoute.queryParams.subscribe((queryParams) => {
      const tenantId = queryParams?.tenantId;
      if (tenantId && this.headerService.IsLogged()) {
        this.authenticationService.changeTenant(tenantId);
      }
    });

    this.subs.add(
      this.featureFlagService.flags$.subscribe((flagsData) => {
        if (
          FeatureFlagHelper.featureOn(companyFeatureWebPushNotifications, flagsData.flags) &&
          this.cordovaService.isAngularApp &&
          this.logged
        ) {
          this.webPushService.initWebNotification();
        }
      }),
    );
  }

  public ngOnInit(): void {
    this.clearSignaturePreviewPDFs();
    this.zendeskService.call('messenger', 'hide');

    this.headerService.outdatedApp$.subscribe(async (outdated) => {
      if (outdated) {
        const store = this.cordovaService.isAndroidApp ? 'Play Store' : 'App Store';
        const res = await this.neoModalService.decision(
          this.translateService.instant('GENERAL.OUTDATED_APP_QUESTION', {
            store,
          }),
          '',
          '',
          AlertButton.Cancel,
          this.translateService.instant('GENERAL.UPDATE'),
          this.translateService.instant('GENERAL.CANCEL'),
          this.translateService.instant('GENERAL.OUTDATED_APP'),
        );
        if (res.ButtonResponse === AlertButton.Accept) {
          const link = this.cordovaService.isAndroidApp ? playStore : appStore;
          this.cordovaService.openLink(link);
        }
      }
    });

    this.dialog.afterOpened.subscribe(() => {
      if (!this.modalQueryParam()) {
        this.headerService.isModalOpen = true;
        const newUrl = this.addModalParam(this.location.href);
        this.location.pushState('modal', '', newUrl);
      }
    });
    this.dialog.afterAllClosed.subscribe(() => {
      if (this.modalQueryParam()) {
        setTimeout(() => {
          this.checkModalBack();
        }, 50);
      }
    });
    this.location.onPopState(() => {
      this.dialog.closeAll();
      this.headerService.isModalOpen = false;
    });

    this.quillRegistration();
    this.matIconRegistration();

    this.initSignalR();

    this.swUpdate.versionUpdates.subscribe(() => {
      this.swUpdate.activateUpdate().then(() => this.authenticationService.reloadPage());
    });

    // Cordova **************************************************************
    if (this.cordovaService.isCordovaApp) {
      this.initializeCordovaPlugins();
    }

    this.onResume();

    this.subs.add(
      this.translateService.onLangChange.subscribe(() => {
        this.adapter.setLocale(this.headerService.currentLanguage);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  private initializeCordovaPlugins(): void {
    if (this.cordovaService.isIOSApp) {
      document.body.classList.add('ios');
      this.cordovaService.setStatusBarBlack();
    } else if (this.cordovaService.isAndroidApp) {
      this.cordovaService.backgroundColorByHexString(colors.color_primary);
    }

    this.nativePushNotificationService.init();

    this.subs.add(
      this.nativePushNotificationService.onRegistration.subscribe((data) => {
        if (data) {
          // const id = localStorage.getItem('fcm');
          // if (data.registrationId !== id) {
          localStorage.setItem(FCM, data.registrationId);
          // console.log('New deviceId: ', data.registrationId, ' - currentUser: ', this.authenticationService.authResponseDTO);
          if (this.authenticationService.authResponseDTO?.token) {
            const user = new AuthRequestDTO();
            user.tokenDeviceId = data.registrationId;
            user.typeSocial = this.cordovaService.isAndroidApp ? TypeSocial.FIREBASE : TypeSocial.APPLE_DEVICEID;
            user.applicationName = environment.packageName;
            this.userService.insertUserDeviceID(user);
          }
          // }
        }
      }),
    );

    this.subs.add(
      this.nativePushNotificationService.onNotification.subscribe((notification) => {
        if (notification) {
          if (notification.additionalData?.customData) {
            this.notificationHandlerService.handlePushNotification(notification);
          }
        }
      }),
    );

    this.subs.add(
      this.nativePushNotificationService.onError.subscribe((error) => {
        if (error) {
          console.error('Native push error', error);
        }
      }),
    );

    this.subs.add(
      (universalLinks as any)?.subscribe(null, (eventData: any) => {
        if (eventData?.url?.length) {
          this.router.navigateByUrl(eventData.url.replace(urlWeb, ''));
        }
      }),
    );

    this.subs.add(
      this.featureFlagService.flags$
        .pipe(
          filter((flags) => flags.flags?.size > 0),
          take(1),
        )
        .subscribe((flagsData) => {
          if (FeatureFlagHelper.featureOn(companyFeatureAppUpdateReminder, flagsData.flags)) {
            (window as any).plugins?.updatePlugin?.update(
              () => {
                // success callback
              },
              (err: Error) => {
                console.error('error updating', err);
              },
              {
                IOS: {
                  type: 'MIXED',
                  flexibleUpdateStalenessDays: 2,
                  immediateUpdateStalenessDays: 5,
                },
                ANDROID: {
                  type: 'MIXED',
                  flexibleUpdateStalenessDays: 2,
                  immediateUpdateStalenessDays: 5,
                },
              },
            );
          }
        }),
    );
  }

  private addModalParam(url: string): string {
    return url.includes('?') ? `${url}&modal=true` : `${url}?modal=true`;
  }

  private modalQueryParam(): boolean {
    return this.location.href.includes('modal=true');
  }

  private userModalQueryParamid(): number {
    const urlParam = this.location.href.match(/(?:userModal=)(\d+)/);
    if (urlParam?.length > 1) {
      return Number(urlParam[1]);
    }
    return -1;
  }

  private initSignalR(): void {
    if (this.authenticationService.authResponseDTO) {
      this.pushService.start(this.authenticationService.authResponseDTO);
    }
  }

  private checkModalBack(): void {
    const extras = this.router.getCurrentNavigation()?.extras?.state;
    if (!this.modalNavigationWait && this.modalQueryParam() && !extras?.userModal && !this.firstNavigaiton) {
      this.location.back();
    }
  }

  private async checkUserModalOpen(): Promise<void> {
    const teammateId = this.userModalQueryParamid();
    const openModals = this.dialog.openDialogs;
    if (teammateId > 0 && openModals?.length === 0) {
      const menuItem = Number(this.activatedRoute.snapshot.queryParamMap.get('userMenu') ?? 0);
      this.dialog.open(DetalleEmpleadoModalComponent, {
        panelClass: ['full-size-sm-1200-lg-modal', 'full-height-sm-700-lg-modal'],
        disableClose: false,
        data: { teammateId, menuItem },
      });
    }
  }

  private quillRegistration(): void {
    Quill.register('modules/blotFormatter', BlotFormatter);
    Quill.register(
      {
        'modules/clipboard': PlainClipboard,
      },
      true,
    );
  }

  private matIconRegistration(): void {
    MAT_ICONS.forEach((icon: string) => {
      let assetUrl = `assets/img/mat-icons/${icon}.svg`;
      if (this.cordovaService.isAngularApp) {
        assetUrl = `/${assetUrl}`;
      } else {
        assetUrl = `./${assetUrl}`;
      }
      this.matIconRegistry.addSvgIcon(icon, this.domSanitizer.bypassSecurityTrustResourceUrl(assetUrl));
    });
  }

  private onResume(): void {
    if (this.cordovaService.isCordovaApp) {
      document.addEventListener(
        'resume',
        () => {
          if (this.cordovaService.isIOSApp && !(window as any).angularBootstrapped) {
            // Previene un problema en iOS que hacia que no cargue luego de varias horas en background
            window.location.reload();
          } else {
            this.initSignalR();
            this.websocketService.reconnect();
          }
        },
        false,
      );
    }
  }

  private clearSignaturePreviewPDFs(): void {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith('signaturePreviewPDF_')) {
        localStorage.removeItem(key);
      }
    });
  }
}
