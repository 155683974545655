import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatCardModule } from '@angular/material/card';
import { SaldoCuentaCorrienteDTO } from '@api/interfaces/saldo-cuenta-corriente.interface';
import { PersonalLegajoBackendService } from '@api/services/personal-legajo-backend.service';
import { BehaviorSubject, filter, map, Observable, switchMap, tap } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { TranslateService } from '@ngx-translate/core';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { featureSaldoCC } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { NgxNeoPipesModule } from 'src/app/shared/lib/ngx-neo-pipes/ngx-neo-pipes.module';

@Component({
  selector: 'app-saldo-cc',
  templateUrl: './saldo-cc.component.html',
  styleUrls: ['./saldo-cc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, MatCardModule, NgxNeoPipesModule],
})
export class SaldoCcComponent implements OnChanges {
  @Input() public ausencia: AusenciaDTO;
  @Input() public personalLegajo: PersonalLegajoBasicoDTO;
  @Output() public availableDays = new EventEmitter<number>();

  public messages$: Observable<{ balance: string; warning: SaldoCuentaCorrienteDTO }>;
  private permisoSaldoCC = false;
  private balanceLoader$ = new BehaviorSubject<void>(undefined);

  constructor(
    private personalLegajoService: PersonalLegajoBackendService,
    private featureFlagService: FeatureFlagService,
    private translateService: TranslateService,
    private appService: HeaderAppService,
  ) {
    this.featureFlagService.flags$.pipe(takeUntilDestroyed()).subscribe(({ flags }) => {
      this.permisoSaldoCC = FeatureFlagHelper.featureOn(featureSaldoCC, flags);
      this.balanceLoader$.next();
    });

    this.messages$ = this.balanceLoader$.pipe(
      filter(() => this.permisoSaldoCC && (this.personalLegajo?.id ?? 0) > 0 && (this.ausencia?.id ?? 0) > 0),
      switchMap(() => this.personalLegajoService.getPersonalLegajosIdSaldoAusenciaIDAusencia(this.personalLegajo.id, this.ausencia.id)),
      tap((balance) => this.availableDays.emit(balance.disponible)),
      map((balance: SaldoCuentaCorrienteDTO) => ({
        balance: this.translateService.instant('TIMEOFF.PERSONAL_ABSENCE_BALANCE', {
          balance: balance.porPolitica,
          available: balance.disponible,
          used: balance.usadoEnCiclo,
        }),
        warning:
          balance.disponible < 0 && !this.appService.modoEmpresa
            ? this.translateService.instant('TIMEOFF.PERSONAL_ABSENCE_BALANCE_NEGATIVE')
            : null,
      })),
    );
  }

  public ngOnChanges(): void {
    this.balanceLoader$.next();
  }
}
