import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

export const TerminalGuard: CanActivateFn = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
  const router = inject(Router);
  const headerService = inject(HeaderAppService);
  if (headerService.IsTerminal) {
    return state.url.startsWith('/clock-in-out-terminal-qr') ? true : router.parseUrl('/clock-in-out-terminal-qr');
  }
  return true;
};
