<div class="d-flex flex-column flex-grow-1 mt-2">
  <mat-accordion class="mb-2">
    <app-lista-posibles-ausencias
      [tituloAusencia]="'TIMEOFF.LICENSES' | translate"
      [descripcionAusencia]="'TIMEOFF.POSSIBLE_LICENSES' | translate"
      [posiblesAusencias]="posiblesLicencias"
    >
    </app-lista-posibles-ausencias>
    <app-lista-posibles-ausencias
      [tituloAusencia]="'TIMEOFF.SICK_LEAVES' | translate"
      [descripcionAusencia]="'TIMEOFF.POSSIBLE_LICENSES' | translate"
      [posiblesAusencias]="posiblesLicenciasE"
    >
    </app-lista-posibles-ausencias>
    <app-lista-posibles-ausencias
      [tituloAusencia]="'TIMEOFF.TYPE.VACATION' | translate"
      [descripcionAusencia]="'TIMEOFF.POSSIBLE_VACATION' | translate"
      [posiblesAusencias]="posiblesVacaciones"
    >
    </app-lista-posibles-ausencias>
    <app-lista-posibles-ausencias
      [tituloAusencia]="'TIMEOFF.ART_LICENSES' | translate"
      [descripcionAusencia]="'TIMEOFF.POSSIBLE_LICENSES' | translate"
      [posiblesAusencias]="posiblesLicenciasART"
    >
    </app-lista-posibles-ausencias>
    <app-lista-posibles-ausencias
      [tituloAusencia]="'TIMEOFF.TYPE.REMOTE_WORK' | translate"
      [descripcionAusencia]="'TIMEOFF.POSSIBLE_LICENSES' | translate"
      [posiblesAusencias]="posiblesLicenciasTR"
    >
    </app-lista-posibles-ausencias>
    <!--app-sanciones-empleado [idPersonal]="personalModel.Legajo.id"></app-sanciones-empleado-->
    <app-entregas-empleado [idPersonal]="personalModel.Legajo.id"></app-entregas-empleado>
    <app-capacitaciones-empleado [idPersonal]="personalModel.Legajo.id"></app-capacitaciones-empleado>
  </mat-accordion>
</div>
