import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgForm } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Sort } from '@angular/material/sort';
import { TipoUnidadDuracionModalidad } from '@api/enums/tipo-unidad-duracion-modalidad.enum';
import { HealthInsuranceCompanyDTO } from '@api/interfaces/health-insurance-company.interface';
import { PaisBackendService } from '@api/services/pais-backend.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subscription, combineLatest, from, timer } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { BankAccountTypeDTO } from 'src/app/ModelDTO/DTO/bankAccountType.DTO';
import { BeneficioPersonalDTO } from 'src/app/ModelDTO/DTO/beneficioPersonal.DTO';
import { BusinessNameDTO } from 'src/app/ModelDTO/DTO/businessName.DTO';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { CurrencyDTO } from 'src/app/ModelDTO/DTO/currency.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { OficinaDTO } from 'src/app/ModelDTO/DTO/oficina.DTO';
import { PaymentModalityType } from 'src/app/ModelDTO/DTO/paymentModalityType.ENUM';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalBeneficioDTO } from 'src/app/ModelDTO/DTO/personalBeneficio.DTO';
import { PersonalBeneficioAusenciaDTO } from 'src/app/ModelDTO/DTO/personalBeneficioAusencia.DTO';
import { PersonalCategoriaDTO } from 'src/app/ModelDTO/DTO/personalCategoria.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalSubcategoriaDTO } from 'src/app/ModelDTO/DTO/personalSubcategoria.DTO';
import { PuestoDTO } from 'src/app/ModelDTO/DTO/puesto.DTO';
import { TimeRangeDTO } from 'src/app/ModelDTO/DTO/timeRange.DTO';
import { WorkDayDTO } from 'src/app/ModelDTO/DTO/workDay.DTO';
import { WorkLocationType } from 'src/app/ModelDTO/DTO/workLocationType.ENUM';
import { LegajoModelDTO } from 'src/app/ModelDTO/legajo.ModelDTO';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { PuestoModelDTO } from 'src/app/ModelDTO/puesto.ModelDTO';
import { AusenciaServiceBackend } from 'src/app/ServiceBackend/ausencia.ServiceBackend';
import { BankAccountTypeServiceBackend } from 'src/app/ServiceBackend/bankAccountType.ServiceBackend';
import { BusinessNameServiceBackend } from 'src/app/ServiceBackend/businessName.ServiceBackend';
import { CurrencyServiceBackend } from 'src/app/ServiceBackend/currency.ServiceBackend';
import { PersonalLegajoServiceBackend } from 'src/app/ServiceBackend/personalLegajo.ServiceBackend';
import { WorkDayServiceBackend } from 'src/app/ServiceBackend/workDay.ServiceBackend';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { AreasService } from 'src/app/layout/administrador/configuracion/areas/areas.service';
import { BeneficiosAdicionalesService } from 'src/app/layout/administrador/configuracion/beneficios-adicionales/beneficios-adicionales.service';
import { CargosService } from 'src/app/layout/administrador/configuracion/cargos/cargos.service';
import { ConvenioColectivoService } from 'src/app/layout/administrador/configuracion/convenio-colectivo/convenio-colectivo.service';
import { TipoModalidadService } from 'src/app/layout/administrador/configuracion/tipo-modalidad/tipo-modalidad.service';
import { PerfilEmpresaService } from 'src/app/layout/administrador/configuracion/perfil-empresa/perfil-empresa.service';
import { countryFeatureHayGrade, featureInformacionContractual } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { detailExpand } from 'src/app/shared/lib/ngx-neo-components-mat/shared/animations/keyframes';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { NgxDatePipe } from 'src/app/shared/lib/ngx-neo-pipes/ngx-date.pipe';
import { TimePadPipe } from 'src/app/shared/lib/ngx-neo-pipes/timePad.pipe';
import { BancoSearchService } from 'src/app/shared/services/search/banco-search.service';
import { CargoSearchService } from 'src/app/shared/services/search/cargo-search.service';
import { HealthInsuranceSearchService } from 'src/app/shared/services/search/health-insurance-search.service';
import { PersonalSearchService } from 'src/app/shared/services/search/personal-search.service';
import { compare, compareDtoId } from 'src/app/shared/shared-functions';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';

interface SelectedDays {
  day: string;
  checked: boolean;
}

enum ColumnNamesBeneficios {
  Beneficio = 'Beneficio',
  Observacion = 'Observacion',
  Desde = 'Desde',
}

enum ColumnNamesBeneficiosAusencia {
  Ausencia = 'Ausencia',
  ObservacionAusencia = 'ObservacionAusencia',
  DesdeAusencia = 'DesdeAusencia',
}

enum ColumnNamesPuestos {
  Area = 'area',
  Sector = 'sector',
  Cargo = 'cargo',
  Supervisor = 'supervisor',
  Accion = 'accion',
}

enum ColumnNamesWorkDays {
  Name = 'NAME',
  Total = 'DURATION',
  Computed = 'COMPUTED_DURATION',
  Actions = 'ACTION',
  Expand = 'expand',
}
@Component({
  selector: 'app-datos-contratacion',
  templateUrl: './datos-contratacion.component.html',
  styleUrls: ['./datos-contratacion.component.scss'],
  animations: [detailExpand],
  providers: [TimePadPipe, NgxDatePipe],
})
export class DatosContratacionComponent implements OnInit, OnDestroy {
  @ViewChild('contratacionForm', { static: true }) public contratacionForm: NgForm;
  @ViewChild('bancoForm', { static: true }) public bancoForm: NgForm;
  @ViewChild('workDayForm', { static: true }) public workDayForm: NgForm;
  @ViewChild('beneficiosForm', { static: true }) public beneficiosForm: NgForm;
  @ViewChild('beneficiosAusenciaForm', { static: true })
  public beneficiosAusenciaForm: NgForm;
  @ViewChild('representacionForm', { static: true }) public representacionForm: NgForm;
  @ViewChild('puestoForm', { static: false }) public puestoForm: NgForm;

  @ViewChild('panelPuesto', { static: false }) public panelPuesto: MatExpansionPanel;
  @Input() public modoEdicion = false;
  @Input() public modoEmpresa = false;
  @Input() public get personalModel(): PersonalModelDTO {
    return this.personalModelDTO;
  }

  public readonly compareId = compareDtoId;
  public readonly ColumnNamesPuestos = ColumnNamesPuestos;
  public readonly ColumnNamesBeneficios = ColumnNamesBeneficios;
  public readonly ColumnNamesBeneficiosAusencia = ColumnNamesBeneficiosAusencia;
  public readonly ColumnNamesWorkDays = ColumnNamesWorkDays;
  public modalidades: ModalidadContractualDTO[];
  public showDescriptionPanel = false;
  public ausenciaXConvenio: { [key: number]: AusenciaDTO[] };
  public categoriaSelected: PersonalCategoriaDTO;
  public subcategoriaSelected: PersonalSubcategoriaDTO;
  public cargos: CargoDTO[] = [];
  public oficinas: OficinaDTO[] = [];
  public businessNames$: Observable<BusinessNameDTO[]>;
  public catEmpty = new PersonalCategoriaDTO();
  public subEmpty = new PersonalSubcategoriaDTO();
  public newWorkDay: WorkDayDTO;
  public today = new Date();
  public beneficiosPersonales: Array<BeneficioPersonalDTO>;
  public nuevoBeneficio: PersonalBeneficioDTO;
  public nuevoBeneficioAusencia: PersonalBeneficioAusenciaDTO;
  public readonly workLocationTypes = LegajoModelDTO.getWorkLocationType().slice(1);
  public readonly workLocationEnum = WorkLocationType;
  public readonly paymentModalityTypes = LegajoModelDTO.getPaymentModalityType().slice(1);
  public readonly paymentModalityEnum = PaymentModalityType;
  public readonly countryFeatureHayGrade = countryFeatureHayGrade;
  public bankAccountPattern = '^[a-zA-Z0-9]+$';

  public displayedColumnsBeneficios: string[] = [
    ColumnNamesBeneficios.Beneficio,
    ColumnNamesBeneficios.Observacion,
    ColumnNamesBeneficios.Desde,
    ' ',
  ];

  public displayedColumnsBeneficiosAusencia: string[] = [
    ColumnNamesBeneficiosAusencia.Ausencia,
    ColumnNamesBeneficiosAusencia.ObservacionAusencia,
    ColumnNamesBeneficiosAusencia.DesdeAusencia,
    ' ',
  ];

  public displayedColumnsPuestos = [
    ColumnNamesPuestos.Area,
    ColumnNamesPuestos.Sector,
    ColumnNamesPuestos.Cargo,
    ColumnNamesPuestos.Supervisor,
    ColumnNamesPuestos.Accion,
  ];

  public displayedColumnsWorkDays = [
    ColumnNamesWorkDays.Name,
    ColumnNamesWorkDays.Total,
    ColumnNamesWorkDays.Computed,
    ColumnNamesWorkDays.Expand,
    ColumnNamesWorkDays.Actions,
  ];

  public puesto: PuestoModelDTO;
  public puestoSeleccionado: PuestoDTO;
  public expandedElement: WorkDayDTO | null;
  public currencies$: Observable<CurrencyDTO[]>;
  public filteredCurrencies: CurrencyDTO[] = [];
  public accountTypes$: Observable<BankAccountTypeDTO[]>;
  public workDays$: Observable<WorkDayDTO[]>;
  public areas$: Observable<PersonalAreaDTO[]>;

  public agregarBeneficioCmd: ICommand = new Command(() => this.agregarBeneficio(), new BehaviorSubject(true), false);
  public agregarBeneficioAusenciaCmd: ICommand = new Command(() => this.agregarBeneficioAusencia(), new BehaviorSubject(true), false);
  public eliminarBeneficioCmd: ICommand = new Command((value) => this.eliminarBeneficio(value), new BehaviorSubject(true), false);
  public eliminarBeneficioAusenciaCmd: ICommand = new Command(
    (value) => this.eliminarBeneficioAusencia(value),
    new BehaviorSubject(true),
    false,
  );
  public consultarSuperiorCmd: ICommand = new Command((value) => this.consultarSuperiorSugerido(value), new BehaviorSubject(true), true);
  public eliminarPuestoCmd: ICommand = new Command((value) => this.eliminarPuesto(value), new BehaviorSubject(true), true);
  public creatJobCertificateCmd: ICommand = new Command((value) => this.createJobCertificate(value), new BehaviorSubject(true), true);

  public get hideContractualInformation(): boolean {
    return this.permissionContractualInformation && !this.modoEmpresa;
  }

  public get disabledWorkMode(): boolean {
    return this.personalModel.Legajo.workDays.length > 0 || !this.modoEdicion || !this.modoEmpresa;
  }

  public set personalModel(value: PersonalModelDTO) {
    this.personalModelDTO = value;
    if (this.personalModelDTO.Id > 0) {
      this.getAbsences();
      this.updateWorkingModality();
    }
  }

  private permissionContractualInformation: boolean;
  private personalModelDTO = new PersonalModelDTO(new PersonalDTO());
  private subsc = new Subscription();
  constructor(
    public personalSearch: PersonalSearchService,
    public cargoSearch: CargoSearchService,
    public bancosSearch: BancoSearchService,
    public healthInsuranceSearch: HealthInsuranceSearchService,
    private perfilService: PerfilEmpleadoSharedService,
    private cargosService: CargosService,
    private beneficioPersonalService: BeneficiosAdicionalesService,
    private neoModalService: NgxNeoModalMatService,
    private oficinasService: PerfilEmpresaService,
    private ausenciasServiceBackend: AusenciaServiceBackend,
    private currencyBackend: CurrencyServiceBackend,
    private modalidadService: TipoModalidadService,
    @Inject(DOCUMENT) private document: Document,
    private banckAccountTypeBackend: BankAccountTypeServiceBackend,
    private featureFlagService: FeatureFlagService,
    private workDayService: WorkDayServiceBackend,
    private translate: TranslateService,
    private timePadPipe: TimePadPipe,
    private personalServiceBackend: PersonalLegajoServiceBackend,
    private translateService: TranslateService,
    private ngxDatePipe: NgxDatePipe,
    private snackBar: SnackBarService,
    private convenioColectivoService: ConvenioColectivoService,
    private businessNameService: BusinessNameServiceBackend,
    private areaService: AreasService,
    private countryApiService: PaisBackendService,
  ) {
    this.ausenciaXConvenio = {};
    this.puesto = new PuestoModelDTO(new PuestoDTO());
    this.beneficiosPersonales = new Array<BeneficioPersonalDTO>();
    this.nuevoBeneficio = new PersonalBeneficioDTO();
    this.nuevoBeneficioAusencia = new PersonalBeneficioAusenciaDTO();
    this.modalidades = new Array<ModalidadContractualDTO>();
    this.categoriaSelected = new PersonalCategoriaDTO();
    this.subcategoriaSelected = new PersonalSubcategoriaDTO();
    this.currencies$ = combineLatest([
      from(this.currencyBackend.getCurrency()),
      this.countryApiService.getPaisesId(this.oficinasService.company.pais.id),
    ]).pipe(
      takeUntilDestroyed(),
      map(([currencies, country]) => {
        const countryCurrencies = currencies.filter((currency) =>
          country.currencies?.some((countryCurrency) => countryCurrency.id === currency.id),
        );

        return (countryCurrencies ?? []).concat(
          (currencies.filter((currency) => !country.currencies?.some((countryCurrency) => countryCurrency.id === currency.id)) ?? []).sort(
            (a, b) => a.name.localeCompare(b.name),
          ),
        );
      }),
      startWith([]),
    );
  }

  public readonly currencyLabelGetter = (item: CurrencyDTO): string => `(${item.display}) ${item.name}`;

  public async ngOnInit(): Promise<void> {
    this.businessNames$ = this.businessNameService.getBusinessNames();
    this.healthInsuranceSearch.country = this.oficinasService.company.pais;

    this.subsc.add(
      this.featureFlagService.flags$.subscribe(({ flags }) => {
        this.permissionContractualInformation = FeatureFlagHelper.featureOn(featureInformacionContractual, flags);
      }),
    );

    this.accountTypes$ = from(this.banckAccountTypeBackend.getBankAccountType());
    this.workDays$ = from(this.workDayService.getWorkDays());
    this.areas$ = from(this.areaService.obtenerAreas());
    this.nuevoPuesto();
    if (!this.modoEmpresa) {
      this.displayedColumnsPuestos.pop();
    }

    this.categoriaSelected.id = this.personalModel.Legajo.categoriaContratacion.id;
    this.categoriaSelected.nombre = this.personalModel.Legajo.categoriaContratacion.nombre;

    this.subcategoriaSelected.id = this.personalModel.Legajo.subcategoriaContratacion.id;
    this.subcategoriaSelected.nombre = this.personalModel.Legajo.subcategoriaContratacion.nombre;

    [this.cargos, this.beneficiosPersonales, this.oficinas, this.modalidades] = await Promise.all([
      this.cargosService.obtenerCargos(),
      this.beneficioPersonalService.obtenerBeneficios(),
      this.oficinasService.obtenerOficinas(),
      this.modalidadService.obtenerModalidadesContractuales(),
    ]);

    this.subsc.add(
      this.contratacionForm.statusChanges.subscribe(() => {
        this.perfilService.updateStep3Status(this.contratacionForm.valid && this.bancoForm.valid && this.representacionForm.valid);
      }),
    );

    // Superior sugerido
    // await this.consultarSuperiorSugerido();
    // Lo comente porque esta provocando forzadamente que aparezca un superior cuando no lo tiene
    // Si aparece un caso donde sea necesario, vemos de descomentarlo y contemplar alguna condición adicional

    const tiposAusenciasPersonal = await this.ausenciasServiceBackend.getAusenciasPersonalLegajoIdTipos(
      this.personalModel.LegajoModel.Id,
      false,
    );
    this.ausenciaXConvenio = tiposAusenciasPersonal?.reduce((rv, tipoAusencia) => {
      // eslint-disable-next-line no-param-reassign
      (rv[tipoAusencia.convenio.id] = rv[tipoAusencia.convenio.id] || []).push(tipoAusencia);
      return rv;
    }, {});
  }

  public ngOnDestroy(): void {
    this.subsc.unsubscribe();
  }

  public verDetallePuesto(puesto: PuestoDTO): void {
    this.puestoSeleccionado = puesto;
    if (this.puestoSeleccionado) {
      timer(10)
        .pipe(take(1))
        .subscribe(() =>
          this.document.getElementById('top-container')?.scrollIntoView({
            behavior: 'smooth',
          }),
        );
    }
  }

  public sortData(sort: Sort): void {
    const dataPuesto = this.personalModel.Legajo.puestos.slice();
    const dataBeneficios = this.personalModel.LegajoModel.Beneficios.slice();
    const dataBeneficiosAusencia = this.personalModel.LegajoModel.BeneficiosAusencia.slice();
    if (!sort.active || !sort.direction?.length) {
      this.personalModel.Legajo.puestos = dataPuesto;
      this.personalModel.LegajoModel.Beneficios = dataBeneficios;
      this.personalModel.LegajoModel.BeneficiosAusencia = dataBeneficiosAusencia;
      return;
    }

    this.personalModel.Legajo.puestos = dataPuesto.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNamesPuestos.Area:
          return compare(a.area.nombre, b.area.nombre, isAsc);
        case ColumnNamesPuestos.Cargo:
          return compare(a.cargo.nombre, b.cargo.nombre, isAsc);
        case ColumnNamesPuestos.Sector:
          return compare(a.sector.nombre, b.sector.nombre, isAsc);
        case ColumnNamesPuestos.Supervisor:
          return compare(a.superior.nombreCompleto, b.superior.nombreCompleto, isAsc);
        default:
          return 0;
      }
    });
    this.personalModel.LegajoModel.Beneficios = dataBeneficios.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNamesBeneficios.Beneficio:
          return compare(a.beneficio.nombre, b.beneficio.nombre, isAsc);
        case ColumnNamesBeneficios.Observacion:
          return compare(a.observacion, b.observacion, isAsc);
        case ColumnNamesBeneficios.Desde:
          return compare(a.fechaDesde?.getTime(), b.fechaDesde?.getTime(), isAsc);
        default:
          return 0;
      }
    });
    this.personalModel.LegajoModel.BeneficiosAusencia = dataBeneficiosAusencia.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNamesBeneficiosAusencia.Ausencia:
          return compare(a.ausencia.nombre, b.ausencia.nombre, isAsc);
        case ColumnNamesBeneficiosAusencia.ObservacionAusencia:
          return compare(a.observacion, b.observacion, isAsc);
        case ColumnNamesBeneficiosAusencia.DesdeAusencia:
          return compare(a.fechaDesde?.getTime(), b.fechaDesde?.getTime(), isAsc);
        default:
          return 0;
      }
    });
  }

  public onCategoriaChange(data: PersonalCategoriaDTO): void {
    this.personalModel.Legajo.categoriaContratacion.id = data.id;
    this.personalModel.Legajo.categoriaContratacion.nombre = data.nombre;
  }

  public onSubcategoriaChange(data: PersonalSubcategoriaDTO): void {
    this.personalModel.Legajo.subcategoriaContratacion.id = data.id;
    this.personalModel.Legajo.subcategoriaContratacion.nombre = data.nombre;
  }

  public agregarBeneficio(): void {
    if (this.beneficiosForm.valid && this.nuevoBeneficio.beneficio.id) {
      this.personalModel.LegajoModel.Beneficios.push(this.nuevoBeneficio);
      this.personalModel.LegajoModel.Beneficios = [...this.personalModel.LegajoModel.Beneficios];
      this.nuevoBeneficio = new PersonalBeneficioDTO();
    } else {
      this.beneficiosForm.control.markAllAsTouched();
      this.neoModalService.warning('GENERAL.COMPLETE_MANDATORY_DATA');
    }
  }

  public agregarBeneficioAusencia(): void {
    if (this.beneficiosAusenciaForm.valid && this.nuevoBeneficioAusencia.ausencia.id) {
      this.personalModel.LegajoModel.BeneficiosAusencia.push(this.nuevoBeneficioAusencia);
      this.personalModel.LegajoModel.BeneficiosAusencia = [...this.personalModel.LegajoModel.BeneficiosAusencia];
      this.nuevoBeneficioAusencia = new PersonalBeneficioAusenciaDTO();
    } else {
      this.beneficiosAusenciaForm.control.markAllAsTouched();
      this.neoModalService.warning('GENERAL.COMPLETE_MANDATORY_DATA');
    }
  }

  public eliminarBeneficio(ben: PersonalBeneficioDTO): void {
    const index = this.personalModel.LegajoModel.Beneficios.indexOf(ben);
    this.personalModel.LegajoModel.Beneficios.splice(index, 1);
    this.personalModel.LegajoModel.Beneficios = [...this.personalModel.LegajoModel.Beneficios];
  }

  public eliminarBeneficioAusencia(ben: PersonalBeneficioAusenciaDTO): void {
    const index = this.personalModel.LegajoModel.BeneficiosAusencia.indexOf(ben);
    this.personalModel.LegajoModel.BeneficiosAusencia.splice(index, 1);
    this.personalModel.LegajoModel.BeneficiosAusencia = [...this.personalModel.LegajoModel.BeneficiosAusencia];
  }

  public async eliminarPuesto(puesto: PuestoDTO): Promise<void> {
    const res = await this.neoModalService.decision('¿Estás seguro que deseas eliminar el puesto?');
    if (res.ButtonResponse === AlertButton.Accept) {
      const index = this.personalModel.LegajoModel.Puestos.indexOf(puesto);
      this.personalModel.LegajoModel.Puestos.splice(index, 1);
      this.personalModel.LegajoModel.Puestos = [...this.personalModel.LegajoModel.Puestos];
      this.reiniciarPuesto();
    }
  }

  public async agregarPuesto(): Promise<void> {
    if (!this.puestoValido()) {
      return;
    }

    const puestoDTO = this.puesto.getEntityDTO();
    const puestoExistente = this.personalModel.LegajoModel.Puestos.find((x) => x.id === puestoDTO.id);
    if (puestoExistente) {
      puestoExistente.superior = new PersonalLegajoBasicoDTO();
      puestoExistente.PrepareDTO(puestoDTO);
      if (!puestoDTO.superior) {
        puestoExistente.superior = null;
      }
    } else {
      puestoDTO.id = (new Date().getTime() & 0x7fffffff) * -1; // truncado a 32bits
      this.personalModel.Legajo.puestos = [...this.personalModel.Legajo.puestos, puestoDTO];
    }

    this.reiniciarPuesto();
  }

  public editarPuesto(puesto: PuestoDTO): void {
    const puestoClone = new PuestoDTO();
    puestoClone.PrepareDTO(puesto);
    this.puesto = new PuestoModelDTO(puestoClone);
    this.panelPuesto.open();
  }

  public switchPositionVisibility(puesto: PuestoDTO): void {
    const puestoClone = new PuestoDTO();
    puestoClone.PrepareDTO(puesto);
    this.puesto = new PuestoModelDTO(puestoClone);
    this.puesto.Oculto = !this.puesto.Oculto;
    this.agregarPuesto();
  }

  public positionVisibilityMode(puesto: PuestoDTO): string {
    return puesto.oculto ? 'GENERAL.SHOW' : 'GENERAL.HIDE';
  }

  public nuevoPuesto(): void {
    this.puesto = new PuestoModelDTO(new PuestoDTO());
  }

  public reiniciarPuesto(): void {
    this.nuevoPuesto();
    this.panelPuesto.close();
  }

  public onDateChange(date: Date): void {
    if (date && this.personalModel.Legajo.modalidad.id && this.personalModel.Legajo.fechaIngreso) {
      this.updateEndDate(this.personalModel.Legajo.modalidad);
    }
  }

  public updateEndDate(contract: ModalidadContractualDTO): void {
    const newEndDate = DateTime.fromJSDate(this.personalModel.Legajo.fechaIngreso);
    switch (contract.duracionUnidad) {
      case TipoUnidadDuracionModalidad.Dias:
        this.personalModel.Legajo.fechaSalida = newEndDate.plus({ days: contract.duracionCantidad }).toJSDate();
        break;
      case TipoUnidadDuracionModalidad.Meses:
        this.personalModel.Legajo.fechaSalida = newEndDate.plus({ months: contract.duracionCantidad }).toJSDate();
        break;
      default:
        break;
    }
  }

  public modalidadString(modalidad: ModalidadContractualDTO): string {
    if (modalidad.id === 11) {
      return 'Práctica profesional';
    }
    return modalidad.nombre;
  }

  public addWorkDay(newWorkDay: WorkDayDTO): void {
    if (this.validAddWorkDay(newWorkDay)) {
      this.personalModel.Legajo.workDays = [...this.personalModel.Legajo.workDays, newWorkDay];
      this.updateWorkingModality();
    }
    this.newWorkDay = null;
  }

  public removeWorkDay(workDay: WorkDayDTO): void {
    this.personalModel.Legajo.workDays = this.personalModel.Legajo.workDays.filter((x) => x.id !== workDay.id);
    this.updateWorkingModality();
  }

  public selectedDaysList(workDay: WorkDayDTO): string {
    const selectedDays: SelectedDays[] = [];
    selectedDays.push(
      {
        day: this.translate.instant('ENUM.DAYS_OF_WEEK.Monday'),
        checked: workDay.monday,
      },
      {
        day: this.translate.instant('ENUM.DAYS_OF_WEEK.Tuesday'),
        checked: workDay.tuesday,
      },
      {
        day: this.translate.instant('ENUM.DAYS_OF_WEEK.Wednesday'),
        checked: workDay.wednesday,
      },
      {
        day: this.translate.instant('ENUM.DAYS_OF_WEEK.Thursday'),
        checked: workDay.thursday,
      },
      {
        day: this.translate.instant('ENUM.DAYS_OF_WEEK.Friday'),
        checked: workDay.friday,
      },
      {
        day: this.translate.instant('ENUM.DAYS_OF_WEEK.Saturday'),
        checked: workDay.saturday,
      },
      {
        day: this.translate.instant('ENUM.DAYS_OF_WEEK.Sunday'),
        checked: workDay.sunday,
      },
    );

    return selectedDays
      .filter((x) => x.checked === true)
      .map((day) => day.day)
      .join(', ');
  }

  public selectedTimeRanges(timeRanges: TimeRangeDTO[]): string {
    return timeRanges.map((range) => `${this.timePadPipe.transform(range.start)} - ${this.timePadPipe.transform(range.end)}`).join(' | ');
  }

  public expandRowOnClick(value: WorkDayDTO | null): void {
    this.expandedElement = this.expandedElement === value ? null : value;
  }

  public nombreConvenio(convenio: ConvenioColectivoDTO): string {
    return this.convenioColectivoService.nombreConvenio(convenio);
  }

  public displayFnHealthInsurance(value: HealthInsuranceCompanyDTO): string {
    return value ? value.acronym || value.fullName : '';
  }

  private async getAbsences(): Promise<void> {
    const tiposAusenciasPersonal = await this.ausenciasServiceBackend.getAusenciasPersonalLegajoIdTipos(
      this.personalModel.LegajoModel.Id,
      false,
    );
    this.ausenciaXConvenio = tiposAusenciasPersonal?.reduce((rv, tipoAusencia) => {
      // eslint-disable-next-line no-param-reassign
      (rv[tipoAusencia.convenio.id] = rv[tipoAusencia.convenio.id] || []).push(tipoAusencia);
      return rv;
    }, {});
  }

  private async consultarSuperiorSugerido(puesto: PuestoModelDTO): Promise<void> {
    if (puesto.Cargo?.id && (!puesto.Superior || !puesto.Superior.id)) {
      const res = await this.perfilService.obtenerSuperiorSugerido(puesto.Cargo.id);
      if (res) {
        // eslint-disable-next-line no-param-reassign
        puesto.Superior = res;
      }
    }
  }

  private async createJobCertificate(puesto: PuestoDTO): Promise<void> {
    const previewMessage = this.translateService.instant('PROFILE.LABOR_SECTION.EMPLOYEE_CERTIFICATE_STATEMENT_CONFIRM', {
      fullName: this.personalModel.NombreCompleto,
      id: this.personalModel.Dni,
      initialDate: this.ngxDatePipe.transform(this.personalModel.Legajo.fechaIngreso, 'shortDateFull'),
      position: puesto.cargo.nombre,
      currency: this.personalModel.Legajo.currency.display,
      salary: this.personalModel.Legajo.sueldoBruto,
    });

    const decision = await this.neoModalService.decision(previewMessage);
    if (decision.ButtonResponse === AlertButton.Accept) {
      await this.personalServiceBackend.insertPersonalLegajosIdCertificadoEmpleoIdCargo(this.personalModel.Legajo.id, puesto.cargo.id);
      this.snackBar.showInfo('PROFILE.LABOR_SECTION.CERTIFICATE_CREATED');
    }
  }

  private puestoValido(): boolean {
    if (!this.puestoForm.valid || !this.puesto?.Cargo.id) {
      this.neoModalService.warning('GENERAL.COMPLETE_MANDATORY_DATA');
      return false;
    }
    return true;
  }

  private daysMatch(workDay: WorkDayDTO, value: WorkDayDTO): boolean {
    return (
      (workDay.monday && value.monday) ||
      (workDay.tuesday && value.tuesday) ||
      (workDay.wednesday && value.wednesday) ||
      (workDay.thursday && value.thursday) ||
      (workDay.friday && value.friday) ||
      (workDay.saturday && value.saturday) ||
      (workDay.sunday && value.sunday)
    );
  }

  private validAddWorkDay(newWorkDay: WorkDayDTO): boolean {
    if (!newWorkDay) {
      return false;
    }
    const duplicated = this.personalModel.Legajo.workDays.some((x) => x.id === newWorkDay.id);
    if (duplicated) {
      const error = this.translate.instant('WORK_DAY.DUPLICATED_WORK_DAY', {
        name: newWorkDay.name,
      });
      this.neoModalService.warning(error);
      return false;
    }

    const overlapWorkDay = this.personalModel.Legajo.workDays.find((workDay) => {
      if (this.daysMatch(workDay, newWorkDay)) {
        return newWorkDay.ranges.some((range) => {
          const newStart = DateTime.fromISO(range.start);
          const newEnd = DateTime.fromISO(range.end);
          return workDay.ranges.some(
            (rangeValue) => DateTime.fromISO(rangeValue.start) < newEnd && newStart < DateTime.fromISO(rangeValue.end),
          );
        });
      }
      return false;
    });

    if (overlapWorkDay) {
      const error = this.translate.instant('WORK_DAY.OVERLAP_WORK_DAY', {
        workDay: overlapWorkDay.name,
      });
      this.neoModalService.warning(error);
    }

    return !overlapWorkDay;
  }

  private updateWorkingModality(): void {
    if (this.personalModel.Legajo.workDays.length) {
      if (this.personalModel.Legajo.workDays.every((workDay) => workDay.workingMode === WorkLocationType.In_office)) {
        this.personalModel.Legajo.modoTrabajo = WorkLocationType.In_office;
      } else if (this.personalModel.Legajo.workDays.every((workDay) => workDay.workingMode === WorkLocationType.Remote)) {
        this.personalModel.Legajo.modoTrabajo = WorkLocationType.Remote;
      } else {
        this.personalModel.Legajo.modoTrabajo = WorkLocationType.Hybrid;
      }
    }
  }
}
