import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { FamiliarDTO } from 'src/app/ModelDTO/DTO/familiar.DTO';
import { RelacionFamiliar } from 'src/app/ModelDTO/DTO/relacionFamiliar.ENUM';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { DomicilioModelDTO } from 'src/app/ModelDTO/domicilio.ModelDTO';


export class FamiliarModelDTO extends EntityModelDTO<FamiliarDTO> {

   private domicilioModel: DomicilioModelDTO;
   private domicilioSubscribe: Subscription;

   public constructor(protected entityDTO: FamiliarDTO) {
      super(entityDTO);
   }

   public static getRelacionFamiliar(): string[] {
      return EntityModelDTO.getEnumArray(RelacionFamiliar);
   }

   public setEntityDTO(entityDTO: FamiliarDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.domicilioModel = new DomicilioModelDTO(this.entityDTO.domicilio);
      this.domicilioSubscribe = this.domicilioModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.domicilioModel.dispose();
      this.domicilioSubscribe.unsubscribe();
   }

   get FechaNacimiento(): Date { return this.entityDTO.fechaNacimiento; }
   set FechaNacimiento(value: Date) { this.notifyChangeDTO('fechaNacimiento', value); }

   get ACargo(): boolean { return this.entityDTO.aCargo; }
   set ACargo(value: boolean) { this.notifyChangeDTO('aCargo', value); }

   get Discapacidad(): boolean { return this.entityDTO.discapacidad; }
   set Discapacidad(value: boolean) { this.notifyChangeDTO('discapacidad', value); }

   get Pep(): boolean { return this.entityDTO.pep; }
   set Pep(value: boolean) { this.notifyChangeDTO('pep', value); }

   get Relacion(): string { return RelacionFamiliar[this.entityDTO.relacion]; }
   set Relacion(value: string) { this.notifyChangeDTO('relacion', RelacionFamiliar[value]); }

   get NombreCompleto(): string { return this.entityDTO.nombreCompleto; }
   set NombreCompleto(value: string) { this.notifyChangeDTO('nombreCompleto', value); }

   get Dni(): string { return this.entityDTO.dni; }
   set Dni(value: string) { this.notifyChangeDTO('dni', value); }

   get DomicilioModel(): DomicilioModelDTO { return this.domicilioModel; }
   get Domicilio(): DomicilioDTO { return this.domicilioModel.getEntityDTO(); }
   set Domicilio(value: DomicilioDTO) { this.notifyChange(() => { this.entityDTO.domicilio = value; this.domicilioModel.setEntityDTO(value); }); }

   get Telefonos(): string { return this.entityDTO.telefonos; }
   set Telefonos(value: string) { this.notifyChangeDTO('telefonos', value); }

   get Email(): string { return this.entityDTO.email; }
   set Email(value: string) { this.notifyChangeDTO('email', value); }

   get Comentario(): string { return this.entityDTO.comentario; }
   set Comentario(value: string) { this.notifyChangeDTO('comentario', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
