import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EstadoAprobacionSolicitud } from '@api/enums/estado-aprobacion-solicitud.enum';
import { TipoAusencia } from '@api/enums/tipo-ausencia.enum';
import { TipoDiasConteo } from '@api/enums/tipo-dias-conteo.enum';
import { TipoUnidadAusencia } from '@api/enums/tipo-unidad-ausencia.enum';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { map, Observable } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { AdministradorAusenciasService } from 'src/app/layout/administrador/administrador-ausencias/administrador-ausencias.service';
import { PersonalAusenciaModelDTO } from 'src/app/ModelDTO/personalAusencia.ModelDTO';
import { SolicitudAusenciaModelDTO } from 'src/app/ModelDTO/solicitudAusencia.ModelDTO';
import { daysFromLabor, HolidaysService } from 'src/app/shared/services/holidays.service';

@Component({
  selector: 'app-detalle-ausencia',
  templateUrl: './detalle-ausencia.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetalleAusenciaComponent implements OnInit {
  @Input() public ausenciaModel: PersonalAusenciaModelDTO;

  public duracion: string;
  public infoDuracion: string;
  public ausenciaDias: boolean;
  public mensaje: string;
  public solicitudAusencia: SolicitudAusenciaModelDTO;

  public get vistaEmpresa$(): Observable<boolean> {
    return this.headerService.vistaEmpresa$;
  }

  public get reviewerText(): Observable<string> {
    return this.solicitudAusencia.Estado === 'Aprobado'
      ? this.translateService.get('TIMEOFF.DECISION_REVIEWER', {
          decision: this.translateService.instant('TIMEOFF.APPROVAL').toLowerCase(),
        })
      : this.translateService.get('TIMEOFF.DECISION_REVIEWER', {
          decision: this.translateService.instant('TIMEOFF.REJECTION').toLowerCase(),
        });
  }

  public get absenceModelState(): Observable<string> {
    return this.translateService.get(`ENUM.TIMEOFF.ESTADO_APROBACION_SOLICITUD.${this.ausenciaModel.Estado}`);
  }

  public get absenceModelType(): Observable<string> {
    const tipo = this.translateService.get(`ENUM.TIMEOFF.TIPO_AUSENCIA.${this.ausenciaModel.AusenciaModel.Tipo}`).pipe(
      map((x) => {
        if (this.ausenciaModel.Ausencia.tipo !== TipoAusencia.Trabajo_remoto) {
          return `(${x})`;
        }
        return '';
      }),
    );
    return tipo;
  }

  public get fromDate(): Date {
    return this.ausenciaModel.Ausencia?.unidad === TipoUnidadAusencia.Horas
      ? this.ausenciaModel.DateTimeFrom
      : this.ausenciaModel.FechaDesde;
  }

  public get toDate(): Date {
    return this.ausenciaModel.Ausencia?.unidad === TipoUnidadAusencia.Horas ? this.ausenciaModel.DateTimeTo : this.ausenciaModel.FechaHasta;
  }

  constructor(
    private headerService: HeaderAppService,
    public administradorAusencias: AdministradorAusenciasService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private holidaysService: HolidaysService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.duracion = this.duracionDiasHoras();
    this.ausenciaDias = this.ausenciaModel.Ausencia.unidad === TipoUnidadAusencia.D1ias;
    if (this.ausenciaModel.ConSolicitud) {
      this.solicitudAusencia = await this.administradorAusencias.getSolicitudPorAusencia(this.ausenciaModel.getEntityDTO().id);
      this.ausenciaModel.Adjuntos =
        this.solicitudAusencia.Adjuntos?.length > 0 ? this.solicitudAusencia.Adjuntos : this.solicitudAusencia.Ausencia?.adjuntos ?? [];
    }
    this.changeDetectorRef.detectChanges();
  }

  public duracionDiasHoras(): string {
    const entity = this.ausenciaModel.getEntityDTO();
    let from = DateTime.fromJSDate(entity.dateTimeFrom);
    let to = DateTime.fromJSDate(entity.dateTimeTo);
    let diff = to.diff(from);
    if (entity.ausencia.unidad === TipoUnidadAusencia.D1ias) {
      from = DateTime.fromJSDate(entity.fechaDesde).startOf('day');
      to = DateTime.fromJSDate(entity.fechaHasta).startOf('day');
      diff = to.diff(from, 'days');
      let daysDiff = diff.as('days') + 1;
      if (entity.ausencia.tipoConteo === TipoDiasConteo.H1abiles) {
        const workdays = daysFromLabor(entity.ausencia.convenio);
        daysDiff -= this.holidaysService.nonWorkingDaysBetweenDates(from, to, workdays);
      }
      this.infoDuracion = daysDiff === 1 ? this.translateService.instant('GENERAL.DAY') : this.translateService.instant('GENERAL.DAYS');
      return `${daysDiff}`;
    }
    this.infoDuracion =
      diff.as('hours') === 1 ? this.translateService.instant('GENERAL.HOUR') : this.translateService.instant('GENERAL.HOURS');
    this.infoDuracion = this.infoDuracion.toLowerCase();
    return diff.toFormat('hh:mm');
  }

  public chipColor(): string {
    switch (this.ausenciaModel.getEntityDTO().estado) {
      case EstadoAprobacionSolicitud.Aprobado:
        return 'success';
      case EstadoAprobacionSolicitud.Pendiente:
        return 'accent';
      case EstadoAprobacionSolicitud.Rechazado:
        return 'danger';
      default:
        return null;
    }
  }
}
