import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SignatureType } from '@api/enums/signature-type.enum';
import { TipoCategoriaDocumento } from '@api/enums/tipo-categoria-documento.enum';
import { map, Observable, Subscription } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { PersonalLegajoDTO } from 'src/app/ModelDTO/DTO/personalLegajo.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoModelDTO } from 'src/app/ModelDTO/personalLegajo.ModelDTO';
import { AttachmentUaalooModelDTOExtended } from 'src/app/shared/attachment-selector/attachment.ModelDTO.extended';
import { featureFirmaDigital } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authRequest.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { parseLink } from 'src/app/shared/shared-functions';
import { AttachmentFirmaService } from './attachment-firma.service';

@Injectable({
  providedIn: 'root',
})
export class PrevisualizacionDocumentoService implements OnDestroy {
  public firmaDigitalPermission$: Observable<boolean>;

  private subs = new Subscription();

  constructor(
    private attachmentFirmaService: AttachmentFirmaService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
    private headerService: HeaderAppService,
    private dialog: MatDialog,
  ) {
    this.firmaDigitalPermission$ = this.featureFlagService.flags$.pipe(
      map(({ flags }) => FeatureFlagHelper.featureOn(featureFirmaDigital, flags)),
    );
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  public async getAttachmentFilePublicLinkUrl(
    attachment: AttachmentUaalooModelDTOExtended,
    personalLegajo: PersonalLegajoModelDTO,
    tipoDocumento: TipoCategoriaDocumento,
    expiredMinutes = 15,
  ): Promise<string> {
    const fileDbId = attachment?.File?.id;
    if (fileDbId) {
      const publicLink = await this.attachmentFirmaService.getPublicLink(
        attachment.getEntityDTO(),
        personalLegajo,
        tipoDocumento,
        expiredMinutes,
      );
      return parseLink(publicLink.link);
    }
    return Promise.reject();
  }

  public visualizarArchivoById(
    idAttachment: number,
    owner: number = 0,
    type: TipoCategoriaDocumento = TipoCategoriaDocumento.No_definido,
    back: boolean = false,
    documentId: number = undefined,
  ): void {
    this.dialog.closeAll();
    const mode = this.headerService.modoEmpresa() ? 'admin' : 'user';
    this.router.navigate([mode, 'documentacion', 'previsualizacion', idAttachment], {
      queryParams: { owner, type, back, documentId },
    });
  }

  public async firmarDocumento(
    tipoFirma: SignatureType,
    owner: PersonalLegajoModelDTO,
    posicionFirma: ConfiguracionFirmaDigitalPosicionDTO,
    attachment: AttachmentUaalooModelDTOExtended,
    tipoDocumento: TipoCategoriaDocumento,
    enviarPorMail: boolean,
    acceptPrivacyPolicy: boolean = false,
  ): Promise<AttachmentUaalooDTO> {
    attachment.PosicionFirma = posicionFirma;
    const attachmentDto = new AttachmentUaalooDTO();
    attachmentDto.PrepareDTO(attachment.getEntityDTO());
    attachmentDto.signatures = null;
    const attachmentFirmado = await this.attachmentFirmaService.firmarAttachmentUserLogged(
      attachmentDto,
      owner,
      tipoFirma,
      tipoDocumento === TipoCategoriaDocumento.P1ublico,
      enviarPorMail,
      acceptPrivacyPolicy,
    );
    return attachmentFirmado;
  }

  public async agregarFirmantes(
    firmantes: PersonalLegajoBasicoDTO[],
    notificarPorEmail: boolean,
    posicionFirma: ConfiguracionFirmaDigitalPosicionDTO,
    attachment: AttachmentUaalooModelDTOExtended,
  ): Promise<AttachmentUaalooModelDTOExtended> {
    if (firmantes && firmantes.length > 0) {
      const attachmentConFirmantes = await this.attachmentFirmaService.agregarFirmantes(
        attachment.getEntityDTO(),
        firmantes,
        notificarPorEmail,
        posicionFirma,
      );
      return new AttachmentUaalooModelDTOExtended(attachmentConFirmantes);
    }
    return null;
  }

  public async descargarCertificado(namedBlob: NamedBlobDTO): Promise<Blob> {
    return this.attachmentFirmaService.obtenerCertificado(namedBlob);
  }

  public async descargarAuditoriaFirma(namedBlob: NamedBlobDTO, owner: PersonalLegajoDTO, attachmentId: number): Promise<Blob> {
    return this.attachmentFirmaService.obtenerAuditoriaFirma(attachmentId, owner, namedBlob);
  }

  public setAuth(password: string, attachment: AttachmentUaalooModelDTOExtended): void {
    const auth = new AuthRequestDTO();
    auth.password = password;
    attachment.AuthRequest = auth;
  }
}
