import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild, ViewContainerRef, signal } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TipoCategoriaDocumento } from '@api/enums/tipo-categoria-documento.enum';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DocumentacionSharedViewModel } from 'src/app/app-common/documentacion-shared/documentacion-shared-view.model';
import { DocumentacionSharedService } from 'src/app/app-common/documentacion-shared/documentacion-shared.service';
import { AltasEmpleadoEnum } from 'src/app/app-common/perfil-empleado-shared/detalle-empleado/detalle-empleado.component';
import { DetalleEmpleadoService } from 'src/app/app-common/perfil-empleado-shared/detalle-empleado/detalle-empleado.service';
import { SectionsEmployee } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-enums';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { AdministradorAusenciasService } from 'src/app/layout/administrador/administrador-ausencias/administrador-ausencias.service';
import { ListadoDeCapacitacionesService } from 'src/app/layout/administrador/administrador-capacitaciones/listado-de-capacitaciones.service';
import { ListadoDeEntregasService } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas.service';
import { OrganigramaService } from 'src/app/layout/administrador/colaboradores/organigrama.service';
import { TeammatesListService } from 'src/app/layout/administrador/colaboradores/teammates-list.service';
import { SancionesService } from 'src/app/layout/administrador/sanciones/sanciones.service';
import { CambiosHistoricosModelDTO } from 'src/app/ModelDTO/cambiosHistoricos.ModelDTO';
import { InformacionDTO } from 'src/app/ModelDTO/DTO/informacion.DTO';
import { LocalidadDTO } from 'src/app/ModelDTO/DTO/localidad.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { InformacionModelDTO } from 'src/app/ModelDTO/informacion.ModelDTO';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { noProfileImage } from 'src/app/shared/constants';
import { featureEdicionColaborador } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { Command } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { AppCacheService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/app-cache/app-cache.service';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { ProfileImageThumbnailUrlPipe } from 'src/app/shared/pipes/profile-image-thumbnail-url.pipe';
import { EMAIL_VALIDATOR } from 'src/app/shared/reg-exp.constants';
import { SelectAndCropData } from 'src/app/shared/select-and-crop/select-and-crop.component';
import { SelectAndCropService } from 'src/app/shared/select-and-crop/select-and-crop.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';

export interface IModalPersonalData {
  teammateId: number;
  menuItem?: SectionsEmployee;
}

@Component({
  selector: 'app-detalle-empleado-modal',
  templateUrl: './detalle-empleado-modal.component.html',
  styleUrls: ['./detalle-empleado-modal.component.scss'],
})
export class DetalleEmpleadoModalComponent implements OnInit {
  @ViewChild('fileImage') public fileImage: ElementRef;
  @HostListener('document:click', ['$event']) public clickout(): void {
    this.disposeOverlay();
  }

  public personalModel: PersonalModelDTO;
  public edicion = false;

  public historial: CambiosHistoricosModelDTO;

  public guardarEditarCmd = new Command(() => this.saveOrEdit(), new BehaviorSubject(true), true);

  public contactForm: UntypedFormGroup;
  public selectedMenu: SectionsEmployee;

  public profileOwnImageUrl: string;
  public edicionColaboradorPermission = false;

  public $vCard = signal('');

  public get notificationAddressControl(): AbstractControl {
    return this.contactForm.controls.notificationAddress;
  }

  public get notificationLocationControl(): AbstractControl {
    return this.contactForm.controls.notificationLocation;
  }

  public get canEdit(): boolean {
    return this.edicionColaboradorPermission || this.personalModel.LegajoModel.Id === this.headerService.personalLegajoId;
  }

  private overlayRef: OverlayRef;
  private subs = new Subscription();
  private originalTeammate: PersonalDTO;

  constructor(
    private dialogRef: MatDialogRef<DetalleEmpleadoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IModalPersonalData,
    private perfilService: PerfilEmpleadoSharedService,
    public overlay: Overlay,
    private neoModalService: NgxNeoModalMatService,
    public viewContainerRef: ViewContainerRef,
    private headerService: HeaderAppService,
    private teammatesListService: TeammatesListService,
    private router: Router,
    private administradorAusenciasService: AdministradorAusenciasService,
    private administradorEntregasService: ListadoDeEntregasService,
    private administradorCapacitacionesService: ListadoDeCapacitacionesService,
    private administradorSancionesService: SancionesService,
    private documentacionSharedService: DocumentacionSharedService,
    private organigramaService: OrganigramaService,
    private featureService: FeatureFlagService,
    private documentacionViewModel: DocumentacionSharedViewModel,
    private detalleEmpleadoService: DetalleEmpleadoService,
    private route: ActivatedRoute,
    private snackBar: SnackBarService,
    private cacheService: AppCacheService,
    private profileImagePipe: ProfileImageThumbnailUrlPipe,
    private selectAndCropService: SelectAndCropService,
  ) {
    this.initForm();
  }

  public ngOnInit(): void {
    this.profileOwnImageUrl = noProfileImage;

    this.perfilService
      .obtenerPersonal(this.data.teammateId)
      .then((teammate) => {
        this.originalTeammate = this.cloneTeammate(teammate.getEntityDTO());
        this.personalModel = teammate;
        this.perfilService.setPersonalModel(this.personalModel);

        if (this.personalModel) {
          if (this.personalModel?.Image) {
            this.profileOwnImageUrl = this.profileImagePipe.transform(this.personalModel.Image, 200);
          } else {
            this.profileOwnImageUrl = noProfileImage;
          }
        }

        this.subs.add(this.detalleEmpleadoService.altaEvento$.subscribe((tipo) => this.onAltaEvent(tipo)));
        const menuParam = this.route.snapshot.queryParamMap.get('userMenu');
        if (menuParam) {
          this.editUrl(Number(menuParam));
        } else {
          this.editUrl(SectionsEmployee.DatosPersonales);
        }

        this.$vCard.set(
          this.perfilService.getVCard({
            name: teammate.Nombre,
            lastName: teammate.Apellido,
            fullName: teammate.NombreCompleto,
            email: teammate.Email,
            phone: teammate.Telefonos,
            role: teammate?.Legajo?.puestos?.[0]?.cargo?.nombre,
            area: teammate?.Legajo?.puestos?.[0]?.area?.nombre,
            birthDay: teammate.FechaNacimiento?.toISOString(),
            address: teammate.Domicilio,
          }),
        );
      })
      .catch((error: Error) => {
        this.dialogRef.close();
        throw error;
      });

    this.subs.add(
      this.featureService.flags$.subscribe(({ flags }) => {
        this.edicionColaboradorPermission = FeatureFlagHelper.featureOn(featureEdicionColaborador, flags);
      }),
    );

    this.edicion = false;

    this.selectedMenu = this.data.menuItem;
  }

  public cancel(): void {
    if (this.edicion) {
      this.edicion = false;
      this.contactForm.disable({ emitEvent: false });
      this.personalModel = new PersonalModelDTO(this.cloneTeammate(this.originalTeammate));
      this.perfilService.setPersonalModel(this.personalModel);
    } else {
      this.dialogRef.close();
    }
  }

  public onAltaEvent(tipoAlta: AltasEmpleadoEnum): void {
    this.dialogRef.close();
    // eslint-disable-next-line default-case
    switch (tipoAlta) {
      case AltasEmpleadoEnum.AltaDocumento:
        this.documentacionSharedService.InformacionAEditarModel = new InformacionModelDTO(new InformacionDTO());
        this.documentacionSharedService.InformacionAEditarModel.Personal.id = this.personalModel.Legajo.id;
        this.documentacionSharedService.InformacionAEditarModel.Personal.legajo = this.personalModel.Legajo.legajo;
        this.documentacionSharedService.InformacionAEditarModel.Categoria.PrepareDTO(this.documentacionViewModel.categoria);
        this.documentacionSharedService.InformacionAEditarModel.Categoria.visualizacion = TipoCategoriaDocumento.Colaborador;
        this.router.navigate(['/admin/documentacion/alta']);
        break;
      case AltasEmpleadoEnum.AltaAusencia:
        this.administradorAusenciasService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/ausencias/alta']);
        break;
      case AltasEmpleadoEnum.AltaEntrega:
        this.administradorEntregasService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/entregas/alta']);
        break;
      case AltasEmpleadoEnum.AltaSancion:
        this.administradorSancionesService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/sanciones/alta-sancion']);
        break;
      case AltasEmpleadoEnum.AltaFeedback:
        this.router.navigate(['/admin/feedback/dar-feedback'], {
          queryParams: { teammate: this.personalModel.getEntityDTO().legajo.id },
        });
        break;
      case AltasEmpleadoEnum.AltaCapacitacion:
        this.administradorCapacitacionesService.PersonalAlta = this.personalModel.getEntityDTO();
        this.router.navigate(['/admin/capacitaciones/alta']);
        break;
    }
  }

  public menuSelected(option: SectionsEmployee): void {
    this.editUrl(option);
  }

  public clickImageInput(): void {
    if (this.canEdit) {
      this.fileImage.nativeElement.click();
    }
  }

  public dropImagen(files: FileList): void {
    if (this.canEdit) {
      this.fileChangeEvent({
        target: {
          files,
        },
      });
    }
  }

  public fileChangeEvent(event: unknown): void {
    const data: SelectAndCropData = {
      event,
    };
    const dialogRef = this.selectAndCropService.openImageCropperDialog(data);
    dialogRef?.afterClosed().subscribe(async (result) => {
      if (result) {
        const image = new ImageEntityDTO();
        image.image = result;
        this.profileOwnImageUrl = image.image;

        const imageDTO = await this.perfilService.guardarImagenPersonal(this.personalModel.Id, image);
        this.personalModel.Image = imageDTO.image;
        this.personalModel.getEntityDTO().image = imageDTO.image;

        if (this.headerService.personalLegajoId === this.personalModel.Id) {
          // Myself
          this.headerService.newProfileImage(imageDTO.image);
        }
        this.refreshTeammate(this.personalModel.getEntityDTO());
      }
    });
  }

  private initForm(): void {
    this.contactForm = new UntypedFormGroup({
      phone: new UntypedFormControl(''),
      accessEmail: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATOR)]),
      companyEmail: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATOR)]),
      currentAddress: new UntypedFormControl(''),
      currentCountry: new UntypedFormControl(),
      currentLocation: new UntypedFormControl(null),
      notificationAddress: new UntypedFormControl(''),
      notificationCountry: new UntypedFormControl(),
      notificationLocation: new UntypedFormControl(null),
      emergency: new UntypedFormGroup({
        emergencyContactName: new UntypedFormControl(''),
        emergencyContactPhone: new UntypedFormControl(''),
        emergencyContactEmail: new UntypedFormControl('', [Validators.pattern(EMAIL_VALIDATOR)]),
        emergencyContactAddress: new UntypedFormControl(''),
        emergencyContactCountry: new UntypedFormControl(),
        emergencyContactLocation: new UntypedFormControl(null),
      }),
    });

    this.contactForm.disable();
  }

  private async saveOrEdit(): Promise<void> {
    if (!this.edicion) {
      this.edicion = true;
      this.contactForm.enable({ emitEvent: false });

      if (!this.contactForm.controls.currentCountry.value?.id) {
        this.contactForm.controls.currentLocation.disable();
      }

      if (!this.contactForm.controls.notificationCountry.value?.id) {
        this.contactForm.controls.notificationLocation.disable();
      }

      if (!this.contactForm.get('emergency.emergencyContactCountry').value?.id) {
        this.contactForm.get('emergency.emergencyContactLocation').disable();
      }
    } else {
      if (this.personalModel.Domicilio.direccion && this.personalModel.Domicilio.localidad?.id > 0) {
        if (!this.notificationAddressControl.value || !this.notificationLocationControl.value) {
          await this.neoModalService.warning('PROFILE.NOTIFICATION_FIELDS_REMOVED_CONTROL');
          return;
        }
      }
      if (this.notificationLocationControl.value?.id > 0) {
        if (!this.notificationAddressControl.value) {
          await this.neoModalService.warning('PROFILE.NOTIFICATION_ADDRESS_CONTROL');
          return;
        }
      }
      const validContactInfo = await this.saveContactPersonal();
      if (validContactInfo) {
        const res: PersonalDTO = await this.perfilService.actualizarPersonal(this.personalModel.Id, this.personalModel.getEntityDTO());
        // Actualizo los organigramas, de ser necesario y la tabla de los colaboradores
        this.refreshTeammate(res);
        this.dialogRef.close();
      }
    }
  }

  private refreshTeammate(person: PersonalDTO): void {
    this.organigramaService.edicionPersonal.next(true);
    this.originalTeammate = person;
    this.teammatesListService.actualizarColaborador(person);
    this.snackBar.showInfo('GENERAL.SUCCESSFUL_MODIFICATION');
    this.cacheService.clearCache('/teammates/recipients');
  }

  private async saveContactPersonal(): Promise<boolean> {
    if (this.contactForm.valid) {
      this.personalModel.Telefonos = this.contactForm.value.phone;
      this.personalModel.Email = this.contactForm.value.accessEmail;
      this.personalModel.Legajo.emailEmpresa = this.contactForm.value.companyEmail;
      this.personalModel.DomicilioResidencia.direccion = this.contactForm.value.currentAddress;
      const location = new LocalidadDTO();
      location.PrepareDTO(this.contactForm.controls.currentLocation.value);
      this.personalModel.DomicilioResidencia.localidad = location;
      this.personalModel.DomicilioResidencia.localidad.provincia.pais = this.contactForm.value.currentCountry;
      this.personalModel.Domicilio.direccion = this.contactForm.value.notificationAddress;
      this.personalModel.Domicilio.localidad = this.contactForm.controls.notificationLocation.value;
      this.personalModel.ContactoEmergencia.nombreCompleto = this.contactForm.value.emergency.emergencyContactName;
      this.personalModel.ContactoEmergencia.telefonos = this.contactForm.value.emergency.emergencyContactPhone;
      this.personalModel.ContactoEmergencia.email = this.contactForm.value.emergency.emergencyContactEmail;
      this.personalModel.ContactoEmergencia.domicilio.direccion = this.contactForm.value.emergency.emergencyContactAddress;
      this.personalModel.ContactoEmergencia.domicilio.localidad = this.contactForm.value.emergency.emergencyContactLocation;
      return true;
    }
    const errors = [];

    if (!this.contactForm.controls.accessEmail.valid) {
      errors.push('PROFILE.ACCESS_EMAIL_CONTROL');
    }

    if (!this.contactForm.controls.companyEmail.valid) {
      errors.push('PROFILE.COMPANY_EMAIL_CONTROL');
    }

    if (!this.contactForm.get('emergency.emergencyContactEmail').valid) {
      errors.push('PROFILE.EMERGENCY_CONTACT_EMAIL_CONTROL');
    }

    if (errors.length) {
      await this.neoModalService.warning(errors);
    }

    return false;
  }

  private editUrl(userMenu: SectionsEmployee): void {
    if (this.personalModel) {
      let queryParams: any = {
        modal: true,
        userModal: this.personalModel.Id,
        userMenu,
      };
      if (userMenu !== SectionsEmployee.Documentos) {
        queryParams = { ...queryParams, folderId: null };
      }
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
        skipLocationChange: false,
        replaceUrl: true,
        state: { userModal: true },
      });
    }
  }

  private disposeOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  private cloneTeammate(teammate: PersonalDTO): PersonalDTO {
    const cloneTeammate = new PersonalDTO();
    // deep clone
    cloneTeammate.PrepareDTO(JSON.parse(JSON.stringify(teammate)));
    return cloneTeammate;
  }
}
