<mat-expansion-panel class="no-shadow" [disabled]="posiblesAusencias?.length === 0">
  <mat-expansion-panel-header>
    <mat-panel-title class="w-33">
      {{ tituloAusencia }}
    </mat-panel-title>
    <mat-panel-description class="hidden-sm">
      {{
        posiblesAusencias?.length
          ? descripcionAusencia
          : ('TIMEOFF.NO_TIMEOFF_WERE_REGISTERED' | translate: { value: tituloAusencia?.toLowerCase() })
      }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="mt-2">
    <table mat-table [dataSource]="posiblesAusencias" matSort (matSortChange)="sortData($event)">
      <ng-container [matColumnDef]="ColumnNames.Nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.FORMS.NAME' | translate }}</th>
        <td mat-cell *matCellDef="let ausencia">{{ ausencia.nombre }}</td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Politica">
        <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TIMEOFF.BY_POLICY' | translate }}</th>
        <td class="text-left" mat-cell *matCellDef="let ausencia">
          {{ totalWithUnit(ausencia.cantUnidades, ausencia.unidad) }}
        </td>
      </ng-container>
      <ng-container *ngIf="permisoSaldoCC" [matColumnDef]="ColumnNames.Asignados">
        <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TIMEOFF.ASSIGNED' | translate }}</th>
        <td class="text-left" mat-cell *matCellDef="let ausencia">
          {{ ausencia.totalAsignados | timeUnit: ausencia.unidad : 'day' }}
        </td>
      </ng-container>
      <ng-container *ngIf="permisoSaldoCC" [matColumnDef]="ColumnNames.Consumidos">
        <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TIMEOFF.CONSUMED' | translate }}</th>
        <td class="text-left" mat-cell *matCellDef="let ausencia">
          {{ ausencia.totalConsumidos | timeUnit: ausencia.unidad : 'day' }}
        </td>
      </ng-container>
      <ng-container *ngIf="permisoSaldoCC" [matColumnDef]="ColumnNames.Disponibles">
        <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TIMEOFF.AVAILABLE_PLURAL' | translate }}</th>
        <td class="text-left" mat-cell *matCellDef="let ausencia">
          {{ ausencia.totalDisponible | timeUnit: ausencia.unidad : 'day' }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.ConHaberes">
        <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'TIMEOFF.WITH_REMUNERATION' | translate }}</th>
        <td class="text-left" mat-cell *matCellDef="let ausencia">
          <span *ngIf="ausencia.gozaHaberes" class="legend-label-color secondary-color-background"></span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsAusencias"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsAusencias"></tr>
    </table>
  </div>
</mat-expansion-panel>
