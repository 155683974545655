import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { ModalEventoDetalleComponent } from 'src/app/app-common/modal-evento-detalle/modal-evento-detalle.component';
import { EventoDTO } from 'src/app/ModelDTO/DTO/evento.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { EventoServiceBackend } from 'src/app/ServiceBackend/evento.ServiceBackend';
import { EventoBackendService } from '@api/services/evento-backend.service';
import { EventFlatDTO } from '@api/interfaces/event-flat.interface';
import { PaginatorDataEventFlatDTO } from '@api/interfaces/paginator-data-event-flat.interface';

@Injectable({
  providedIn: 'root',
})
export class EventosService {
  public get EventoACrear(): EventoDTO {
    return this.eventoACrear;
  }
  public set EventoACrear(evento: EventoDTO) {
    this.eventoACrear = evento;
  }

  private eventoACrear: EventoDTO;
  constructor(
    private eventosBackend: EventoServiceBackend,
    private eventsServiceBackend: EventoBackendService,
    private dialog: MatDialog,
  ) {}

  public async obtenerPorId(id: number): Promise<EventoDTO> {
    return this.eventosBackend.getEventoId(id);
  }

  public obtenerEntreFechas(
    pageNumber: number,
    pageSize: number,
    fechaDesde: Date,
    fechaHasta: Date,
  ): Observable<PaginatorDataEventFlatDTO> {
    return this.eventsServiceBackend.getEventoFiltrado({ fechaDesde, fechaHasta, pageNumber, pageSize });
  }

  public obtenerProximos(): Observable<EventFlatDTO[]> {
    return this.eventsServiceBackend.getEventoProximos();
  }

  public async agregarEvento(formData: FormData, imageEntityDTO: ImageEntityDTO): Promise<EventoDTO> {
    const res = await this.eventosBackend.insertEvento(formData);
    if (res && imageEntityDTO.image) {
      await this.eventosBackend.insertEventoIdImage(res.id, imageEntityDTO);
    }
    return res;
  }

  public async editarEvento(eventoId: number, evento: FormData, imageEntityDTO: ImageEntityDTO): Promise<EventoDTO> {
    const res = await this.eventosBackend.updateEventoId(eventoId, evento);
    if (res && imageEntityDTO && imageEntityDTO.image) {
      await this.eventosBackend.insertEventoIdImage(res.id, imageEntityDTO);
    }
    return res;
  }

  public async eliminarEvento(eventoDTO: EventoDTO): Promise<void> {
    await this.eventosBackend.deleteEventoId(eventoDTO.id);
  }
  public async obtenerAdjuntos(id: number): Promise<Array<AttachmentUaalooDTO>> {
    return this.eventosBackend.getEventoIdAdjuntos(id);
  }

  public openEventModal(eventoDTO: EventoDTO): void {
    this.dialog.open(ModalEventoDetalleComponent, {
      data: { evento: eventoDTO },
      panelClass: ['full-size-sm-900-lg-modal', 'full-height-sm-700-lg-modal', 'no-padding'],
      hasBackdrop: true,
      disableClose: false,
    });
  }

  public async openEventById(idEvent: number): Promise<void> {
    const event = await this.obtenerPorId(idEvent);
    this.openEventModal(event);
  }
}
