<h1 mat-dialog-title class="d-flex flew-row justify-content-between">
  <span>Editar historial</span>
  <span class="dialog-close-icon material-symbols-outlined" (click)="close()"> close </span>
</h1>
<div mat-dialog-content>
  <div class="d-flex flex-row gap-1">
    <mat-form-field class="flex-fill mt-1" appearance="outline">
      <mat-label>Cambio</mat-label>
      <input matInput id="tel" name="tel" [(ngModel)]="data.estado" [onReturn]="fecD" disabled />
    </mat-form-field>
    <neo-date-selector
      appearance="outline"
      #fecD
      formClass="flex-fill mt-1"
      label="Desde"
      id="fecD"
      [required]="true"
      [neoAutofocus]="true"
      [(date)]="data.desde"
      [onReturn]="fecH"
    >
    </neo-date-selector>
    <neo-date-selector
      appearance="outline"
      #fecH
      formClass="flex-fill mt-1"
      label="Hasta"
      id="fecH"
      [required]="true"
      [min]="data.desde"
      [(date)]="data.hasta"
      [onReturn]="guardar"
      [disabled]="true"
    >
    </neo-date-selector>
  </div>
</div>
<div mat-dialog-actions class="d-flex flex-row-reverse">
  <button #guardar mat-flat-button color="primary" [command]="editarCmd">Guardar</button>
</div>
