import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';

export interface SelectAndCropData {
  event: any;
  aspectRatio?: number;
  resizeW?: number;
  resizeH?: number;
  previewW?: number;
  previewH?: number;
  containWithinAspectRatio?: boolean;
}

@Component({
  selector: 'app-select-and-crop',
  templateUrl: './select-and-crop.component.html',
  styleUrls: ['./select-and-crop.component.scss'],
})
export class SelectAndCropComponent implements OnInit {
  @Input() public aspRatio = 1 / 1;
  @Input() public resizeWidth = 350;
  @Input() public resizeHeight = 350;

  @Input() public widthPreview = 200;
  @Input() public heightPreview = 200;

  public cancelarCmd: ICommand = new Command(() => this.cancelar(), new BehaviorSubject(true), false);
  public croppedImage = null;
  public imageChangedEvent: any = '';
  public loading = true;
  public containWithinAspectRatio = false;

  private canSave = new BehaviorSubject(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public aceptarCmd: ICommand = new Command(() => this.save(), this.canSave, false);

  constructor(
    private dialogRef: MatDialogRef<SelectAndCropComponent>,
    private neoModalService: NgxNeoModalMatService,
    @Inject(MAT_DIALOG_DATA) public data: SelectAndCropData,
  ) {}

  public ngOnInit(): void {
    this.containWithinAspectRatio = this.data.containWithinAspectRatio ?? false;
    this.imageChangedEvent = this.data.event;
    if (this.data.aspectRatio) {
      this.aspRatio = this.data.aspectRatio;
    }
    if (this.data.resizeW && this.data.resizeH) {
      this.resizeHeight = this.data.resizeH;
      this.resizeWidth = this.data.resizeW;
    }

    if (this.data.previewW && this.data.previewH) {
      this.heightPreview = this.data.previewH;
      this.widthPreview = this.data.previewW;
    }
  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    this.canSave.next(true);
  }

  public imageLoaded(): void {
    this.loading = false;
  }

  public loadImageFailed(): void {
    this.loading = false;
    this.neoModalService.warning('DOCUMENTS.IMAGE_DROP_ERROR');
  }

  public async save(): Promise<void> {
    this.dialogRef.close(this.croppedImage);
  }

  public cancelar(): void {
    this.dialogRef.close();
  }
}
