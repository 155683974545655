import { Injectable } from '@angular/core';
import { BancoServiceBackend } from 'src/app/ServiceBackend/banco.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class BancoEntityService {
  constructor(private bancoServiceBackend: BancoServiceBackend) {}

  public async getEntitiesByNombre(search: string) {
    const res = await this.bancoServiceBackend.getBancosAutoComplete(search, 30);
    return res;
  }
}
