<div class="main-backdrop" *ngIf="show" [ngClass]="inverted ? 'inverted' : 'default'">
  <div class="loader">
    <div class="lds-ring">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <svg width="44" height="35" viewBox="0 0 44 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        class="eye"
        d="M0.230003 20.68C0.230003 15.53 4.03 10.48 10.54 10.48C17.04 10.48 20.85 15.53 20.85 20.72C20.85 25.91 17.05 30.92 10.54 30.92C4.04 30.92 0.230003 25.91 0.230003 20.72V20.68ZM3.93 20.75C3.93 24.59 6.84 27.47 10.54 27.47C14.24 27.47 17.15 24.59 17.15 20.72C17.15 16.85 14.24 13.97 10.54 13.97C6.84 13.97 3.93 16.85 3.93 20.72V20.75Z"
      />
      <path
        class="eye"
        d="M22.8 20.68C22.8 15.53 26.6 10.48 33.11 10.48C39.61 10.48 43.42 15.53 43.42 20.72C43.42 25.91 39.62 30.92 33.11 30.92C26.61 30.92 22.8 25.91 22.8 20.72V20.68ZM26.5 20.75C26.5 24.59 29.41 27.47 33.11 27.47C36.81 27.47 39.72 24.59 39.72 20.72C39.72 16.85 36.81 13.97 33.11 13.97C29.41 13.97 26.5 16.85 26.5 20.72V20.75Z"
      />
      <path
        d="M4.05 0V1.96C4.05 3.84 5.15 4.09 7.18 4.09H17.01C19.92 4.09 21.63 6.58 22.16 7.5C22.55 6.01 24.11 4.09 26.89 4.09H36.76C38.89 4.09 39.89 3.84 39.89 1.96V0H42.8V3.59C42.8 6.11 41.31 7.89 38.07 7.89H28.2C25.64 7.89 24.57 9.56 24.04 11.09H20.21C20.03 9.85 18.68 7.89 15.84 7.89H5.83C2.6 7.89 1.17 6.11 1.17 3.59V0H4.05Z"
        fill="#293041"
      />
      <path d="M23.93 35H20.06V30.38H23.93V35Z" fill="#293041" />
    </svg>
  </div>
</div>
