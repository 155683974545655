import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoginService } from 'src/app/login/login.service';
import { UserServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/backend/user.ServiceBackend';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';
import { TenantResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/tenantResponse.DTO';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authRequest.DTO';
import { TypeSocial } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/typeSocial.ENUM';
import { NotificationHandlerService } from 'src/app/shared/services/notification-handler.service';
import { AuthSecondFactorComponent } from './auth-second-factor.component';

@Injectable({
  providedIn: 'root',
})
export class AuthSecondFactorService {
  constructor(
    private userServiceBackend: UserServiceBackend,
    private loginService: LoginService,
    private authenticationService: AuthenticationService,
    private notificationHandlerService: NotificationHandlerService,
    private dialog: MatDialog,
  ) {}

  public async changeTenant(selectedTenant: TenantResponseDTO, token2fa?: string): Promise<void> {
    try {
      const tenant = await this.userServiceBackend.getUserTenantsTOKEN(selectedTenant.id, token2fa);
      const authRequest = new AuthRequestDTO();
      authRequest.typeSocial = TypeSocial.Tenant;
      authRequest.tokenId = tenant.token;
      authRequest.token2fa = token2fa;
      await this.loginService.login(authRequest);
      await this.notificationHandlerService.getAllNotification();
      this.authenticationService.reloadPage();
    } catch (ex) {
      if (ex.status === 428) {
        this.insertToken2fa(ex.statusText, selectedTenant);
      }
    }
  }

  private async insertToken2fa(name: string, tenant: TenantResponseDTO): Promise<void> {
    const newToken2fa = await lastValueFrom(
      this.dialog
        .open(AuthSecondFactorComponent, {
          data: { accountName: name },
          hasBackdrop: true,
        })
        .afterClosed(),
    );
    if (newToken2fa) {
      this.changeTenant(tenant, newToken2fa);
    }
  }
}
