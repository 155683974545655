<mat-form-field class="flex-fill" [subscriptSizing]="subscriptSizing">
  <mat-label>{{ label }}</mat-label>
  <input #input (input)="filter()" type="text" matInput [formControl]="control" [matAutocomplete]="auto" />
  <mat-autocomplete [requireSelection]="true" #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option.originalObject">
      {{ option.title }}
    </mat-option>
  </mat-autocomplete>
  <mat-hint align="start"> {{ hint | translate }} </mat-hint>
</mat-form-field>
