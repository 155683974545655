import { Injectable } from '@angular/core';
import { TipoCapacitacionDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacion.DTO';
import { TipoCapacitacionFlatDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacionFlat.DTO';
import { TipoCapacitacionServiceBackend } from 'src/app/ServiceBackend/tipoCapacitacion.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class CapacitacionesService {
  private tipoAEditar: TipoCapacitacionDTO;

  constructor(private tipoCapacitacionServiceBackend: TipoCapacitacionServiceBackend) {}

  public async obtenerTipoDeCapacitaciones(): Promise<Array<TipoCapacitacionFlatDTO>> {
    const res = await this.tipoCapacitacionServiceBackend.getTipoCapacitacion();
    return res;
  }

  public async obtenerTipoCapacitacionId(id: number): Promise<TipoCapacitacionDTO> {
    return this.tipoCapacitacionServiceBackend.getTipoCapacitacionId(id);
  }

  public async agregarTipo(tipo: TipoCapacitacionDTO): Promise<TipoCapacitacionDTO> {
    const res = await this.tipoCapacitacionServiceBackend.insertTipoCapacitacion(tipo);
    return res;
  }

  public async editarTipo(tipo: TipoCapacitacionDTO): Promise<TipoCapacitacionDTO> {
    const res = await this.tipoCapacitacionServiceBackend.updateTipoCapacitacionId(tipo.id, tipo);
    return res;
  }

  public async eliminarTipo(tipo: TipoCapacitacionDTO): Promise<void> {
    await this.tipoCapacitacionServiceBackend.deleteTipoCapacitacionId(tipo.id);
  }

  public async obtenerCapacitacionesXLegajo(legajoId: number) {
    const res = await this.tipoCapacitacionServiceBackend.getTipoCapacitacionPersonalIDPERSONALLEGAJO(legajoId);
    return res;
  }

  public async obtenerCapacitacionesPorPersonal(legajoId: number): Promise<TipoCapacitacionDTO[]> {
    const res = await this.tipoCapacitacionServiceBackend.getTipoCapacitacionFullpersonalIDPERSONALLEGAJO(legajoId);
    return res;
  }

  public async obtenerCapacitacionesXConvenio(idConvenio: number) {
    const res = await this.tipoCapacitacionServiceBackend.getTipoCapacitacionConvenioIDCONVENIO(idConvenio);
    return res;
  }
}
