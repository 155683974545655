/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { LocalidadDTO } from 'src/app/api/interfaces/localidad.interface';

@Injectable({
   providedIn: 'root'
})
export class LocalidadBackendService {

   constructor(protected http: HttpClient) { }


   public getLocalidadesIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/localidades/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getLocalidades(): Observable<LocalidadDTO[]> {
      return this.http.get<DataDTO<LocalidadDTO>>(Constants.apiURL + '/localidades/').pipe(map((res) => convertJsonDates(res.data) as LocalidadDTO[]));
   }

   public insertLocalidades(localidadDTO: LocalidadDTO): Observable<LocalidadDTO> {
      return this.http.post<LocalidadDTO>(Constants.apiURL + '/localidades/', localidadDTO).pipe(map((res) => convertJsonDates(res) as LocalidadDTO ));
   }

   public updateLocalidadesId(id: number, localidadDTO: LocalidadDTO): Observable<LocalidadDTO> {
      return this.http.put<LocalidadDTO>(Constants.apiURL + '/localidades/' + id, localidadDTO).pipe(map((res) => convertJsonDates(res) as LocalidadDTO ));
   }

   public deleteLocalidadesId(id: number, deleteFromDb: boolean = false): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/localidades/' + id + '?deleteFromDb=' + deleteFromDb);
   }
   public getLocalidadesId(id: number): Observable<LocalidadDTO> {
      return this.http.get<LocalidadDTO>(Constants.apiURL + '/localidades/' + id).pipe(map((res) => convertJsonDates(res) as LocalidadDTO ));
   }

   public getLocalidadesAutoComplete(params: { contiene: string, pageSize: number, paisId?: number, includeAll?: boolean }, ): Observable<LocalidadDTO[]> {
      const { contiene, pageSize, paisId = 0, includeAll = false } = params;
      return this.http.get<DataDTO<LocalidadDTO>>(Constants.apiURL + '/localidades/autoComplete' + '?contiene=' + contiene +
            '&pageSize=' + pageSize +
            '&paisId=' + paisId +
            '&includeAll=' + includeAll).pipe(map((res) => convertJsonDates(res.data) as LocalidadDTO[]));
   }
}
