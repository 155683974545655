<div class="d-flex flex-column flex-grow-1">
  <mat-tab-group mat-align-tabs="center">
    <mat-tab [label]="'ACCOUNT.USER' | translate">
      <app-account-user [editMode]="editMode" [employeeModel]="employeeModel" />
    </mat-tab>
    <mat-tab [label]="'GENERAL.FORMS.STATUS' | translate">
      <app-cambio-de-estado [modoEdicion]="editMode" [personalModel]="employeeModel" />
    </mat-tab>
  </mat-tab-group>
</div>
