import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { CompetenceCategoryChargeDTO } from 'src/app/ModelDTO/DTO/competenceCategoryCharge.DTO';
import { CargoBasicoDTO } from 'src/app/ModelDTO/DTO/cargoBasico.DTO';


export class CargoConCategoriasDTO implements IEntityDTO {

   categories: Array<CompetenceCategoryChargeDTO>;
   superior: CargoBasicoDTO;
   nombre = '';
   descripcion = '';
   id = 0;

   constructor() {
      this.categories = new Array<CompetenceCategoryChargeDTO>();
      this.superior = new CargoBasicoDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.categories != null) { for (const item of jsonObj.categories) { const itemDTO = new CompetenceCategoryChargeDTO(); itemDTO.PrepareDTO(item); this.categories.push(itemDTO); } }
      if (jsonObj.superior != null) { this.superior.PrepareDTO(jsonObj.superior); }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
