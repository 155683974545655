import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarHeaderComponent<D> implements OnDestroy {
  private pDestroyed = new Subject<void>();

  constructor(
    private pCalendar: MatCalendar<D>,
    private pDateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private pDateFormats: MatDateFormats,
    cdr: ChangeDetectorRef,
  ) {
    pCalendar.stateChanges.pipe(takeUntil(this.pDestroyed)).subscribe(() => cdr.markForCheck());
  }

  public ngOnDestroy(): void {
    this.pDestroyed.next();
    this.pDestroyed.complete();
  }

  get periodLabel() {
    return this.pDateAdapter.format(this.pCalendar.activeDate, this.pDateFormats.display.monthYearLabel).toLocaleUpperCase();
  }

  public previousClicked(): void {
    this.pCalendar.activeDate = this.pDateAdapter.addCalendarMonths(this.pCalendar.activeDate, -1);
  }

  public nextClicked(): void {
    this.pCalendar.activeDate = this.pDateAdapter.addCalendarMonths(this.pCalendar.activeDate, 1);
  }
}
