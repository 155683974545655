import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Input, input } from '@angular/core';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { PaisDTO as JsonCountry } from '@api/interfaces/pais.interface';

type Country = PaisDTO | JsonCountry;

@Component({
  selector: 'app-country',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './country.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryComponent {
  @Input() public useNationality = false;
  public $country = input.required<Country>();
  public $flagSize = input<'sm' | 'md' | 'lg' | 'xl'>('sm');

  public $flagClass = computed(() => `fflag ff-${this.$flagSize()} fflag-${this.$country()?.iso}`);
}
