import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class WebpackTranslateLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return from(import(`../../assets/i18n/${lang}.json`)).pipe(
      catchError(
        () => from(import(`../../assets/i18n/es_ar.json`)), // Load default
      ),
    );
  }
}
