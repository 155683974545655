import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { WorkDayDTO } from 'src/app/ModelDTO/DTO/workDay.DTO';
import { VistaCalendario } from '../ModelDTO/DTO/vistaCalendario.ENUM';
import { VistaJornadasPorColaboradorDTO } from '../ModelDTO/DTO/vistaJornadasPorColaborador.DTO';
import { Constants } from '../shared/constants';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';

@Injectable({
   providedIn: 'root'
})
export class WorkDayServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getWorkDaysIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/workDays/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getWorkDaysIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/workDays/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
         const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getWorkDays(): Promise<Array<WorkDayDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/workDays/'));
         const resJson = res.data;
         const resDTO = new Array<WorkDayDTO>();
         for (const item of resJson) {
            const itemDTO = new WorkDayDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertWorkDays(workDayDTO: WorkDayDTO): Promise<WorkDayDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/workDays/', workDayDTO));
         const resDTO = new WorkDayDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateWorkDaysId(id: number, workDayDTO: WorkDayDTO): Promise<WorkDayDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/workDays/' + id, workDayDTO));
         const resDTO = new WorkDayDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteWorkDaysId(id: number): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/workDays/' + id));

   }

   public async getWorkDaysId(id: number): Promise<WorkDayDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/workDays/' + id));
         if (!res) { return null; }
         const resDTO = new WorkDayDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getWorkDaysAutoComplete(contiene: string, pageSize: number): Promise<Array<WorkDayDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/workDays/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<WorkDayDTO>();
         for (const item of resJson) {
            const itemDTO = new WorkDayDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public getWorkDaysCalendarView(vista: VistaCalendario, fechaDesde: Date, fechaHasta: Date = undefined , workDayId: number = 0, areaId: number = 0, sectorId: number = 0, officeId = 0): Observable<VistaJornadasPorColaboradorDTO[]> {

         return this.http.get<DataDTO>(Constants.apiURL + '/workDays/calendarView' + '?vista=' + vista +
            '&fechaDesde=' + fechaDesde?.toISOString() +
            '&fechaHasta=' + fechaHasta?.toISOString() +
            '&workDayId=' + workDayId +
            '&officeId=' + officeId +
            '&areaId=' + areaId +
            '&sectorId=' + sectorId).pipe(map(res => {
         const resJson = res.data;
         const resDTO = new Array<VistaJornadasPorColaboradorDTO>();
         for (const item of resJson) {
            const itemDTO = new VistaJornadasPorColaboradorDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;
      }));
   }

}
