import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ngxshortNumber',
})
export class NgxShortNumberPipe implements PipeTransform {
  public transform(value: number): any {
    const SUFFIX = ['', 'k', 'M'];

    var tier = (Math.log10(Math.abs(value)) / 3) | 0;

    if (tier === 0) return value;

    const suffix = SUFFIX[tier];
    const scale = Math.pow(10, tier * 3);
    const reduced = Math.floor(value / scale);

    return `${reduced.toFixed(0)}${suffix}`;
  }
}
