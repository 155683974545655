import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TipoUnidadAusencia } from 'src/app/ModelDTO/DTO/tipoUnidadAusencia.ENUM';

@Pipe({
  name: 'timeUnit',
  standalone: true,
})
export class TimeUnitPipe implements PipeTransform {
  private translate = inject(TranslateService);

  public transform(time: number, unitType: TipoUnidadAusencia, showTypeLabel: 'day' | 'hour' | 'all' | 'none' = 'none'): string {
    if (unitType === TipoUnidadAusencia.D1ias) {
      return showTypeLabel === 'day' || showTypeLabel === 'all'
        ? `${time} ${this.translate.instant(`ENUM.TIMEOFF.TIPO_UNIDAD_AUSENCIA.${time === 1 ? 'D1ia' : 'D1ias'}`).toLowerCase()}`
        : time.toString();
    }

    const absTime = Math.abs(time);
    const hours = Math.trunc(absTime / 60);
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = (absTime % 60).toString().padStart(2, '0');
    const sign = time < 0 ? '-' : '';

    const timeString = `${sign} ${hoursStr}:${minutesStr}`;
    return showTypeLabel === 'hour' || showTypeLabel === 'all'
      ? `${timeString} ${this.translate.instant(`ENUM.TIMEOFF.TIPO_UNIDAD_AUSENCIA.${TipoUnidadAusencia[unitType]}`).toLowerCase()}`
      : timeString;
  }
}
