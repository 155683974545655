import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import localeEsAR from '@angular/common/locales/es-AR';
import { ErrorHandler, InjectionToken, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import 'hammerjs';
import { NgxMaskModule } from 'ngx-mask';
import { ComponentRouteReuseStrategy } from 'src/app/core/component-route-reuse-strategy/component-route-reuse-strategy';
import { DefaultTranslationHelper } from 'src/app/shared/default-translation.helper';
import { NgxNeoFrontendMatModule } from 'src/app/shared/lib/ngx-neo-frontend-mat/ngx-neo-frontend-mat.module';
import { AuthInterceptor } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/auth.interceptor';
import { FrontEndConfig } from 'src/app/shared/lib/ngx-neo-frontend-mat/FrontendConfig';
import { NgxNeoComponentsModule } from 'src/app/shared/lib/ngx-neo-components-mat/ngx-neo-components.module';
import { HttpClientExtended } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/http/httpClient.extended';
import { CustomHammerConfig } from 'src/app/shared/lib/ngx-neo-components-mat/shared/services/hammer/custom-hammer-config';
import { NgxNeoLoaderModule } from 'src/app/shared/lib/ngx-neo-loader/ngx-neo-loader.module';
import { NgxNeoCompleterMatModule } from 'src/app/shared/lib/ngx-neo-completer-mat/ngx-neo-completer-mat.module';
import { NgxNeoPipesModule } from 'src/app/shared/lib/ngx-neo-pipes/ngx-neo-pipes.module';
import { NgxNeoModalMatModule } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.module';
import { LoaderInterceptor } from 'src/app/shared/loader-interceptor';
import { CdnImageUrlPipe } from 'src/app/shared/pipes/cdn-image-url.pipe';
import { ProfileImageThumbnailUrlPipe } from 'src/app/shared/pipes/profile-image-thumbnail-url.pipe';
import { WebpackTranslateLoader } from 'src/app/shared/webpack-translate-loader';
import { QuillConfigModule, QuillModule } from 'ngx-quill';
import { EmojiModule } from 'src/app/lib/quill-emoji/emoji-module';
import { environment } from 'src/environments/environment';
import { quillModuloCelular } from 'src/app/shared/shared-functions';
import { MatStepperIntl } from '@angular/material/stepper';
import { StepperIntlService } from 'src/app/shared/lib/stepper-intl.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { RecaptchaModule } from './core/recaptcha/recaptcha.module';
import { PublicModule } from './public/public.module';
import { searchDelay, urlAPI } from './shared/constants';
import { featureFlagServiceProvider } from './shared/feature-flags/feature-flags.service.provider';
import 'src/app/lib/quill-formatters/video-module-formatter';
import 'src/app/lib/quill-formatters/link-formatter';
import { AppErrorHandlerService } from './shared/services/app.ErrorHandler';
import { AWSInterceptor } from './shared/aws.interceptor';

registerLocaleData(localeEsAR, 'es-AR');

const frontEndConfigService = new InjectionToken<FrontEndConfig>('FrontEndConfig');
const config: FrontEndConfig = {
  delaySearchMilliseconds: searchDelay,
  apiURL: urlAPI,
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HammerModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NgxNeoComponentsModule,
    NgxNeoFrontendMatModule.forRoot({
      apiURL: urlAPI,
      delaySearchMilliseconds: searchDelay,
    }),
    NgxNeoCompleterMatModule.forRoot(),
    NgxNeoPipesModule.forRoot(),
    NgxNeoLoaderModule.forRoot(),
    NgxNeoModalMatModule,
    CoreModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    RecaptchaModule.forRoot(),
    QuillModule.forRoot(),
    QuillConfigModule.forRoot({
      ...quillModuloCelular,
      customModules: [
        {
          implementation: EmojiModule,
          path: 'modules/emoji',
        },
      ],
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorker === true,
      registrationStrategy: 'registerImmediately',
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: DefaultTranslationHelper,
      },
    }),
    PublicModule,
    MatNativeDateModule,
  ],
  providers: [
    featureFlagServiceProvider,
    { provide: 'googleTagManagerId', useValue: 'GTM-MH6XMK8T' },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: ErrorHandler, useClass: AppErrorHandlerService },
    { provide: HttpClient, useClass: HttpClientExtended },
    { provide: HTTP_INTERCEPTORS, useClass: AWSInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig,
    },
    {
      provide: frontEndConfigService,
      useValue: config,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: false } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
    { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { requireSelection: true } },
    { provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, useValue: { hideMultipleSelectionIndicator: true, hideSingleSelectionIndicator: true } },
    { provide: MatStepperIntl, useClass: StepperIntlService },
    CdnImageUrlPipe,
    ProfileImageThumbnailUrlPipe,
    {
      provide: RouteReuseStrategy,
      useClass: ComponentRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
