import { Injectable, signal } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AutocompleteServiceData } from 'src/app/shared/autocomplete-service-selector/autocomplete-service-data';
import { CompleterItem } from 'src/app/shared/lib/ngx-neo-completer-mat/components/completer-item';
import { PaisDTO as CountryDTO } from '@api/interfaces/pais.interface';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { LocalidadBackendService } from '@api/services/localidad-backend.service';
import { LocalidadDTO } from 'src/app/ModelDTO/DTO/localidad.DTO';

@Injectable({
  providedIn: 'root',
})
export class LocalidadSearchService implements AutocompleteServiceData {
  public pais: PaisDTO | CountryDTO;
  public $errorMessage = signal('');

  constructor(public localidadBackendService: LocalidadBackendService) {}

  public displayFn(value: LocalidadDTO): string {
    return `${value?.nombre || ''}${value?.provincia?.nombre ? ` - ${value.provincia.nombre}` : ''}${value?.codigoPostal ? ` (${value.codigoPostal})` : ''}`;
  }

  public getEntitiesSearch(startWith: string, displayFn: (item: any) => string): Observable<CompleterItem[]> {
    return this.localidadBackendService.getLocalidadesAutoComplete({ contiene: startWith, pageSize: 20, paisId: this.pais?.id ?? 0 }).pipe(
      map((res: any[]) =>
        res.map((item: any) => {
          const completerItem: CompleterItem = {
            title: displayFn(item),
            originalObject: item,
          };

          return completerItem;
        }),
      ),
    );
  }
}
