import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';

export class SeleccionFirmantesModalData {
  public personalSeleccionado: PersonalLegajoBasicoDTO[];
  public descripcion: string;
  public nombreBotonConfirmar: string;
  public notificarPorEmail: boolean;
  public modalClosedByConfirm: boolean; // para diferenciar de close por ESC, backdrop click, etc.
  public posicionFirma: ConfiguracionFirmaDigitalPosicionDTO;
  public personalDestacado: PersonalLegajoBasicoDTO[];

  constructor(
    personalElegido = [],
    descripcion = '',
    personalDestacado = [],
    nombreBotonConfirmar = '',
    modalClosedByConfirm = false,
    posicionFirma = null,
  ) {
    this.personalSeleccionado = personalElegido;
    this.descripcion = descripcion;
    this.personalDestacado = personalDestacado;
    this.modalClosedByConfirm = modalClosedByConfirm;
    this.nombreBotonConfirmar = nombreBotonConfirmar;
    this.notificarPorEmail = true;
    this.posicionFirma = posicionFirma;
  }
}
