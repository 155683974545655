import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { TipoUnidadAusencia } from 'src/app/ModelDTO/DTO/tipoUnidadAusencia.ENUM';
import { featureSaldoCC } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { compare } from 'src/app/shared/shared-functions';

enum ColumnNames {
  Nombre = 'Nombre',
  Politica = 'Politica',
  Asignados = 'Asignados',
  Consumidos = 'Consumidos',
  Disponibles = 'Disponibles',
  ConHaberes = 'Con haberes',
}
@Component({
  selector: 'app-lista-posibles-ausencias',
  templateUrl: './lista-posibles-ausencias.component.html',
  styleUrls: ['./lista-posibles-ausencias.component.scss'],
})
export class ListaPosiblesAusenciasComponent implements OnInit, OnDestroy {
  @Input() public tituloAusencia: string;
  @Input() public descripcionAusencia: string;
  @Input() public posiblesAusencias: Array<AusenciaDTO> = new Array<AusenciaDTO>();
  public readonly ColumnNames = ColumnNames;
  public displayedColumnsAusencias: string[] = [];
  public permisoSaldoCC = false;
  private subs = new Subscription();

  constructor(
    private translate: TranslateService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public ngOnInit(): void {
    this.subs.add(
      this.featureFlagService.flags$.subscribe(({ flags }) => {
        this.permisoSaldoCC = FeatureFlagHelper.featureOn(featureSaldoCC, flags);
        this.displayedColumnsAusencias = [ColumnNames.Nombre, ColumnNames.Politica];
        if (this.permisoSaldoCC) {
          this.displayedColumnsAusencias.push(ColumnNames.Asignados, ColumnNames.Consumidos, ColumnNames.Disponibles);
        }
        this.displayedColumnsAusencias.push(ColumnNames.ConHaberes);
      }),
    );
  }

  public sortData(sort: Sort): void {
    const data = this.posiblesAusencias.slice();
    if (!sort.active || !sort.direction?.length) {
      this.posiblesAusencias = data;
      return;
    }

    this.posiblesAusencias = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNames.Nombre:
          return compare(a.nombre, b.nombre, isAsc);
        case ColumnNames.Politica:
          return compare(a.cantUnidades, b.cantUnidades, isAsc);
        case ColumnNames.ConHaberes:
          return compare(a.gozaHaberes, b.gozaHaberes, isAsc);
        default:
          return 0;
      }
    });
  }

  public totalWithUnit(cantUnidades: number, unidad: TipoUnidadAusencia): string {
    // eslint-disable-next-line no-nested-ternary
    const unitKey = unidad === TipoUnidadAusencia.D1ias ? (cantUnidades === 1 ? 'D1ia' : 'D1ias') : cantUnidades === 1 ? 'Hora' : 'Horas';
    return `${cantUnidades} ${this.translate.instant(`ENUM.TIMEOFF.TIPO_UNIDAD_AUSENCIA.${unitKey}`).toLowerCase()}`;
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
