import { Component, Input, ViewChild } from '@angular/core';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { BehaviorSubject } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { SidebarService } from 'src/app/shared/lib/ngx-neo-components-mat/sidebar/sidebar.service';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-datos-personales-agrupados',
  templateUrl: './datos-personales-agrupados.component.html',
})
export class DatosPersonalesAgrupadosComponent {
  @Input() public personalModel: PersonalModelDTO;
  @Input() public modoEdicion = false;
  @Input() public modoEmpresa = false;
  @Input() public contactForm: UntypedFormGroup;
  @ViewChild(MatTabGroup, { static: true }) public matTabs: MatTabGroup;

  public updatePersona = new BehaviorSubject<PersonalModelDTO>(null);
  public selectedTab = 0;

  constructor(
    private sidebarService: SidebarService,
    private activeRoute: ActivatedRoute,
  ) {
    toObservable(this.sidebarService.$collapsedState)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.matTabs?.realignInkBar();
      });

    this.activeRoute.queryParams.pipe(takeUntilDestroyed()).subscribe((params) => {
      if (params.tab !== undefined) {
        this.selectedTab = params.tab;
      }
    });
  }
}
