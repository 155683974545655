import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TerminalGuard } from 'src/app/core/guards/terminal.guard';
import { LoginGuard } from 'src/app/shared/lib/ngx-neo-frontend-mat/guards/login.guard';
import { AppAuthChildGuard, AppAuthGuard } from './core/guards/app-auth.guard';
import { RefreshComponent } from './refresh.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AppAuthGuard, TerminalGuard],
    canActivateChild: [AppAuthChildGuard, TerminalGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [LoginGuard],
    data: { redirect: true },
  },
  {
    path: 'demo',
    loadChildren: () => import('./login-demo/login-demo.module').then((m) => m.LoginDemoModule),
    canActivate: [],
    data: { redirect: true },
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./under-maintenance/under-maintenance.module').then((m) => m.UnderMaintenanceModule),
    canActivate: [],
    data: { redirect: true },
  },
  {
    path: 'ios-landing',
    loadChildren: () => import('./ios-landing/ios-landing.module').then((m) => m.IosLandingModule),
    canActivate: [],
  },
  {
    path: 'resetpass',
    loadChildren: () => import('./blanquear-pass/blanquear-pass.module').then((m) => m.BlanquearPassModule),
  },
  {
    path: 'new',
    loadChildren: () => import('./register/register.module').then((m) => m.RegisterModule),
  },
  {
    path: 'clock-in-out-terminal-qr',
    loadComponent: () =>
      import('./clock-in-out-terminal/clock-in-out-terminal-qr/clock-in-out-terminal-qr.component').then(
        (c) => c.ClockInOutTerminalQrComponent,
      ),
    canActivate: [AppAuthGuard],
  },
  {
    path: 'clock-in-out-terminal-facial',
    loadComponent: () =>
      import('./clock-in-out-terminal/clock-in-out-terminal-facial/clock-in-out-terminal-facial.component').then(
        (c) => c.ClockInOutTerminalFacialComponent,
      ),
    canActivate: [AppAuthGuard],
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: 'refreshComponent',
    component: RefreshComponent,
  },
  /**
   * Esto es necesario ya que no es posible hacer un parseUrl('/').
   * Por eso en role y userType hacemos parseUrl('/home') que es capturado por ese redirect
   *
   */
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
