import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appTrim]',
})
export class TrimDirective {
  constructor(private el: ElementRef, private ngControl: NgControl) {}

  @HostListener('blur') onBlur() {
    if (this.el.nativeElement?.value) {
      this.ngControl.control.setValue(this.el.nativeElement.value.trim());
    }
  }
}
