import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { LocalidadDTO } from '@api/interfaces/localidad.interface';
import { LocalidadBackendService } from '@api/services/localidad-backend.service';
import { PersonalLegajoBackendService } from '@api/services/personal-legajo-backend.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, EMPTY, from, Observable, Subscription, switchMap, tap } from 'rxjs';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { LocalidadSearchService } from 'src/app/shared/services/search/localidad-search.service';
import { compareDtoId, domicilioGoogleMaps } from 'src/app/shared/shared-functions';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';

const createLocalidadSearchService = (localidadBackendService: LocalidadBackendService): LocalidadSearchService =>
  new LocalidadSearchService(localidadBackendService);

@Component({
  selector: 'app-datos-contacto',
  templateUrl: './datos-contacto.component.html',
  styleUrls: ['./datos-contacto.component.scss'],
  providers: [
    {
      provide: 'LocalidadSearchCurrentCountry',
      useFactory: createLocalidadSearchService,
      deps: [LocalidadBackendService],
    },
    {
      provide: 'LocalidadSearchNotificationCountry',
      useFactory: createLocalidadSearchService,
      deps: [LocalidadBackendService],
    },
    {
      provide: 'LocalidadSearchContactCountry',
      useFactory: createLocalidadSearchService,
      deps: [LocalidadBackendService],
    },
  ],
})
export class DatosContactoComponent implements OnInit, OnDestroy {
  @Input() public modoEmpresa = false;
  @Input() public contactForm: UntypedFormGroup;

  @Input() public set modoEdicion(editMode) {
    this.editMode = editMode;
  }

  public personal: PersonalModelDTO;
  public readonly domicilioGoogleMaps = domicilioGoogleMaps;
  public readonly compareId = compareDtoId;
  public pendienteValidacion = false;
  public paises: PaisDTO[] = [];
  public reenviarNuevoMailCmd: ICommand = new Command(() => this.reenviarNuevoMail(), new BehaviorSubject(true), true);
  public editMode = false;

  public get canEditNotificationAddress(): boolean {
    return !this.personal?.Domicilio?.direccion?.length || this.modoEmpresa;
  }

  public get accessEmail(): string {
    return `mailto:${this.personal?.Email}`;
  }

  public get companyEmail(): string {
    return `mailto:${this.personal?.Legajo.emailEmpresa}`;
  }

  public get personalPhoneNumber(): string {
    return `tel:${this.personal?.Telefonos}`;
  }

  public get enableNotificationAddressMap(): boolean {
    return !this.editMode && this.contactForm?.get('notificationAddress').value;
  }

  public get enableCurrentAddressMap(): boolean {
    return !this.editMode && this.contactForm?.get('currentAddress').value;
  }

  public get enableEmergencyAddressMap(): boolean {
    return !this.editMode && this.contactForm?.get('emergency.emergencyContactAddress').value;
  }

  public get canGenerateDeclaracionJurada(): boolean {
    const notificationLocation = this.contactForm?.get('notificationLocation')?.value;
    const notificationAddress = this.contactForm?.get('notificationAddress')?.value;

    return !!notificationLocation && !!notificationAddress;
  }

  public get disabledTooltip(): string {
    // eslint-disable-next-line no-nested-ternary
    return this.editMode
      ? 'EMPLOYEES.ADRESS_SWORN_STATEMENT_SAVE_DATA'
      : !this.canGenerateDeclaracionJurada
        ? 'EMPLOYEES.ADRESS_SWORN_STATEMENT_COMPLETE_DATA'
        : null;
  }

  private subs = new Subscription();
  private readonly pageSize = 10;

  constructor(
    @Inject('LocalidadSearchCurrentCountry') public localidadSearchCurrentCountry: LocalidadSearchService,
    @Inject('LocalidadSearchNotificationCountry') public localidadSearchNotificationCountry: LocalidadSearchService,
    @Inject('LocalidadSearchContactCountry') public localidadSearchContactCountry: LocalidadSearchService,
    private perfilEmpleadoService: PerfilEmpleadoSharedService,
    private personalLegajoBackendService: PersonalLegajoBackendService,
    private translateService: TranslateService,
    private neoModalService: NgxNeoModalMatService,
    private snackBar: SnackBarService,
    private localidadBackendService: LocalidadBackendService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.paises = await this.perfilEmpleadoService.obtenerPaises();

    this.subs.add(this.perfilEmpleadoService.empleadoArrived$.subscribe((persona) => this.patchValues(persona)));

    this.subs.add(
      this.contactForm.controls.notificationCountry.valueChanges
        .pipe(
          tap((country) => {
            this.localidadSearchNotificationCountry.pais = country;

            if (this.canEditNotificationAddress) {
              this.contactForm.controls.notificationLocation.setValue(null);
              this.contactForm.controls.notificationLocation.enable();
              this.localidadSearchNotificationCountry.$errorMessage.set('GENERAL.TYPE_A_CITY');
            }
          }),
          switchMap((country) => this.getLocalidades(country)),
        )
        .subscribe((locations) => {
          this.handleLocation(locations, this.contactForm.controls.notificationLocation, this.localidadSearchNotificationCountry);
        }),
    );

    this.subs.add(
      this.contactForm.controls.notificationLocation.valueChanges.subscribe((location) => {
        if (location?.id) {
          this.localidadSearchNotificationCountry.$errorMessage.set('');
        }
      }),
    );

    this.subs.add(
      this.contactForm.controls.currentCountry.valueChanges
        .pipe(
          tap((country) => {
            this.localidadSearchCurrentCountry.pais = country;
            this.contactForm.controls.currentLocation.setValue(null);
            this.contactForm.controls.currentLocation.enable();
            this.localidadSearchCurrentCountry.$errorMessage.set('GENERAL.TYPE_A_CITY');
          }),
          switchMap((country) => this.getLocalidades(country)),
        )
        .subscribe((locations) => {
          this.handleLocation(locations, this.contactForm.controls.currentLocation, this.localidadSearchCurrentCountry);
        }),
    );

    this.subs.add(
      this.contactForm.controls.currentLocation.valueChanges.subscribe((location) => {
        if (location?.id) {
          this.localidadSearchCurrentCountry.$errorMessage.set('');
        }
      }),
    );

    this.subs.add(
      this.contactForm
        .get('emergency.emergencyContactCountry')
        .valueChanges.pipe(
          tap((country) => {
            this.localidadSearchContactCountry.pais = country;
            this.contactForm.get('emergency.emergencyContactLocation').setValue(null);
            this.contactForm.get('emergency.emergencyContactLocation').enable();
            this.localidadSearchContactCountry.$errorMessage.set('GENERAL.TYPE_A_CITY');
          }),
          switchMap((country) => this.getLocalidades(country)),
        )
        .subscribe((locations) => {
          this.handleLocation(locations, this.contactForm.get('emergency.emergencyContactLocation'), this.localidadSearchContactCountry);
        }),
    );

    this.subs.add(
      this.contactForm.get('emergency.emergencyContactLocation').valueChanges.subscribe((location) => {
        if (location?.id) {
          this.localidadSearchContactCountry.$errorMessage.set('');
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  public async reenviarNuevoMail(): Promise<void> {
    const res = await this.neoModalService.decision('Está a punto de enviar el correo de validación de la dirección de e-mail');
    if (res.ButtonResponse === AlertButton.Accept) {
      await this.perfilEmpleadoService.reenviarNuevoMail(this.personal);
      this.snackBar.showInfo('E-mail enviado con éxito');
    }
  }

  public generateDeclaracionJurada = (): Observable<unknown> => {
    if (this.personal.Domicilio) {
      const data = `\n\n${this.personal.NombreCompleto} - ${this.personal.Cuil}. \n ${this.personal.Domicilio.direccion}, ${this.personal.Domicilio.localidad?.nombre}, ${this.personal.Domicilio.localidad?.provincia?.pais?.nombre}.`;
      const previewMessage = this.translateService.instant('EMPLOYEES.ADDRESS_SWORN_STATEMENT_CONFIRM', { data });

      return from(this.neoModalService.decision(previewMessage)).pipe(
        switchMap((res) => {
          if (res.ButtonResponse === AlertButton.Accept) {
            return this.personalLegajoBackendService.insertPersonalLegajosIdDeclaraciondomicilio(this.personal.Legajo.id);
          }
          return EMPTY;
        }),
        tap(() => {
          this.snackBar.showInfo('EMPLOYEES.ADDRESS_SWORN_STATEMENT_SUCCESS');
        }),
      );
    }

    return EMPTY;
  };

  private patchValues(personalModel: PersonalModelDTO): void {
    this.personal = personalModel;
    if (personalModel) {
      if (personalModel.DomicilioResidencia?.localidad?.provincia?.pais?.id > 0) {
        const paisResidencia = this.paises.find((x) => x.id === personalModel.DomicilioResidencia.localidad.provincia.pais.id);
        this.contactForm.controls.currentCountry.patchValue(paisResidencia, { emitEvent: false });
        this.localidadSearchCurrentCountry.pais = paisResidencia;
      } else {
        this.localidadSearchCurrentCountry.$errorMessage.set('GENERAL.SELECT_A_COUNTRY');
      }

      if (personalModel.Domicilio?.localidad?.provincia?.pais?.id > 0) {
        const pais = this.paises.find((x) => x.id === personalModel.Domicilio.localidad.provincia.pais.id);
        this.contactForm.controls.notificationCountry.patchValue(pais, { emitEvent: false });
        this.localidadSearchNotificationCountry.pais = pais;
      } else {
        this.localidadSearchNotificationCountry.$errorMessage.set('GENERAL.SELECT_A_COUNTRY');
      }

      if (personalModel.ContactoEmergencia?.domicilio?.localidad?.provincia?.pais?.id > 0) {
        const paisContactoEmergencia = this.paises.find(
          (x) => x.id === personalModel.ContactoEmergencia.domicilio.localidad.provincia.pais.id,
        );
        this.contactForm.controls.emergency.patchValue({ emergencyContactCountry: paisContactoEmergencia }, { emitEvent: false });
        this.localidadSearchContactCountry.pais = paisContactoEmergencia;
      } else {
        this.localidadSearchContactCountry.$errorMessage.set('GENERAL.SELECT_A_COUNTRY');
      }

      this.contactForm.patchValue({
        phone: personalModel.Telefonos,
        accessEmail: personalModel.Email,
        companyEmail: personalModel.Legajo.emailEmpresa,
        currentAddress: personalModel.DomicilioResidencia?.direccion,
        currentLocation: personalModel.DomicilioResidencia?.localidad,
        notificationAddress: personalModel.Domicilio?.direccion,
        notificationLocation: personalModel.Domicilio?.localidad,
      });

      this.contactForm.controls.emergency.patchValue({
        emergencyContactName: personalModel.ContactoEmergencia?.nombreCompleto,
        emergencyContactPhone: personalModel.ContactoEmergencia?.telefonos,
        emergencyContactEmail: personalModel.ContactoEmergencia?.email,
        emergencyContactAddress: personalModel.ContactoEmergencia?.domicilio.direccion,
        emergencyContactLocation: personalModel.ContactoEmergencia?.domicilio.localidad,
      });

      this.pendienteValidacion = personalModel.ConfirmarEmail;
    }
  }

  private handleLocation(locations: LocalidadDTO[], control: AbstractControl, service: LocalidadSearchService): void {
    const unknownLocation = locations.find((location) => location.nombre === 'Desconocida');

    if (unknownLocation && locations.length === 1) {
      control.setValue(unknownLocation);
      service.$errorMessage.set('EMPLOYEES.NO_LOCATIONS_AVAILABLE');
    }
  }

  private getLocalidades(country: PaisDTO): Observable<LocalidadDTO[]> {
    return this.localidadBackendService.getLocalidadesAutoComplete({
      contiene: null,
      pageSize: this.pageSize,
      paisId: country?.id,
      includeAll: true,
    });
  }
}
