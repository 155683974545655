import { IEntityDTO } from './entity.DTO';
import { NamedBlobDTO } from './namedBlob.DTO';

export class ImageFileDTO implements IEntityDTO {
  maxHeightInPixels = 0;
  maxWidthInPixels = 0;
  maxSizeInBytes = 0;
  image = '';
  id = 0;
  cacheStamp = 0;

  constructor() {}

  public PrepareDTO(jsonObj): void {
    if (jsonObj === null) {
      return;
    }
    if (jsonObj.maxHeightInPixels != null) {
      this.maxHeightInPixels = jsonObj.maxHeightInPixels;
    }
    if (jsonObj.maxWidthInPixels != null) {
      this.maxWidthInPixels = jsonObj.maxWidthInPixels;
    }
    if (jsonObj.maxSizeInBytes != null) {
      this.maxSizeInBytes = jsonObj.maxSizeInBytes;
    }
    if (jsonObj.image != null) {
      this.image = jsonObj.image;
    }
    if (jsonObj.id != null) {
      this.id = jsonObj.id;
    }
    if (jsonObj.cacheStamp != null) {
      this.cacheStamp = jsonObj.cacheStamp;
    }
  }

  public isNewEntity(): boolean {
    return this.id === 0;
  }
}
