import { Injectable } from '@angular/core';
import { EstadisticaTotalesDTO } from 'src/app/ModelDTO/DTO/estadisticaTotales.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { ModalidadContractualServiceBackend } from 'src/app/ServiceBackend/modalidadContractual.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class TipoModalidadService {
  constructor(private modalidadServiceBackend: ModalidadContractualServiceBackend) {}

  public async obtenerModalidadesContractuales(): Promise<Array<ModalidadContractualDTO>> {
    return this.modalidadServiceBackend.getModalidad();
  }

  public async obtenerModalidadContractualId(id: number): Promise<ModalidadContractualDTO> {
    return this.modalidadServiceBackend.getModalidadId(id);
  }

  public async insertarModalidadContractual(modalidad: ModalidadContractualDTO): Promise<ModalidadContractualDTO> {
    return this.modalidadServiceBackend.insertModalidad(modalidad);
  }

  public async actualizarModalidadContractual(id: number, modalidad: ModalidadContractualDTO): Promise<ModalidadContractualDTO> {
    return this.modalidadServiceBackend.updateModalidadId(id, modalidad);
  }

  public async eliminarModalidadContractual(id: number): Promise<void> {
    return this.modalidadServiceBackend.deleteModalidadId(id);
  }

  public async teammatesByContract(contract: ModalidadContractualDTO): Promise<EstadisticaTotalesDTO> {
    return this.modalidadServiceBackend.getModalidadIdTeammatesCount(contract.id);
  }
}
