import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, CanActivateFn, CanActivateChildFn } from '@angular/router';
import { inject } from '@angular/core';
import { HeaderAppService } from 'src/app/core/header/header-app.service';

// eslint-disable-next-line consistent-return
export const AppAuthGuard: CanActivateFn = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree => {
  const router = inject(Router);
  const headerService = inject(HeaderAppService);
  const cordovaService = inject(CordovaService);

  if (headerService.IsLogged()) {
    return true;
  }
  if (headerService.userLogged.role.id === 50) {
    if (!state.url.startsWith('/clock-in-out-terminal-qr')) {
      return router.parseUrl('/clock-in-out-terminal-qr');
    }
  } else if (cordovaService.isIOSApp) {
    return router.parseUrl('/ios-landing');
  } else {
    return router.parseUrl(`/login?redirectUrl=${state.url}`);
  }
};

export const AppAuthChildGuard: CanActivateChildFn = (): boolean | UrlTree => {
  const router = inject(Router);
  const headerService = inject(HeaderAppService);
  const cordovaService = inject(CordovaService);

  if (headerService.IsLogged()) {
    return true;
  }
  if (cordovaService.isIOSApp) {
    return router.parseUrl('/ios-landing');
  }
  return router.parseUrl('/login');
};
