import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSortModule, Sort } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { SeleccionFirmantesModalData } from 'src/app/app-common/previsualizacion-documentos/seleccion-firmantes-modal/seleccion-firmantes-modal-data';
import { SeleccionFirmantesModalComponent } from 'src/app/app-common/previsualizacion-documentos/seleccion-firmantes-modal/seleccion-firmantes-modal.component';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { featureFirmaDigital } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { compare, fileIconClass } from 'src/app/shared/shared-functions';
import { TipoCategoriaDocumento } from '@api/enums/tipo-categoria-documento.enum';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { MatTooltipModule } from '@angular/material/tooltip';
import { WarningCardComponent } from 'src/app/shared/warning-card/warning-card.component';
import { MatIconModule } from '@angular/material/icon';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-neo-directives.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PersonalLegajoBasicoDTO as EmployeeRecordFile } from '@api/interfaces/personal-legajo-basico.interface';

import { AttachmentUaalooModelDTOExtended } from './attachment.ModelDTO.extended';
import { AttachmentSelectorService } from './attachment-selector.service';

enum ColumnNames {
  Nombre = 'nombre',
  Acciones = 'acciones',
}
@Component({
  selector: 'app-attachment-selector',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatTooltipModule,
    MatIconModule,
    NgxNeoDirectivesModule,
    WarningCardComponent,
  ],
  templateUrl: './attachment-selector.component.html',
  styleUrls: ['./attachment-selector.component.scss'],
})
export class AttachmentSelectorComponent implements OnInit, OnDestroy {
  @Input() public accept = 'image/jpeg, image/png, image/jpg, application/pdf,.doc,.docx,.xls,.xlsx';
  @Input() public onlyOneFile = false;
  @Input() public colaborador?: PersonalLegajoBasicoDTO | EmployeeRecordFile;
  @Input() public visibilityColaborador?: TipoCategoriaDocumento;
  @Input() public attachDisabled = false;

  @ViewChild('fileInput', { static: false }) public fileInput: ElementRef;

  @Input() public set solicitarFirma(value: boolean) {
    this.pSolicitarFirma = value;
    this.establecerColumnasAMostrar();
  }

  public readonly fileIconClass = fileIconClass;

  public attachmentsASubir: Array<AttachmentUaalooModelDTOExtended>;
  public firmanteColaborador: PersonalLegajoBasicoDTO;
  public fileSelectedString: string;
  public displayedColumns: string[] = [ColumnNames.Nombre, ColumnNames.Acciones];
  public alMenosUnArchivoPermiteFirma = false;
  public smallScreen = false;
  public vistaEmpresa$ = this.headerService.vistaEmpresa$;
  public firmaDigitalPermission$: Observable<boolean>;
  public alerta = false;

  public pedirFirmaCmd: ICommand = new Command(() => this.agregarFirmantes(), new BehaviorSubject(true), false);
  public readonly ColumnNames = ColumnNames;

  public get solicitarFirma(): boolean {
    return this.pSolicitarFirma;
  }

  private pSolicitarFirma = false;
  private displayedColumnsEmpresa: string[] = [ColumnNames.Nombre, ColumnNames.Acciones];
  private displayedColumnsColaborador: string[] = [ColumnNames.Nombre, ColumnNames.Acciones];

  private subs: Subscription = new Subscription();

  constructor(
    private headerService: HeaderAppService,
    public attachmentSelectorService: AttachmentSelectorService,
    private dialog: MatDialog,
    private featureFlagService: FeatureFlagService,
  ) {
    this.attachmentSelectorService.init();
    this.firmanteColaborador = new PersonalLegajoBasicoDTO();
    this.attachmentsASubir = [];
    this.establecerColumnasAMostrar();
    this.fileSelectedString = '';
  }

  public ngOnInit(): void {
    this.attachmentSelectorService.borrarYAgregarFirmantesSolicitados(new Array<PersonalLegajoBasicoDTO>());
    this.firmaDigitalPermission$ = this.featureFlagService.flags$.pipe(
      map(({ flags }) => FeatureFlagHelper.featureOn(featureFirmaDigital, flags)),
    );

    this.subs.add(
      this.attachmentSelectorService.arrayAttachmentsASubirChange$.subscribe((attachments) => {
        this.attachmentsASubir = attachments;
        this.alMenosUnArchivoPermiteFirma = attachments?.some((x) => x.AllowsDigitalSignature);
        this.fileSelectedString = attachments.map((x) => x.File.fileName).reduce((p, c) => `${c}, ${p}`, '');
      }),
    );
    this.subs.add(
      this.attachmentSelectorService.firmanteColaboradorChange$.subscribe((firmante) => {
        this.firmanteColaborador = firmante;
      }),
    );

    this.subs.add(
      this.headerService.smallScreen$.subscribe((small) => {
        this.smallScreen = small;
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  public sortData(sort: Sort): number {
    const data = this.attachmentsASubir.slice();
    if (!sort.active || !sort.direction?.length) {
      this.attachmentsASubir = data;
      return;
    }

    this.attachmentsASubir = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNames.Nombre:
          return compare(a.File.fileName, b.File.fileName, isAsc);
        default:
          return 0;
      }
    });
  }

  public establecerColumnasAMostrar(): void {
    this.displayedColumns =
      this.headerService.modoEmpresa() && this.solicitarFirma ? this.displayedColumnsEmpresa : this.displayedColumnsColaborador;
  }

  public async onDocumentacionChange(event: Event): Promise<void> {
    const { files } = event.target as HTMLInputElement;

    if (files) {
      if (this.onlyOneFile) {
        this.attachmentSelectorService.removerAdjuntos();
      }

      Array.from(files).forEach(async (file) => {
        await this.attachmentSelectorService.agregarNuevoFile(file);
      });
      this.fileInput.nativeElement.value = '';
    }
  }

  public removerAdjunto(adjunto: AttachmentUaalooModelDTOExtended): void {
    this.attachmentSelectorService.removerAdjunto(adjunto);
  }

  public agregarFirmantes(): void {
    const descripcionModal = 'Seleccionar los colaboradores que deben firmar el(los) documento(s) en PDF.';
    const personalDestacado = this.colaborador?.id ? [this.colaborador] : [];
    const dialogRef = this.dialog.open(SeleccionFirmantesModalComponent, {
      panelClass: ['full-size-sm-600-lg-modal', 'full-height-sm-700-lg-modal'],
      disableClose: false,
      data: new SeleccionFirmantesModalData(this.attachmentSelectorService.firmantesSeleccionados, descripcionModal, personalDestacado),
    });
    dialogRef.afterClosed().subscribe((passedData: SeleccionFirmantesModalData) => {
      if (passedData && passedData.modalClosedByConfirm) {
        this.attachmentSelectorService.posicionFirma = passedData.posicionFirma;
        this.attachmentSelectorService.notificarPorMail = passedData.notificarPorEmail;
        this.attachmentSelectorService.borrarYAgregarFirmantesSolicitados(passedData.personalSeleccionado ?? []);
      }
      this.mostrarAlerta(passedData);
    });
  }

  private async mostrarAlerta(passedData: SeleccionFirmantesModalData): Promise<void> {
    this.alerta = false;
    const colaboradorId = this.colaborador?.id;
    if (TipoCategoriaDocumento[this.visibilityColaborador] === TipoCategoriaDocumento[TipoCategoriaDocumento.Colaborador]) {
      passedData?.personalSeleccionado?.forEach((res) => {
        if (colaboradorId !== res.id && this.headerService.personalLegajoId !== res.id) {
          this.alerta = true;
        }
      });
    }
  }
}
