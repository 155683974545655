import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { BehaviorSubject } from 'rxjs';
import { CdnImageUrlPipe } from 'src/app/shared/pipes/cdn-image-url.pipe';
import { PerfilEmpresaService } from 'src/app/layout/administrador/configuracion/perfil-empresa/perfil-empresa.service';
import { TenantResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/tenantResponse.DTO';

export enum TenantModalMode {
  ACCOUNT,
  ROLE,
}

export interface TenantModalInfo {
  tenants: Array<TenantResponseDTO>;
  mode: TenantModalMode;
}

export interface TenantModalResponse {
  tenant: TenantResponseDTO;
}

@Component({
  selector: 'app-select-account-modal',
  templateUrl: './select-account-modal.component.html',
  styleUrls: ['./select-account-modal.component.scss'],
  providers: [CdnImageUrlPipe],
})
export class SelectAccountModalComponent implements OnInit {
  public tenantCmd: ICommand = new Command((tenant: TenantResponseDTO) => this.tenantSelect(tenant), new BehaviorSubject(true), false);

  public originalDataSource: TenantResponseDTO[] = [];
  public filteredDataSource: TenantResponseDTO[] = [];
  public $company = this.empresaService.company$;
  public TenantModalMode = TenantModalMode;
  public searchText = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TenantModalInfo,
    private dialogRef: MatDialogRef<SelectAccountModalComponent>,
    private cdnImageUrlPipe: CdnImageUrlPipe,
    private empresaService: PerfilEmpresaService,
  ) {}

  public ngOnInit(): void {
    this.originalDataSource = this.data.tenants?.filter((company) => !company.name?.includes('no usar'));
    this.filterDataSource();
  }

  public onSearchTextChange(searchText: string): void {
    this.searchText = searchText || '';
    this.filterDataSource(this.searchText);
  }

  public getImageUrl(tenant: TenantResponseDTO): string {
    if (tenant.image) {
      return this.cdnImageUrlPipe.transform(tenant.image);
    }
    return 'assets/img/logo-mini.png';
  }

  public close(): void {
    this.dialogRef.close();
  }

  private filterDataSource(searchText: string = ''): void {
    const source = [...this.originalDataSource];

    if (searchText) {
      this.filteredDataSource = source.filter((tenant: TenantResponseDTO) =>
        tenant?.name?.toLowerCase().includes(searchText.toLowerCase()),
      );
    } else {
      this.filteredDataSource = source;
    }
  }

  private tenantSelect(tenant: TenantResponseDTO): void {
    this.dialogRef.close({ tenant });
  }
}
