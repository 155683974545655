import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'calcularEdad',
})
export class CalcularEdadPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: Date): string {
    const momentDate = DateTime.fromJSDate(date);
    const hoy = DateTime.now();
    if (date && hoy > momentDate) {
      const diff = hoy.diff(momentDate);
      let edad = Math.trunc(diff.as('years'));
      if (edad === 0) {
        edad = Math.trunc(diff.as('months'));
        switch (edad) {
          case 0:
            return this.translateService.instant('PROFILE.FAMILY.LESS_THAN_A_MONTH');
          case 1:
            return `1 ${this.translateService.instant('GENERAL.MONTH').toLowerCase()}`;
          default:
            return `${edad} ${this.translateService.instant('GENERAL.MONTHS').toLowerCase()}`;
        }
      } else {
        switch (edad) {
          case 1:
            return `1 ${this.translateService.instant('GENERAL.YEAR').toLowerCase()}`;
          default:
            return `${edad} ${this.translateService.instant('GENERAL.YEARS').toLowerCase()}`;
        }
      }
    } else {
      return '';
    }
  }
}
