export const FCM = 'fcm';

export const CURRENT_USER_WEB = 'currentUserWeb';

export const CURRENT_COMPANY = 'currentCompany';

export const TERMINAL = 'terminal';

export const SCROLL = 'scroll';

export const LAST_ABSENCES_MENU_OPTION_SELECTED = 'lastAbsencesMenuOptionSelected';

export const NEO_COMPONENT_ASYNC = 'NeoComponentAsync';

export const LAST_RELOAD = 'lastReload';

export const LAST_CLOCK_IN_OUT = 'lastClockInOut';

export const LAST_CLOCK_IN_OUT_MODE = 'lastClockInOutMode';
export const MODE_QR = 'qr';
export const MODE_REMOTE = 'remote';

export const USER_PERMISSION_FLAGS = 'userPermissionFlags';

export const WEB_DEVICE_ID = 'webDeviceId';

export const CHAT_AUTH = 'chatToken';
export const CHAT_WIDGET = 'chatWidget';

export const GLOBAL_SETTINGS = 'GlobalSettings';
export const GLOBAL_SETTINGS_TIMESTAMP = 'GlobalSettingsTimestamp';

export const PINNED_STATE_KEY = 'pinnedState';
