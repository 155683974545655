import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { SeleccionFirmantesModalData } from 'src/app/app-common/previsualizacion-documentos/seleccion-firmantes-modal/seleccion-firmantes-modal-data';
import { GlobalSettingsService } from 'src/app/layout/administrador/configuracion/configuracion-sistema/global-settings.service';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { Command } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { PersonalSearchService } from 'src/app/shared/services/search/personal-search.service';
import { compareDtoId } from 'src/app/shared/shared-functions';

@Component({
  selector: 'app-seleccion-firmantes-modal',
  templateUrl: './seleccion-firmantes-modal.component.html',
  styleUrls: ['./seleccion-firmantes-modal.component.scss'],
})
export class SeleccionFirmantesModalComponent implements OnInit {
  public completerModel: any;
  public posicionesFirma: ConfiguracionFirmaDigitalPosicionDTO[];
  public readonly compareId = compareDtoId;
  public confirmarCmd = new Command(() => this.confirmar(), new BehaviorSubject(true), false);

  constructor(
    private dialogRef: MatDialogRef<SeleccionFirmantesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public passedData: SeleccionFirmantesModalData,
    public personalSearch: PersonalSearchService,
    private globalSettingsService: GlobalSettingsService,
  ) {}

  public async ngOnInit(): Promise<void> {
    const configFirmaDigital = await this.globalSettingsService.getSettings();
    this.posicionesFirma = configFirmaDigital.firmaDigital.posicionesFirma;
    this.passedData.posicionFirma =
      this.posicionesFirma.find((pos) => pos.nombre?.toLocaleLowerCase().includes('colaborador')) ?? this.posicionesFirma?.[0];
  }

  public volver(): void {
    this.dialogRef.close(this.passedData);
  }

  private async confirmar(): Promise<void> {
    this.passedData.modalClosedByConfirm = true;
    this.dialogRef.close(this.passedData);
  }
}
