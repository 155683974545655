import { ChangeDetectionStrategy, Component, inject, Input, OnChanges, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatOption } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RoleDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/role.DTO';
import { BannerComponent } from 'src/app/shared/banner/banner.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { combineLatest, distinctUntilChanged, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { featureBorradoEmpresa } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { RoleServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/backend/role.ServiceBackend';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { ThrottledDirective } from 'src/app/shared/directives/throttled-execution.directive';
import { compareDtoId } from 'src/app/shared/shared-functions';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { PersonalLegajoBasicoDTO as PersonalLegajoBasicoDTOClass } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';

@Component({
  selector: 'app-account-user',
  standalone: true,
  imports: [BannerComponent, MatButton, MatFormField, MatOption, MatSelect, TranslateModule, ReactiveFormsModule, ThrottledDirective],
  templateUrl: './account-user.component.html',
  styleUrl: './account-user.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountUserComponent implements OnChanges {
  @Input() public editMode = false;
  @Input() public employeeModel: PersonalModelDTO;

  public $roles = signal<RoleDTO[]>([]);

  public formControlRole = new FormControl<number>(0);

  public compareDtoId = compareDtoId;

  public get disabledTeammateStatus(): boolean {
    return !this.employeeModel?.Legajo.estadoActual?.tipo?.esAlta;
  }

  private readonly translate = inject(TranslateService);
  private readonly featureService = inject(FeatureFlagService);
  private readonly rolesService = inject(RoleServiceBackend);
  private readonly employeeProfileSharedService = inject(PerfilEmpleadoSharedService);
  private readonly snackBar = inject(SnackBarService);
  private readonly neoModalService = inject(NgxNeoModalMatService);

  constructor() {
    combineLatest([this.featureService.flags$, this.rolesService.getUsuariosrolesBasic()])
      .pipe(
        takeUntilDestroyed(),
        map(([flags, roles]) => {
          const canDeleteCompany = FeatureFlagHelper.featureOn(featureBorradoEmpresa, flags.flags);
          return { roles, canDeleteCompany };
        }),
      )
      .subscribe(({ roles, canDeleteCompany }) => {
        this.$roles.set(roles.filter((x) => (x.id === 1 && canDeleteCompany) || (x.id !== 1 && x.id !== 50)));
      });
    this.formControlRole.valueChanges
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(),
        switchMap(() => this.changeRole()),
      )
      .subscribe((employeeRecord) => {
        if (employeeRecord) {
          this.snackBar.showInfo('GENERAL.SUCCESSFUL_MODIFICATION');
          this.employeeModel.Legajo.esAdmin = employeeRecord.esAdmin;
        }
        this.formControlRole.setValue(this.employeeModel.Legajo.esAdmin);
      });
  }

  public ngOnChanges(): void {
    if (this.employeeModel) {
      this.formControlRole.setValue(this.employeeModel.Legajo.esAdmin);
    }
  }

  public invalidarToken(): Observable<void> {
    return from(this.employeeProfileSharedService.invalidarToken(this.employeeModel.Legajo)).pipe(
      tap(() => {
        this.snackBar.showInfo('EMPLOYEES.AUTHENTICATION_INVALIDATED_SUCCESS');
      }),
    );
  }

  private changeRole(): Observable<PersonalLegajoBasicoDTOClass | null> {
    if (this.employeeModel.Legajo.esAdmin !== this.formControlRole.value) {
      const legajoDTO = new PersonalLegajoBasicoDTOClass();
      legajoDTO.id = this.employeeModel.Legajo.id;
      legajoDTO.esAdmin = this.formControlRole.value;
      const msg = this.translate.instant('SETTINGS.PERMISSIONS.CHANGE_ROLE', {
        name: this.employeeModel.Nombre,
        lastname: this.employeeModel.Apellido,
      });
      return from(this.neoModalService.decision(msg)).pipe(
        switchMap((confirm) => {
          if (confirm.ButtonResponse === AlertButton.Accept) {
            return from(this.employeeProfileSharedService.cambiarRolPersonalLegajo(legajoDTO)).pipe(map(() => legajoDTO));
          }
          return of(null);
        }),
      );
    }
    return of(null);
  }
}
