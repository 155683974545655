import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-neo-directives.module';
import { DateSelectorComponent } from './date-selector/date-selector.component';
import { MobileSidebarComponent } from './mobile-sidebar/mobile-sidebar.component';
import { NgxNeoComponentsComponent } from './ngx-neo-components.component';
import { NgxSharedModule } from './ngx-shared.module';
import { PullToRefreshComponent } from './pull-to-refresh/pull-to-refresh.component';
import { SliderComponent } from './slider/slider.component';
import { HoursDirective } from './timepicker/nextHour.directive';
import { TimepickerComponent } from './timepicker/timepicker.component';
import { UndoElementsDirective } from './undo-component/undo-elements.directive';
import { UndoComponent } from './undo-component/undo.component';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    NgxNeoComponentsComponent,
    MobileSidebarComponent,
    PullToRefreshComponent,
    SliderComponent,
    UndoComponent,
    UndoElementsDirective,
    TimepickerComponent,
    HoursDirective,
    DateSelectorComponent,
  ],
  imports: [
    CommonModule,
    CdkStepperModule,
    ScrollingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSharedModule,
    NgxNeoDirectivesModule,
    NgxMaskModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    NgxNeoComponentsComponent,
    MobileSidebarComponent,
    PullToRefreshComponent,
    SliderComponent,
    UndoComponent,
    UndoElementsDirective,
    TimepickerComponent,
    HoursDirective,
    DateSelectorComponent,
  ],
})
export class NgxNeoComponentsModule {}
