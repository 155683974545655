<div class="d-flex flex-column flex-grow-1">
  <h4 class="primary-color">{{ 'GENERAL.GENERAL' | translate }}</h4>
  <form [formGroup]="contactForm">
    <div class="d-flex flex-row flex-wrap gap-2">
      <div class="d-flex flex-grow-1">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'PROFILE.PHONE_NUMBERS' | translate }}</mat-label>
          <input matInput id="tel" name="tel" formControlName="phone" />
          @if (this.personal?.Telefonos) {
            <a
              target="_self"
              mat-icon-button
              matSuffix
              [matTooltip]="'GENERAL.CALL' | translate"
              color="accent"
              [href]="personalPhoneNumber"
            >
              <mat-icon fontSet="material-symbols-outlined">phone</mat-icon>
            </a>
          }
        </mat-form-field>
      </div>
      <div class="d-flex flex-grow-1">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'PROFILE.EMAIL_ACCESS' | translate }}</mat-label>
          <input matInput id="em" name="em" formControlName="accessEmail" appTrim [onReturn]="eme" />
          <div matSuffix class="align-items-center d-flex">
            @if (pendienteValidacion) {
              <a
                color="warn"
                mat-icon-button
                [matTooltip]="'GENERAL.PENDING_VALIDATION' | translate"
                [command]="reenviarNuevoMailCmd"
                [commandValue]="false"
              >
                <mat-icon fontSet="material-symbols-outlined">warning</mat-icon>
              </a>
            }
            @if (this.personal?.Email) {
              <a target="_blank" mat-icon-button [matTooltip]="'GENERAL.SEND_AN_EMAIL' | translate" color="accent" [href]="accessEmail">
                <mat-icon fontSet="material-symbols-outlined">email</mat-icon>
              </a>
            }
          </div>
        </mat-form-field>
      </div>
      <div class="d-flex flex-grow-1">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'PROFILE.COMPANY_EMAIL' | translate }}</mat-label>
          <input #eme matInput id="eme" name="eme" appTrim formControlName="companyEmail" />
          @if (this.personal?.Legajo.emailEmpresa) {
            <a
              target="_blank"
              mat-icon-button
              matSuffix
              [matTooltip]="'GENERAL.SEND_AN_EMAIL' | translate"
              color="accent"
              [href]="companyEmail"
            >
              <mat-icon fontSet="material-symbols-outlined">email</mat-icon>
            </a>
          }
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row gap-1 align-items-start">
      <div class="d-flex d-flex-full">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'PROFILE.COUNTRY' | translate }}</mat-label>
          <mat-select formControlName="currentCountry" [compareWith]="compareId" name="currentCountry">
            <mat-select-trigger>
              <app-country [$country]="contactForm.get('currentCountry').value" />
            </mat-select-trigger>
            <mat-option *ngFor="let pais of paises" [value]="pais">
              <app-country [$country]="pais" />
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex flex-column d-flex-full">
        <app-autocomplete-service-selector
          class="flex-fill"
          [label]="'GENERAL.LOCATION' | translate"
          [hint]="editMode ? localidadSearchCurrentCountry.$errorMessage() : ''"
          [datasource]="localidadSearchCurrentCountry"
          formControlName="currentLocation"
          [displayFn]="localidadSearchCurrentCountry.displayFn"
          subscriptSizing="dynamic"
        />
      </div>
      <div class="d-flex d-flex-full">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'PROFILE.ADDRESS_YOU_LIVE' | translate }}</mat-label>
          <input #dir matInput id="dir" name="dir" [matTooltip]="'PROFILE.ADDRESS_FORMAT' | translate" formControlName="currentAddress" />
          <a
            [disabled]="!enableCurrentAddressMap"
            target="_blank"
            mat-icon-button
            matSuffix
            [matTooltip]="'GENERAL.OPEN_THIS_LOCATION_IN_GOOGLE_MAPS' | translate"
            [matTooltipDisabled]="!enableCurrentAddressMap"
            color="accent"
            [href]="domicilioGoogleMaps(personal?.DomicilioResidencia)"
          >
            <mat-icon fontSet="material-symbols-outlined" svgIcon="google-maps-grey"></mat-icon>
          </a>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row gap-1 align-items-start">
      <div class="d-flex d-flex-full">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'PROFILE.COUNTRY' | translate }}</mat-label>
          <mat-select formControlName="notificationCountry" name="notificationCountry" [compareWith]="compareId">
            <mat-select-trigger>
              <app-country [$country]="contactForm.get('notificationCountry').value" />
            </mat-select-trigger>
            <mat-option *ngFor="let pais of paises" [value]="pais">
              <app-country [$country]="pais" />
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex flex-column d-flex-full">
        <app-autocomplete-service-selector
          class="flex-fill"
          [label]="'GENERAL.LOCATION' | translate"
          [hint]="editMode ? localidadSearchNotificationCountry.$errorMessage() : ''"
          [datasource]="localidadSearchNotificationCountry"
          formControlName="notificationLocation"
          [displayFn]="localidadSearchNotificationCountry.displayFn"
          subscriptSizing="dynamic"
        />
      </div>
      <div class="d-flex d-flex-full">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'PROFILE.ADDRESS_NOTIFICATIONS' | translate }}</mat-label>
          <input
            #dirreside
            matInput
            id="dirReside"
            name="dirReside"
            [matTooltip]="'PROFILE.ADDRESS_FORMAT' | translate"
            formControlName="notificationAddress"
          />
          <a
            [disabled]="!enableNotificationAddressMap"
            target="_blank"
            mat-icon-button
            matSuffix
            [matTooltip]="'GENERAL.OPEN_THIS_LOCATION_IN_GOOGLE_MAPS' | translate"
            [matTooltipDisabled]="!enableNotificationAddressMap"
            color="accent"
            [href]="domicilioGoogleMaps(personal?.Domicilio)"
          >
            <mat-icon fontSet="material-symbols-outlined" svgIcon="google-maps-grey"></mat-icon>
          </a>
        </mat-form-field>
      </div>
    </div>
    @if (modoEmpresa) {
      <div class="d-flex justify-content-right align-items-center">
        <div [matTooltip]="disabledTooltip | translate" [matTooltipDisabled]="!(editMode || !canGenerateDeclaracionJurada)">
          <button
            mat-raised-button
            color="primary"
            [disabled]="editMode || !canGenerateDeclaracionJurada"
            [throttledExecution]="generateDeclaracionJurada"
          >
            {{ 'EMPLOYEES.GENERATE_ADDRESS_SWORN_STATEMENT' | translate }}
          </button>
        </div>
      </div>
    }
    <h4 class="primary-color">{{ 'PROFILE.EMERGENCY' | translate }}</h4>
    <div formGroupName="emergency">
      <div class="d-flex flex-row">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label>{{ 'PROFILE.FULL_NAME' | translate }}</mat-label>
          <input #dir matInput id="nem" name="nem" formControlName="emergencyContactName" />
        </mat-form-field>
      </div>
      <div class="d-flex flex-row flex-wrap gap-1">
        <div class="d-flex flex-grow-1">
          <mat-form-field class="flex-fill" appearance="outline">
            <mat-label>{{ 'PROFILE.PHONE_NUMBERS' | translate }}</mat-label>
            <input matInput type="text" id="teler" name="teler" formControlName="emergencyContactPhone" />
          </mat-form-field>
        </div>
        <div class="d-flex flex-grow-1">
          <mat-form-field class="flex-fill" appearance="outline">
            <mat-label>{{ 'GENERAL.EMAIL' | translate }}</mat-label>
            <input matInput type="text" id="emailer" appTrim name="emailer" formControlName="emergencyContactEmail" />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row gap-1 align-items-start">
        <div class="d-flex d-flex-full">
          <mat-form-field class="flex-fill" appearance="outline">
            <mat-label>{{ 'PROFILE.COUNTRY' | translate }}</mat-label>
            <mat-select formControlName="emergencyContactCountry" [compareWith]="compareId" name="emergencyContactCountry">
              <mat-select-trigger>
                <app-country [$country]="contactForm.get('emergency').get('emergencyContactCountry').value" />
              </mat-select-trigger>
              <mat-option *ngFor="let pais of paises" [value]="pais">
                <app-country [$country]="pais" />
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="d-flex flex-column d-flex-full">
          <app-autocomplete-service-selector
            class="flex-fill"
            [label]="'GENERAL.LOCATION' | translate"
            [hint]="editMode ? localidadSearchContactCountry.$errorMessage() : ''"
            [datasource]="localidadSearchContactCountry"
            formControlName="emergencyContactLocation"
            [displayFn]="localidadSearchContactCountry.displayFn"
            subscriptSizing="dynamic"
          />
        </div>
        <div class="d-flex d-flex-full">
          <mat-form-field class="flex-fill" appearance="outline">
            <mat-label>{{ 'PROFILE.ADDRESS' | translate }}</mat-label>
            <input
              #dir
              matInput
              id="direr"
              name="direr"
              [matTooltip]="'PROFILE.ADDRESS_FORMAT' | translate"
              formControlName="emergencyContactAddress"
            />
            <a
              [disabled]="!enableEmergencyAddressMap"
              target="_blank"
              mat-icon-button
              matSuffix
              color="accent"
              [matTooltip]="'GENERAL.OPEN_THIS_LOCATION_IN_GOOGLE_MAPS' | translate"
              [matTooltipDisabled]="!enableEmergencyAddressMap"
              [href]="domicilioGoogleMaps(personal?.ContactoEmergencia?.domicilio)"
            >
              <mat-icon fontSet="material-symbols-outlined" svgIcon="google-maps-grey"></mat-icon>
            </a>
          </mat-form-field>
        </div>
      </div>
    </div>
  </form>
</div>
