import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { CambioDTO } from 'src/app/ModelDTO/DTO/cambio.DTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { ModalEdicionHistorialComponent } from 'src/app/app-common/perfil-empleado-shared/detalle-empleado/datos-historicos/modal-edicion-historial/modal-edicion-historial.component';

interface HistoryWithPercentage {
  changes: CambioDTO;
  percentage: string;
}

@Component({
  selector: 'app-lista-historial-cambios-con-porcentaje',
  templateUrl: './lista-historial-cambios-con-porcentaje.component.html',
  styleUrls: ['./lista-historial-cambios-con-porcentaje.component.scss'],
})
export class ListaHistorialCambiosConPorcentajeComponent {
  @Input() public titulo: string;
  @Input() public personalModel: PersonalModelDTO;
  @Input() public disabled = false;
  @Input() public set listado(value: CambioDTO[]) {
    this.initialList = value;
    this.buildHistoryWithPercentage(value);
  }

  public historyWithPercentage: HistoryWithPercentage[] = [];
  public editarCmd: ICommand = new Command((value) => this.editarCambio(value), new BehaviorSubject(true), true);
  public eliminarCmd: ICommand = new Command((value) => this.eliminarCambio(value), new BehaviorSubject(true), true);

  private initialList: CambioDTO[];

  constructor(
    private dialog: MatDialog,
    private neoModalService: NgxNeoModalMatService,
    private perfilEmpleadoSharedService: PerfilEmpleadoSharedService,
    private snackBar: SnackBarService,
  ) {}

  private buildHistoryWithPercentage(list: CambioDTO[]): void {
    this.historyWithPercentage = [];
    if (list?.length) {
      this.historyWithPercentage.push({
        changes: list[0],
        percentage: '0%',
      });
      list.forEach((value, index) => {
        if (index !== 0) {
          const initialValue = parseFloat(list[index - 1].estado);
          const finalValue = parseFloat(value.estado);
          const percentage = ((finalValue - initialValue) / initialValue) * 100;
          this.historyWithPercentage.push({
            changes: list[index],
            percentage: `${percentage.toFixed(2)}%`,
          });
        }
      });
    }
  }

  private async editarCambio(value: CambioDTO): Promise<void> {
    const dialogRef = this.dialog.open(ModalEdicionHistorialComponent, {
      disableClose: false,
      data: value,
    });
    dialogRef.afterClosed().subscribe(async (result: CambioDTO) => {
      if (result) {
        const updatedChange = await this.perfilEmpleadoSharedService.editarCambioHistorico(this.personalModel.Legajo.id, result);
        this.snackBar.showInfo('GENERAL.SUCCESSFUL_MODIFICATION');
        this.initialList = this.initialList?.map((change) => {
          if (change.id !== updatedChange.id) {
            return change;
          }

          return result;
        });
        this.buildHistoryWithPercentage(this.initialList);
      }
    });
  }

  private async eliminarCambio(value: CambioDTO): Promise<void> {
    const res = await this.neoModalService.decision('¿Estás seguro que deseas eliminar el item del historial?');
    if (res.ButtonResponse === AlertButton.Accept) {
      await this.perfilEmpleadoSharedService.eliminarCambioHistorico(this.personalModel.Legajo.id, value);
      this.initialList = this.initialList?.filter((change) => change.id !== value.id);
      this.buildHistoryWithPercentage(this.initialList);
    }
  }
}
