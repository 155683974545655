@if (completitudPerfil?.total?.porcentaje < 100) {
  <mat-progress-bar
    mode="determinate"
    class="mb-2"
    color="primary"
    [value]="completitudPerfil?.total?.porcentaje ?? 0"
    [matTooltipClass]="['text-prewrap', 'text-left']"
    [matTooltip]="completitudPerfil?.toString()"
  ></mat-progress-bar>
}
<div class="d-flex flex-row w-100 flex-grow-1">
  @if (personalModelDTO) {
    <div class="overflow-auto flex-shrink-0 sidebar-empleado hidden-sm">
      @if ((vistaEmpresa$ | async) === false) {
        <div class="d-flex justify-content-center mb-4 p-relative">
          <input
            hidden
            type="file"
            accept="image/jpeg,image/png,image/jpg;capture=camera"
            (change)="fileChangeEvent($event)"
            #fileImage
            id="file"
          />
          <app-circle-image
            (click)="clickImageInput()"
            appDragAndDrop
            (fileDropped)="dropImagen($event)"
            [imageSource]="profileOwnImageUrl"
          >
          </app-circle-image>
          @if (canEdit) {
            <button (click)="fileImage.click()" class="edit-button" mat-mini-fab color="primary">
              <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
            </button>
          }
        </div>
      }
      <mat-action-list class="list-group my-2 overflow-auto-wh px-2">
        <a
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.DatosPersonales)"
          [class.router-link-active]="currentSection === SectionsEmployee.DatosPersonales"
        >
          <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> person </mat-icon>
          {{ 'PROFILE.PERSONAL_INFORMATION' | translate }}
        </a>
        <a
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.DatosLaborales)"
          [class.router-link-active]="currentSection === SectionsEmployee.DatosLaborales"
        >
          <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> badge </mat-icon>
          {{ 'PROFILE.WORKING_INFORMATION' | translate }}
        </a>
        @if ((vistaEmpresa$ | async) !== true) {
          <a
            class="list-group-item"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Company)"
            [class.router-link-active]="currentSection === SectionsEmployee.Company"
          >
            <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> business </mat-icon>
            {{ 'PROFILE.BUSINESS_DATA' | translate }}
          </a>
        }
        @if (vistaEmpresa$ | async) {
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureAusenciasEquipo'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Ausencias)"
            [class.router-link-active]="currentSection === SectionsEmployee.Ausencias"
          >
            <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> date_range </mat-icon>
            {{ 'GENERAL.TIMEOFF' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureDocumentos'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Documentos)"
            [class.router-link-active]="currentSection === SectionsEmployee.Documentos"
          >
            <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> folder </mat-icon>
            {{ 'GENERAL.DOCUMENTS' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureFeedback'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Feedback)"
            [class.router-link-active]="currentSection === SectionsEmployee.Feedback"
          >
            <mat-icon
              matListItemIcon
              fontSet="material-symbols-outlined"
              class="pr-2 material-symbols-outlined"
              svgIcon="feedback"
            ></mat-icon>
            {{ 'GENERAL.FEEDBACK' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureCapacitaciones'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Capacitaciones)"
            [class.router-link-active]="currentSection === SectionsEmployee.Capacitaciones"
          >
            <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> school </mat-icon>
            {{ 'GENERAL.TRAININGS' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureEntregas'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Entregas)"
            [class.router-link-active]="currentSection === SectionsEmployee.Entregas"
          >
            <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> inventory_2 </mat-icon>
            {{ 'GENERAL.DELIVERIES' | translate }}
          </a>
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureSancion'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.Disciplina)"
            [class.router-link-active]="currentSection === SectionsEmployee.Disciplina"
          >
            <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> policy </mat-icon>
            {{ 'GENERAL.COMPLIANCE' | translate }}
          </a>
        }
        <a
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.Notas)"
          [class.router-link-active]="currentSection === SectionsEmployee.Notas"
        >
          <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> note_alt </mat-icon>
          {{ 'GENERAL.NOTES' | translate }}
        </a>
        <a
          class="list-group-item"
          mat-list-item
          *ngIfFeatureFlag="'featurePoliticas'"
          (click)="selectedItem(SectionsEmployee.Politicas)"
          [class.router-link-active]="currentSection === SectionsEmployee.Politicas"
        >
          <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> developer_guide </mat-icon>
          {{ 'PROFILE.POLICIES' | translate }}
        </a>
        <a
          class="list-group-item"
          mat-list-item
          (click)="selectedItem(SectionsEmployee.Trayectoria)"
          [class.router-link-active]="currentSection === SectionsEmployee.Trayectoria"
        >
          <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined"> work_history </mat-icon>
          {{ 'PROFILE.CAREER' | translate }}
        </a>
        @if (vistaEmpresa$ | async) {
          <a
            class="list-group-item"
            *ngIfFeatureFlag="'featureCambioRol'"
            mat-list-item
            (click)="selectedItem(SectionsEmployee.CambioDeEstado)"
            [class.router-link-active]="currentSection === SectionsEmployee.CambioDeEstado"
          >
            <mat-icon matListItemIcon fontSet="material-symbols-outlined" class="pr-2 material-symbols-outlined">
              identity_platform
            </mat-icon>
            {{ 'GENERAL.ACCOUNT' | translate }}
          </a>
        }
      </mat-action-list>
    </div>
    <div class="flex-grow-1 p-relative overflow-auto">
      <div class="p-absolute h-100 w-100">
        <div class="d-flex flex-column pad-selection hidden-lg">
          @if ((vistaEmpresa$ | async) === false) {
            <div class="d-flex justify-content-center mb-4 p-relative">
              <input
                hidden
                type="file"
                accept="image/jpeg,image/png,image/jpg;capture=camera"
                (change)="fileChangeEvent($event)"
                #fileImage
                id="file"
              />
              <app-circle-image
                (click)="clickImageInput()"
                appDragAndDrop
                (fileDropped)="dropImagen($event)"
                [imageSource]="profileOwnImageUrl"
              >
              </app-circle-image>
              @if (canEdit) {
                <button (click)="fileImage.click()" class="edit-button" mat-mini-fab color="primary">
                  <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
                </button>
              }
            </div>
          }
          <mat-form-field class="flex-fill gap-1" subscriptSizing="dynamic" appearance="outline">
            <mat-select [(ngModel)]="currentSection" (selectionChange)="selectedItem(currentSection)">
              <mat-option [value]="SectionsEmployee.DatosPersonales"> {{ 'PROFILE.PERSONAL_INFORMATION' | translate }}</mat-option>
              <mat-option [value]="SectionsEmployee.DatosLaborales">{{ 'PROFILE.WORKING_INFORMATION' | translate }}</mat-option>
              @if ((vistaEmpresa$ | async) !== true) {
                <mat-option [value]="SectionsEmployee.Company">
                  {{ 'PROFILE.BUSINESS_DATA' | translate }}
                </mat-option>
              }
              @if (vistaEmpresa$ | async) {
                <mat-option *ngIfFeatureFlag="'featureAusenciasEquipo'" [value]="SectionsEmployee.Ausencias">
                  {{ 'GENERAL.TIMEOFF' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureDocumentos'" [value]="SectionsEmployee.Documentos">
                  {{ 'GENERAL.DOCUMENTS' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureFeedback'" [value]="SectionsEmployee.Feedback">
                  {{ 'GENERAL.FEEDBACK' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureCapacitaciones'" [value]="SectionsEmployee.Capacitaciones">
                  {{ 'GENERAL.TRAININGS' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureEntregas'" [value]="SectionsEmployee.Entregas">
                  {{ 'GENERAL.DELIVERIES' | translate }}
                </mat-option>
                <mat-option *ngIfFeatureFlag="'featureSancion'" [value]="SectionsEmployee.Disciplina">
                  {{ 'GENERAL.COMPLIANCE' | translate }}
                </mat-option>
              }
              <mat-option [value]="SectionsEmployee.Notas">{{ 'GENERAL.NOTES' | translate }}</mat-option>
              <mat-option *ngIfFeatureFlag="'featurePoliticas'" [value]="SectionsEmployee.Politicas">
                {{ 'PROFILE.POLICIES' | translate }}
              </mat-option>
              <mat-option [value]="SectionsEmployee.Trayectoria">{{ 'PROFILE.CAREER' | translate }}</mat-option>
              @if (vistaEmpresa$ | async) {
                <mat-option *ngIfFeatureFlag="'featureCambioRol'" [value]="SectionsEmployee.CambioDeEstado">
                  {{ 'GENERAL.ACCOUNT' | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="d-flex-flex-column mt-1 pl-2 pr-4">
          @if (currentSection === SectionsEmployee.DatosPersonales) {
            <app-datos-personales-agrupados
              [modoEmpresa]="vistaEmpresa$ | async"
              [modoEdicion]="modoEdicion"
              [personalModel]="personalModelDTO"
              [contactForm]="contactForm"
            />
          }
          @if (currentSection === SectionsEmployee.DatosLaborales) {
            <app-datos-contratacion [modoEmpresa]="vistaEmpresa$ | async" [modoEdicion]="modoEdicion" [personalModel]="personalModelDTO" />
          }
          @if (currentSection === SectionsEmployee.Politicas) {
            <app-datos-resumen [modoEmpresa]="vistaEmpresa$ | async" [modoEdicion]="modoEdicion" [personalModel]="personalModelDTO" />
          }
          @if (currentSection === SectionsEmployee.CambioDeEstado) {
            <app-account-detail [editMode]="modoEdicion" [employeeModel]="personalModelDTO" />
          }
          @if (currentSection === SectionsEmployee.Trayectoria) {
            <app-datos-historicos
              [historial]="historial"
              [modoEmpresa]="vistaEmpresa$ | async"
              [modoEdicion]="modoEdicion"
              [personalModel]="personalModelDTO"
            />
          }
        </div>
        @if (currentSection === SectionsEmployee.Notas) {
          <div class="d-flex flex-row mt-6 pl-2 pr-4">
            @if (vistaEmpresa$ | async) {
              <app-comentarios
                [id]="personalModelDTO.Legajo.id"
                [comentarioHandler]="comentarioHandler"
                [canBeNotified]="[convertToJson(personalModelDTO.Legajo)]"
                cantBeNotifiedKey="NO_NOTIFICATION_TEAMMATE_PROFILE"
              />
            }
            @if ((vistaEmpresa$ | async) === false) {
              <app-comentarios-lista class="w-100" [id]="personalModelDTO.Legajo.id" [comentarioHandler]="comentarioHandler" />
            }
          </div>
        }
        <div class="d-flex-flex-column mt-4 pl-2 pr-4">
          @if (currentSection === SectionsEmployee.Entregas) {
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center mb-2">
              <h3>{{ 'GENERAL.DELIVERIES' | translate }}</h3>
              @if (isActive) {
                <button type="button" mat-button color="primary" [command]="altaEntregaCmd">
                  {{ 'DELIVERIES.ADD_DELIVERY' | translate }}
                </button>
              }
            </div>
          }
          @if (lazyEntregas && currentSection === SectionsEmployee.Entregas) {
            <ng-template [ngComponentOutlet]="lazyEntregas | async" />
          }
          @if (currentSection === SectionsEmployee.Capacitaciones) {
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center mb-2">
              <h3>{{ 'GENERAL.TRAININGS' | translate }}</h3>
              @if (isActive) {
                <button type="button" mat-button color="primary" [command]="altaCapacitacionCmd">
                  {{ 'TRAINING.ASSIGN_TRAINING' | translate }}
                </button>
              }
            </div>
          }
          @if (lazyCapacitaciones && currentSection === SectionsEmployee.Capacitaciones) {
            <ng-template [ngComponentOutlet]="lazyCapacitaciones | async" />
          }
          @if (currentSection === SectionsEmployee.Disciplina) {
            <div class="d-flex flex-row flex-wrap align-items-center justify-content-between mb-2">
              <h3>{{ 'GENERAL.COMPLIANCE' | translate }}</h3>
              <button type="button" mat-button color="primary" [command]="altaSancionCmd">{{ 'GENERAL.REGISTER' | translate }}</button>
            </div>
          }
          @if (lazyDisciplina && currentSection === SectionsEmployee.Disciplina) {
            <ng-template [ngComponentOutlet]="lazyDisciplina | async" />
          }
          @if (currentSection === SectionsEmployee.Feedback) {
            <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
              <h3>{{ 'GENERAL.FEEDBACK' | translate }}</h3>
              @if (isActive) {
                <button type="button" mat-button color="primary" [command]="altaFeedbackCmd">
                  {{ 'FEEDBACK.GIVE_FEEDBACK' | translate }}
                </button>
              }
            </div>
          }
          @if (lazyFeedback && currentSection === SectionsEmployee.Feedback) {
            <ng-template [ngComponentOutlet]="lazyFeedback | async" />
          }
          @if (currentSection === SectionsEmployee.Ausencias) {
            <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
              <h3>{{ 'GENERAL.TIME_OFF' | translate }}</h3>
              @if (isActive) {
                <button type="button" mat-button color="primary" [command]="altaAusenciaCmd">
                  {{ 'TIMEOFF.ADD_ABSENCE' | translate }}
                </button>
              }
            </div>
          }
          @if (lazyAusencias && currentSection === SectionsEmployee.Ausencias) {
            <ng-template [ngComponentOutlet]="lazyAusencias | async" />
          }
          @if (lazyDocumentos && currentSection === SectionsEmployee.Documentos) {
            <ng-template [ngComponentOutlet]="lazyDocumentos | async" />
          }
          @if (lazyCompany && currentSection === SectionsEmployee.Company) {
            <ng-template [ngComponentOutlet]="lazyCompany | async" />
          }
        </div>
      </div>
    </div>
  } @else {
    <div class="w-25 mt-4 hidden-sm">
      <ngx-skeleton-loader [theme]="{ margin: '1rem 0 0 10px' }" count="8" appearance="line"></ngx-skeleton-loader>
    </div>
    <div class="flex-grow-1">
      <app-skeleton-card [lines]="10" [hideBorder]="true" [pictureTheme]="{ height: '64px', width: '64px' }"></app-skeleton-card>
    </div>
  }
</div>
