<mat-expansion-panel class="no-shadow" [disabled]="!listado?.length">
  <mat-expansion-panel-header>
    <mat-panel-title class="w-33">
      {{ titulo }}
    </mat-panel-title>
    <mat-panel-description class="text-left">
      <ng-container *ngIf="!listado?.length">{{ 'GENERAL.NO_CHANGES_WERE_REGISTERED' | translate }}</ng-container>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="d-flex flex-row">
    <div class="w-75 d-flex flex-row">
      <div class="w-33"></div>
      <div class="w-33 hidden-sm">{{ 'GENERAL.SINCE' | translate }}</div>
      <div class="w-33 hidden-sm">{{ 'GENERAL.UNTIL' | translate }}</div>
    </div>
    <div class="w-25"></div>
  </div>
  <div class="d-flex flex-row mb-2 align-items-center" *ngFor="let item of listado">
    <div class="w-75 d-flex flex-row">
      <div class="w-33">{{ item.estado }}</div>
      <div class="w-33 hidden-sm">{{ item.desde | ngxdate: 'fullDate' }}</div>
      <div class="w-33 hidden-sm">{{ item.hasta | ngxdate: 'fullDate' }}</div>
    </div>
    <div class="w-25 d-flex justify-content-end">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icono 3 puntitos">
        <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button [disabled]="disabled" color="primary" mat-menu-item [command]="editarCmd" [commandValue]="item">
          <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
          <span>{{ 'GENERAL.EDIT' | translate }}</span>
        </button>
        <button [disabled]="disabled" color="danger" mat-menu-item [command]="eliminarCmd" [commandValue]="item">
          <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
          <span>{{ 'GENERAL.DELETE' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</mat-expansion-panel>
