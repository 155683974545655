import { Injectable } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom,  Observable } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { NameNumberDetailDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/nameNumberDetail.DTO';
import { CargoConCategoriasDTO } from 'src/app/ModelDTO/DTO/cargoConCategorias.DTO';
import { CargoConPersonalDTO } from 'src/app/ModelDTO/DTO/cargoConPersonal.DTO';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { NameDetailDTO } from '@api/interfaces/name-detail.interface';

@Injectable({
   providedIn: 'root'
})
export class CargoServiceBackend {

   constructor(protected http: HttpClient) { }

   public async getCargosIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cargos/' + id + '/Auditory'));
      const resJson = res.data;
      const resDTO = new Array<AuditLogEntryDTO>();
      for (const item of resJson) {
         const itemDTO = new AuditLogEntryDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
   }

   public async getCargosIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {
      const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cargos/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
               const resDTO = new Blob([res.body], { type: 'application/pdf'});
      if (namedBlob) {
         namedBlob.setBlobNameFromHttpResponse(res);
         namedBlob.blob = res.body;
      }
      return resDTO;
   }

   public async getCargos(): Promise<Array<CargoDTO>> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cargos/'));
      const resJson = res.data;
      const resDTO = new Array<CargoDTO>();
      for (const item of resJson) {
         const itemDTO = new CargoDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
   }

   public async insertCargos(cargoConCategoriasDTO: CargoConCategoriasDTO): Promise<CargoConCategoriasDTO> {
      const res = await lastValueFrom(this.http.post(Constants.apiURL + '/cargos/', cargoConCategoriasDTO));
      const resDTO = new CargoConCategoriasDTO();
      resDTO.PrepareDTO(res);
      return resDTO;
   }

   public async updateCargosId(id: number, cargoConCategoriasDTO: CargoConCategoriasDTO): Promise<CargoConCategoriasDTO> {
      const res = await lastValueFrom(this.http.put(Constants.apiURL + '/cargos/' + id, cargoConCategoriasDTO));
      const resDTO = new CargoConCategoriasDTO();
      resDTO.PrepareDTO(res);
      return resDTO;
   }

   public async deleteCargosId(id: number, deleteFromDb: boolean = false): Promise<void> {
      await lastValueFrom(this.http.delete(Constants.apiURL + '/cargos/' + id + '?deleteFromDb=' + deleteFromDb));
   }

   public async getCargosId(id: number): Promise<CargoConCategoriasDTO> {
      const res = await lastValueFrom(this.http.get(Constants.apiURL + '/cargos/' + id));
         if (!res) { return null; }
         const resDTO = new CargoConCategoriasDTO();
         resDTO.PrepareDTO(res);
         return resDTO;
   }

   public async getCargosTotalPersonas(): Promise<Array<NameNumberDetailDTO>> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cargos/totalPersonas'));
      const resJson = res.data;
      const resDTO = new Array<NameNumberDetailDTO>();
      for (const item of resJson) {
         const itemDTO = new NameNumberDetailDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
   }

   public async getCargosConPersonal(): Promise<Array<CargoConPersonalDTO>> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cargos/conPersonal'));
      const resJson = res.data;
      const resDTO = new Array<CargoConPersonalDTO>();
      for (const item of resJson) {
         const itemDTO = new CargoConPersonalDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
   }

   public async getCargosAutoComplete(contiene: string, pageSize: number): Promise<Array<CargoDTO>> {
      const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/cargos/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
      const resJson = res.data;
      const resDTO = new Array<CargoDTO>();
      for (const item of resJson) {
         const itemDTO = new CargoDTO();
         itemDTO.PrepareDTO(item);
         resDTO.push(itemDTO);
      }
      return resDTO;
   }
}
