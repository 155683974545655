import { Injectable } from '@angular/core';
import { MatStepperIntl } from '@angular/material/stepper';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class StepperIntlService extends MatStepperIntl {
  constructor(private translate: TranslateService) {
    super();

    this.translate.get('GENERAL.OPTIONAL').subscribe((translation) => {
      this.optionalLabel = translation;
      this.changes.next();
    });
  }
}
