import { AsyncPipe, NgFor } from '@angular/common';
import { Component, ElementRef, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule, SubscriptSizing } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, filter, Observable, Subject, Subscription, switchMap } from 'rxjs';
import { AutocompleteServiceData } from 'src/app/shared/autocomplete-service-selector/autocomplete-service-data';
import { CompleterItem } from 'src/app/shared/lib/ngx-neo-completer-mat/components/completer-item';

@Component({
  selector: 'app-autocomplete-service-selector',
  standalone: true,
  imports: [NgFor, AsyncPipe, MatAutocompleteModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './autocomplete-service-selector.component.html',
  styleUrls: ['./autocomplete-service-selector.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AutocompleteServiceSelectorComponent), multi: true }],
})
export class AutocompleteServiceSelectorComponent implements OnInit, ControlValueAccessor, OnDestroy, OnChanges {
  @ViewChild('input') public input: ElementRef<HTMLInputElement>;
  @Input() public datasource: AutocompleteServiceData;
  @Input() public required: boolean | string = false;
  @Input() public label: string;
  @Input() public hint: string;
  @Input() public subscriptSizing: SubscriptSizing = 'fixed';
  @Input() public minSearchLength = 3;
  @Input() public displayFn: (value: any) => string;

  public filteredOptions$: Observable<CompleterItem[]>;
  public readonly control = new FormControl<any | string>('');

  private filter$ = new Subject();
  private subs = new Subscription();

  public ngOnInit(): void {
    this.filteredOptions$ = this.filter$.pipe(
      debounceTime(150),
      distinctUntilChanged(),
      filter((value) => typeof value === 'string' && value.length >= this.minSearchLength),
      switchMap((value: string) => this.datasource.getEntitiesSearch(value, this.displayFn)),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.required?.currentValue || changes.required?.currentValue === '') {
      this.control.setValidators(Validators.required);
    }
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  public filter(): void {
    const filterValue = this.input?.nativeElement.value?.toLowerCase() ?? '';
    this.filter$.next(filterValue);
  }

  public writeValue(input: any): void {
    if (input?.id > 0) {
      this.control.setValue(input);
    } else {
      this.control.setValue(null);
    }
  }

  public registerOnChange(fn: (value: any | null) => void): void {
    this.subs.add(this.control.valueChanges.subscribe(fn));
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  public onTouch = (): void => undefined;
}
