import { Subscription, BehaviorSubject } from 'rxjs';
import { EntityModelDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/entity.ModelDTO';
import { PuestoDTO } from 'src/app/ModelDTO/DTO/puesto.DTO';
import { PersonalAreaDTO } from 'src/app/ModelDTO/DTO/personalArea.DTO';
import { PersonalAreaModelDTO } from 'src/app/ModelDTO/personalArea.ModelDTO';
import { PersonalSectorDTO } from 'src/app/ModelDTO/DTO/personalSector.DTO';
import { PersonalSectorModelDTO } from 'src/app/ModelDTO/personalSector.ModelDTO';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { CargoModelDTO } from 'src/app/ModelDTO/cargo.ModelDTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoBasicoModelDTO } from 'src/app/ModelDTO/personalLegajoBasico.ModelDTO';


export class PuestoModelDTO extends EntityModelDTO<PuestoDTO> {

   private areaModel: PersonalAreaModelDTO;
   private areaSubscribe: Subscription;
   private sectorModel: PersonalSectorModelDTO;
   private sectorSubscribe: Subscription;
   private cargoModel: CargoModelDTO;
   private cargoSubscribe: Subscription;
   private superiorModel: PersonalLegajoBasicoModelDTO;
   private superiorSubscribe: Subscription;

   public constructor(protected entityDTO: PuestoDTO) {
      super(entityDTO);
   }

   public setEntityDTO(entityDTO: PuestoDTO) {
      super.setEntityDTO(entityDTO);
      if (entityDTO === null) { return; }
      this.areaModel = new PersonalAreaModelDTO(this.entityDTO.area);
      this.areaSubscribe = this.areaModel.changed.subscribe((changed) => this.changed.next(changed));
      this.sectorModel = new PersonalSectorModelDTO(this.entityDTO.sector);
      this.sectorSubscribe = this.sectorModel.changed.subscribe((changed) => this.changed.next(changed));
      this.cargoModel = new CargoModelDTO(this.entityDTO.cargo);
      this.cargoSubscribe = this.cargoModel.changed.subscribe((changed) => this.changed.next(changed));
      this.superiorModel = new PersonalLegajoBasicoModelDTO(this.entityDTO.superior);
      this.superiorSubscribe = this.superiorModel.changed.subscribe((changed) => this.changed.next(changed));
   }

   public isNewEntity(): boolean {
      return this.entityDTO.id === 0;
   }

   public dispose(): void {
      this.areaModel.dispose();
      this.areaSubscribe.unsubscribe();
      this.sectorModel.dispose();
      this.sectorSubscribe.unsubscribe();
      this.cargoModel.dispose();
      this.cargoSubscribe.unsubscribe();
      this.superiorModel.dispose();
      this.superiorSubscribe.unsubscribe();
   }

   get AreaModel(): PersonalAreaModelDTO { return this.areaModel; }
   get Area(): PersonalAreaDTO { return this.areaModel.getEntityDTO(); }
   set Area(value: PersonalAreaDTO) { this.notifyChange(() => { this.entityDTO.area = value; this.areaModel.setEntityDTO(value); }); }

   get SectorModel(): PersonalSectorModelDTO { return this.sectorModel; }
   get Sector(): PersonalSectorDTO { return this.sectorModel.getEntityDTO(); }
   set Sector(value: PersonalSectorDTO) { this.notifyChange(() => { this.entityDTO.sector = value; this.sectorModel.setEntityDTO(value); }); }

   get CargoModel(): CargoModelDTO { return this.cargoModel; }
   get Cargo(): CargoDTO { return this.cargoModel.getEntityDTO(); }
   set Cargo(value: CargoDTO) { this.notifyChange(() => { this.entityDTO.cargo = value; this.cargoModel.setEntityDTO(value); }); }

   get SuperiorModel(): PersonalLegajoBasicoModelDTO { return this.superiorModel; }
   get Superior(): PersonalLegajoBasicoDTO { return this.superiorModel.getEntityDTO(); }
   set Superior(value: PersonalLegajoBasicoDTO) { this.notifyChange(() => { this.entityDTO.superior = value; this.superiorModel.setEntityDTO(value); }); }

   get Oculto(): boolean { return this.entityDTO.oculto; }
   set Oculto(value: boolean) { this.notifyChangeDTO('oculto', value); }

   get Id(): number { return this.entityDTO.id; }
   set Id(value: number) { this.notifyChangeDTO('id', value); }

   get CacheStamp(): number { return this.entityDTO.cacheStamp; }
   set CacheStamp(value: number) { this.notifyChangeDTO('cacheStamp', value); }
}
