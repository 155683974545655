import { Injectable, Inject } from '@angular/core';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { CompleterItem } from 'src/app/shared/lib/ngx-neo-completer-mat/components/completer-item';
import { FrontEndConfig, FrontEndConfigService } from 'src/app/shared/lib/ngx-neo-frontend-mat/FrontendConfig';
import { BaseEntitiesSearch } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/search/base-entitites.search';
import { CargoEntityService } from 'src/app/shared/services/entities/cargo-entity.service';

@Injectable({
  providedIn: 'root',
})
export class CargoSearchService extends BaseEntitiesSearch {
  constructor(
    @Inject(FrontEndConfigService) frontEndConfig: FrontEndConfig,
    private cargoEntityService: CargoEntityService,
  ) {
    super(frontEndConfig);
  }

  public async getEntitiesSearch(startWith: string): Promise<void> {
    const res = await this.cargoEntityService.getEntitiesByName(startWith);
    const matches: CompleterItem[] = new Array<CompleterItem>(res.length);
    res.forEach((item, index) => {
      matches[index] = this.convertToItem(item);
    });
    this.next(matches);
  }

  public convertToItem?(item: CargoDTO): CompleterItem | null {
    return { title: item.nombre, originalObject: item } as CompleterItem;
  }
}
