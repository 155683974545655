import { Injectable, NgZone } from '@angular/core';
import posthog from 'posthog-js';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PosthogService {
  private readonly enabled = !environment.production;

  constructor(
    private ngZone: NgZone,
    private router: Router,
  ) {
    if (this.enabled) {
      this.ngZone.runOutsideAngular(() => {
        posthog.init(environment.posthogKey, {
          api_host: 'https://eu.i.posthog.com',
          debug: false,
          cross_subdomain_cookie: true,
          person_profiles: 'identified_only',
          capture_pageview: false,
          capture_pageleave: true,
          loaded: (posthogInstance) => {
            this.router.events
              .pipe(
                filter((event) => event instanceof NavigationEnd),
                takeUntilDestroyed(),
              )
              .subscribe(() => {
                posthogInstance.capture('$pageview');
              });
          },
        });
      });
    }
  }
}
