<mat-card *ngIf="show" class="primary-color primary-color-border no-shadow color-primary">
  <mat-card-content>
    <div class="d-flex flex-row justify-content-between align-items-center gap-4">
      <div class="d-flex flex-row gap-2 align-items-center">
        <mat-icon color="primary" class="material-symbols-outlined info-button flex-shrink-0" *ngIf="icon">{{ icon }}</mat-icon>
        <small> <ng-content></ng-content> </small>
      </div>
      <mat-icon *ngIf="showButtonClose" color="primary" (click)="close()" class="material-symbols-outlined pointer flex-shrink-0">
        close
      </mat-icon>
    </div>
  </mat-card-content>
</mat-card>
