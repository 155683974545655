import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'antiguedad',
})
export class AntiguedadPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(value: number): string {
    let str = '';
    if (value) {
      const arregloVal = value.toString().split('.');
      if (value % 1 === 0) {
        arregloVal.push('0');
      }
      if (arregloVal[0].length <= 2 && arregloVal[1].length <= 2) {
        const anios = +arregloVal[0];
        let meses = 0;
        if (arregloVal[1] === '1') {
          meses = 10;
        } else {
          meses = +arregloVal[1];
        }
        switch (anios) {
          case 0:
            break;
          case 1:
            str = `1 ${this.translateService.instant('EMPLOYEES.ANTIQUITIES.YEAR').toLowerCase()}`;
            break;
          default:
            str = `${anios} ${this.translateService.instant('EMPLOYEES.ANTIQUITIES.YEARS').toLowerCase()}`;
            break;
        }
        if (anios && meses) {
          str += ` ${this.translateService.instant('GENERAL.AND').toLowerCase()} `;
        }
        switch (meses) {
          case 0:
            break;
          case 1:
            str += `1 ${this.translateService.instant('EMPLOYEES.ANTIQUITIES.MONTH').toLowerCase()}`;
            break;
          default:
            str = `${str + meses} ${this.translateService.instant('EMPLOYEES.ANTIQUITIES.MONTHS').toLowerCase()}`;
            break;
        }
      } else {
        str = this.translateService.instant('EMPLOYEES.ANTIQUITIES.INVALID_FORMAT');
      }
    } else {
      str = `0 ${this.translateService.instant('EMPLOYEES.ANTIQUITIES.MONTHS').toLowerCase()}`;
    }
    return str;
  }
}
