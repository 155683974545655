import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PersonalBackendService } from '@api/services/personal-backend.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { AttachmentFirmaService } from 'src/app/app-common/previsualizacion-documentos/attachment-firma.service';
import { NivelDeEstudioService } from 'src/app/layout/administrador/configuracion/nivel-de-estudio/nivel-de-estudio.service';
import { DomicilioDTO } from 'src/app/ModelDTO/DTO/domicilio.DTO';
import { NivelEstudioDTO } from 'src/app/ModelDTO/DTO/nivelEstudio.DTO';
import { PaisDTO } from 'src/app/ModelDTO/DTO/pais.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { ImageEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageEntity.DTO';
import { ImageFileDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/imageFile.DTO';
import { AlertButton, AlertResult } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { ProfileImageThumbnailUrlPipe } from 'src/app/shared/pipes/profile-image-thumbnail-url.pipe';
import { SelectAndCropData } from 'src/app/shared/select-and-crop/select-and-crop.component';
import { SelectAndCropService } from 'src/app/shared/select-and-crop/select-and-crop.service';
import { compareDtoId } from 'src/app/shared/shared-functions';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  providers: [ProfileImageThumbnailUrlPipe],
})
export class DatosPersonalesComponent implements OnInit, OnDestroy {
  @Input() public personalModel: PersonalModelDTO;

  @Input() public modoEmpresa = false;

  @ViewChild('personalesForm') public form: NgForm;

  @Input() public set editMode(edit: boolean) {
    this.modoEdicion = edit;
    this.afipBehaviourt.next(edit);
  }

  public maskPersonalTaxId: string | null;
  public cuilPattern: string;
  public maskPersonalId: string | null;
  public dniPattern: string;
  public modoEdicion = false;
  public generos: Array<string>;
  public estadosCiviles: Array<string>;
  public imagenPersonal: ImageEntityDTO;
  public minDate: Date;
  public countries: Array<PaisDTO>;
  public filteredCountries: Array<PaisDTO>;
  public grupoSanguineo = ['A+', 'A-', 'AB+', 'AB-', 'B+', 'B-', 'O+', 'O-'];
  public nivelesEstudio: NivelEstudioDTO[] = [];
  public canEditBirthDate: boolean;
  public afipBehaviourt = new BehaviorSubject<boolean>(false);
  public requiredCuil = false;
  public maxLengthId = 18;
  public maxLengthTax = 18;
  public calcularEdadCmd: ICommand = new Command(() => this.calcularEdad(), new BehaviorSubject(true), false);
  public autoCompleteCmd: ICommand = new Command((actualizar) => this.autoComplete(actualizar), this.afipBehaviourt.asObservable(), true);
  public generarCertificadoCmd: ICommand = new Command(() => this.generarCertificado(), new BehaviorSubject(true), true);
  public readonly compareId = compareDtoId;

  public get weightSuffix(): string {
    return ` ${this.translateService.instant('GENERAL.POUNDS')}`;
  }

  public get heightSuffix(): string {
    return ` ${this.translateService.instant('GENERAL.FEET')}`;
  }

  public get headCircumferenceSuffix(): string {
    return ` ${this.translateService.instant('GENERAL.INCHES')}`;
  }

  public get isArgentinaIssuingCountry(): boolean {
    return this.personalModel.IssuingCountry?.id === 1;
  }

  private subs = new Subscription();
  private today = new Date();

  constructor(
    private perfilEmpleadoService: PerfilEmpleadoSharedService,
    private teammateBackendService: PersonalBackendService,
    private neoModalService: NgxNeoModalMatService,
    private perfilEmpleadoSharedService: PerfilEmpleadoSharedService,
    private nivelEstudioService: NivelDeEstudioService,
    private attachmentFirmaService: AttachmentFirmaService,
    private translateService: TranslateService,
    private snackBar: SnackBarService,
    private profileImagePipe: ProfileImageThumbnailUrlPipe,
    private cdr: ChangeDetectorRef,
    private selectAndCropService: SelectAndCropService,
  ) {
    this.minDate = new Date(this.today.getFullYear() - 100, this.today.getMonth(), this.today.getDate());
    this.countries = new Array<PaisDTO>();
    this.filteredCountries = [];
  }

  public readonly countryLabelGetter = (item: PaisDTO): string => item.nacionalidad;

  public async ngOnInit(): Promise<void> {
    this.nivelesEstudio = await this.nivelEstudioService.obtenerNivelesDeEstudio();
    this.countries = await this.perfilEmpleadoSharedService.obtenerPaises();

    this.generos = PersonalModelDTO.getTipoGenero().slice(1);
    this.estadosCiviles = PersonalModelDTO.getEstadoCivil().slice(1);

    this.subs.add(
      this.form.statusChanges.subscribe(() => {
        this.perfilEmpleadoSharedService.updateStep1Status(this.form.valid && this.personalModel.Dni?.length > 0);
      }),
    );

    this.subs.add(
      this.perfilEmpleadoService.empleadoArrived$.subscribe((persona) => {
        if (persona) {
          this.personalModel = persona;
          if (this.personalModel.NivelEstudio.id > 0) {
            this.personalModel.NivelEstudio = this.nivelesEstudio.find((x) => x.id === this.personalModel.NivelEstudio.id);
          }
          if (this.personalModel.Nacionalidad.id > 0) {
            this.personalModel.Nacionalidad = this.countries.find((x) => x.id === this.personalModel.Nacionalidad.id);
          }
          if (this.personalModel.IssuingCountry.id > 0) {
            this.issuingCountryChange();
          }
          this.canEditBirthDate = !!this.personalModel.FechaNacimiento && !this.modoEmpresa;
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  public async generarCertificado(): Promise<void> {
    let res: AlertResult = null;
    if (this.personalModel.Legajo.tieneCertificado) {
      res = await this.neoModalService.decision('PROFILE.OVERWRITE_SIGNATURE_CERTIFICATE');
    }
    if (res === null || res?.ButtonResponse === AlertButton.Accept) {
      const dtoRes = await this.attachmentFirmaService.generarCertificadoColaborador(this.personalModel.Legajo.id);
      this.snackBar.showInfo('PROFILE.SIGNATURE_CERTIFICATE_CREATED_SUCCESSFULLY');
      this.personalModel.Legajo.tieneCertificado = dtoRes.tieneCertificado;
    }
  }

  public quitaFirmaOlografica(): void {
    this.personalModel.FirmaOlografaImage = undefined;
    this.personalModel.UsaSuFirmaOlografa = false;
  }

  public async signaturefileChangeEvent(event: Event): Promise<void> {
    await this.guardarImagen(event, async (image: ImageEntityDTO) => {
      const imageFile = new ImageFileDTO();
      imageFile.maxHeightInPixels = 300;
      imageFile.maxWidthInPixels = 350;
      imageFile.maxSizeInBytes = 1000000;
      imageFile.image = image.image;
      const imageUrl = await this.perfilEmpleadoService.agregarFirmaOlografa(this.personalModel, imageFile);
      this.personalModel.FirmaOlografaImage = imageUrl.image;
      this.personalModel.UsaSuFirmaOlografa = true;
    });
  }

  public ownSignatureImageUrl(): string {
    if (this.personalModel.FirmaOlografaImage) {
      return this.profileImagePipe.transform(this.personalModel.FirmaOlografaImage, 250);
    }
    return null;
  }

  public issuingCountryChange(): void {
    this.maskPersonalTaxId = null;
    this.cuilPattern = '';
    this.requiredCuil = false;

    this.maskPersonalId = null;
    this.dniPattern = '';

    if (this.personalModel.IssuingCountry.emploeeTaxIdRequired) {
      this.maskPersonalTaxId = this.personalModel.IssuingCountry.emploeeTaxIdRequired ? this.personalModel.IssuingCountry.maskTaxId : null;
      if (this.personalModel.IssuingCountry.validateTaxId) {
        this.cuilPattern = this.personalModel.IssuingCountry.validatorPatternTaxId;
      }
      this.requiredCuil = this.personalModel.IssuingCountry.emploeeTaxIdRequired;
    }

    if (this.personalModel.IssuingCountry?.validateId) {
      this.dniPattern = this.personalModel.IssuingCountry.validatorPatternId;
    }

    if (this.isArgentinaIssuingCountry) {
      const cuil = this.personalModel.Cuil ? this.personalModel.Cuil.replace(/-/g, '') : '';
      if (cuil) {
        const dni = cuil.slice(2, -1);
        this.personalModel.Dni = dni;
        this.cdr.detectChanges();
      }
    }

    this.maskPersonalId = this.personalModel.IssuingCountry?.maskId ?? null;
    this.maxLengthId = this.personalModel.IssuingCountry?.maskId?.length ?? 18;
    this.maxLengthTax = this.personalModel.IssuingCountry?.maskTaxId?.length ?? 18;
  }

  private calcularEdad(): void {
    this.personalModel.Edad = 0;
    const entity: PersonalDTO = this.personalModel.getEntityDTO();
    if (entity.fechaNacimiento) {
      const birthDay = DateTime.fromJSDate(entity.fechaNacimiento);
      this.personalModel.Edad = Math.floor(DateTime.now().diff(birthDay, 'years').years);
    }
  }

  private autoComplete(actualizar: boolean): void {
    if (this.personalModel.Dni && this.modoEdicion && this.isArgentinaIssuingCountry) {
      this.teammateBackendService
        .getPersonalBusquedaPorAfipdocumento(this.personalModel.Dni, this.personalModel.IssuingCountry?.id)
        .subscribe({
          next: (res) => {
            if (res) {
              if (actualizar) {
                this.personalModel.Nombre = res.nombre;
                this.personalModel.Apellido = res.apellido;
                this.personalModel.Cuil = res.cuil;
                this.personalModel.FechaNacimiento = res.fechaNacimiento.toJSDate();
                if (res.domicilio?.direccion) {
                  const newAdress = new DomicilioDTO();
                  newAdress.PrepareDTO(res.domicilio);
                  this.personalModel.DomicilioResidencia = newAdress;
                }
              } else {
                const personal = new PersonalDTO();
                personal.PrepareDTO(res);
                this.personalModel.setEntityDTO(personal);
              }
              this.calcularEdad();
              this.perfilEmpleadoService.setPersonalModel(this.personalModel);
            } else {
              this.neoModalService.warning(this.translateService.instant('PROFILE.NO_ID_DATA_FOUND', { id: this.personalModel.Dni }));
            }
          },
        });
    }
  }

  private async guardarImagen(event: Event, saveService: (image: ImageEntityDTO) => Promise<void>): Promise<void> {
    const data: SelectAndCropData = {
      event,
      aspectRatio: 16 / 9,
      resizeW: 350,
      resizeH: 200,
      previewW: 250,
      previewH: 140,
    };
    const dialogRef = this.selectAndCropService.openImageCropperDialog(data);

    dialogRef?.afterClosed().subscribe(async (result) => {
      if (result) {
        const image = new ImageEntityDTO();
        image.image = result;
        await saveService(image);
      }
    });
  }
}
