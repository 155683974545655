<h1 mat-dialog-title class="d-flex justify-content-between">
  <span>{{ 'WELCOME.REFER_AND_WIN' | translate }} </span>
  <span class="dialog-close-icon material-symbols-outlined pt-1" (click)="close()"> close </span>
</h1>
<div mat-dialog-content>
  <mat-tab-group (selectedTabChange)="tabChange($event)" class="mat-tab-fill-height d-flex-full" mat-align-tabs="center">
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'WELCOME.REFER' | translate }}</span>
      </ng-template>
      <ng-template matTabContent> </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'GENERAL.MY_REFERRALS' | translate }}</span>
      </ng-template>
      <ng-template matTabContent> </ng-template>
    </mat-tab>
  </mat-tab-group>
  <div class="mt-2" *ngIf="selectedTab === 0">
    <div class="conditions">
      {{ 'WELCOME.HELP_SME' | translate: { price: ReferralPrice } }}
    </div>
    <div class="conditions conditions-blue mt-2 p-2">
      {{ 'WELCOME.REFERRAL_DATA' | translate }}
    </div>
    <form id="referralForm" class="mt-2 d-flex flex-column" [formGroup]="referredForm" (ngSubmit)="referredCmd.execute()">
      <mat-form-field class="mb-2" subscriptSizing="dynamic" appearance="outline">
        <mat-label>{{ 'WELCOME.REFERRAL_NAME' | translate }}</mat-label>
        <input #referredN matInput type="text" id="referredN" name="referredN" formControlName="referredName" />
      </mat-form-field>
      <mat-form-field class="mb-2" subscriptSizing="dynamic" appearance="outline">
        <mat-label>{{ 'WELCOME.REFERRAL_EMAIL' | translate }}</mat-label>
        <input #referredE matInput type="text" id="referredE" name="referredE" formControlName="referredEmail" />
      </mat-form-field>
      <mat-form-field class="mb-2" subscriptSizing="dynamic" appearance="outline">
        <mat-label>{{ 'WELCOME.REFERRAL_TELEPHONE' | translate }}</mat-label>
        <input #referredP matInput type="text" id="referredP" name="referredP" formControlName="referredPhone" />
      </mat-form-field>
      <mat-form-field class="mb-4" subscriptSizing="dynamic" appearance="outline">
        <mat-label>{{ 'WELCOME.REFERRED_BUSINESS' | translate }}</mat-label>
        <input #referredC matInput type="text" id="referredC" name="referredC" formControlName="referredCompany" />
      </mat-form-field>
    </form>
  </div>
  <div class="mt-2" *ngIf="selectedTab === 1">
    <table *ngIf="!loading" mat-table [dataSource]="referrals">
      <ng-container [matColumnDef]="ColumnNames.Date">
        <th mat-header-cell *matHeaderCellDef>{{ 'GENERAL.DATE' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createDate | ngxdate: 'shortDateFull' }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Name">
        <th mat-header-cell *matHeaderCellDef>{{ 'GENERAL.BUSINESS' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.referredOrganization }}</td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Status">
        <th mat-header-cell *matHeaderCellDef>{{ 'GENERAL.STATUS' | translate }}</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            confirmed:
              element.status === SalesCrmStatus.WON ||
              element.status === SalesCrmStatus.DEMO_DONE ||
              element.status === SalesCrmStatus.DEMO_DONE_LOST
          }"
        >
          {{ 'ENUM.REFERRALS.STATUS.' + element.status | translate }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.DemoReward">
        <th class="text-right" mat-header-cell *matHeaderCellDef>{{ 'GENERAL.DEMO' | translate }}</th>
        <td class="text-right amount" mat-cell *matCellDef="let element">${{ element.refersDemoAmount | number: '1.0-0' }}</td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Reward">
        <th class="text-right" mat-header-cell *matHeaderCellDef>{{ 'GENERAL.CONTRACTING' | translate }}</th>
        <td class="text-right amount" mat-cell *matCellDef="let element">${{ element.refersAmount | number: '1.0-0' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="d-flex flex-row justify-content-between mt-2" mat-dialog-footer *ngIf="selectedTab === 0">
    <div class="conditions mr-4">
      {{ 'WELCOME.ACEEPT_TERMS_START' | translate }}
      <a ios-link href="{{ urlWebPage }}/terminos-referidos">{{ 'WELCOME.TERMS' | translate }}</a>
      {{ 'WELCOME.ACEEPT_TERMS_END' | translate | lowercase }}
    </div>
    <button mat-raised-button color="primary" type="submit" form="referralForm" [disabled]="!referredForm.valid">
      {{ 'WELCOME.REFER' | translate }}
    </button>
  </div>
  <div class="mt-4" *ngIf="selectedTab === 1">
    <div>
      <div>
        <span class="confirmed total-label"> 💰 {{ 'GENERAL.CONTRACT_MADE' | translate }} </span>
        <span> ${{ totalAmount | number: '1.0-0' }} </span>
      </div>
      <div>
        <span class="total-label mt-1"> 🤑 {{ 'GENERAL.POTENTIAL' | translate }} </span>
        <span> ${{ potentialAmount | number: '1.0-0' }} </span>
      </div>
    </div>
    <div
      class="conditions conditions-blue mt-2 p-2"
      [innerHTML]="'WELCOME.POTENTIAL_AMOUNT_MSG' | translate: { amount: potentialAmount | number: '1.0-0' }"
    ></div>
  </div>
</div>
