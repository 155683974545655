import { Directive, Input, OnInit, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Subscription, tap } from 'rxjs';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { IFeatureFlag } from 'src/app/shared/feature-flags/flag.interface';
import { FeatureFlagService } from './feature-flags.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngIfFeatureFlag]',
  standalone: true,
})
export class NgIfFeatureFlagDirective implements OnInit, OnDestroy {
  @Input() public ngIfFeatureFlagAllRequired = false;
  @Input() public set ngIfFeatureFlag(feature: string | string[]) {
    if (!feature) {
      this.featureNames = [];
    } else if (typeof feature === 'string') {
      this.featureNames = [feature];
    } else {
      this.featureNames = feature;
    }
    this.showOrHide(this.loadedFlags);
  }

  private featureNames: string[] = [];
  private hasView = false;
  private subs: Subscription = new Subscription();
  private loadedFlags: Map<string, IFeatureFlag>;

  constructor(
    private featureFlagService: FeatureFlagService,
    private templateRef: TemplateRef<any>,
    private vcr: ViewContainerRef,
  ) {}

  public ngOnInit(): void {
    this.subs.add(
      this.featureFlagService.flags$
        .pipe(
          tap(({ flags }) => {
            this.loadedFlags = flags;
          }),
        )
        .subscribe(({ flags }) => this.showOrHide(flags)),
    );
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  private showOrHide(flags: Map<string, IFeatureFlag>): void {
    let featureOn = false;
    if (!this.featureNames.length) {
      featureOn = true;
    } else if (this.ngIfFeatureFlagAllRequired) {
      featureOn = this.featureNames.reduce((on, current) => on && FeatureFlagHelper.featureOn(current, flags), true);
    } else {
      this.featureNames.forEach((feature) => {
        featureOn = featureOn || !feature || FeatureFlagHelper.featureOn(feature, flags);
      });
    }

    if (featureOn && !this.hasView) {
      this.vcr.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!featureOn && this.hasView) {
      this.vcr.clear();
      this.hasView = false;
    }
  }
}
