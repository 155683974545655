import { Directive, Input, ElementRef, HostListener } from '@angular/core';
import { ICommand } from './ngx-command/command.directive';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[changeCommand]',
})
export class NeoChangeCommandDirective {
  @Input() changeCommand: ICommand;

  private lastValue: string;

  constructor(private element: ElementRef) {}

  @HostListener('keyup', ['$event']) onInputChange(): void {
    const value = this.element.nativeElement.value;

    if (!this.lastValue || this.lastValue !== value) {
      this.lastValue = value;
      this.changeCommand.execute(value);
    }
  }
}
