<div class="d-flex flex-column flex-grow-1 py-6">
  <app-banner icon="info" type="info">
    <span class="break-line">
      {{ 'EMPLOYEES.CHANGE_STATE_EXPLANATION_HEADER' | translate }}
    </span>
    <ul>
      <li>{{ 'EMPLOYEES.CHANGE_STATE_EXPLANATION_CASE_1' | translate }}</li>
      <li>{{ 'EMPLOYEES.CHANGE_STATE_EXPLANATION_CASE_2' | translate }}</li>
      <li>{{ 'EMPLOYEES.CHANGE_STATE_EXPLANATION_CASE_3' | translate }}</li>
      <li>{{ 'EMPLOYEES.CHANGE_STATE_EXPLANATION_CASE_4' | translate }}</li>
    </ul>
    <span class="break-line">
      {{ 'EMPLOYEES.CHANGE_STATE_EXPLANATION_FOOTER' | translate }}
    </span>
  </app-banner>
  <h4 class="primary-color mt-4">{{ 'GENERAL.CURRENT_STATUS' | translate }}</h4>
  <form [formGroup]="actualFormGroup">
    <div class="d-flex flex-row flex-wrap align-items-center gap-1">
      <mat-form-field class="flex-fill" appearance="outline" subscriptSizing="dynamic">
        <mat-label> {{ 'GENERAL.TYPE' | translate }} </mat-label>
        <mat-select formControlName="type" required [compareWith]="compareDtoId">
          @for (typeState of $actualTypesStates(); track typeState.id) {
            <mat-option [value]="typeState"> {{ typeState.nombre }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ 'GENERAL.SINCE' | translate }}</mat-label>
        <input matInput [matDatepicker]="pickerActualSince" formControlName="since" [matDatepickerFilter]="beforeOrSameToday" required />
        <mat-datepicker-toggle matSuffix [for]="pickerActualSince" />
        <mat-datepicker #pickerActualSince [calendarHeaderComponent]="calendarH" />
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline" subscriptSizing="dynamic">
        <mat-label> {{ 'GENERAL.OBSERVATION' | translate }} </mat-label>
        <input
          matInput
          type="text"
          id="estado-actual-obs"
          name="estado-actual-obs"
          class="form-control"
          formControlName="observation"
          required
        />
      </mat-form-field>
      @if ($actualStateEditing()) {
        <button mat-stroked-button color="primary" (click)="cancelActualEdit()" [disabled]="modoEdicion">
          {{ 'GENERAL.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" [throttledExecution]="newActualState" [disabled]="modoEdicion">
          {{ 'GENERAL.SAVE' | translate }}
        </button>
      } @else {
        <button mat-icon-button color="primary" (click)="activateEditActual()" [disabled]="modoEdicion">
          <mat-icon>edit</mat-icon>
        </button>
      }
    </div>
  </form>
  <h4 class="primary-color">{{ 'GENERAL.PLAN_NEXT_STATE' | translate }}</h4>
  <form [formGroup]="nextFormGroup">
    <div class="d-flex flex-row gap-1 flex-wrap align-items-center">
      <mat-form-field
        class="flex-fill"
        appearance="outline"
        subscriptSizing="dynamic"
        [matTooltip]="
          (this.personalModel.Legajo.estadoActual?.id > 0 ? 'EMPLOYEES.ONLY_ONE_NEXT_STATE' : 'EMPLOYEES.ONLY_TWO_NEXT_STATE') | translate
        "
        [matTooltipDisabled]="$canCreateFutureState() || $isNextStateEditing()"
      >
        <mat-label>{{ 'GENERAL.NEW_STATUS' | translate }}</mat-label>
        <mat-select formControlName="type" required [compareWith]="compareDtoId">
          @for (typeState of $nextTypesStates(); track typeState.id) {
            <mat-option [value]="typeState"> {{ typeState.nombre }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field
        class="flex-fill"
        appearance="outline"
        subscriptSizing="dynamic"
        [matTooltip]="
          (this.personalModel.Legajo.estadoActual?.id > 0 ? 'EMPLOYEES.ONLY_ONE_NEXT_STATE' : 'EMPLOYEES.ONLY_TWO_NEXT_STATE') | translate
        "
        [matTooltipDisabled]="$canCreateFutureState() || $isNextStateEditing()"
      >
        <mat-label>{{ 'EMPLOYEES.DATE_NEW_STATE' | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="pickerNextSince"
          formControlName="since"
          [matDatepickerFilter]="afterFutureStateOrToday"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="pickerNextSince" />
        <mat-datepicker #pickerNextSince [calendarHeaderComponent]="calendarH" />
      </mat-form-field>
      <mat-form-field
        class="flex-fill"
        appearance="outline"
        subscriptSizing="dynamic"
        [matTooltip]="
          (this.personalModel.Legajo.estadoActual?.id > 0 ? 'EMPLOYEES.ONLY_ONE_NEXT_STATE' : 'EMPLOYEES.ONLY_TWO_NEXT_STATE') | translate
        "
        [matTooltipDisabled]="$canCreateFutureState() || $isNextStateEditing()"
      >
        <mat-label> {{ 'GENERAL.OBSERVATION' | translate }} </mat-label>
        <input
          matInput
          type="text"
          id="next-state-observation"
          name="next-state-observation"
          class="form-control"
          required
          formControlName="observation"
        />
      </mat-form-field>
      @if ($isNextStateEditing()) {
        <button mat-stroked-button color="primary" (click)="cancelFutureStateEdit()" [disabled]="modoEdicion">
          {{ 'GENERAL.CANCEL' | translate }}
        </button>
        <button mat-flat-button color="primary" [disabled]="modoEdicion" [throttledExecution]="saveNextState">
          {{ 'GENERAL.SAVE' | translate }}
        </button>
      } @else {
        <button
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="modoEdicion || !$canCreateFutureState()"
          [throttledExecution]="newNextState"
        >
          {{ 'GENERAL.SAVE' | translate }}
        </button>
      }
    </div>
  </form>
  @if (statusChangesDataSource.data.length > 0) {
    <table mat-table [dataSource]="statusChangesDataSource">
      <ng-container [matColumnDef]="ColumnNames.NextState">
        <th mat-header-cell *matHeaderCellDef>{{ 'EMPLOYEES.NEXT_STATE' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.tipo.nombre }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="ColumnNames.Date">
        <th mat-header-cell *matHeaderCellDef>{{ 'GENERAL.DATE' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.desde | ngxdate: 'shortDateFull' }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="ColumnNames.Observation">
        <th mat-header-cell *matHeaderCellDef>{{ 'GENERAL.OBSERVATION' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.observacion }}
        </td>
      </ng-container>

      <ng-container [matColumnDef]="ColumnNames.Actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Más Acciones" class="d-inline-block">
            <mat-icon fontSet="material-symbols-outlined">more_vert|</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="loadNextStateEdit(element)">
              <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
              <span>{{ 'GENERAL.EDIT' | translate }}</span>
            </button>
            <button mat-menu-item (click)="removeFutureState(element)">
              <mat-icon fontSet="material-symbols-outlined">cancel</mat-icon>
              <span>{{ 'GENERAL.CANCEL' | translate }}</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  }
  <h4 class="primary-color">{{ 'GENERAL.LITIGATION' | translate }}</h4>
  <form [formGroup]="litigationFormGroup">
    <mat-slide-toggle
      [disabled]="modoEdicion"
      class="my-2 wrap-content"
      color="primary"
      [checked]="$litigationActive()"
      (change)="activeLitigation($event)"
    >
      {{ 'GENERAL.IN_LITIGATION' | translate }}
    </mat-slide-toggle>
    <div class="d-flex flex-row flex-wrap align-items-center gap-1">
      <mat-form-field class="flex-fill" appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ 'GENERAL.SINCE' | translate }}</mat-label>
        <input matInput [matDatepicker]="pickerLitigation" formControlName="since" required />
        <mat-datepicker-toggle matSuffix [for]="pickerLitigation" />
        <mat-datepicker #pickerLitigation [calendarHeaderComponent]="calendarH" />
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline" subscriptSizing="dynamic">
        <mat-label> {{ 'GENERAL.OBSERVATION' | translate }} </mat-label>
        <input matInput type="text" class="form-control" required formControlName="observation" />
      </mat-form-field>
    </div>
  </form>
  <div class="d-flex flex-row justify-content-end mt-4">
    <button
      type="button"
      mat-raised-button
      color="primary"
      [disabled]="modoEdicion || !$litigationActive()"
      [throttledExecution]="saveLitigation"
    >
      {{ 'GENERAL.SAVE' | translate }}
    </button>
  </div>
</div>
