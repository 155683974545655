import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { from } from 'rxjs';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { FeriadoDTO } from 'src/app/ModelDTO/DTO/feriado.DTO';
import { FeriadoServiceBackend } from 'src/app/ServiceBackend/feriado.ServiceBackend';
import { Weekdays } from 'src/app/shared/interfaces/weekdays.interface';
import { sameDateTimeDay } from 'src/app/shared/shared-functions';

export const daysFromLabor = (labor: ConvenioColectivoDTO): Weekdays => ({
  sunday: labor?.domingo ?? false,
  monday: labor?.lunes ?? true,
  tuesday: labor?.martes ?? true,
  wednesday: labor?.miercoles ?? true,
  thursday: labor?.jueves ?? true,
  friday: labor?.viernes ?? true,
  saturday: labor?.sabado ?? false,
});

@Injectable({
  providedIn: 'root',
})
export class HolidaysService {
  private holidays: FeriadoDTO[] = [];

  constructor(private holidayService: FeriadoServiceBackend) {
    from(this.holidayService.getFeriados()).subscribe((holidays) => {
      this.holidays = holidays;
    });
  }

  public nonWorkingDaysBetweenDates(startDate: DateTime, endDate: DateTime, weekdays: Weekdays): number {
    let current = startDate.startOf('day');
    const end = endDate.startOf('day');
    let count = 0;
    const holidays = this.holidays?.map((holiday) => DateTime.fromJSDate(holiday.fecha)) ?? [];

    while (current <= end) {
      if (!this.isWorkingDay(current, holidays, weekdays)) {
        count += 1;
      }
      current = current.plus({ days: 1 });
    }

    return count;
  }

  private isWorkingDay(date: DateTime, holidays: DateTime[], weekdays: Weekdays): boolean {
    const weekday = date.setLocale('en-US').weekdayLong.toLowerCase();
    const isHoliday = holidays.some((holiday) => sameDateTimeDay(holiday, date));
    return weekdays[weekday] && !isHoliday;
  }
}
