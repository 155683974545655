import { NivelEstudioDTO } from 'src/app/ModelDTO/DTO/nivelEstudio.DTO';
import { Injectable } from '@angular/core';
import { NivelEstudioServiceBackend } from 'src/app/ServiceBackend/nivelEstudio.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class NivelDeEstudioService {
  constructor(private nivelEstudioServiceBackend: NivelEstudioServiceBackend) {}

  public async agregarNivelEstudio(nivelEstudio: NivelEstudioDTO): Promise<NivelEstudioDTO> {
    const res = await this.nivelEstudioServiceBackend.insertNivelEstudio(nivelEstudio);
    return res;
  }

  public async obtenerNivelEstudio(id: number): Promise<NivelEstudioDTO> {
    const res = await this.nivelEstudioServiceBackend.getNivelEstudioId(id);
    return res;
  }

  public async obtenerNivelesDeEstudio(): Promise<NivelEstudioDTO[]> {
    const res = await this.nivelEstudioServiceBackend.getNivelEstudio();
    return res;
  }

  public async eliminarNivelEstudio(id: number): Promise<void> {
    await this.nivelEstudioServiceBackend.deleteNivelEstudioId(id);
  }

  public async editarNivelEstudio(id: number, nivelEstudio: NivelEstudioDTO): Promise<NivelEstudioDTO> {
    const res = await this.nivelEstudioServiceBackend.updateNivelEstudioId(id, nivelEstudio);
    return res;
  }
}
