import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReferredModalComponent } from 'src/app/app-common/welcome/referred-modal/referred-modal.component';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { SidebarService } from 'src/app/shared/lib/ngx-neo-components-mat/sidebar/sidebar.service';
import { MobileSidebarService } from 'src/app/shared/lib/ngx-neo-components-mat/mobile-sidebar/mobile-sidebar.service';
import { environment } from 'src/environments/environment';
import { NotificationHandlerService } from 'src/app/shared/services/notification-handler.service';
import { AuthSecondFactorService } from 'src/app/login/auth-second-factor/authSecondFactor.service';
import { LoginService } from 'src/app/login/login.service';
import { UserServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/backend/user.ServiceBackend';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxNeoPipesModule } from 'src/app/shared/lib/ngx-neo-pipes/ngx-neo-pipes.module';
import { CircleImageComponent } from 'src/app/shared/circle-image/circle-image.component';
import { SelectVistaModalComponent, SelectVistaModalResult } from './select-vista-modal/select-vista-modal.component';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, MatDialogModule, TranslateModule, MatBadgeModule, NgxNeoPipesModule, CircleImageComponent],
})
export class SidebarMenuComponent {
  @Input() public isClockInOut = false;

  public $collapsedState = this.sidebarService.$collapsedState;
  public vistaEmpresa$ = this.headerService.vistaEmpresa$;
  public appVersion = environment.appVersion;
  public $teammateFile = this.headerService.$teammateFile;

  public get username(): string {
    return this.headerService.userLogged.firstName;
  }

  constructor(
    private headerService: HeaderAppService,
    public notificationService: NotificationHandlerService,
    private dialog: MatDialog,
    private router: Router,
    private sidebarService: SidebarService,
    private mobileSidebarService: MobileSidebarService,
    private loginService: LoginService,
    private userServiceBackend: UserServiceBackend,
    private authSecondFactorService: AuthSecondFactorService,
  ) {}

  public async menuVista(): Promise<void> {
    const dialogRef = this.dialog.open(SelectVistaModalComponent, {
      position: { bottom: '5rem', left: '1rem' },
      disableClose: false,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      // eslint-disable-next-line default-case
      switch (result) {
        case SelectVistaModalResult.CerrarSesion:
          await this.headerService.Logout();
          break;
        case SelectVistaModalResult.CambiarVista:
          this.headerService.cambiarModo();
          break;
        case SelectVistaModalResult.CambiarAFichaje:
          // We use this to close the sidebar menu before opening the new modal and avoid issues with the auto-closing behavior we have for modals.
          setTimeout(() => {
            this.headerService.openClockInOutTerminal();
          }, 100);
          break;
        case SelectVistaModalResult.Notifiaciones:
          this.router.navigateByUrl('/notifications');
          break;
        case SelectVistaModalResult.MiCuenta:
          this.router.navigateByUrl('/mi-cuenta');
          break;
        case SelectVistaModalResult.CambiarCuenta:
          await this.showAllTenantAccount();
          break;
        case SelectVistaModalResult.Ayuda:
          this.router.navigateByUrl('/ayuda');
          break;
        case SelectVistaModalResult.Referral:
          setTimeout(() => {
            this.dialog.open(ReferredModalComponent, {
              panelClass: 'full-size-sm-900-lg-modal',
              disableClose: false,
            });
          }, 100);
          break;
        case SelectVistaModalResult.Perks:
          this.router.navigateByUrl('/admin/perks');
          break;
        case SelectVistaModalResult.Backoffice:
          this.router.navigateByUrl('/backoffice');
          break;
      }

      this.mobileSidebarService.showSidebar.next(false);
    });
  }

  public openMenu(): void {
    if (this.$collapsedState() || this.isClockInOut) {
      this.menuVista();
    } else {
      this.router.navigate(['user/social-profile', this.headerService.$user().user.userTypeId]);
    }
  }

  private async showAllTenantAccount(): Promise<void> {
    const res = await this.userServiceBackend.getUserAllTenants();
    const tenant = await this.loginService.showAllTenantAccount(res);
    await this.authSecondFactorService.changeTenant(tenant);
  }
}
