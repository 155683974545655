/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { PersonalCambioEstadoDTO } from 'src/app/api/interfaces/personal-cambio-estado.interface';

@Injectable({
   providedIn: 'root'
})
export class PersonaCambioEstadolBackendService {

   constructor(protected http: HttpClient) { }


   public getCambioEstadoIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/cambioEstado/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public insertCambioEstadoIdLegajo(idLegajo: number, personalCambioEstadoDTO: PersonalCambioEstadoDTO): Observable<PersonalCambioEstadoDTO> {
      return this.http.post<PersonalCambioEstadoDTO>(Constants.apiURL + '/cambioEstado/' + idLegajo, personalCambioEstadoDTO).pipe(map((res) => convertJsonDates(res) as PersonalCambioEstadoDTO ));
   }

   public insertCambioEstadoTeammatefileId(id: number, iD: number, personalCambioEstadoDTO: PersonalCambioEstadoDTO): Observable<PersonalCambioEstadoDTO> {
      return this.http.post<PersonalCambioEstadoDTO>(Constants.apiURL + '/cambioEstado/teammate-file/' + id, personalCambioEstadoDTO).pipe(map((res) => convertJsonDates(res) as PersonalCambioEstadoDTO ));
   }

   public updateCambioEstadoId(id: number, personalCambioEstadoDTO: PersonalCambioEstadoDTO): Observable<PersonalCambioEstadoDTO> {
      return this.http.put<PersonalCambioEstadoDTO>(Constants.apiURL + '/cambioEstado/' + id, personalCambioEstadoDTO).pipe(map((res) => convertJsonDates(res) as PersonalCambioEstadoDTO ));
   }

   public deleteCambioEstadoId(id: number): Observable<PersonalCambioEstadoDTO> {
      return this.http.delete<PersonalCambioEstadoDTO>(Constants.apiURL + '/cambioEstado/' + id).pipe(map((res) => convertJsonDates(res) as PersonalCambioEstadoDTO ));
   }
}
