import { SalesCrmStatus } from 'src/app/ModelDTO/DTO/salesCrmStatus.ENUM';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class SalesCrmDTO implements IEntityDTO {

   createDate: Date;
   name = '';
   status: SalesCrmStatus;
   referStage: SalesCrmStatus;
   referStatus: SalesCrmStatus;
   refersName = '';
   refersLastName = '';
   refersEmail = '';
   refersCompany = '';
   refersCode = '';
   refersAmount = 0;
   refersDemoAmount = 0;
   referredName = '';
   referredPhone = '';
   referredEmail = '';
   referredOrganization = '';
   id = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.createDate != null) { this.createDate = new Date(jsonObj.createDate); }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.status != null) { this.status = jsonObj.status as SalesCrmStatus; }
      if (jsonObj.referStage != null) { this.referStage = jsonObj.referStage as SalesCrmStatus; }
      if (jsonObj.referStatus != null) { this.referStatus = jsonObj.referStatus as SalesCrmStatus; }
      if (jsonObj.refersName != null) { this.refersName = jsonObj.refersName; }
      if (jsonObj.refersLastName != null) { this.refersLastName = jsonObj.refersLastName; }
      if (jsonObj.refersEmail != null) { this.refersEmail = jsonObj.refersEmail; }
      if (jsonObj.refersCompany != null) { this.refersCompany = jsonObj.refersCompany; }
      if (jsonObj.refersCode != null) { this.refersCode = jsonObj.refersCode; }
      if (jsonObj.refersAmount != null) { this.refersAmount = jsonObj.refersAmount; }
      if (jsonObj.refersDemoAmount != null) { this.refersDemoAmount = jsonObj.refersDemoAmount; }
      if (jsonObj.referredName != null) { this.referredName = jsonObj.referredName; }
      if (jsonObj.referredPhone != null) { this.referredPhone = jsonObj.referredPhone; }
      if (jsonObj.referredEmail != null) { this.referredEmail = jsonObj.referredEmail; }
      if (jsonObj.referredOrganization != null) { this.referredOrganization = jsonObj.referredOrganization; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
