<div class="d-flex flex-row mb-4">
  <div class="d-flex flex-row align-items-center w-75">
    <app-circle-image [width]="40" class="mr-2" [personalLegajo]="ausenciaModel.PersonalLegajo"> </app-circle-image>
    <div class="d-flex flex-column">
      <h4 class="m-0 line-1">
        {{ ausenciaModel.PersonalLegajoModel.NombreCompleto }}
      </h4>
      <small class="color-gray-light"
        >{{ 'PROFILE.FILE' | translate }}: {{ ausenciaModel.PersonalLegajoModel.Legajo }} | {{ 'PROFILE.TIN' | translate }}:
        {{ ausenciaModel.PersonalLegajoModel.Cuil }}</small
      >
    </div>
  </div>
  <div class="d-flex justify-content-end w-25">
    <mat-chip-set aria-label="estado solicitud">
      <mat-chip [color]="chipColor()">
        {{ absenceModelState | async }}
      </mat-chip>
    </mat-chip-set>
  </div>
</div>

<div class="d-flex flex-row align-items-center">
  <div class="d-flex flex-column">
    <div class="d-flex flex-row m-0">
      <p *ngIf="ausenciaDias; else ausenciaHoras">
        {{ 'TIMEOFF.PERIOD' | translate }}:
        <strong>
          {{ fromDate | ngxdate: 'shortDateFull' }} {{ 'GENERAL.TO' | translate }}
          {{ toDate | ngxdate: 'shortDateFull' }}
        </strong>
      </p>
      <ng-template #ausenciaHoras>
        <p>
          {{ 'TIMEOFF.PERIOD' | translate }}:<strong>
            {{ fromDate | ngxdate: 'fullDateShortTime' }} {{ 'GENERAL.TO' | translate }} {{ toDate | ngxdate: 'fullDateShortTime' }}</strong
          >
        </p>
      </ng-template>
    </div>
    <div class="d-flex flex-row m-0">
      <p>
        {{ 'GENERAL.TYPE' | translate }}:<strong> {{ ausenciaModel.Ausencia.nombre }} {{ absenceModelType | async | accent: true }}</strong>
      </p>
    </div>
    <div class="d-flex flex-row m-0">
      <p>
        {{ 'TIMEOFF.PAID' | translate }}:<strong>
          {{ ausenciaModel.Ausencia.gozaHaberes ? ('GENERAL.YES' | translate) : ('GENERAL.NO' | translate) }}</strong
        >
      </p>
    </div>
    <div class="d-flex flex-row m-0" *ngIf="ausenciaModel.Creador?.id">
      <p>
        {{ 'GENERAL.CREATOR' | translate }}:<strong> {{ ausenciaModel.Creador.nombreCompleto }}</strong>
      </p>
    </div>
    <div class="d-flex flex-row m-0" *ngIf="solicitudAusencia?.Responsable?.id">
      <p>
        {{ reviewerText | async }}:<strong>
          {{ solicitudAusencia.Responsable.nombreCompleto }}
        </strong>
      </p>
    </div>
    <div class="d-flex flex-row m-0" *ngIf="!ausenciaModel.ConSolicitud">
      <p>{{ 'TIMEOFF.CREATED_WITHOUT_PREVIOUS_REQUEST' | translate }}</p>
    </div>
    <app-file-chips class="my-2" [attachments]="ausenciaModel.Adjuntos" [noFilesMessage]="''"></app-file-chips>
  </div>
  <div class="d-flex flex-column flex-fill text-right color-gray-light">
    <h1 class="m-0 line-1 mb-1">{{ duracion }}</h1>
    <span class="lowercase">{{ infoDuracion | accent: true }}</span>
  </div>
</div>

<app-saldo-cc [ausencia]="ausenciaModel.Ausencia" [personalLegajo]="ausenciaModel.PersonalLegajo"></app-saldo-cc>

<div *ngIf="ausenciaModel.Descripcion" class="mt-2">
  <label class="gray-text-muted">{{ 'TIMEOFF.REQUEST_MOTIVE' | translate }}</label>
  <div class="d-flex flex-column html-view-container">
    <quill-view-html [content]="ausenciaModel.Descripcion | emptyParagraph"></quill-view-html>
  </div>
</div>

<div *ngIf="(vistaEmpresa$ | async) && solicitudAusencia?.ObservacionSupervisor" class="mt-2">
  <label class="gray-text-muted">{{ 'TIMEOFF.REVIEWER_OBSERVATIONS' | translate }}</label>
  <div class="d-flex flex-column html-view-container">
    <quill-view-html [content]="solicitudAusencia?.ObservacionSupervisor | emptyParagraph"></quill-view-html>
  </div>
</div>
