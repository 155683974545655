<div class="d-flex flex-column flex-grow-1">
  <mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedTab">
    <mat-tab [label]="'PROFILE.PERSONAL' | translate">
      <app-datos-personales [modoEmpresa]="modoEmpresa" [editMode]="modoEdicion" [personalModel]="personalModel"> </app-datos-personales>
    </mat-tab>
    <mat-tab [label]="'PROFILE.FAMILY.FAMILY' | translate">
      <app-datos-familiares [modoEmpresa]="modoEmpresa" [modoEdicion]="modoEdicion" [personalModel]="personalModel"> </app-datos-familiares>
    </mat-tab>
    <mat-tab [label]="'PROFILE.CONTACT' | translate">
      <app-datos-contacto [modoEmpresa]="modoEmpresa" [modoEdicion]="modoEdicion" [contactForm]="contactForm"> </app-datos-contacto>
    </mat-tab>
  </mat-tab-group>
</div>
