<div class="d-flex flex-column justify-content-center gap-1">
  <span *ngIf="accountName" class="smaller gray-text-muted mb-2"> {{ accountName }}</span>
  <div class="d-flex justify-content-center code-container">
    <!-- Intente usar un array para no repetir los input pero el binding tenia fallas -->
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <input
        matInput
        [(ngModel)]="code0"
        placeholder="-"
        (input)="onInput(code0, 0)"
        (keyup)="onKeyUp($event, 0)"
        id="code0"
        cdkFocusInitial
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        [neoAutofocus]="autoFocus"
        (focus)="onFocus($event)"
        [disabled]="disabled"
      />
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <input
        matInput
        [(ngModel)]="code1"
        placeholder="-"
        (input)="onInput(code1, 1)"
        (keyup)="onKeyUp($event, 1)"
        id="code1"
        maxlength="1"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        (focus)="onFocus($event)"
        [disabled]="disabled"
      />
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <input
        matInput
        [(ngModel)]="code2"
        placeholder="-"
        (input)="onInput(code2, 2)"
        (keyup)="onKeyUp($event, 2)"
        id="code2"
        maxlength="1"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        (focus)="onFocus($event)"
        [disabled]="disabled"
      />
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <input
        matInput
        [(ngModel)]="code3"
        placeholder="-"
        (input)="onInput(code3, 3)"
        (keyup)="onKeyUp($event, 3)"
        id="code3"
        maxlength="1"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        (focus)="onFocus($event)"
        [disabled]="disabled"
      />
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <input
        matInput
        [(ngModel)]="code4"
        placeholder="-"
        (input)="onInput(code4, 4)"
        (keyup)="onKeyUp($event, 4)"
        id="code4"
        maxlength="1"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        (focus)="onFocus($event)"
        [disabled]="disabled"
      />
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <input
        matInput
        [(ngModel)]="code5"
        placeholder="-"
        (input)="onInput(code5, 5)"
        (keyup)="onKeyUp($event, 5)"
        id="code5"
        maxlength="1"
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        (focus)="onFocus($event)"
        [disabled]="disabled"
      />
    </mat-form-field>
  </div>
</div>
