import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxNeoDirectivesModule } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-neo-directives.module';

@Component({
  selector: 'app-auth-code',
  templateUrl: './auth-code.component.html',
  styleUrls: ['./auth-code.component.scss'],
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, FormsModule, NgxNeoDirectivesModule],
})
export class AuthCodeComponent {
  @Input() public accountName = '';
  @Input() public autoFocus = false;
  @Input() public disabled = false;
  @Output() public codeEntered = new EventEmitter<string>();

  public code0 = '';
  public code1 = '';
  public code2 = '';
  public code3 = '';
  public code4 = '';
  public code5 = '';

  public onInput(value: string, index: number): void {
    if (value.length) {
      // copy/paste handle
      if (index === 0) {
        setTimeout(() => {
          this.code0 = value.length > 0 ? value.charAt(0) : '';
          this.code1 = value.length > 1 ? value.charAt(1) : '';
          this.code2 = value.length > 2 ? value.charAt(2) : '';
          this.code3 = value.length > 3 ? value.charAt(3) : '';
          this.code4 = value.length > 4 ? value.charAt(4) : '';
          this.code5 = value.length > 5 ? value.charAt(5) : '';

          if (value.length >= 6) {
            this.submit();
          }
        });
      }

      if (index < 5) {
        const nextInput = document.getElementById(`code${index + 1}`) as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      } else {
        this.submit();
      }
    }
  }

  public onKeyUp(event: KeyboardEvent, index: number): void {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (index > 0) {
        const nextInput = document.getElementById(`code${index - 1}`) as HTMLInputElement;
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  }

  public submit(): void {
    const code = [this.code0, this.code1, this.code2, this.code3, this.code4, this.code5].join('').replace(' ', '');
    if (code.length === 6) {
      this.codeEntered.emit(code);
    }
  }

  public onFocus(event: FocusEvent): void {
    (event.target as HTMLInputElement)?.select();
  }
}
