/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { CountryDetailDTO } from 'src/app/api/interfaces/country-detail.interface';
import { PaisDTO } from 'src/app/api/interfaces/pais.interface';

@Injectable({
   providedIn: 'root'
})
export class PaisBackendService {

   constructor(protected http: HttpClient) { }


   public getPaisesIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/paises/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getPaises(): Observable<PaisDTO[]> {
      return this.http.get<DataDTO<PaisDTO>>(Constants.apiURL + '/paises/').pipe(map((res) => convertJsonDates(res.data) as PaisDTO[]));
   }

   public insertPaises(paisDTO: PaisDTO): Observable<PaisDTO> {
      return this.http.post<PaisDTO>(Constants.apiURL + '/paises/', paisDTO).pipe(map((res) => convertJsonDates(res) as PaisDTO ));
   }

   public updatePaisesId(id: number, paisDTO: PaisDTO): Observable<PaisDTO> {
      return this.http.put<PaisDTO>(Constants.apiURL + '/paises/' + id, paisDTO).pipe(map((res) => convertJsonDates(res) as PaisDTO ));
   }

   public deletePaisesId(id: number, deleteFromDb: boolean = false): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/paises/' + id + '?deleteFromDb=' + deleteFromDb);
   }
   public getPaisesId(id: number): Observable<CountryDetailDTO> {
      return this.http.get<CountryDetailDTO>(Constants.apiURL + '/paises/' + id).pipe(map((res) => convertJsonDates(res) as CountryDetailDTO ));
   }

   public getPaisesAutoComplete(contiene: string, pageSize: number): Observable<CountryDetailDTO[]> {
      return this.http.get<DataDTO<CountryDetailDTO>>(Constants.apiURL + '/paises/autoComplete' + '?contiene=' + contiene +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as CountryDetailDTO[]));
   }

   public getPaisesNacionalidadesAutoComplete(contiene: string, pageSize: number): Observable<PaisDTO[]> {
      return this.http.get<DataDTO<PaisDTO>>(Constants.apiURL + '/paises/nacionalidades/autoComplete' + '?contiene=' + contiene +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PaisDTO[]));
   }
}
