import { Component, Input } from '@angular/core';
import { CambioDTO } from 'src/app/ModelDTO/DTO/cambio.DTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { ModalEdicionHistorialComponent } from 'src/app/app-common/perfil-empleado-shared/detalle-empleado/datos-historicos/modal-edicion-historial/modal-edicion-historial.component';

@Component({
  selector: 'app-lista-historial-cambios',
  templateUrl: './lista-historial-cambios.component.html',
})
export class ListaHistorialCambiosComponent {
  @Input() titulo: string;
  @Input() listado: Array<CambioDTO>;
  @Input() personalModel: PersonalModelDTO;
  @Input() disabled = false;

  public editarCmd: ICommand = new Command((value) => this.editarCambio(value), new BehaviorSubject(true), true);
  public eliminarCmd: ICommand = new Command((value) => this.eliminarCambio(value), new BehaviorSubject(true), true);

  constructor(
    private dialog: MatDialog,
    private neoModalService: NgxNeoModalMatService,
    private perfilEmpleadoSharedService: PerfilEmpleadoSharedService,
    private snackBar: SnackBarService,
  ) {}

  public async editarCambio(value: CambioDTO): Promise<void> {
    const dialogRef = this.dialog.open(ModalEdicionHistorialComponent, {
      disableClose: false,
      data: value,
    });
    dialogRef.afterClosed().subscribe(async (result: CambioDTO) => {
      if (result) {
        const res = await this.perfilEmpleadoSharedService.editarCambioHistorico(this.personalModel.Legajo.id, result);
        value = res;
        this.snackBar.showInfo('GENERAL.SUCCESSFUL_MODIFICATION');
        this.listado = [...this.listado];
      }
    });
  }

  public async eliminarCambio(value: CambioDTO): Promise<void> {
    const res = await this.neoModalService.decision('¿Estás seguro que deseas eliminar el item del historial?');
    if (res.ButtonResponse === AlertButton.Accept) {
      await this.perfilEmpleadoSharedService.eliminarCambioHistorico(this.personalModel.Legajo.id, value);
      const index = this.listado.findIndex((x) => x.id === value.id);
      this.listado.splice(index, 1);
      this.listado = [...this.listado];
    }
  }
}
