import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { ModalidadContractualDTO } from 'src/app/ModelDTO/DTO/modalidadContractual.DTO';
import { EstadisticaTotalesDTO } from 'src/app/ModelDTO/DTO/estadisticaTotales.DTO';

@Injectable({
   providedIn: 'root'
})
export class ModalidadContractualServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getModalidadIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/modalidad/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getModalidadIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/modalidad/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getModalidad(): Promise<Array<ModalidadContractualDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/modalidad/'));
         const resJson = res.data;
         const resDTO = new Array<ModalidadContractualDTO>();
         for (const item of resJson) {
            const itemDTO = new ModalidadContractualDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertModalidad(modalidadContractualDTO: ModalidadContractualDTO): Promise<ModalidadContractualDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/modalidad/', modalidadContractualDTO));
         const resDTO = new ModalidadContractualDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateModalidadId(id: number, modalidadContractualDTO: ModalidadContractualDTO): Promise<ModalidadContractualDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/modalidad/' + id, modalidadContractualDTO));
         const resDTO = new ModalidadContractualDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteModalidadId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/modalidad/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getModalidadId(id: number): Promise<ModalidadContractualDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/modalidad/' + id));
         if (!res) { return null; }
         const resDTO = new ModalidadContractualDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getModalidadIdTeammatesCount(id: number): Promise<EstadisticaTotalesDTO> {

       const res = await lastValueFrom(this.http.get(Constants.apiURL + '/modalidad/' + id + '/teammates-count'));
       if (!res) { return null; }
       const resDTO = new EstadisticaTotalesDTO();
       resDTO.PrepareDTO(res);
       return resDTO;

 }
}
