import { Directive, ElementRef } from '@angular/core';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[ios-link]',
  standalone: true,
})
export class IosLinkDirective {
  public get linkTarget(): string {
    return this.cordovaService.isIOSApp ? '_system' : '_blank';
  }

  constructor(
    private cordovaService: CordovaService,
    private el: ElementRef,
  ) {
    this.el.nativeElement.target = this.linkTarget;
  }
}
