import { Injectable } from '@angular/core';
import { BeneficioPersonalDTO } from 'src/app/ModelDTO/DTO/beneficioPersonal.DTO';
import { BeneficioPersonalServiceBackend } from 'src/app/ServiceBackend/beneficioPersonal.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class BeneficiosAdicionalesService {
  private beneficioAEditar: BeneficioPersonalDTO;

  constructor(private beneficioAdicionalServiceBackend: BeneficioPersonalServiceBackend) {}

  public async obtenerBeneficios(): Promise<Array<BeneficioPersonalDTO>> {
    return this.beneficioAdicionalServiceBackend.getBeneficiosPersonal();
  }

  public async agregarBeneficio(beneficio: BeneficioPersonalDTO): Promise<BeneficioPersonalDTO> {
    const res = await this.beneficioAdicionalServiceBackend.insertBeneficiosPersonal(beneficio);
    return res;
  }

  public async editarBeneficio(beneficio: BeneficioPersonalDTO): Promise<BeneficioPersonalDTO> {
    const res = await this.beneficioAdicionalServiceBackend.updateBeneficiosPersonalId(beneficio.id, beneficio);
    return res;
  }

  public async eliminarBeneficio(beneficio: BeneficioPersonalDTO): Promise<void> {
    await this.beneficioAdicionalServiceBackend.deleteBeneficiosPersonalId(beneficio.id);
  }

  get BeneficioAEditar(): BeneficioPersonalDTO {
    return this.beneficioAEditar;
  }

  set BeneficioAEditar(t: BeneficioPersonalDTO) {
    this.beneficioAEditar = t;
  }
}
