import { Injectable } from '@angular/core';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { BehaviorSubject } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { SignatureStatusType } from '@api/enums/signature-status-type.enum';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { ConfiguracionFirmaDigitalPosicionDTO } from 'src/app/ModelDTO/DTO/configuracionFirmaDigitalPosicion.DTO';
import { AttachmentUaalooDTO } from 'src/app/ModelDTO/DTO/attachmentUaaloo.DTO';
import { AttachmentUaalooModelDTOExtended } from './attachment.ModelDTO.extended';

@Injectable({
  providedIn: 'root',
})
export class AttachmentSelectorService {
  public firmantesSeleccionados: PersonalLegajoBasicoDTO[] = [];
  public posicionFirma: ConfiguracionFirmaDigitalPosicionDTO;
  public notificarPorMail: boolean;

  private arrayAttachmentsASubir: Array<AttachmentUaalooModelDTOExtended> = [];
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public arrayAttachmentsASubirChange$ = new BehaviorSubject<Array<AttachmentUaalooModelDTOExtended>>(this.arrayAttachmentsASubir);

  private firmanteColaborador: PersonalLegajoBasicoDTO = new PersonalLegajoBasicoDTO();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public firmanteColaboradorChange$ = new BehaviorSubject<PersonalLegajoBasicoDTO>(this.firmanteColaborador);

  private creadorDelArchivo: PersonalLegajoBasicoDTO = new PersonalLegajoBasicoDTO();

  constructor(
    private neoModalService: NgxNeoModalMatService,
    private headerService: HeaderAppService,
  ) {
    this.init();
  }

  public init(): void {
    this.arrayAttachmentsASubir = [];
    this.arrayAttachmentsASubirChange$.next(this.arrayAttachmentsASubir);
    this.creadorDelArchivo = new PersonalLegajoBasicoDTO();
    this.firmanteColaborador = new PersonalLegajoBasicoDTO();

    this.creadorDelArchivo.id = this.headerService.personalLegajoId;
    this.creadorDelArchivo.nombreCompleto = this.headerService.userLogged.fullName;
  }

  public setFirmanteColaborador(nuevoFirmante: PersonalLegajoBasicoDTO): void {
    const firmante = nuevoFirmante ?? new PersonalLegajoBasicoDTO();
    this.arrayAttachmentsASubir.forEach((x) => {
      x.removeSignature(this.firmanteColaborador);
    });
    this.firmanteColaborador = firmante;
    this.firmanteColaboradorChange$.next(this.firmanteColaborador);
  }

  public async agregarNuevoFile(file: File): Promise<void> {
    if (file === null || file === undefined) {
      return;
    }
    if (this.creadorDelArchivo.id === 0) {
      this.creadorDelArchivo.id = this.headerService.personalLegajoId;
      this.creadorDelArchivo.nombreCompleto = this.headerService.userLogged.fullName;
      if (this.creadorDelArchivo.id === 0) {
        this.neoModalService.warning('DOCUMENTS.FILE_CREATOR_ERROR');
        return;
      }
    }

    const nuevoAdjunto = new AttachmentUaalooModelDTOExtended(new AttachmentUaalooDTO());
    nuevoAdjunto.setFromFile(file);
    nuevoAdjunto.CreatorPersonalLegajo = this.creadorDelArchivo;

    const existingFile = this.arrayAttachmentsASubir.some((f) => f.RawFile.name === file.name);
    if (!existingFile) {
      this.arrayAttachmentsASubir.unshift(nuevoAdjunto);
      this.setAttachmentsSeleccionados(this.arrayAttachmentsASubir);
    }
  }

  public removerAdjunto(adjunto: AttachmentUaalooModelDTOExtended): void {
    this.setAttachmentsSeleccionados(this.arrayAttachmentsASubir.filter((x) => x !== adjunto));
  }

  public removerAdjuntos(): void {
    this.arrayAttachmentsASubir = [];
    this.setAttachmentsSeleccionados(this.arrayAttachmentsASubir);
  }

  public hayAttachmentsASubirInvalidos(): boolean {
    if (this.arrayAttachmentsASubir.length === 0) {
      this.neoModalService.warning('DOCUMENTS.FILE_QUANTITY_CONTROL');
      return true;
    }
    return false;
  }

  public borrarYAgregarFirmantesSolicitados(personalLegajoArray: PersonalLegajoBasicoDTO[]): void {
    if (personalLegajoArray) {
      this.firmantesSeleccionados = personalLegajoArray;
      this.arrayAttachmentsASubir.forEach((attachment) => {
        // eslint-disable-next-line no-param-reassign
        attachment.PosicionFirma = this.posicionFirma;
        // eslint-disable-next-line no-param-reassign
        attachment.Signatures = [];
        if (attachment.AllowsDigitalSignature) {
          personalLegajoArray.forEach((x) =>
            attachment.addSignature(x, SignatureStatusType.Requested, this.creadorDelArchivo, this.notificarPorMail),
          );
        }
      });
    }
  }

  public setAttachmentsSeleccionados(attachments: AttachmentUaalooModelDTOExtended[]): void {
    this.arrayAttachmentsASubir = [...attachments];
    this.borrarYAgregarFirmantesSolicitados(this.firmantesSeleccionados);
    this.arrayAttachmentsASubirChange$.next(this.arrayAttachmentsASubir);
  }
}
