/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { DataDTO } from 'src/app/api/interfaces/data.interface';
import { Constants } from 'src/app/shared/constants';
import { convertJsonDates } from 'src/app/shared/shared-functions';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { HealthInsuranceCompanyDTO } from 'src/app/api/interfaces/health-insurance-company.interface';

@Injectable({
   providedIn: 'root'
})
export class HealthInsuranceCompanyBackendService {

   constructor(protected http: HttpClient) { }


   public getHealthinsurancecompanyIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/health-insurance-company/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public getHealthinsurancecompany(countryId: number = 0, searchValue: string = ''): Observable<HealthInsuranceCompanyDTO[]> {
      return this.http.get<DataDTO<HealthInsuranceCompanyDTO>>(Constants.apiURL + '/health-insurance-company/' + '?countryId=' + countryId +
            '&searchValue=' + searchValue).pipe(map((res) => convertJsonDates(res.data) as HealthInsuranceCompanyDTO[]));
   }
}
