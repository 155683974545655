import { Injectable } from '@angular/core';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { Observable, lastValueFrom, map } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { BusinessNameDTO } from 'src/app/ModelDTO/DTO/businessName.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';

@Injectable({
   providedIn: 'root'
})
export class BusinessNameServiceBackend {

   constructor(protected http: HttpClient) { }

   public async getBusinessNamesIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/business-name/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public getBusinessNames(): Observable<Array<BusinessNameDTO>> {
      return this.http.get<DataDTO>(Constants.apiURL + '/business-name/').pipe(
        map((res)=>{
          const resJson = res.data;
          const resDTO = new Array<BusinessNameDTO>();
          for (const item of resJson) {
             const itemDTO = new BusinessNameDTO();
             itemDTO.PrepareDTO(item);
             resDTO.push(itemDTO);
          }
          return resDTO;
        }));
   }

   public async insertBusinessNames(businessNameDTO: BusinessNameDTO): Promise<BusinessNameDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/business-name/', businessNameDTO));
         const resDTO = new BusinessNameDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateBusinessNamesId(id: number, businessNameDTO: BusinessNameDTO): Promise<BusinessNameDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/business-name/' + id, businessNameDTO));
         const resDTO = new BusinessNameDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteBusinessNamesId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/business-name/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getBusinessNamesId(id: number): Promise<BusinessNameDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/business-name/' + id));
         if (!res) { return null; }
         const resDTO = new BusinessNameDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }
}
