import { Component, Input, OnInit } from '@angular/core';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { CambiosHistoricosModelDTO } from 'src/app/ModelDTO/cambiosHistoricos.ModelDTO';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';

@Component({
  selector: 'app-datos-historicos',
  templateUrl: './datos-historicos.component.html',
})
export class DatosHistoricosComponent implements OnInit {
  @Input() public personalModel: PersonalModelDTO;
  @Input() public modoEdicion = false;
  @Input() public modoEmpresa = false;
  @Input() public historial: CambiosHistoricosModelDTO;

  constructor(private perfilEmpleadoSharedService: PerfilEmpleadoSharedService) {}

  public ngOnInit(): void {
    this.perfilEmpleadoSharedService.obtenerHistorialCambios(this.personalModel.Legajo.id).then((h) => {
      this.historial = h;
    });
  }
}
