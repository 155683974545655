import { EstadoAsistencia } from 'src/app/ModelDTO/DTO/estadoAsistencia.ENUM';
import { EstadoFichaje } from 'src/app/ModelDTO/DTO/estadoFichaje.ENUM';
import { JornadasPorDiaDTO } from 'src/app/ModelDTO/DTO/jornadasPorDia.DTO';
import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';


export class VistaJornadasPorColaboradorDTO implements IEntityDTO {

   personalOficina = '';
   personalArea = '';
   personalSector = '';
   horasEsperadas = '';
   horasFichajes = '';
   diasFichajes = 0;
   balance = '';
   jornadasPorDia: Array<JornadasPorDiaDTO>;
   personalId = 0;
   personalNombre = '';
   personalApellido = '';
   personalImage = '';
   estadoFichaje: EstadoFichaje;
   estadoAsistencia: EstadoAsistencia;
   diasJornadas?: number;
   diasPresenciales?: number;
   diasRemotos?: number;
   diasHibridos?: number;
   diasConDescansoComputado?: number;
   diasConDescansoSinComputar?: number;
   diasSinDescanso?: number;
   id = 0;

   constructor() {
      this.jornadasPorDia = new Array<JornadasPorDiaDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.personalOficina != null) { this.personalOficina = jsonObj.personalOficina; }
      if (jsonObj.personalArea != null) { this.personalArea = jsonObj.personalArea; }
      if (jsonObj.personalSector != null) { this.personalSector = jsonObj.personalSector; }
      if (jsonObj.horasEsperadas != null) { this.horasEsperadas = jsonObj.horasEsperadas; }
      if (jsonObj.horasFichajes != null) { this.horasFichajes = jsonObj.horasFichajes; }
      if (jsonObj.balance != null) { this.balance = jsonObj.balance; }
      if (jsonObj.jornadasPorDia != null) { for (const item of jsonObj.jornadasPorDia) { const itemDTO = new JornadasPorDiaDTO(); itemDTO.PrepareDTO(item); this.jornadasPorDia.push(itemDTO); } }
      if (jsonObj.personalId != null) { this.personalId = jsonObj.personalId; }
      if (jsonObj.personalNombre != null) { this.personalNombre = jsonObj.personalNombre; }
      if (jsonObj.personalApellido != null) { this.personalApellido = jsonObj.personalApellido; }
      if (jsonObj.personalImage != null) { this.personalImage = jsonObj.personalImage; }
      if (jsonObj.estadoFichaje != null) { this.estadoFichaje = jsonObj.estadoFichaje as EstadoFichaje; }
      if (jsonObj.estadoAsistencia != null) { this.estadoAsistencia = jsonObj.estadoAsistencia as EstadoAsistencia; }
      if (jsonObj.diasJornadas != null) { this.diasJornadas = jsonObj.diasJornadas; }
      if (jsonObj.diasPresenciales != null) { this.diasPresenciales = jsonObj.diasPresenciales; }
      if (jsonObj.diasRemotos != null) { this.diasRemotos = jsonObj.diasRemotos; }
      if (jsonObj.diasHibridos != null) { this.diasHibridos = jsonObj.diasHibridos; }
      if (jsonObj.diasFichajes != null) { this.diasFichajes = jsonObj.diasFichajes; }
      if (jsonObj.diasConDescansoComputado != null) { this.diasConDescansoComputado = jsonObj.diasConDescansoComputado; }
      if (jsonObj.diasConDescansoSinComputar != null) { this.diasConDescansoSinComputar = jsonObj.diasConDescansoSinComputar; }
      if (jsonObj.diasSinDescanso != null) { this.diasSinDescanso = jsonObj.diasSinDescanso; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
