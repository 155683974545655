<div class="d-flex flex-column flex-grow-1 pb-6">
  <h4 class="primary-color">{{ 'ACCOUNT.USER_ROLE' | translate }}</h4>
  <div class="d-flex flex-column flex-wrap gap-2">
    <app-banner icon="info" type="info">
      <span class="break-line">
        {{ 'SETTINGS.PERMISSIONS.ROL_ORDER_EXPLANATION' | translate }}
      </span>
    </app-banner>
    <mat-form-field class="w-100 w-lg-33" subscriptSizing="dynamic" appearance="outline">
      <mat-select [formControl]="formControlRole" [disabled]="editMode || disabledTeammateStatus" class="form-control">
        @for (role of $roles(); track role.id) {
          <mat-option [value]="role.id"> {{ role.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <h4 class="primary-color">{{ 'GENERAL.AUTHENTICATION' | translate }}</h4>
  <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
    <button type="button" mat-raised-button color="primary" [throttledExecution]="invalidarToken">
      {{ 'GENERAL.FORCE_LOGOUT' | translate }}
    </button>
  </div>
</div>
