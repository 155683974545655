<div class="d-flex flex-column flex-grow-1 justify-content-center">
  <div class="d-flex flex-column align-items-center">
    <mat-card class="auth-form-container d-flex flex-column align-items-center align-self-center">
      <mat-card-content class="text-center">
        <h3 class="primary-color text-center my-0">
          <strong>{{ 'ACCOUNT.USER2FA' | translate }}</strong>
        </h3>
        <span class="smaller gray-text-muted">{{ 'ACCOUNT.USER2FA_REQUEST' | translate }}</span>
        <hr class="mb-4" />
        <app-auth-code [autoFocus]="true" [accountName]="accountName" (codeEntered)="update2FA($event)"></app-auth-code>
        <button class="mt-4" (click)="close()" mat-button type="button">{{ 'GENERAL.CANCEL' | translate }}</button>
      </mat-card-content>
    </mat-card>
  </div>
</div>
