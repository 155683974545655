import { Injectable } from '@angular/core';
import { CargoServiceBackend } from 'src/app/ServiceBackend/cargo.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class CargoEntityService {
  constructor(private cargoServiceBackend: CargoServiceBackend) {}

  public async getEntitiesByName(search: string) {
    const res = await this.cargoServiceBackend.getCargosAutoComplete(search, 30);
    return res;
  }
}
