import { Injectable } from '@angular/core';

@Injectable()
export class Searcher {
  private list: unknown[] = [];
  private searchProperty = '';
  private searchText = '';
  private previousSearchText = '';
  private previousInputtype = '';
  private filteredList: unknown[] = [];
  private previousFilteredList: unknown[] = [];
  private shouldReturnPreviousFilteredList = true;
  private labelGetter: (item: unknown) => string;

  public initSearch(list: unknown[], labelGenerator: (item: unknown) => string): void {
    this.list = list;
    this.previousFilteredList = this.list;
    this.labelGetter = labelGenerator;
  }

  public filterList(inputEvent: InputEvent): unknown[] | undefined {
    if (inputEvent.data === ' ') {
      return undefined;
    }
    const searchText = (inputEvent.target as HTMLInputElement).value;
    const removeWhitespaces = (text: string) => text.split(' ').join('');
    const searchTextInLowerCase = removeWhitespaces(searchText).toLocaleLowerCase();
    this.searchText = searchTextInLowerCase;

    const list = this.getList();
    this.previousSearchText = searchTextInLowerCase;
    this.previousInputtype = inputEvent.inputType;
    if (!list) {
      this.filteredList = this.previousFilteredList;
      return this.previousFilteredList;
    }
    this.filteredList = list.filter((item) =>
      removeWhitespaces(this.labelGetter ? this.labelGetter(item) : this.defaultToString(item))
        .toLowerCase()
        .includes(this.searchText)
    );
    return this.filteredList;
  }

  private getList(): unknown[] | undefined {
    if (this.previousSearchText && this.searchText.includes(this.previousSearchText)) {
      this.previousFilteredList = this.filteredList;
      this.shouldReturnPreviousFilteredList = true;
      return this.filteredList;
    }

    const isLastTextFromPaste = this.previousInputtype === 'insertFromPaste';
    const canReturnPreviousFilteredList = this.isBackSpacedLastChar() && !isLastTextFromPaste && this.shouldReturnPreviousFilteredList;

    if (canReturnPreviousFilteredList) {
      this.shouldReturnPreviousFilteredList = false;
      return undefined;
    }
    return this.list;
  }

  private isBackSpacedLastChar(): boolean {
    const isTextDecrementedBy1 = this.previousSearchText.length - this.searchText.length === 1;
    const lastChar = this.previousSearchText.charAt(this.previousSearchText.length - 1);
    const concatedText = this.searchText + lastChar;
    return isTextDecrementedBy1 && concatedText === this.previousSearchText;
  }

  private defaultToString(item: unknown): string {
    return Object.values(item).toString();
  }
}
