/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';
import { StreamingOptions } from 'src/app/api/interfaces/streaming-options.interface';
import { StreamingResult } from 'src/app/api/interfaces/streaming-result.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { NameDetailDTO } from 'src/app/api/interfaces/name-detail.interface';
import { NotificationDTO } from 'src/app/api/interfaces/notification.interface';
import { NotificationListDTO } from 'src/app/api/interfaces/notification-list.interface';
import { AddresseesDTO } from 'src/app/api/interfaces/addressees.interface';
import { ComentarioDTO } from 'src/app/api/interfaces/comentario.interface';
import { CommentReactionDTO } from 'src/app/api/interfaces/comment-reaction.interface';
import { CurrentReactionDTO } from 'src/app/api/interfaces/current-reaction.interface';
import { NewCommentReactionDTO } from 'src/app/api/interfaces/new-comment-reaction.interface';
import { HttpClientExtended } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/http/httpClient.extended';

@Injectable({
   providedIn: 'root'
})
export class ComunicadoBackendService {

   constructor(protected http: HttpClient) { }


   public getIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public async insertNotificationsIdUsers(id: number, addresseesDTO: AddresseesDTO, progress: Subject<number>, options?: StreamingOptions): Promise<NotificationListDTO> {
         const res = await (<HttpClientExtended>this.http).GetResponseByStreamingOnPost<StreamingResult<NotificationListDTO>>(Constants.apiURL +
'/notifications/' + id + '/users', addresseesDTO, progress, options);
         return convertJsonDates(res.message) as NotificationListDTO
      }


   public updateNotificationsId(id: number, notificationDTO: NotificationDTO): Observable<NotificationDTO> {
      return this.http.put<NotificationDTO>(Constants.apiURL + '/notifications/' + id, notificationDTO).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }

   public getNotificationsIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/notifications/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertNotificationsIdComentarios(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/notifications/' + id + '/comentarios', stream);
   }
   public insertNotificationsChatgptDescription(nameDetailDTO: NameDetailDTO): Observable<NameDetailDTO> {
      return this.http.post<NameDetailDTO>(Constants.apiURL + '/notifications/chat-gpt/description', nameDetailDTO).pipe(map((res) => convertJsonDates(res) as NameDetailDTO ));
   }

   public updateNotificationsIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/notifications/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deleteNotificationsIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/notifications/' + id + '/comentarios/' + iDComentario);
   }
   public getNotificationsIdReactions(id: number): Observable<CommentReactionDTO[]> {
      return this.http.get<DataDTO<CommentReactionDTO>>(Constants.apiURL + '/notifications/' + id + '/reactions').pipe(map((res) => convertJsonDates(res.data) as CommentReactionDTO[]));
   }

   public updateNotificationsIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Observable<CurrentReactionDTO> {
      return this.http.put<CurrentReactionDTO>(Constants.apiURL + '/notifications/' + id + '/reactions', newCommentReactionDTO).pipe(map((res) => convertJsonDates(res) as CurrentReactionDTO ));
   }

   public deleteNotificationsIdReactionsEMOJIID(id: number, emojiId: string): Observable<CurrentReactionDTO> {
      return this.http.delete<CurrentReactionDTO>(Constants.apiURL + '/notifications/' + id + '/reactions/' + emojiId).pipe(map((res) => convertJsonDates(res) as CurrentReactionDTO ));
   }

   public getUserNotificationsIdComentarios(id: number): Observable<ComentarioDTO[]> {
      return this.http.get<DataDTO<ComentarioDTO>>(Constants.apiURL + '/user/notifications/' + id + '/comentarios').pipe(map((res) => convertJsonDates(res.data) as ComentarioDTO[]));
   }

   public insertUserNotificationsIdComentarios(id: number, stream: FormData): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/user/notifications/' + id + '/comentarios', stream);
   }
   public updateUserNotificationsIdComentariosIDCOMENTARIO(id: number, iDComentario: number, comentarioDTO: ComentarioDTO): Observable<ComentarioDTO> {
      return this.http.put<ComentarioDTO>(Constants.apiURL + '/user/notifications/' + id + '/comentarios/' + iDComentario, comentarioDTO).pipe(map((res) => convertJsonDates(res) as ComentarioDTO ));
   }

   public deleteUserNotificationsIdComentariosIDCOMENTARIO(id: number, iDComentario: number): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/user/notifications/' + id + '/comentarios/' + iDComentario);
   }
   public getUserNotificationsIdReactions(id: number): Observable<CommentReactionDTO[]> {
      return this.http.get<DataDTO<CommentReactionDTO>>(Constants.apiURL + '/user/notifications/' + id + '/reactions').pipe(map((res) => convertJsonDates(res.data) as CommentReactionDTO[]));
   }

   public updateUserNotificationsIdReactions(id: number, newCommentReactionDTO: NewCommentReactionDTO): Observable<NotificationDTO> {
      return this.http.put<NotificationDTO>(Constants.apiURL + '/user/notifications/' + id + '/reactions', newCommentReactionDTO).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }

   public deleteUserNotificationsIdReactionsEMOJIID(id: number, emojiId: string): Observable<NotificationDTO> {
      return this.http.delete<NotificationDTO>(Constants.apiURL + '/user/notifications/' + id + '/reactions/' + emojiId).pipe(map((res) => convertJsonDates(res) as NotificationDTO ));
   }
}
