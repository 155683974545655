import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AuthCodeComponent } from 'src/app/shared/auth-code/auth-code.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

export interface Auth2faData {
  accountName: string;
}

@Component({
  selector: 'app-auth-second-factor',
  standalone: true,
  imports: [CommonModule, AuthCodeComponent, TranslateModule, MatCardModule, MatButtonModule],
  templateUrl: './auth-second-factor.component.html',
  styleUrls: ['./auth-second-factor.component.scss'],
})
export class AuthSecondFactorComponent {
  public isLoading2fa = false;
  public accountName = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Auth2faData,
    private dialogRef: MatDialogRef<AuthSecondFactorComponent>,
  ) {
    this.accountName = data.accountName;
  }

  public update2FA(token2fa: string): void {
    this.dialogRef.close(token2fa);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
