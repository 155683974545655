import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  exhaustMap,
  EMPTY,
  filter,
  map,
  Observable,
  of,
  range,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
  Subscription,
} from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { CHAT_WEBSOCKET_API_URL, CHAT_WEBSOCKET_PROTOCOL } from 'src/app/shared/constants';
import { CHAT_AUTH } from 'src/app/shared/localStorageConstants';
import {
  BackendResponse,
  ClockInQRResponse,
  MessageResponse,
  NewClockInResponse,
  NotificationResponse,
  ServiceChangeResponse,
} from 'src/app/app-common/chat/interfaces/chat-backend-response.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DateTime } from 'luxon';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';
import { AuthResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authResponse.DTO';
import { RoleDTO } from '@api/interfaces/role.interface';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { companyFeatureChat, companyFeatureWebsocket } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';

export interface WebSocketMessage {
  tenantId: string;
  channelId: string;
  userId: string;
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  public clientId: string;

  #connected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public connected$: Observable<boolean> = this.#connected$.asObservable();

  #error$: Subject<void> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public error$: Observable<void> = this.#error$.asObservable();

  public get shouldExecuteReconnect(): boolean {
    const minutesAfterDisconnect = this.lastDisconnectTime?.diffNow('minutes').minutes ?? Infinity;
    return Math.abs(minutesAfterDisconnect) >= 10;
  }

  // The WebSocket Subject is used internally for mantaining a single configuration
  // and a single point for sending messages.
  private webSocket$: WebSocketSubject<string>;
  private websocketSubscription: Subscription;
  // A message subject is used to manage parsed backend messages
  // and to discern between client messages and protocol/error messages
  private messages$: Subject<MessageResponse | NotificationResponse | ServiceChangeResponse | NewClockInResponse | ClockInQRResponse> =
    new Subject<MessageResponse | NotificationResponse | ServiceChangeResponse | NewClockInResponse | ClockInQRResponse>();

  private tenantId: string;

  private lastDisconnectTime: DateTime;

  private readonly maxReconnectAttempts = 5;
  private reconnectAbort$ = new Subject<void>();
  private reconnectTrigger$ = new Subject<void>();
  private clientIdTrigger$ = new Subject<string>();

  constructor(
    private authenticationService: AuthenticationService,
    private headerService: HeaderAppService,
    private featureFlagService: FeatureFlagService,
  ) {
    combineLatest([this.authenticationService.loggedEvent$, this.featureFlagService.flags$])
      .pipe(
        switchMap(([logData, flagsData]) => {
          const websocketRequired =
            FeatureFlagHelper.featureOn(companyFeatureChat, flagsData.flags) ||
            FeatureFlagHelper.featureOn(companyFeatureWebsocket, flagsData.flags);
          if (websocketRequired && logData?.token) {
            return of(logData);
          }
          this.disconnect();
          return EMPTY;
        }),
        switchMap<AuthResponseDTO, Observable<[AuthResponseDTO, string]>>((logData) => {
          if (this.isterminal(logData)) {
            return this.clientIdTrigger$.pipe(map((terminalId) => [logData, terminalId]));
          }
          return of([logData, logData.userName]);
        }),
        takeUntilDestroyed(),
      )
      .subscribe(([logData, clientId]) => {
        this.setupWebSocketConfig(logData, clientId);
        // Close preexisting resources if connected
        if (this.#connected$.getValue()) {
          this.disconnect();
        }
        this.connect();
      });

    this.headerService.loggedOut$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.disconnect();
      this.clearLastDisconnect();
      this.clientId = '';
      this.tenantId = '';
    });

    // Reconnection pipe
    const reconnectionPipe = range(1, this.maxReconnectAttempts + 1).pipe(
      map((attempt) => 2 ** attempt * 1000),
      concatMap((delay) => timer(delay)),
      tap({
        complete: () => {
          this.#connected$.next(false);
          console.error('websocket error: max amount of retries reached.');
        },
      }),
      takeUntil(this.reconnectAbort$),
    );

    // exhaustMap so it doesn't run a new pipe while another is running
    this.reconnectTrigger$.pipe(exhaustMap(() => reconnectionPipe)).subscribe({
      next: () => {
        this.connect();
      },
    });
  }
  public sendMessage(message: WebSocketMessage): void {
    if (!this.#connected$.value) {
      this.connect();
    }
    const parsedMessage = JSON.stringify({
      action: 'sendMessage',
      ...message,
    });
    this.webSocket$.next(parsedMessage);
  }

  public subscribeToChannel(channel: string): Observable<BackendResponse> {
    return this.messages$.pipe(filter((msg) => msg.action === channel));
  }

  public reconnect(): void {
    if (!this.#connected$.value) {
      this.connect();
    }
  }

  public setClientId(id: string): void {
    this.clientIdTrigger$.next(id);
  }

  public refreshToken(): void {
    this.requestJWT();
  }

  private clearLastDisconnect(): void {
    this.lastDisconnectTime = null;
  }

  private setupWebSocketConfig(authData: AuthResponseDTO, clientId: string): void {
    this.clientId = clientId;
    this.tenantId = authData.tenant;

    this.webSocket$ = webSocket<string>({
      url: `${CHAT_WEBSOCKET_API_URL}?userId=${this.clientId}&tenantId=${this.tenantId}`,
      protocol: [CHAT_WEBSOCKET_PROTOCOL, authData.token],
      openObserver: {
        next: () => {
          if (!this.authenticationService.chatAuth && localStorage.getItem(CHAT_AUTH)) {
            this.authenticationService.setChatAuth(JSON.parse(localStorage.getItem(CHAT_AUTH)));
            this.#connected$.next(true);
          } else {
            this.requestJWT();
          }
          this.reconnectAbort$.next();
        },
      },
      closeObserver: {
        next: () => {
          this.lastDisconnectTime = DateTime.now();
          this.reconnectTrigger$.next();
        },
      },
      // override default serializers
      deserializer: (event) => event.data,
      serializer: (msg) => msg,
    });
  }

  private isterminal(authData: AuthResponseDTO): boolean {
    return (authData.role as RoleDTO)?.id === 50;
  }

  private connect(): void {
    if (this.#connected$.value) {
      return;
    }

    if (!this.authenticationService.authResponseDTO?.token) {
      this.#connected$.next(false);
      return;
    }

    if (!this.webSocket$) {
      this.setupWebSocketConfig(this.authenticationService.authResponseDTO, this.clientId);
    }

    if (this.websocketSubscription && !this.websocketSubscription.closed) {
      this.websocketSubscription.unsubscribe();
    }

    // Subscribe to the websocket subject, therefore initiating the connection
    this.websocketSubscription = this.webSocket$.subscribe({
      next: (msg) => this.onWebSocketMsgReceived(JSON.parse(msg) as BackendResponse),
      // Error comes when establishing connection through websocket:
      // Missing or incorrect tenant or client data, websocket protocol data, or auth token
      error: () => {
        this.#connected$.next(false);
        this.#error$.next();
      },
      // Executed when connection is closed without error:
      // - Log out
      // - Idle disconnect
      complete: () => {
        this.#connected$.next(false);
      },
    });
  }

  private disconnect(): void {
    if (this.webSocket$ && !this.webSocket$.closed) {
      this.websocketSubscription?.unsubscribe();
      this.webSocket$.complete();
      this.#connected$.next(false);
    }
  }

  private requestJWT(): void {
    const messageJSON = JSON.stringify({
      action: 'Token',
      tenantId: this.tenantId,
      userId: this.clientId,
    });

    this.webSocket$.next(messageJSON);
  }
  private saveJWT(token: string): void {
    const authData = { token, userId: this.clientId, tenantId: this.tenantId };
    this.authenticationService.setChatAuth(authData);
    this.#connected$.next(true);
  }

  private onWebSocketMsgReceived(messageData: BackendResponse): void {
    switch (messageData.action) {
      case 'Message':
      case 'NewNotification':
      case 'ServiceChange':
      case 'ClockInQRToken':
      case 'NewClockIn':
        this.messages$.next(messageData);
        break;
      case 'Token':
        this.saveJWT(messageData.token);
        break;
      case 'Error':
        // Error comes from $default route:
        // - An error occured when soliciting JWT, the connection is closed
        this.disconnect();
        break;
    }
  }
}
