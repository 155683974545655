<div class="d-flex flex-column flex-grow-1 py-6">
  <ng-container *ngIf="personalModel.Familiares.length > 0; else sinFamiliares">
    <table mat-table [dataSource]="personalModel.Familiares" class="noShadow hidden-sm" matSort (matSortChange)="sortData($event)">
      <ng-container [matColumnDef]="ColumnNames.Nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="gap-1 text-left w-15">
          {{ 'PROFILE.FULL_NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let familiar">
          {{ familiar.nombreCompleto }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Parentesco">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="gap-1 text-left w-15">
          {{ 'PROFILE.FAMILY.KINSHIP' | translate }}
        </th>
        <td mat-cell *matCellDef="let familiar">
          {{ relacionFamiliar[familiar.relacion] | enumTranslate: 'ENUM.PROFILE.FAMILY.RELACION_FAMILIAR' | unisex }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Nacimiento">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="gap-1 text-center w-15">
          {{ 'PROFILE.BIRTH_DATE' | translate }}
        </th>
        <td mat-cell *matCellDef="let familiar">
          {{ familiar.fechaNacimiento | ngxdate: 'shortDateFull' }} ({{ familiar.fechaNacimiento | calcularEdad }})
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Dni">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="gap-1 text-right w-10">
          {{ 'PROFILE.ID_NUMBER' | translate }}
        </th>
        <td mat-cell *matCellDef="let familiar">
          {{ familiar.dni > 0 ? familiar.dni : '' }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Acargo">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="gap-1 text-left w-10">
          {{ 'PROFILE.FAMILY.IN_CHARGE' | translate }}
        </th>
        <td mat-cell *matCellDef="let familiar">
          {{ familiar.aCargo ? ('GENERAL.YES' | translate) : '' }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Discapacidad">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="gap-1 text-left w-10">
          {{ 'PROFILE.FAMILY.DISABILITY' | translate }}
        </th>
        <td mat-cell *matCellDef="let familiar">
          {{ familiar.discapacidad ? ('GENERAL.YES' | translate) : '' }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Pep">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="gap-1 text-left w-15">
          {{ 'PROFILE.FAMILY.PUBLIC_OFFICIAL' | translate }}
        </th>
        <td mat-cell *matCellDef="let familiar">
          {{ familiar.pep ? ('GENERAL.YES' | translate) : '' }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Comentario">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let familiar">
          <button [matTooltip]="familiar.comentario" *ngIf="familiar.comentario?.length > 1" color="accent" mat-icon-button>
            <mat-icon fontSet="material-symbols-outlined">info</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.Accion">
        <th mat-header-cell *matHeaderCellDef class="text-left w-20">{{ 'GENERAL.ACTION' | translate }}</th>
        <td mat-cell *matCellDef="let familiar">
          <div class="w-sm-40 text-right">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icono 3 puntitos">
              <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
            </button>
          </div>
          <mat-menu #menu="matMenu">
            <button color="danger" mat-menu-item [command]="eliminarCmd" [commandValue]="familiar" class="mr-2" [disabled]="!modoEdicion">
              <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
              <span>{{ 'GENERAL.DELETE' | translate }}</span>
            </button>
            <button color="primary" mat-menu-item (click)="editar(familiar)" [disabled]="!modoEdicion">
              <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
              <span>{{ 'GENERAL.EDIT' | translate }}</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let row; columns: columns"></tr>
    </table>

    <!--VISTA MOBILE-->
    <div class="hidden-lg">
      <mat-card class="no-shadow mb-2 border-form" *ngFor="let familiar of personalModel.Familiares">
        <mat-card-content>
          <div class="d-flex flex-row justify-content-between align-items-center">
            <h5>
              {{ familiar.nombreCompleto }}
            </h5>
            <div class="w-sm-40 text-right">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icono 3 puntitos">
                <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon>
              </button>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [command]="eliminarCmd" [commandValue]="familiar" class="mr-2" [disabled]="!modoEdicion">
                <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                <span>{{ 'GENERAL.DELETE' | translate }}</span>
              </button>
              <button mat-menu-item (click)="editar(familiar)" [disabled]="!modoEdicion">
                <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
                <span>{{ 'GENERAL.EDIT' | translate }}</span>
              </button>
            </mat-menu>
          </div>
          <div class="d-flex flex-column">
            <small>
              {{ relacionFamiliar[familiar.relacion] | enumTranslate: 'ENUM.PROFILE.FAMILY.RELACION_FAMILIAR' | unisex }}
              {{ familiar.aCargo ? ('PROFILE.FAMILY.IN_CHARGE' | translate) : '' }}
              {{ familiar.discapacidad ? ('PROFILE.FAMILY.DISABILITY' | translate) : '' }}</small
            >
            <small>
              {{ 'PROFILE.ID_NUMBER' | translate }}: {{ familiar.dni > '0' ? familiar.dni : '-' }} - {{ 'PROFILE.BIRTH_DATE' | translate }}:
              {{ familiar.fechaNacimiento | ngxdate: 'shortDateFull' }} ({{ familiar.fechaNacimiento | calcularEdad }})
            </small>
            <small *ngIf="familiar.pep"> {{ 'PROFILE.FAMILY.PUBLIC_OFFICIAL' | translate }} </small>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>
  <ng-template #sinFamiliares>
    <app-warning-card [mensaje]="'PROFILE.FAMILY.NO_UPLOADED_FAMILY_MEMBER'"></app-warning-card>
  </ng-template>
  <mat-expansion-panel (afterCollapse)="reiniciarFamiliar()" [disabled]="!modoEdicion" class="my-2 no-shadow">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon fontSet="material-symbols-outlined" *ngIf="familiar.id === 0; else addIcon">add</mat-icon>
        <ng-template #addIcon>
          <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
        </ng-template>
        {{ familiar.id === 0 ? ('PROFILE.FAMILY.ADD_A_FAMILY_MEMBER' | translate) : ('PROFILE.FAMILY.UPDATE_A_FAMILY_MEMBER' | translate) }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <form #familiarForm="ngForm">
      <div class="d-flex flex-column">
        <div class="d-flex flex-row flex-wrap gap-1">
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'PROFILE.ID_NUMBER' | translate }} </mat-label>
            <input
              type="text"
              [onReturn]="'searchFam'"
              matInput
              name="docf"
              [(ngModel)]="familiar.dni"
              [command]="buscarFamiliarAfipCmd"
              [mask]="country?.maskId"
              required
            />
            <mat-icon
              fontSet="material-symbols-outlined"
              #searchFam
              *ngIf="country?.id === 1"
              class="pointer"
              id="searchFam"
              name="searchFam"
              type="button"
              mat-raised-button
              color="primary"
              [command]="buscarFamiliarAfipCmd"
              matSuffix
              >search
            </mat-icon>
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'PROFILE.FULL_NAME' | translate }} </mat-label>
            <input required matInput type="text" id="nombref" name="nombref" [(ngModel)]="familiar.nombreCompleto" />
          </mat-form-field>
        </div>
        <div class="d-flex flex-row flex-wrap gap-1">
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'PROFILE.FAMILY.KINSHIP' | translate }} </mat-label>
            <mat-select required [(ngModel)]="familiar.relacion" id="rel" name="rel" chan>
              <mat-option *ngFor="let relacion of relaciones" [value]="relacionFamiliar[relacion]">{{ relacion | unisex }} </mat-option>
            </mat-select>
          </mat-form-field>
          <neo-date-selector
            appearance="outline"
            #fechaN
            formClass="flex-fill"
            [label]="'PROFILE.BIRTH_DATE' | translate"
            id="fechaNf"
            [form]="familiarForm"
            [(date)]="familiar.fechaNacimiento"
            (dateChange)="fechaNacMenorCmd.execute()"
            [onlyValidDate]="true"
            [required]="true"
          >
          </neo-date-selector>
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'PROFILE.AGE' | translate }} </mat-label>
            <input matInput type="text" id="edad" name="edad" disabled [value]="familiar.fechaNacimiento | calcularEdad" />
          </mat-form-field>
          <div class="d-flex flex-row flex-fill gap-1 flex-wrap flex-grow-0 align-items-center">
            <mat-checkbox id="checkC" name="checkC" color="primary" [(ngModel)]="familiar.aCargo">
              {{ 'PROFILE.FAMILY.IN_CHARGE' | translate }}
            </mat-checkbox>
            <mat-checkbox id="checkD" name="checkD" color="primary" [(ngModel)]="familiar.discapacidad">
              {{ 'PROFILE.FAMILY.DISABILITY' | translate }}
            </mat-checkbox>
            <mat-checkbox id="checkF" name="checkF" color="primary" [(ngModel)]="familiar.pep">
              {{ 'PROFILE.FAMILY.PUBLIC_OFFICIAL' | translate }}
            </mat-checkbox>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap gap-1">
          <div class="d-flex flex-grow-1">
            <mat-form-field class="flex-fill" appearance="outline">
              <mat-label> {{ 'PROFILE.COUNTRY' | translate }} </mat-label>
              <mat-select
                #pa
                [(ngModel)]="pais"
                id="pa"
                name="pa"
                [disabled]="!modoEdicion"
                [compareWith]="compareId"
                (selectionChange)="paisChanged($event.value)"
              >
                <mat-option *ngFor="let pais of paises" [value]="pais">{{ pais.nombre }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <app-autocomplete-service-selector
            name="location"
            class="flex-fill"
            [label]="'GENERAL.LOCATION' | translate"
            [datasource]="localidadSearch"
            [(ngModel)]="familiar.domicilio.localidad"
            [displayFn]="localidadSearch.displayFn"
          ></app-autocomplete-service-selector>
          <div class="d-flex flex-grow-1">
            <mat-form-field class="flex-fill" appearance="outline">
              <mat-label> {{ 'PROFILE.ADDRESS' | translate }} </mat-label>
              <input
                matInput
                #dirf
                matInput
                id="dirf"
                [matTooltip]="'PROFILE.USE_ADDRESS_FORMAT' | translate"
                name="dirf"
                [(ngModel)]="familiar.domicilio.direccion"
              />
              <a
                target="_blank"
                mat-icon-button
                matSuffix
                color="accent"
                [matTooltip]="'GENERAL.OPEN_THIS_LOCATION_IN_GOOGLE_MAPS' | translate"
                [href]="domicilioGoogleMaps(familiar.domicilio)"
              >
                <mat-icon fontSet="material-symbols-outlined" svgIcon="google-maps-grey"></mat-icon>
              </a>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex flex-row flex-wrap gap-1">
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'PROFILE.PHONE_NUMBERS' | translate }} </mat-label>
            <input matInput type="text" id="telf" name="telf" [(ngModel)]="familiar.telefonos" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'PROFILE.EMAIL' | translate }} </mat-label>
            <input matInput type="text" id="emailf" name="emailf" [(ngModel)]="familiar.email" />
          </mat-form-field>
        </div>
        <div class="d-flex flex-row">
          <mat-form-field appearance="outline" class="flex-fill">
            <mat-label> {{ 'GENERAL.COMMENT' | translate }} </mat-label>
            <input #comentario matInput type="text" id="comentario" name="comentario" [(ngModel)]="familiar.comentario" />
          </mat-form-field>
        </div>
        <div class="d-flex justify-content-end">
          <button class="mr-2" [disabled]="!modoEdicion" mat-flat-button (click)="reiniciarFamiliar()">
            {{ 'GENERAL.CANCEL' | translate }}
          </button>
          <button [disabled]="!modoEdicion" mat-flat-button color="primary" (click)="agregarFamiliar(familiar)">
            {{ familiar.id === 0 ? ('GENERAL.ADD' | translate) : ('GENERAL.UPDATE' | translate) }}
          </button>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</div>
