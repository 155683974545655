import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';

@Injectable({
   providedIn: 'root'
})
export class AusenciaServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getAusenciasIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/ausencias/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async insertAusencias(ausenciaDTO: AusenciaDTO): Promise<AusenciaDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/ausencias/', ausenciaDTO));
         const resDTO = new AusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateAusenciasId(id: number, ausenciaDTO: AusenciaDTO): Promise<AusenciaDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/ausencias/' + id, ausenciaDTO));
         const resDTO = new AusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteAusenciasId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/ausencias/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getAusenciasId(id: number): Promise<AusenciaDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/ausencias/' + id));
         if (!res) { return null; }
         const resDTO = new AusenciaDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getAusencias(idConvenio: number): Promise<Array<AusenciaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ausencias/' + '?idConvenio=' + idConvenio));
         const resJson = res.data;
         const resDTO = new Array<AusenciaDTO>();
         for (const item of resJson) {
            const itemDTO = new AusenciaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getAusenciasPosibles(): Promise<Array<AusenciaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ausencias/posibles'));
         const resJson = res.data;
         const resDTO = new Array<AusenciaDTO>();
         for (const item of resJson) {
            const itemDTO = new AusenciaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getAusenciasPersonalLegajoIdTipos(id: number, aplicaFiltros: boolean = true): Promise<Array<AusenciaDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/ausencias/personalLegajo/' + id + '/tipos' + '?aplicaFiltros=' + aplicaFiltros));
         const resJson = res.data;
         const resDTO = new Array<AusenciaDTO>();
         for (const item of resJson) {
            const itemDTO = new AusenciaDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

}
