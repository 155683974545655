import { Injectable, signal } from '@angular/core';
import { PINNED_STATE_KEY } from 'src/app/shared/localStorageConstants';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private $collapsedStateSidebar = signal(!this.getInitialPinnedState());
  public $collapsedState = this.$collapsedStateSidebar.asReadonly();

  private $pinnedStateSidebar = signal(this.getInitialPinnedState());
  public $pinnedState = this.$pinnedStateSidebar.asReadonly();

  private getInitialPinnedState(): boolean {
    return localStorage.getItem(PINNED_STATE_KEY) === 'true';
  }

  public toggleSidebar(): void {
    this.$collapsedStateSidebar.update((current) => !current);
  }

  public collapseSidebar(): void {
    if (!this.$pinnedState()) {
      this.$collapsedStateSidebar.set(true);
    }
  }

  public expandSidebar(): void {
    this.$collapsedStateSidebar.set(false);
  }

  public togglePin(): void {
    this.$pinnedStateSidebar.update((current) => {
      const newState = !current;
      localStorage.setItem(PINNED_STATE_KEY, String(newState));
      return newState;
    });
  }
}
