import { Injectable } from '@angular/core';
import { PaisDTO } from '@api/interfaces/pais.interface';
import { HealthInsuranceCompanyBackendService } from '@api/services/health-insurance-company-backend.service';
import { Observable, map } from 'rxjs';
import { AutocompleteServiceData } from 'src/app/shared/autocomplete-service-selector/autocomplete-service-data';
import { CompleterItem } from 'src/app/shared/lib/ngx-neo-completer-mat/components/completer-item';

@Injectable({
  providedIn: 'root',
})
export class HealthInsuranceSearchService implements AutocompleteServiceData {
  public country: PaisDTO;

  constructor(private healthInsuranceCompanyBackendService: HealthInsuranceCompanyBackendService) {}

  public getEntitiesSearch(startWith: string, displayFn: (item: any) => string): Observable<CompleterItem[]> {
    return this.healthInsuranceCompanyBackendService.getHealthinsurancecompany(this.country?.id, startWith).pipe(
      map((res: any[]) =>
        res.map((item: any) => {
          const completerItem: CompleterItem = {
            title: displayFn(item),
            originalObject: item,
          };

          return completerItem;
        }),
      ),
    );
  }
}
