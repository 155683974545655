<div class="d-flex flex-row flex-wrap gap-1">
  <mat-form-field class="flex-grow-1" appearance="outline" matTooltipClass="break-line">
    <mat-label>{{ 'GENERAL.CATEGORY' | translate }}</mat-label>
    <mat-select
      [compareWith]="compareId"
      (selectionChange)="onCategoriaChange()"
      [(ngModel)]="categoria"
      id="categoria"
      name="categoria"
      [disabled]="disabled"
    >
      <mat-option *ngFor="let categoria of categorias" [value]="categoria">{{ categoria.nombre }} </mat-option>
    </mat-select>
    <mat-hint class="elypsis" [matTooltip]="nombreConvenio(categoria?.convenio)" *ngIf="categoria?.id">
      {{ nombreConvenio(categoria?.convenio) }}
    </mat-hint>
  </mat-form-field>
  <mat-form-field class="flex-grow-1" appearance="outline" matTooltipClass="break-line">
    <mat-label>{{ 'GENERAL.SUBCATEGORY' | translate }}</mat-label>
    <mat-select
      [compareWith]="compareId"
      (selectionChange)="onSubcategoriaChange()"
      [disabled]="!categoria?.id || disabled"
      [(ngModel)]="subcategoria"
      id="sub"
      name="sub"
    >
      <mat-option *ngFor="let sub of categoria?.subcategorias" [value]="sub">{{ sub.nombre }} </mat-option>
    </mat-select>
  </mat-form-field>
</div>
