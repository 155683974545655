import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { TipoAgrupamiento } from 'src/app/ModelDTO/DTO/tipoAgrupamiento.ENUM';


export class EntregaAgrupadaDTO implements IEntityDTO {

   nombre = '';
   descripcion = '';
   categoria = '';
   subcategoria = '';
   cantidad = 0;
   fecha: Date;
   tipoAgrupamiento: TipoAgrupamiento;
   id = 0;
   cacheStamp = 0;

   constructor() {
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.categoria != null) { this.categoria = jsonObj.categoria; }
      if (jsonObj.subcategoria != null) { this.subcategoria = jsonObj.subcategoria; }
      if (jsonObj.cantidad != null) { this.cantidad = jsonObj.cantidad; }
      if (jsonObj.fecha != null) { this.fecha = new Date(jsonObj.fecha); }
      if (jsonObj.tipoAgrupamiento != null) { this.tipoAgrupamiento = jsonObj.tipoAgrupamiento as TipoAgrupamiento; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
