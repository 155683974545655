import { Component, Input } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { compare } from 'src/app/shared/shared-functions';
import { CapacitacionesService } from 'src/app/layout/administrador/configuracion/capacitaciones/capacitaciones.service';
import { TipoCapacitacionDTO } from 'src/app/ModelDTO/DTO/tipoCapacitacion.DTO';
import { TipoDevolucionCapacitacion } from '@api/enums/tipo-devolucion-capacitacion.enum';
import { TipoUnidadDevolucion } from '@api/enums/tipo-unidad-devolucion.enum';

enum ColumnNames {
  Nombre = 'Nombre',
  TiempoDevolucion = 'TiempoDevolucion',
  DiasPrevioAviso = 'DiasPrevioAviso',
}
@Component({
  selector: 'app-capacitaciones-empleado',
  templateUrl: './capacitaciones-empleado.component.html',
  styleUrls: ['./capacitaciones-empleado.component.scss'],
})
export class CapacitacionesEmpleadoComponent {
  @Input()
  public set idPersonal(value: number) {
    this.pIdPersonal = value;
    this.actualizarCapacitaciones();
  }

  public posibleCapacitaciones: TipoCapacitacionDTO[];
  public readonly ColumnNames = ColumnNames;
  public displayedColumns: string[] = [ColumnNames.Nombre, ColumnNames.TiempoDevolucion, ColumnNames.DiasPrevioAviso];
  public mostrarDias = false;
  private pIdPersonal: number;

  constructor(
    private capacitacionesService: CapacitacionesService,
    private translate: TranslateService,
  ) {}

  public sortData(sort: Sort): void {
    const data = this.posibleCapacitaciones.slice();
    if (!sort.active || !sort.direction?.length) {
      this.posibleCapacitaciones = data;
      return;
    }

    this.posibleCapacitaciones = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case ColumnNames.Nombre:
          return compare(a.nombre, b.nombre, isAsc);
        case ColumnNames.TiempoDevolucion:
          return compare(a.tiempoDevolucion, b.tiempoDevolucion, isAsc);
        case ColumnNames.DiasPrevioAviso:
          return compare(a.diasPrevioAviso, b.diasPrevioAviso, isAsc);
        default:
          return 0;
      }
    });
  }

  public obtenerFechaVencimiento(tipoCapacitacion: TipoCapacitacionDTO): string {
    if (
      tipoCapacitacion.tipoDevolucion === TipoDevolucionCapacitacion.Sin_vencimiento ||
      tipoCapacitacion.tipoDevolucion === TipoDevolucionCapacitacion.No_definido
    ) {
      return '';
    }

    return `${tipoCapacitacion.tiempoDevolucion} ${this.translate.instant(
      `ENUM.CONTRACT_TYPES.TYPE_UNIT_DURATION_MODALITY.${TipoUnidadDevolucion[tipoCapacitacion.unidadDevolucion]}`,
    )}`;
  }

  private async actualizarCapacitaciones(): Promise<void> {
    if (this.pIdPersonal > 0) {
      this.posibleCapacitaciones = await this.capacitacionesService.obtenerCapacitacionesPorPersonal(this.pIdPersonal);
    }
  }
}
