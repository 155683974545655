import { Component, Input } from '@angular/core';
import { TipoAusencia } from '@api/enums/tipo-ausencia.enum';
import { AusenciaDTO } from 'src/app/ModelDTO/DTO/ausencia.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { AusenciaServiceBackend } from 'src/app/ServiceBackend/ausencia.ServiceBackend';

@Component({
  selector: 'app-datos-resumen',
  templateUrl: './datos-resumen.component.html',
})
export class DatosResumenComponent {
  @Input() public modoEdicion = false;
  @Input() public modoEmpresa = false;
  @Input() public get personalModel(): PersonalModelDTO {
    return this.personalModelDTO;
  }

  public posiblesVacaciones: Array<AusenciaDTO>;
  public posiblesLicencias: Array<AusenciaDTO>;
  public posiblesLicenciasE: Array<AusenciaDTO>;
  public posiblesLicenciasART: Array<AusenciaDTO>;
  public posiblesLicenciasTR: Array<AusenciaDTO>;

  public displayedColumnsAusencias: string[] = ['Nombre', 'Dias', 'Con haberes'];
  public displayedColumnsBeneficios: string[] = ['Fecha', 'Nombre', 'Observacion'];

  public set personalModel(value: PersonalModelDTO) {
    this.personalModelDTO = value;
    if (this.personalModelDTO.Id > 0) {
      this.armarArreglosDeAusencias();
    }
  }

  private personalModelDTO = new PersonalModelDTO(new PersonalDTO());

  constructor(private ausenciasServiceBackend: AusenciaServiceBackend) {
    this.posiblesVacaciones = new Array<AusenciaDTO>();
    this.posiblesLicencias = new Array<AusenciaDTO>();
    this.posiblesLicenciasE = new Array<AusenciaDTO>();
    this.posiblesLicenciasART = new Array<AusenciaDTO>();
    this.posiblesLicenciasTR = new Array<AusenciaDTO>();
  }

  private async armarArreglosDeAusencias(): Promise<void> {
    this.posiblesVacaciones = new Array<AusenciaDTO>();
    this.posiblesLicencias = new Array<AusenciaDTO>();
    this.posiblesLicenciasE = new Array<AusenciaDTO>();
    this.posiblesLicenciasTR = new Array<AusenciaDTO>();
    // Hago todo en un mismo recorrido, para no hacer 3 filter
    const posiblesAusencias = await this.ausenciasServiceBackend.getAusenciasPersonalLegajoIdTipos(this.personalModel.Legajo.id);
    this.personalModel.PosiblesAusencias = posiblesAusencias;

    this.personalModel.PosiblesAusencias.forEach((ausencia) => {
      switch (ausencia.tipo) {
        case TipoAusencia.Vacaciones:
          this.posiblesVacaciones.push(ausencia);
          break;
        case TipoAusencia.Licencia_general:
          this.posiblesLicencias.push(ausencia);
          break;
        case TipoAusencia.Licencia_por_enfermedad:
          this.posiblesLicenciasE.push(ausencia);
          break;
        case TipoAusencia.Licencia_por_ART:
          this.posiblesLicenciasART.push(ausencia);
          break;
        case TipoAusencia.Trabajo_remoto:
          this.posiblesLicenciasTR.push(ausencia);
          break;
        default:
          break;
      }
    });

    this.posiblesVacaciones = [...this.posiblesVacaciones];
    this.posiblesLicencias = [...this.posiblesLicencias];
    this.posiblesLicenciasE = [...this.posiblesLicenciasE];
    this.posiblesLicenciasART = [...this.posiblesLicenciasART];
    this.posiblesLicenciasTR = [...this.posiblesLicenciasTR];
  }
}
