import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class SpinnerComponent implements OnInit, OnDestroy {
  @Input() inverted = false;
  @Input() delay = 200;

  public show = false;

  private loaderSubscription: Subscription;

  public ngOnInit(): void {
    this.loaderSubscription = timer(this.delay).subscribe(() => {
      this.show = true;
    });
  }

  public ngOnDestroy(): void {
    this.loaderSubscription?.unsubscribe();
  }
}
