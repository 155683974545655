import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';

export class PersonalLegajoWithSelection {
  public selected: boolean;
  public personalLegajo: PersonalLegajoBasicoDTO;
  public disabled: boolean;

  constructor(personalLegajo: PersonalLegajoBasicoDTO, disabled = false) {
    this.personalLegajo = personalLegajo;
    this.selected = false;
    this.disabled = disabled;
  }
}
