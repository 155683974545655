<mat-expansion-panel class="no-shadow" [disabled]="!posibleEntregas?.length">
  <mat-expansion-panel-header>
    <mat-panel-title class="w-33"> {{ 'GENERAL.DELIVERIES' | translate }} </mat-panel-title>
    <mat-panel-description class="hidden-sm">
      {{ (posibleEntregas?.length ? 'DELIVERIES.POSSIBLE_DELIVERIES' : 'DELIVERIES.NO_DELIVERIES_WERE_REGISTERED') | translate }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="mt-2">
    <table mat-table [dataSource]="posibleEntregas" matSort (matSortChange)="sortData($event)">
      <ng-container [matColumnDef]="ColumnNames.Nombre">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.FORMS.NAME' | translate }}</th>
        <td mat-cell *matCellDef="let entrega">{{ entrega.nombre }}</td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.TiempoDevolucion">
        <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.RETURN_TIME' | translate }}</th>
        <td class="text-left" mat-cell *matCellDef="let entrega">
          {{ obtenerFechaDevolucion(entrega) }}
        </td>
      </ng-container>
      <ng-container [matColumnDef]="ColumnNames.DiasPrevioAviso">
        <th class="text-right" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'GENERAL.FORMS.DAYS_OF_PRIOR_NOTICE' | translate }}</th>
        <td class="text-left" mat-cell *matCellDef="let entrega">
          {{ entrega.diasPrevioAviso === 0 ? '' : entrega.diasPrevioAviso }}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</mat-expansion-panel>
