<div class="d-flex flew-row align-items-center py-3 p-relative">
  <a
    [matBadge]="notificationService.notificationsNotSeen | ngxshortNumber"
    [matBadgeHidden]="notificationService.notificationsNotSeen === 0 || isClockInOut"
    [matBadgeColor]="$any('danger')"
  >
    <app-circle-image
      (click)="openMenu()"
      [stopPropagation]="false"
      [width]="50"
      [openProfile]="false"
      [personalLegajo]="$teammateFile()"
      [imageSource]="$teammateFile().imgSource"
    />
  </a>
  <div
    *ngIf="!isClockInOut"
    class="d-flex flex-column justify-content-center sidebar-menu-container"
    [ngClass]="{ 'pl-3 pr-1': !$collapsedState(), expanded: !$collapsedState() }"
  >
    <strong
      class="d-flex justify-content-between align-items-center"
      [ngClass]="{ 'primary-color': (vistaEmpresa$ | async) === false, 'color-green': vistaEmpresa$ | async }"
      (click)="menuVista()"
    >
      {{ username }} <span class="material-symbols-outlined white-color icon-adjust">arrow_drop_down</span>
    </strong>
    <label>{{ (vistaEmpresa$ | async) ? ('LOWER_MENU.ADMIN_VIEW' | translate) : ('LOWER_MENU.MEMBER_VIEW' | translate) }}</label>
    <label class="app-version">{{ appVersion }}</label>
  </div>
</div>
