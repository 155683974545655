export enum TipoNotificacion {
   No_definido = 0,
   NuevaSolicitudAusencia = 1,
   CambioEstadoSolicitudAusencia = 2,
   NuevaAusencia = 3,
   NuevaSolicitudEntrega = 4,
   NuevasEntregasPendientes = 5,
   EmpleadoDevolucionVencida = 6,
   DevolucionesVencidas = 7,
   CambioEstadoSolicitudEntrega = 8,
   NuevaEntrega = 9,
   NuevoEvento = 10,
   EventoModificado = 11,
   NuevaSancion = 12,
   NuevoDocumento = 13,
   NuevoDocumentoPublico = 14,
   NuevoDocumentoEmpresa = 15,
   NuevoCuestionario = 16,
   CuestionarioDesaprobado = 17,
   FirmaPendiente = 18,
   FirmaSolicitada = 19,
   FirmaRealizada = 20,
   FirmaNoRequerida = 21,
   NuevaSolicitudCapacitacion = 22,
   NuevasCapacitacionesPendientes = 23,
   CambioEstadoSolicitudCapacitacion = 24,
   NuevaCapacitacion = 25,
   NuevoComentarioEvento = 26,
   NuevoComentarioPerfil = 27,
   NuevoComentarioComunicado = 28,
   NuevoComentarioFeedback = 29,
   VencimientoContrato = 30,
   NuevoFeedback = 31,
   NuevoComentarioSancion = 32,
   NuevoFeedbackMuroDeLaFama = 33,
   DueTasks = 34,
   NewTasks = 35,
   NuevoComentarioACandidato = 36,
   NuevoComentarioAusencia = 37,
   DomicilioRequerido = 38,
   NotificacionMasiva = 39,
   MergeAccount = 40,
   NuevoComentarioTarea = 41,
   ComunicadosSinLeer = 42,
   NuevoCuestionarioCompetencias = 43,
   NewProfileEditions = 44,
   NotificationNeedHelp = 45,
   NewPostulatedCandidate = 46,
   NewCommentSocialPost = 47,
   GoalEdition = 48,
   SubordinatedGoalEdition = 49,
   ReevaluateGoal = 50,
   MentionInSocialPost = 51,
   DeleteSocialPost = 52,
   ChatNewMessage = 53,
 }
