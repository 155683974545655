import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { BancoDTO } from 'src/app/ModelDTO/DTO/banco.DTO';

@Injectable({
   providedIn: 'root'
})
export class BancoServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getBancosIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/bancos/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getBancosIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/bancos/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getBancos(): Promise<Array<BancoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/bancos/'));
         const resJson = res.data;
         const resDTO = new Array<BancoDTO>();
         for (const item of resJson) {
            const itemDTO = new BancoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertBancos(bancoDTO: BancoDTO): Promise<BancoDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/bancos/', bancoDTO));
         const resDTO = new BancoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async updateBancosId(id: number, bancoDTO: BancoDTO): Promise<BancoDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/bancos/' + id, bancoDTO));
         const resDTO = new BancoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async deleteBancosId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/bancos/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getBancosId(id: number): Promise<BancoDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/bancos/' + id));
         if (!res) { return null; }
         const resDTO = new BancoDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public async getBancosAutoComplete(contiene: string, pageSize: number): Promise<Array<BancoDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/bancos/autoComplete' + '?contiene=' + contiene + '&pageSize=' + pageSize));
         const resJson = res.data;
         const resDTO = new Array<BancoDTO>();
         for (const item of resJson) {
            const itemDTO = new BancoDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

}
