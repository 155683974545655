<h1 mat-dialog-title class="d-flex flex-row justify-content-between">
  <span>Solicitar firma </span>
  <span class="dialog-close-icon material-symbols-outlined pt-1" [mat-dialog-close]="true"> close </span>
</h1>
<div mat-dialog-content class="dialog-expand">
  <span class="mb-4 label-text" *ngIf="passedData.descripcion">{{ passedData.descripcion }}</span>

  <app-multiple-personal-selector
    [(personalLegajosSelected)]="passedData.personalSeleccionado"
    [permitirSeleccionarTodo]="true"
    [personalDestacado]="passedData.personalDestacado"
  >
  </app-multiple-personal-selector>
  <div class="d-flex flex-row flex-wrap mt-4 align-items-center">
    <mat-form-field class="fill flex-grow-1" appearance="outline">
      <mat-label>Posición de firma</mat-label>
      <mat-select id="selectorSelect" name="posicionFirmaSelect" [compareWith]="compareId" [(value)]="passedData.posicionFirma">
        <mat-option *ngFor="let posicionFirma of posicionesFirma" [value]="posicionFirma">{{ posicionFirma.nombre }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-checkbox color="primary" class="flex-grow-1 ml-lg-3" [(ngModel)]="passedData.notificarPorEmail"
      >Notificarme cuando firmen</mat-checkbox
    >
  </div>
</div>
<div mat-dialog-actions align="end">
  <button type="button" mat-raised-button color="primary" [command]="confirmarCmd">Aceptar</button>
</div>
