import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CategoriaEmpleadosService } from 'src/app/layout/administrador/configuracion/categoria-empleados/categoria-empleados.service';
import { ConvenioColectivoService } from 'src/app/layout/administrador/configuracion/convenio-colectivo/convenio-colectivo.service';
import { ConvenioColectivoDTO } from 'src/app/ModelDTO/DTO/convenioColectivo.DTO';
import { PersonalCategoriaDTO } from 'src/app/ModelDTO/DTO/personalCategoria.DTO';
import { PersonalSubcategoriaDTO } from 'src/app/ModelDTO/DTO/personalSubcategoria.DTO';
import { compareDtoId } from 'src/app/shared/shared-functions';

@Component({
  selector: 'app-selector-categoria',
  templateUrl: './selector-categoria.component.html',
})
export class SelectorCategoriaComponent implements OnInit {
  @Input() public categoria: PersonalCategoriaDTO;
  @Input() public subcategoria: PersonalSubcategoriaDTO;
  @Input() public opcionTodos = false;
  @Input() public disabled = false;
  @Input() public conHintCategoria = false;
  @Output() public categoriaChange = new EventEmitter<PersonalCategoriaDTO>();
  @Output() public subcategoriaChange = new EventEmitter<PersonalSubcategoriaDTO>();
  public readonly compareId = compareDtoId;
  public categorias: Array<PersonalCategoriaDTO>;

  constructor(
    private categoriasService: CategoriaEmpleadosService,
    private convenioColectivoService: ConvenioColectivoService,
    private translateService: TranslateService,
  ) {
    this.categorias = new Array<PersonalCategoriaDTO>();
  }

  public async ngOnInit(): Promise<void> {
    this.categorias = await this.categoriasService.obtenerCategorias();
    const sinCategoria = new PersonalCategoriaDTO();
    sinCategoria.nombre = this.translateService.instant('GENERAL.NO_CATEGORY');
    const sinSubCategoria = new PersonalSubcategoriaDTO();
    sinSubCategoria.nombre = this.translateService.instant('GENERAL.NO_SUBCATEGORY');
    this.categorias.unshift(sinCategoria);
    this.categorias[0].subcategorias.unshift(sinSubCategoria);
    if (this.opcionTodos) {
      this.ponerTodos();
    }
    if (this.categoria) {
      this.categoria = this.categorias.find((x) => x.id === this.categoria.id);
      if (this.categoria && this.subcategoria) {
        this.subcategoria = this.categoria.subcategorias.find((x) => x.id === this.subcategoria.id);
      }
    } else {
      // Area y sector son undefined pero opcion todos es true -> selecciono esa opcion
      // eslint-disable-next-line no-lonely-if
      if (this.opcionTodos) {
        this.categoria = this.categorias[0];
        this.subcategoria = this.categoria.subcategorias[0];
      }
    }
  }

  public nombreConvenio(convenio: ConvenioColectivoDTO): string {
    return this.convenioColectivoService.nombreConvenio(convenio);
  }

  public onCategoriaChange(): void {
    if (this.categoria && this.categoria.subcategorias.length > 0) {
      const sinSubCategoria = new PersonalSubcategoriaDTO();
      sinSubCategoria.nombre = 'Sin subcategoria';
      this.categoria.subcategorias.unshift(sinSubCategoria);
      this.subcategoria = this.categoria.subcategorias[0];
    } else {
      this.subcategoria = new PersonalSubcategoriaDTO();
    }
    this.categoriaChange.emit(this.categoria);
    this.subcategoriaChange.emit(this.subcategoria);
  }

  public onSubcategoriaChange(): void {
    this.categoriaChange.emit(this.categoria);
    this.subcategoriaChange.emit(this.subcategoria);
  }

  private ponerTodos(): void {
    const todas = new PersonalCategoriaDTO();
    todas.nombre = 'Todas';
    const todosSub = new PersonalSubcategoriaDTO();
    todosSub.nombre = 'Todos';
    todas.subcategorias.unshift(todosSub);
    this.categorias.unshift(todas);

    // Opcion todos los sectores en todas las areas
    this.categorias.forEach((ar) => {
      ar.subcategorias.unshift(todosSub);
    });
  }
}
