<h1 mat-dialog-title>Ajustar im&aacute;gen</h1>
<div mat-dialog-content>
  <mat-slide-toggle color="primary my-3" [(ngModel)]="containWithinAspectRatio">
    {{ 'BUSINESS_PROFILE.PRESERVE_ASPECT_RATIO' | translate }}
  </mat-slide-toggle>
  <div class="crop-container">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      output="base64"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspRatio"
      [resizeToWidth]="resizeWidth"
      [resizeToHeight]="resizeHeight"
      [containWithinAspectRatio]="false"
      [cropperMinWidth]="200"
      [alignImage]="'center'"
      [onlyScaleDown]="true"
      [containWithinAspectRatio]="containWithinAspectRatio"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
    >
    </image-cropper>
  </div>

  <div class="d-flex align-items-center justify-content-center" *ngIf="!loading">
    <img
      [style.width.px]="widthPreview"
      [style.height.px]="heightPreview"
      [style.border]="croppedImage ? '1px solid black' : 'none'"
      [src]="croppedImage"
    />
  </div>
  <div class="loader-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div mat-dialog-actions align="end">
  <div *ngIf="!loading">
    <button type="button" mat-raised-button [command]="cancelarCmd">Cancelar</button>
    <button #accept type="button" mat-raised-button color="primary" [command]="aceptarCmd">Guardar</button>
  </div>
</div>
