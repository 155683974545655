import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { CompetenceCategoryDTO } from 'src/app/ModelDTO/DTO/competenceCategory.DTO';
import { ConfigurationLevelRangeDTO } from 'src/app/ModelDTO/DTO/configurationLevelRange.DTO';


export class CompetenceCategoryChargeDTO implements IEntityDTO {

   category: CompetenceCategoryDTO;
   rangeLevel: ConfigurationLevelRangeDTO;
   id = 0;

   constructor() {
      this.category = new CompetenceCategoryDTO();
      this.rangeLevel = new ConfigurationLevelRangeDTO();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.category != null) { this.category.PrepareDTO(jsonObj.category); }
      if (jsonObj.rangeLevel != null) { this.rangeLevel.PrepareDTO(jsonObj.rangeLevel); }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
