import { ConfiguracionDTO } from '@api/interfaces/configuracion.interface';
import Dexie, { Table } from 'dexie';

interface EmployeeDescriptor {
  image: string;
  descriptor: Float32Array;
}

interface PdfPaySlip {
  tenantId: string;
  pdfBase64: string;
}

export class AppDB extends Dexie {
  public globalSettings!: Table<ConfiguracionDTO, number>;
  public globalSettingsTimestamp!: Table<{ id: number; date: string }, number>;
  public employeeDescriptors!: Table<EmployeeDescriptor, string>;
  public pdfPaySlips!: Table<PdfPaySlip, string>;

  constructor() {
    super('ngdexieliveQuery');
    this.version(1).stores({
      globalSettings: 'id',
      globalSettingsTimestamp: 'id',
      employeeDescriptors: 'image',
      pdfPaySlips: 'tenantId',
    });
  }
}

export const db = new AppDB();
