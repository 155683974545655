<div class="d-flex flex-row mt-4 file-input">
  <input type="text" class="flex-text-truncate upload-file-input file-input-displayed" readonly (click)="fileInput.click()" />
  <div class="file-input-content">
    <p [innerHTML]="fileSelectedString || 'DOCUMENTS.ATTACH_A_FILE' | translate"></p>
    <button type="button" color="primary" mat-stroked-button (click)="fileInput.click()">
      {{ 'GENERAL.ATTACH' | translate }}
    </button>
  </div>
  <input
    class="file-input-hidden"
    [accept]="accept"
    (change)="onDocumentacionChange($event)"
    #fileInput
    type="file"
    id="file"
    [multiple]="!onlyOneFile"
    [disabled]="attachDisabled"
  />
</div>

<table mat-table [dataSource]="attachmentsASubir" *ngIf="attachmentsASubir.length" matSort (matSortChange)="sortData($event)">
  <ng-container [matColumnDef]="ColumnNames.Nombre">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
    <td mat-cell *matCellDef="let adjunto">
      <div class="d-flex flex-row flex-fill flex-nowrap align-items-end p-relative">
        <div class="d-flex flex-fill align-items-center gap-1">
          <span>
            <mat-icon fontSet="material-symbols-outlined" [svgIcon]="fileIconClass(adjunto.File.fileName)"></mat-icon>
          </span>
          <span class="flex-text-truncate">{{ adjunto.File.fileName }}</span>
        </div>
        <span class="label-cantidad-firmantes label-text-disable" *ngIf="adjunto.AllowsDigitalSignature && adjunto.Signatures.length > 0">
          {{ adjunto.Signatures.length + ' firmante(s)' }}
        </span>
      </div>
    </td>
  </ng-container>
  <ng-container [matColumnDef]="ColumnNames.Acciones">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let adjunto" class="text-right">
      <div class="form-sm text-right">
        <button type="button" mat-stroked-button color="danger" (click)="removerAdjunto(adjunto)">Remover</button>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<ng-container *ngIf="(firmaDigitalPermission$ | async) && solicitarFirma && (vistaEmpresa$ | async)">
  <div class="d-flex flex-row mt-3">
    <div class="d-flex align-items-center">
      <div [matTooltip]="!alMenosUnArchivoPermiteFirma ? ('DOCUMENTS.SIGN_BTN_DISABLED_UNSUPPORTED_FILE' | translate) : ''">
        <button mat-raised-button color="primary" [command]="pedirFirmaCmd" [disabled]="!alMenosUnArchivoPermiteFirma">
          {{
            attachmentSelectorService.firmantesSeleccionados?.length
              ? attachmentSelectorService.firmantesSeleccionados?.length + ' firmante(s)'
              : ('GENERAL.REQUEST_SIGNATURE' | translate)
          }}
        </button>
      </div>
      <mat-error *ngIf="!alMenosUnArchivoPermiteFirma && attachmentsASubir.length">
        Recuerde que solo los archivos PDF pueden ser firmados
      </mat-error>
    </div>
  </div>
  <div class="mt-2" *ngIf="alerta">
    <app-warning-card [mensaje]="'DOCUMENTS.SIGNATURE_WARNING'"></app-warning-card>
  </div>
</ng-container>
