import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BehaviorSubject } from 'rxjs';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';
import { PersonalLegajoServiceBackend } from 'src/app/ServiceBackend/personalLegajo.ServiceBackend';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { PersonalLegajoWithSelection } from 'src/app/shared/teammates-selector/multiple-personal-selector/personal-legajo-with-selection';

@Component({
  selector: 'app-multiple-personal-selector',
  templateUrl: './multiple-personal-selector.component.html',
  styleUrls: ['./multiple-personal-selector.component.scss'],
})
export class MultiplePersonalSelectorComponent implements OnInit {
  @Input() public permitirSeleccionarTodo = true;
  @Input() public personalLegajosSelectedById: number[] = [];
  @Input() public personalLegajosSelected: PersonalLegajoBasicoDTO[] = [];
  @Input() public personalDestacado: PersonalLegajoBasicoDTO[] = [];
  @Input() public orgfilterActive = false;
  @Input() public disabledStaffId: Map<number, boolean>;
  @Input() public disabledTooltip: string;
  @Output() public personalLegajosSelectedChange = new EventEmitter<PersonalLegajoBasicoDTO[]>();

  public textFilter = '';
  public dataOriginal: PersonalLegajoWithSelection[] = [];
  public dataFiltered: PersonalLegajoWithSelection[] = [];
  public mostrarSoloSeleccionados = false;

  public filterChangeCmd: ICommand = new Command(() => this.onTextFilterChanged(), new BehaviorSubject(true), false, 150);

  constructor(private personalLegajoServiceBackend: PersonalLegajoServiceBackend) {}

  public async ngOnInit(): Promise<void> {
    let personalLegajos = await this.personalLegajoServiceBackend.getPersonalLegajos(true, this.orgfilterActive);
    this.personalDestacado.sort((a, b) => b.nombreCompleto.localeCompare(a.nombreCompleto));
    personalLegajos = personalLegajos.filter((x) => !this.personalDestacado.some((y) => y.id === x.id));
    this.personalDestacado.forEach((x) => personalLegajos.unshift(x));
    this.dataOriginal = personalLegajos.map((x) => {
      const disabled = this.disabledStaffId?.get(x.id) ?? false;
      const teammate = new PersonalLegajoWithSelection(x, disabled);
      return teammate;
    });
    this.setDataSelectedFromInput();
    this.setDataSelectedByIdFromInput();
  }

  public onTextFilterChanged(): void {
    this.filterData();
  }

  public filterData(): void {
    let dataFiltrada = this.mostrarSoloSeleccionados ? this.dataOriginal.filter((x) => x.selected) : this.dataOriginal;
    dataFiltrada = dataFiltrada.filter((x) => x.personalLegajo.nombreCompleto.toLowerCase().includes(this.textFilter.toLowerCase()));
    this.dataFiltered = [...dataFiltrada];
  }

  public onMostarSoloSeleccionadosChanged(): void {
    this.textFilter = '';
    this.filterData();
  }

  public onRowClick(data: PersonalLegajoWithSelection): void {
    if (!data.disabled) {
      data.selected = !data.selected;
      this.filterData();
      this.personalLegajosSelectedChange.emit(this.dataOriginal.filter((x) => x.selected).map((x) => x.personalLegajo));
    }
  }

  public onSelectAllChanged(matCheckbox: MatCheckboxChange): void {
    if (matCheckbox.checked) {
      this.dataOriginal.forEach((x) => {
        x.selected = true;
      });
    } else {
      this.dataOriginal.forEach((x) => {
        x.selected = false;
      });
    }
    this.filterData();
    this.personalLegajosSelectedChange.emit(this.dataOriginal.filter((x) => x.selected).map((x) => x.personalLegajo));
  }

  public trackByData(index: number, item: PersonalLegajoWithSelection): number {
    if (!item) {
      return null;
    }
    return item.personalLegajo.id;
  }

  private setDataSelectedFromInput(): void {
    this.dataOriginal.forEach((x) => {
      x.selected = !!this.personalLegajosSelected.some((y) => y.id === x.personalLegajo.id);
    });
    this.dataFiltered = [...this.dataOriginal];
  }

  private setDataSelectedByIdFromInput(): void {
    if (this.personalLegajosSelectedById?.length) {
      this.dataOriginal.forEach((x) => {
        x.selected = !!this.personalLegajosSelectedById.some((y) => y === x.personalLegajo.id);
      });
      this.dataFiltered = [...this.dataOriginal];
    }
    this.personalLegajosSelectedChange.emit(this.dataOriginal.filter((x) => x.selected).map((x) => x.personalLegajo));
  }
}
