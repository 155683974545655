import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { PersonalLegajoBasicoDTO } from 'src/app/ModelDTO/DTO/personalLegajoBasico.DTO';


export class CargoConPersonalDTO implements IEntityDTO {

   personal: Array<PersonalLegajoBasicoDTO>;
   nombre = '';
   descripcion = '';
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.personal = new Array<PersonalLegajoBasicoDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.personal != null) { for (const item of jsonObj.personal) { const itemDTO = new PersonalLegajoBasicoDTO(); itemDTO.PrepareDTO(item); this.personal.push(itemDTO); } }
      if (jsonObj.nombre != null) { this.nombre = jsonObj.nombre; }
      if (jsonObj.descripcion != null) { this.descripcion = jsonObj.descripcion; }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
