import { Injectable } from '@angular/core';
import { PersonalCategoriaDTO } from 'src/app/ModelDTO/DTO/personalCategoria.DTO';
import { PersonalCategoriaServiceBackend } from 'src/app/ServiceBackend/personalCategoria.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class CategoriaEmpleadosService {
  private categoriaAEditar: PersonalCategoriaDTO;

  constructor(private categoriasServiceBackend: PersonalCategoriaServiceBackend) {}

  public async obtenerCategorias(): Promise<Array<PersonalCategoriaDTO>> {
    const res = await this.categoriasServiceBackend.getPersonalCategorias();
    return res;
  }

  public async obtenerCategoriasAgrupadas(): Promise<Map<number, Array<PersonalCategoriaDTO>>> {
    const mapeoIdConvenioCategorias = new Map<number, Array<PersonalCategoriaDTO>>();
    const categorias: Array<PersonalCategoriaDTO> = await this.obtenerCategorias();

    for (const categoria of categorias) {
      const convenidoId = categoria.convenio.id ? categoria.convenio.id : -1;

      if (!mapeoIdConvenioCategorias.has(convenidoId)) {
        mapeoIdConvenioCategorias.set(convenidoId, new Array<PersonalCategoriaDTO>());
      }
      const arregloCategorias = mapeoIdConvenioCategorias.get(convenidoId);
      arregloCategorias.push(categoria);
      mapeoIdConvenioCategorias.set(convenidoId, arregloCategorias);
    }
    return this.ordenarMap(mapeoIdConvenioCategorias);
  }

  private ordenarMap(mapDesordenado: Map<number, Array<PersonalCategoriaDTO>>) {
    const mapOrdenado: Map<number, Array<PersonalCategoriaDTO>> = new Map<number, Array<PersonalCategoriaDTO>>();

    Array.from(mapDesordenado.keys())
      .sort((a, b) => a - b)
      .forEach((k) => {
        mapOrdenado.set(k, mapDesordenado.get(k));
      });

    return mapOrdenado;
  }

  public async agregarCategoria(cat: PersonalCategoriaDTO): Promise<PersonalCategoriaDTO> {
    const res = await this.categoriasServiceBackend.insertPersonalCategorias(cat);
    return res;
  }

  public async editarCategoria(cat: PersonalCategoriaDTO): Promise<PersonalCategoriaDTO> {
    const res = await this.categoriasServiceBackend.updatePersonalCategoriasId(cat.id, cat);
    return res;
  }

  public async eliminarCategoria(cat: PersonalCategoriaDTO): Promise<void> {
    await this.categoriasServiceBackend.deletePersonalCategoriasId(cat.id);
  }

  get CategoriaAEditar(): PersonalCategoriaDTO {
    return this.categoriaAEditar;
  }

  set CategoriaAEditar(t: PersonalCategoriaDTO) {
    this.categoriaAEditar = t;
  }
}
