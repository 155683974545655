import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EstadoAprobacionSolicitud } from '@api/enums/estado-aprobacion-solicitud.enum';
import { TipoUnidadAusencia } from '@api/enums/tipo-unidad-ausencia.enum';
import { DateTime } from 'luxon';
import { BehaviorSubject } from 'rxjs';
import { DetalleAusenciaModalComponent } from 'src/app/app-common/ausencias/detalle-ausencia-modal/detalle-ausencia-modal.component';
import {
  DetalleSolicitudAusenciaModalComponent,
  RespuestaSolicitudDetalleAusenciaModal,
} from 'src/app/app-common/ausencias/detalle-solicitud-ausencia-modal/detalle-solicitud-ausencia-modal.component';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { UserAusenciasService } from 'src/app/layout/user/user-ausencias/user-ausencias.service';
import { LegajoDTO } from 'src/app/ModelDTO/DTO/legajo.DTO';
import { PersonalDTO } from 'src/app/ModelDTO/DTO/personal.DTO';
import { PersonalAusenciaDTO } from 'src/app/ModelDTO/DTO/personalAusencia.DTO';
import { DayEventModel } from 'src/app/shared/calendario/dayEvent.model';
import { colors } from 'src/app/shared/colors';

@Injectable({
  providedIn: 'root',
})
export class UserAusenciasViewModel {
  public ausencias: Array<PersonalAusenciaDTO>;
  public personalSelected: PersonalDTO;
  public incluirBajas: boolean = false;

  public desde: Date;
  public hasta: Date;
  public mesCalendario: Date;
  public estado: EstadoAprobacionSolicitud;
  public estadosSolicitud = EstadoAprobacionSolicitud;
  public ausenciasCalendar: Array<PersonalAusenciaDTO>;
  private eventos = new BehaviorSubject<DayEventModel[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public calendarEvents$ = this.eventos.asObservable();

  constructor(
    private headerService: HeaderAppService,
    private userAusenciasService: UserAusenciasService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.initViewModel();

    this.headerService.loggedOut$.subscribe((data) => {
      if (data) {
        this.initViewModel();
      }
    });
  }

  public initViewModel(): void {
    this.estado = this.estadosSolicitud.Aprobado;
    this.personalSelected = new PersonalDTO();
    this.personalSelected.legajo = new LegajoDTO();
    this.personalSelected.legajo.id = this.headerService.personalLegajoId;
    this.ausencias = [];
    this.ausenciasCalendar = [];
    this.eventos.next([]);
    const hoy = new Date();
    this.mesCalendario = new Date(hoy.getFullYear(), hoy.getMonth(), 1);
    this.hasta = new Date(hoy.getFullYear(), hoy.getMonth(), hoy.getDate());
    this.desde = new Date();
    this.desde.setMonth(this.desde.getMonth() - 1);
    this.hasta.setMonth(this.hasta.getMonth() + 1);
  }

  public async filtrar(): Promise<void> {
    if (this.desde && this.hasta) {
      if (this.headerService.personalLegajoId === this.personalSelected.legajo.id) {
        const res = await this.userAusenciasService.obtenerAusenciasPropias(this.desde, this.hasta, this.estado);
        this.ausencias = [...res];
      } else {
        const res = await this.userAusenciasService.obtenerAusenciasFiltradas(
          this.desde,
          this.hasta,
          this.estado,
          this.personalSelected.legajo.id,
          this.incluirBajas,
        );
        this.ausencias = [...res];
      }
    }
  }

  public async filtrarCalendar(): Promise<void> {
    const date = DateTime.fromObject({ year: this.mesCalendario.getFullYear(), month: this.mesCalendario.getMonth(), day: 1 });
    const from = date.toJSDate();
    const to = date.endOf('month').toJSDate();

    if (this.headerService.personalLegajoId === this.personalSelected.legajo.id) {
      const res = await this.userAusenciasService.obtenerAusenciasPropias(from, to, this.estado);
      this.ausenciasCalendar = [...res];
    } else {
      const res = await this.userAusenciasService.obtenerAusenciasFiltradas(from, to, this.estado, this.personalSelected.legajo.id);
      this.ausenciasCalendar = [...res];
    }
    this.eventos.next(this.generarEventos());
  }

  public async filterByDate(from: Date, to: Date): Promise<void> {
    if (this.headerService.personalLegajoId === this.personalSelected.legajo.id) {
      const res = await this.userAusenciasService.obtenerAusenciasPropias(from, to, this.estado);
      this.ausenciasCalendar = [...res];
    } else {
      const res = await this.userAusenciasService.obtenerAusenciasFiltradas(from, to, this.estado, this.personalSelected.legajo.id);
      this.ausenciasCalendar = [...res];
    }
    this.eventos.next(this.generarEventos());
  }

  public async openOwnAbsence(absence: PersonalAusenciaDTO): Promise<void> {
    if (absence.conSolicitud) {
      const solicitud = await this.userAusenciasService.obtenerSolicitudDeAusencia(absence.id);
      const dialogRef = this.dialog.open(DetalleSolicitudAusenciaModalComponent, {
        disableClose: false,
        data: solicitud,
        panelClass: 'full-size-sm-600-lg-modal',
      });
      dialogRef.afterClosed().subscribe((data: RespuestaSolicitudDetalleAusenciaModal) => {
        if (data && data.editar) {
          this.router.navigate(['/user/ausencias/edit-request/', data.solicitud.Id], {});
        }
      });
    } else {
      const ausenciaFull = await this.userAusenciasService.getAusenciaPorId(absence);
      this.dialog.open(DetalleAusenciaModalComponent, {
        disableClose: false,
        data: ausenciaFull,
        panelClass: 'full-size-sm-600-lg-modal',
      });
    }
  }

  private generarEventos(): Array<DayEventModel> {
    const eventos = [];
    if (this.ausenciasCalendar.length > 0) {
      this.ausenciasCalendar.forEach((x) => {
        const color = this.dayColor(x);
        const allDay = x.ausencia.unidad === TipoUnidadAusencia.D1ias;
        const dayEvent = new DayEventModel(
          x.ausencia.nombre,
          allDay ? x.fechaDesde : x.dateTimeFrom,
          allDay ? x.fechaHasta : x.dateTimeTo,
          x.id,
          allDay,
          color,
        );
        eventos.push(dayEvent);
      });
    }
    return eventos;
  }

  private dayColor(ausencia: PersonalAusenciaDTO): string {
    if (ausencia.estado === EstadoAprobacionSolicitud.Aprobado) {
      return colors.color_green_500;
    }
    if (ausencia.estado === EstadoAprobacionSolicitud.Pendiente) {
      return colors.color_blue_400;
    }
    if (ausencia.estado === EstadoAprobacionSolicitud.Rechazado) {
      return colors.color_red_500;
    }
    return null;
  }
}
