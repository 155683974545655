import { Component, DestroyRef, Input, OnChanges, OnDestroy, OnInit, inject, signal, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { EMPTY, from, Observable, Subscription, switchMap, tap, map, of } from 'rxjs';
import { AlertButton } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.component';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { SnackBarService } from 'src/app/shared/snack-bar/snack-bar.service';
import { DateTime } from 'luxon';
import { PerfilEmpleadoSharedService } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-shared.service';
import { classToJson, compareDtoId, isTodayOrLess } from 'src/app/shared/shared-functions';
import { PersonaCambioEstadolBackendService } from '@api/services/persona-cambio-estadol-backend.service';
import { throttledObservableGenerator, ThrottledObservableGeneratorData } from 'src/app/shared/utils/throttled-obsevable-generator';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { TipoCambioEstadoPersonalDTO } from 'src/app/ModelDTO/DTO/tipoCambioEstadoPersonal.DTO';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { EmployeeChangeStateWithNextBeforeDTO } from 'src/app/ModelDTO/DTO/employeeChangeStateWithNextBeforeDTO';
import { PersonalCambioEstadoDTO } from '@api/interfaces/personal-cambio-estado.interface';
import { PersonalLegajoBasicoDTO } from '@api/interfaces/personal-legajo-basico.interface';
import { CalendarHeaderComponent } from 'src/app/shared/calendar-header/calendar-header.component';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { ThrottledDirective } from 'src/app/shared/directives/throttled-execution.directive';
import { BannerComponent } from 'src/app/shared/banner/banner.component';
import { NgxDatePipe } from 'src/app/shared/lib/ngx-neo-pipes/ngx-date.pipe';

enum ColumnNames {
  'NextState' = 'NextState',
  'Date' = 'Date',
  'Observation' = 'Observation',
  'Actions' = 'Actions',
}

@Component({
  selector: 'app-cambio-de-estado',
  templateUrl: './cambio-de-estado.component.html',
  styleUrls: ['./cambio-de-estado.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    BannerComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatTableModule,
    MatMenuModule,
    MatSlideToggleModule,
    ThrottledDirective,
    NgxDatePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CambioDeEstadoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public personalModel: PersonalModelDTO;
  @Input() public modoEdicion = false;

  public displayedColumns: string[] = [ColumnNames.NextState, ColumnNames.Date, ColumnNames.Observation, ColumnNames.Actions];
  public readonly ColumnNames = ColumnNames;
  public $actualStateEditing = signal(false);
  public $isNextStateEditing = signal(false);
  public $canCreateFutureState = signal(true);
  public statusChangesDataSource = new MatTableDataSource<EmployeeChangeStateWithNextBeforeDTO>();

  public actualFormGroup = inject(FormBuilder).group({
    type: [null],
    since: [null],
    observation: [''],
  });
  public $actualTypesStates = signal<TipoCambioEstadoPersonalDTO[]>([]);
  public nextFormGroup = inject(FormBuilder).group({
    type: [null],
    since: [null],
    observation: [''],
  });
  public $nextTypesStates = signal<TipoCambioEstadoPersonalDTO[]>([]);
  public litigationFormGroup = inject(FormBuilder).group({
    since: [null],
    observation: [''],
  });
  public $litigationActive = signal(false);

  public readonly calendarH = CalendarHeaderComponent;
  public compareDtoId = compareDtoId;

  private statusTypes: TipoCambioEstadoPersonalDTO[] = [];
  private subs = new Subscription();
  private nextStateEditing: PersonalCambioEstadoDTO | null;

  private executeRemoveFutureState: ThrottledObservableGeneratorData<unknown>;

  private employeeChangeStateBackendService = inject(PersonaCambioEstadolBackendService);

  constructor(
    private perfilEmpleadoSharedService: PerfilEmpleadoSharedService,
    private neoModalService: NgxNeoModalMatService,
    private snackBar: SnackBarService,
    private cambioEstadoService: PersonaCambioEstadolBackendService,
    destroyRef: DestroyRef,
  ) {
    this.executeRemoveFutureState = throttledObservableGenerator<PersonalCambioEstadoDTO>(
      (employeeFutureState: PersonalCambioEstadoDTO) => this.cancelFutureState(employeeFutureState),
      destroyRef,
    );
  }

  public beforeOrSameToday = (d: Date | null): boolean => d <= new Date();
  public afterFutureStateOrToday = (d: Date | null): boolean => d > (this.personalModel.Legajo.nextState?.desde ?? new Date());

  public async ngOnInit(): Promise<void> {
    this.actualFormGroup.disable();
    this.litigationFormGroup.disable();
    this.statusTypes = await this.perfilEmpleadoSharedService.obtenerTiposDeCambioDeEstado();

    if (this.personalModel) {
      this.currentStatus();
    }
    this.verifyConditionsFormGroups();
  }

  public ngOnChanges(): void {
    if (this.personalModel?.Legajo?.estadoActual?.id > 0) {
      this.loadActualState();
    }
    if (this.modoEdicion) {
      this.actualFormGroup.disable();
      this.nextFormGroup.disable();
    } else {
      if (this.$actualStateEditing()) {
        this.actualFormGroup.enable();
      }
      this.verifyConditionsFormGroups();
    }
    if (this.personalModel?.Legajo?.nextState?.id > 0) {
      this.statusChangesDataSource.data = [this.personalModel.Legajo.nextState];
      if (this.personalModel?.Legajo?.nextState?.nextState?.id > 0) {
        const dto = new EmployeeChangeStateWithNextBeforeDTO();
        dto.PrepareDTO(this.personalModel.Legajo.nextState.nextState);
        this.statusChangesDataSource.data = [...this.statusChangesDataSource.data, dto];
      }
    }
  }

  public ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  public removeFutureState(employeeFutureState: PersonalCambioEstadoDTO): void {
    this.executeRemoveFutureState.executionSubject$.next(employeeFutureState);
  }

  public cancelActualEdit(): void {
    this.$actualStateEditing.set(false);
    this.actualFormGroup.disable();
    this.currentStatus();
    if (this.personalModel.Legajo.estadoActual.id > 0) {
      this.loadActualState();
    } else {
      this.actualFormGroup.reset();
    }
  }

  public activateEditActual(): void {
    this.actualFormGroup.reset();
    this.actualFormGroup.enable();
    this.$actualTypesStates.set(
      this.statusTypes.filter((x) =>
        this.personalModel.Legajo.estadoActual.tipo.id === 0 ? x.esAlta : x.esAlta !== this.personalModel.Legajo.estadoActual.tipo.esAlta,
      ),
    );
    this.$actualStateEditing.set(true);
  }

  public newActualState = (): Observable<PersonalCambioEstadoDTO> =>
    this.actualFormGroup.valid
      ? this.confirmChangeActualState().pipe(
          switchMap((continueWithEdition) => {
            if (continueWithEdition) {
              return this.insertNewState(this.actualFormGroup).pipe(
                tap((newEmployeeChangeState) => {
                  if (newEmployeeChangeState) {
                    this.actualFormGroup.disable();
                    this.cancelActualEdit();
                  }
                }),
              );
            }
            return of(null);
          }),
        )
      : of(null);

  public newNextState = (): Observable<PersonalCambioEstadoDTO> =>
    this.nextFormGroup.valid
      ? this.insertNewState(this.nextFormGroup).pipe(
          tap(() => {
            this.verifyConditionsFormGroups();
          }),
        )
      : of(null);

  public cancelFutureStateEdit(): void {
    this.nextFormGroup.reset();
    this.$isNextStateEditing.set(false);
    this.currentStatus();
    this.nextStateEditing = null;
    this.verifyConditionsFormGroups();
  }

  public saveNextState = (): Observable<PersonalCambioEstadoDTO> => {
    if (this.nextFormGroup.valid) {
      const nextModifiedDTO: PersonalCambioEstadoDTO = {
        ...this.nextStateEditing,
        personal: classToJson(this.personalModel.Legajo),
        desde: DateTime.fromJSDate(this.nextFormGroup.value.since),
        observacion: this.nextFormGroup.value.observation,
      };
      return this.employeeChangeStateBackendService.updateCambioEstadoId(this.nextStateEditing.id, nextModifiedDTO).pipe(
        tap((newEmployeeChangeState) => {
          this.showSuccess(newEmployeeChangeState, this.nextFormGroup);
        }),
      );
    }
    return of(null);
  };

  public loadNextStateEdit(nextState: PersonalCambioEstadoDTO): void {
    this.nextStateEditing = nextState;
    this.$isNextStateEditing.set(true);
    this.nextFormGroup.setValue({
      type: nextState.tipo,
      since: nextState.desde,
      observation: nextState.observacion,
    });
    this.$nextTypesStates.set(this.statusTypes.filter((x) => (nextState.tipo.id === 0 ? !x.esAlta : x.esAlta === nextState.tipo.esAlta)));
    this.nextFormGroup.enable();
  }

  public activeLitigation(changeEvent: MatSlideToggleChange): void {
    this.$litigationActive.set(changeEvent.checked);
    if (changeEvent.checked) {
      this.litigationFormGroup.enable();
    } else {
      this.litigationFormGroup.disable();
    }
  }

  public saveLitigation = (): Observable<unknown> => {
    if (this.litigationFormGroup.valid) {
      const changeStateDTO: PersonalCambioEstadoDTO = {
        ...classToJson(this.personalModel.Legajo.estadoActual),
        personal: classToJson(this.personalModel.Legajo),
        litigioFecha: DateTime.fromJSDate(this.litigationFormGroup.value.since),
        litigioObservacion: this.litigationFormGroup.value.observation,
        litigio: this.$litigationActive(),
      };
      return this.employeeChangeStateBackendService.updateCambioEstadoId(this.personalModel.Legajo.estadoActual.id, changeStateDTO).pipe(
        tap((res) => {
          this.snackBar.showInfo('GENERAL.LITIGATION_MARK_SUCCESS');
          this.personalModel.Legajo.estadoActual.PrepareDTO(res);
        }),
      );
    }

    return from(this.neoModalService.warning('GENERAL.COMPLETE_MANDATORY_DATA'));
  };

  private cancelFutureState = (employeeFutureState: PersonalCambioEstadoDTO): Observable<unknown> =>
    from(
      this.neoModalService.decision(
        this.personalModel.Legajo?.nextState?.nextState?.id > 0 && this.personalModel.Legajo?.nextState?.id === employeeFutureState.id
          ? 'EMPLOYEES.NEXT_STATES_CANCEL'
          : 'EMPLOYEES.NEXT_STATE_CANCEL',
      ),
    ).pipe(
      switchMap((res) => {
        if (res.ButtonResponse === AlertButton.Accept) {
          return this.cambioEstadoService.deleteCambioEstadoId(employeeFutureState.id);
        }

        return EMPTY;
      }),
      tap(() => {
        this.snackBar.showInfo('EMPLOYEES.NEXT_STATE_REMOVED');
        if (this.personalModel.Legajo.nextState.id === employeeFutureState.id) {
          this.personalModel.Legajo.nextState = null;
        } else {
          this.personalModel.Legajo.nextState.nextState = null;
        }
        this.statusChangesDataSource.data = this.statusChangesDataSource.data.filter((x) => x.id !== employeeFutureState.id);
        this.currentStatus();
        this.verifyConditionsFormGroups();
      }),
    );

  private currentStatus(): void {
    this.$actualTypesStates.set(this.statusTypes.filter((x) => x.esAlta === this.personalModel.Legajo.estadoActual.tipo.esAlta));
    const stateToVerify =
      this.personalModel.Legajo.nextState?.id > 0 ? this.personalModel.Legajo.nextState : this.personalModel.Legajo.estadoActual;
    this.$nextTypesStates.set(this.statusTypes.filter((x) => x.esAlta !== stateToVerify.tipo.esAlta));
    if (
      !this.personalModel.Legajo.estadoActual.tipo.esAlta &&
      this.statusTypes.every((x) => x.id !== this.personalModel.Legajo.estadoActual.tipo.id)
    ) {
      this.$actualTypesStates.update((actualTypesStates) => [...actualTypesStates, this.personalModel.Legajo.estadoActual.tipo]);
    }
  }

  private loadActualState(): void {
    this.actualFormGroup.setValue({
      type: this.personalModel.Legajo.estadoActual.tipo,
      since: this.personalModel.Legajo.estadoActual.desde ?? '',
      observation: this.personalModel.Legajo.estadoActual.observacion,
    });
  }

  private confirmChangeActualState(): Observable<boolean> {
    return this.personalModel?.Legajo?.nextState?.id > 0
      ? from(this.neoModalService.decision('EMPLOYEES.NEXT_STATE_OVERRIDE_WITH_CURRENT')).pipe(
          map((decision) => decision.ButtonResponse === AlertButton.Accept),
        )
      : of(true);
  }

  private generateNewState(form: FormGroup): PersonalCambioEstadoDTO {
    return {
      desde: form.value.since,
      tipo: form.value.type,
      observacion: form.value.observation,
      litigio: false,
      id: 0,
    };
  }

  private showSuccess(employeeChangeStatus: PersonalCambioEstadoDTO, form: FormGroup): void {
    this.snackBar.showInfo('GENERAL.SUCCESSFUL_MODIFICATION');
    const newStateDate = employeeChangeStatus.desde;
    form.reset();
    if (isTodayOrLess(newStateDate.toJSDate())) {
      this.personalModel.Legajo.estadoActual.PrepareDTO(employeeChangeStatus);
      this.personalModel.Legajo.nextState = null;
    } else if (this.personalModel.Legajo.nextState?.id > 0) {
      if (this.personalModel.Legajo.nextState.id === employeeChangeStatus.id) {
        this.personalModel.Legajo.nextState.PrepareDTO(employeeChangeStatus);
        this.statusChangesDataSource.data = this.statusChangesDataSource.data.map((x) => {
          if (x.id === employeeChangeStatus.id) {
            const dto = new EmployeeChangeStateWithNextBeforeDTO();
            dto.PrepareDTO(employeeChangeStatus);
            return dto;
          }
          return x;
        });
      } else {
        const dto = new EmployeeChangeStateWithNextBeforeDTO();
        dto.PrepareDTO(employeeChangeStatus);
        this.personalModel.Legajo.nextState.nextState = dto;
        this.statusChangesDataSource.data = [...this.statusChangesDataSource.data, dto];
      }
    } else {
      const dto = new EmployeeChangeStateWithNextBeforeDTO();
      dto.PrepareDTO(employeeChangeStatus);
      this.personalModel.Legajo.nextState = dto;
      this.statusChangesDataSource.data = [...this.statusChangesDataSource.data, dto];
    }
    this.currentStatus();
  }

  private insertNewState(form: FormGroup): Observable<PersonalCambioEstadoDTO> {
    const newState = this.generateNewState(form);
    newState.personal = {
      id: this.personalModel.Id,
    } as PersonalLegajoBasicoDTO;
    newState.estadoAnterior =
      this.personalModel.Legajo.nextState?.id > 0 && newState.desde > DateTime.now()
        ? classToJson(this.personalModel.Legajo.nextState)
        : classToJson(this.personalModel.Legajo.estadoActual);
    return this.employeeChangeStateBackendService.insertCambioEstadoIdLegajo(this.personalModel.Legajo.id, newState).pipe(
      tap((res) => {
        if (res) {
          this.showSuccess(res, form);
        }
      }),
    );
  }

  private verifyConditionsFormGroups(): void {
    if (this.personalModel.Legajo.estadoActual?.id > 0) {
      if (this.personalModel.Legajo.nextState?.id > 0) {
        this.$canCreateFutureState.set(false);
        this.nextFormGroup.disable();
      } else {
        this.$canCreateFutureState.set(true);
        this.nextFormGroup.enable();
      }
    } else if (this.personalModel.Legajo.nextState?.nextState?.id > 0) {
      this.$canCreateFutureState.set(false);
      this.nextFormGroup.disable();
    } else {
      this.$canCreateFutureState.set(true);
      this.nextFormGroup.enable();
    }
  }
}
