<div class="d-flex flex-column flex-grow-1 py-6">
  <form #personalesForm="ngForm">
    <div class="d-flex flex-row justify-content-center align-items-end flex-wrap gap-1">
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.NATIONALITY' | translate }} </mat-label>
        <mat-select #nac [(ngModel)]="personalModel.Nacionalidad" id="nac" name="nac" [disabled]="!modoEdicion" [compareWith]="compareId">
          <mat-select-trigger>
            <app-country [$country]="personalModel.Nacionalidad" [useNationality]="true" />
          </mat-select-trigger>
          <mat-option [disabled]="true">
            <ngx-mat-select-search [labelGenerator]="countryLabelGetter" [list]="countries" (filtered)="filteredCountries = $event">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let pais of filteredCountries" [value]="pais">
            <app-country [$country]="pais" [useNationality]="true" />
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="flex-fill gat-1 d-flex flex-row align-items-center">
        <mat-form-field class="flex-fill" appearance="outline">
          <mat-label> {{ 'PROFILE.ID_NUMBER' | translate }} </mat-label>
          <input
            #doc
            matInput
            id="doc"
            name="doc"
            #dniModel="ngModel"
            allowNullValue
            [mask]="maskPersonalId?.toString()"
            [pattern]="dniPattern"
            [(ngModel)]="personalModel.Dni"
            [disabled]="!modoEdicion || !modoEmpresa"
            [onReturn]="['updButt', 'afipButton']"
            [command]="autoCompleteCmd"
            [commandValue]="personalModel?.Id > 0"
            [maxLength]="maxLengthId"
            required
          />
          @if (modoEmpresa && personalModel?.Id > 0) {
            <mat-icon
              fontSet="material-symbols-outlined"
              [class.pointer]="modoEdicion && isArgentinaIssuingCountry"
              [class.color-gray-light]="!isArgentinaIssuingCountry"
              #updButt
              id="updButt"
              name="updButt"
              type="button"
              mat-raised-button
              color="primary"
              [command]="autoCompleteCmd"
              [commandValue]="true"
              matSuffix
              >search
            </mat-icon>
          }
          @if (modoEmpresa && !personalModel.Id) {
            <mat-icon
              fontSet="material-symbols-outlined"
              [class.pointer]="modoEdicion && isArgentinaIssuingCountry"
              [class.color-gray-light]="!isArgentinaIssuingCountry"
              #afipButton
              id="afipButton"
              name="afipButton"
              type="button"
              mat-raised-button
              color="primary"
              [command]="autoCompleteCmd"
              [commandValue]="false"
              matSuffix
              >search
            </mat-icon>
          }
          <mat-error *ngIf="dniModel.errors?.pattern"> {{ 'EMPLOYEES.INVALID_ID_NUMBER' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.ISSUING_COUNTRY' | translate }} </mat-label>
        <mat-select
          [compareWith]="compareId"
          name="issuingCountry"
          [(ngModel)]="personalModel.IssuingCountry"
          (selectionChange)="issuingCountryChange()"
          [disabled]="!modoEdicion"
        >
          <mat-select-trigger>
            <app-country [$country]="personalModel.IssuingCountry" />
          </mat-select-trigger>
          <mat-option *ngFor="let icountry of countries" [value]="icountry">
            <app-country [$country]="icountry" />
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label>{{ 'PROFILE.TIN' | translate }}</mat-label>
        <input
          #cu
          matInput
          id="cu"
          name="cu"
          #cuilModel="ngModel"
          [(ngModel)]="personalModel.Cuil"
          [mask]="maskPersonalTaxId?.toString()"
          [pattern]="cuilPattern"
          [disabled]="!modoEdicion || !modoEmpresa"
          [onReturn]="gs"
          [maxLength]="maxLengthTax"
          [required]="requiredCuil"
        />
        <mat-error *ngIf="cuilModel.errors?.pattern"> {{ 'EMPLOYEES.INVALID_TIN' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="d-flex flex-row flex-wrap gap-1">
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.LAST_NAME' | translate }} </mat-label>
        <input
          matInput
          type="text"
          id="ap"
          name="ap"
          [(ngModel)]="personalModel.Apellido"
          [disabled]="!modoEdicion || !modoEmpresa"
          [onReturn]="nom"
          required
        />
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.FIRST_NAME' | translate }} </mat-label>
        <input
          #nom
          matInput
          type="text"
          id="nom"
          name="nom"
          [(ngModel)]="personalModel.Nombre"
          [disabled]="!modoEdicion || !modoEmpresa"
          [onReturn]="fechaN"
          required
        />
      </mat-form-field>
    </div>
    <div class="d-flex flex-row flex-wrap gap-1">
      <neo-date-selector
        appearance="outline"
        #fechaN
        class="flex-fill"
        formClass="w-100"
        [label]="'PROFILE.BIRTH_DATE' | translate"
        id="fechaN"
        [min]="minDate"
        [disabled]="!modoEdicion || canEditBirthDate"
        [(date)]="personalModel.FechaNacimiento"
        (dateChange)="calcularEdadCmd.execute()"
        [onlyValidDate]="true"
        [onReturn]="gen"
        [form]="personalesForm"
        [useCurrentLocaleOffset]="true"
      >
      </neo-date-selector>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.AGE' | translate }} </mat-label>
        <input matInput type="text" id="edad" name="edad" [value]="personalModel.Edad" disabled />
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.GENDER' | translate }} </mat-label>
        <mat-select [disabled]="!modoEdicion" #gen [(ngModel)]="personalModel.Genero" id="gen" name="gen" [onReturn]="ec">
          <mat-option *ngFor="let genero of generos | arrayTranslate: 'ENUM.PROFILE.TIPO_GENERO'" [value]="genero.value">
            {{ genero.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex flex-row flex-wrap gap-1">
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label>{{ 'PROFILE.PASSPORT' | translate }}</mat-label>
        <input matInput id="passport" name="passport" [(ngModel)]="personalModel.Passport" [disabled]="!modoEdicion" />
      </mat-form-field>

      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.MARITAL_STATUS' | translate }} </mat-label>
        <mat-select #ec [disabled]="!modoEdicion" [(ngModel)]="personalModel.EstadoCivil" id="ec" name="ec" [onReturn]="doc">
          <mat-option *ngFor="let status of estadosCiviles | arrayTranslate: 'ENUM.PROFILE.ESTADO_CIVIL'" [value]="status.value">
            {{ status.label | unisex }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.BLOOD_TYPE' | translate }} </mat-label>
        <mat-select #gs id="gs" name="gs" [onReturn]="altura" [disabled]="!modoEdicion" [(ngModel)]="personalModel.GrupoSanguineo">
          <mat-option *ngFor="let g of grupoSanguineo" [value]="g">{{ g }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <h4 class="primary-color">{{ 'PROFILE.STUDIES' | translate }}</h4>
    <div class="d-flex flex-row flex-wrap gap-1">
      <mat-form-field class="w-33 w-100-md" appearance="outline">
        <mat-label> {{ 'PROFILE.STUDIES_LEVEL' | translate }} </mat-label>
        <mat-select
          #nivel
          id="nivel"
          name="nivel"
          [disabled]="!modoEdicion"
          [compareWith]="compareId"
          [(ngModel)]="personalModel.NivelEstudio"
        >
          <mat-option *ngFor="let nivel of nivelesEstudio" [value]="nivel">{{ nivel.nombre }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <h4 class="primary-color">{{ 'PROFILE.BODY_MEASUREMENTS' | translate }}</h4>
    <div class="d-flex flex-row flex-wrap gap-1">
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.HEIGHT' | translate }} </mat-label>
        <input
          #altura
          matInput
          id="altura"
          name="altura"
          [(ngModel)]="personalModel.Altura"
          [disabled]="!modoEdicion"
          [onReturn]="circc"
          currencyMask
          [options]="{
            prefix: '',
            suffix: heightSuffix,
            thousands: '.',
            decimal: ',',
            allowNegative: false,
            max: 250
          }"
        />
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.HEAD_CIRCUMFERENCE' | translate }} </mat-label>
        <input
          #circc
          matInput
          id="circc"
          name="circc"
          [(ngModel)]="personalModel.CircunferenciaCabeza"
          [disabled]="!modoEdicion"
          [onReturn]="tallep"
          currencyMask
          [options]="{
            prefix: '',
            suffix: headCircumferenceSuffix,
            thousands: '.',
            decimal: ',',
            allowNegative: false,
            max: 70
          }"
        />
      </mat-form-field>
    </div>
    <div class="d-flex flex-row flex-wrap gap-1">
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.PANTS_SIZE' | translate }} </mat-label>
        <input
          #tallep
          matInput
          id="tallep"
          name="tallep"
          [(ngModel)]="personalModel.TallePantalon"
          [disabled]="!modoEdicion"
          [onReturn]="tallec"
        />
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.SHIRT_SIZE' | translate }} </mat-label>
        <input
          matInput
          #tallec
          id="tallec"
          name="tallec"
          [(ngModel)]="personalModel.TalleCamisa"
          [disabled]="!modoEdicion"
          [onReturn]="tallecal"
        />
      </mat-form-field>
      <mat-form-field class="flex-fill" appearance="outline">
        <mat-label> {{ 'PROFILE.SHOE_SIZE' | translate }} </mat-label>
        <input #tallecal matInput id="tallecal" name="tallecal" [(ngModel)]="personalModel.TalleCalzado" [disabled]="!modoEdicion" />
      </mat-form-field>
    </div>
    <h4 class="primary-color">{{ 'PROFILE.MOBILITY' | translate }}</h4>
    <div class="d-flex flex-row flex-wrap gap-1">
      <mat-checkbox
        id="checkDispo"
        [disabled]="!modoEdicion"
        class="pr-6"
        id="checkDispo"
        name="checkDispo"
        color="primary"
        [(ngModel)]="personalModel.DisponibilidadTraslado"
      >
        {{ 'PROFILE.AVAILABILITY_FOR_TRANSFER' | translate }}
      </mat-checkbox>
      <mat-checkbox
        id="checkMovilidad"
        [disabled]="!modoEdicion"
        id="checkMovilidad"
        name="checkMovilidad"
        color="primary"
        [(ngModel)]="personalModel.MovilidadPropia"
      >
        {{ 'PROFILE.OWN_MOBILITY' | translate }}
      </mat-checkbox>
    </div>
    <ng-container *ngIf="modoEmpresa">
      <h4 class="primary-color">{{ 'PROFILE.SIGNATURE_CERTIFICATE' | translate }}</h4>
      <div class="d-flex flex-row flex-wrap flex-grow-1 justify-content-between">
        <ng-container *ngIf="personalModel.Legajo.tieneCertificado; else sinCertificado">
          <span class="mb-2">{{ 'PROFILE.UPLOADED_SIGNATURE_CERTIFICATE' | translate }}</span>
        </ng-container>
        <ng-template #sinCertificado>
          <span class="mb-2"> {{ 'PROFILE.NO_UPLOADED_SIGNATURE_CERTIFICATE' | translate }} </span>
        </ng-template>
      </div>
      <div>
        <button
          [matTooltip]="'PROFILE.GENERATE_SIGNATURE_CERTIFICATE' | translate"
          class="no-shadow mr-2"
          mat-mini-fab
          color="primary"
          [disabled]="!modoEdicion"
          [command]="generarCertificadoCmd"
        >
          <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
        </button>
      </div>
      <ng-container *ngIfFeatureFlag="'featureFirmaOlografa'">
        <h4 class="primary-color">{{ 'PROFILE.HOLOGRAPHIC_SIGNATURE' | translate }}</h4>
        <div class="d-flex flex-row flex-wrap flex-grow-1 justify-content-between">
          <div class="d-flex flex-column">
            <input
              hidden
              accept="image/jpeg,image/png,image/jpg"
              (change)="signaturefileChangeEvent($event)"
              #fileInputU
              type="file"
              id="fileU"
            />
            <ng-container *ngIf="ownSignatureImageUrl(); else sinImagenOlografa">
              <img
                class="mb-2 material-border"
                [src]="ownSignatureImageUrl()"
                [alt]="'PROFILE.OWN_HOLOGRAPHIC_SIGNATURE_IMAGE' | translate"
                loading="lazy"
              />
            </ng-container>
            <ng-template #sinImagenOlografa>
              <span class="mb-2"> {{ 'PROFILE.NO_UPLOADED_SIGNATURE' | translate }} </span>
            </ng-template>
            <div class="d-flex">
              <button
                [matTooltip]="'PROFILE.SELECT_SIGNATURE_IMAGE' | translate"
                class="no-shadow mr-2"
                (click)="fileInputU.click()"
                mat-mini-fab
                color="primary"
                [disabled]="!modoEdicion"
              >
                <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
              </button>
              <button color="danger" (click)="quitaFirmaOlografica()" mat-icon-button [disabled]="!modoEdicion">
                <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
              </button>
            </div>
          </div>

          <div class="d-flex flex-column flex-wrap gap-1">
            <mat-checkbox
              id="checkUseOwnSignature"
              [disabled]="!modoEdicion"
              id="checkUseOwnSignature"
              name="checkUseOwnSignature"
              color="primary"
              [(ngModel)]="personalModel.UsaSuFirmaOlografa"
            >
              {{ 'PROFILE.USE_HOLOGRAPHIC_SIGNATURE' | translate }}
            </mat-checkbox>
            <mat-checkbox
              id="checkAddAnagraphicData"
              [disabled]="!modoEdicion"
              id="checkAddAnagraphicData"
              name="checkAddAnagraphicData"
              color="primary"
              [(ngModel)]="personalModel.AgregarDatosAnagraficos"
            >
              {{ 'PROFILE.ADD_ANAGRAPHIC_DATA' | translate }}
            </mat-checkbox>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
</div>
