<div mat-dialog-content class="d-flex flex-column">
  <div class="w-100 d-flex justify-content-center">
    <strong class="primary-color mr-2">Naaloo</strong><span class="smaller gray-text-muted">{{ version }}</span>
  </div>
  <mat-divider class="mb-2 mt-1"></mat-divider>
  <button
    *ngIf="isAdmin && !isClockInOutTerminal"
    class="justify-content-start"
    mat-button
    [mat-dialog-close]="resultEnum.CambiarVista"
    cdkFocusInitial
  >
    <mat-icon
      fontSet="material-symbols-outlined"
      color="accent"
      svgIcon="user-view-switch"
      *ngIf="vistaEmpresa$ | async"
      class="pr-6 mr-0"
    ></mat-icon>
    <mat-icon
      fontSet="material-symbols-outlined"
      color="accent"
      svgIcon="company-view-switch"
      *ngIf="(vistaEmpresa$ | async) === false"
      class="pr-6 mr-0"
    ></mat-icon>
    <label class="pointer">{{
      (vistaEmpresa$ | async) ? ('LOWER_MENU.MEMBER_VIEW' | translate) : ('LOWER_MENU.ADMIN_VIEW' | translate)
    }}</label>
  </button>
  <button class="justify-content-start notification" mat-button [mat-dialog-close]="resultEnum.Notifiaciones" *ngIf="!isClockInOutTerminal">
    <mat-icon
      class="mr-0"
      fontSet="material-symbols-outlined"
      [matBadge]="notificationService.notificationsNotSeen | ngxshortNumber"
      matBadgePosition="after"
      [matBadgeHidden]="notificationService.notificationsNotSeen === 0"
      [matBadgeColor]="$any('danger')"
      >notifications</mat-icon
    >
    <label class="pl-6 pointer">{{ 'GENERAL.NOTIFICATIONS' | translate }}</label>
  </button>
  <button
    *ngIf="puedeIrFichaje && !isClockInOutTerminal"
    class="justify-content-start"
    mat-button
    [mat-dialog-close]="resultEnum.CambiarAFichaje"
    cdkFocusInitial
  >
    <mat-icon fontSet="material-symbols-outlined" class="pr-6 mr-0">qr_code</mat-icon>
    <label class="pointer">{{ 'LOWER_MENU.QR_CLOCK_IN_TERMINAL' | translate }}</label>
  </button>

  <button *ngIf="isAdmin && !isClockInOutTerminal" class="justify-content-start" mat-button [mat-dialog-close]="resultEnum.Perks">
    <mat-icon fontSet="material-symbols-outlined" class="pr-6 mr-0">card_giftcard</mat-icon>
    <label class="pointer">{{ 'GENERAL.PARTNERS' | translate }}</label>
  </button>
  <button class="justify-content-start" mat-button [mat-dialog-close]="resultEnum.MiCuenta" *ngIf="!isClockInOutTerminal">
    <mat-icon fontSet="material-symbols-outlined" class="pr-6 mr-0">account_circle</mat-icon>
    <label class="pointer">{{ 'LOWER_MENU.MY_ACCOUNT' | translate }}</label>
  </button>
  <button
    class="justify-content-start"
    mat-button
    [mat-dialog-close]="resultEnum.CambiarCuenta"
    *ngIf="tieneOtrasCuentas && !isClockInOutTerminal"
  >
    <mat-icon fontSet="material-symbols-outlined" class="pr-6 mr-0">compare_arrows</mat-icon>
    <label class="pointer">{{ 'LOWER_MENU.SWITCH_ACCOUNT' | translate }}</label>
  </button>
  <button class="justify-content-start" mat-button [mat-dialog-close]="resultEnum.Backoffice" *ngIf="headerService.isSupport">
    <mat-icon fontSet="material-symbols-outlined" class="pr-6 mr-0">security</mat-icon>
    <label class="pointer">{{ 'LOWER_MENU.BACKOFFICE' | translate }}</label>
  </button>
  <button class="justify-content-start" mat-button [mat-dialog-close]="resultEnum.Ayuda">
    <mat-icon fontSet="material-symbols-outlined" class="pr-6 mr-0">help</mat-icon>
    <label class="pointer">{{ 'GENERAL.HELP' | translate }}</label>
  </button>
  <button *ngIf="!isClockInOutTerminal" class="justify-content-start referral-button" mat-button [mat-dialog-close]="resultEnum.Referral">
    <mat-icon fontSet="material-symbols-outlined" class="pr-6 mr-0">emoji_events</mat-icon>
    <label class="pointer">{{ 'WELCOME.REFER_AND_WIN' | translate }}</label>
  </button>
  <button class="justify-content-start" mat-button [mat-dialog-close]="resultEnum.CerrarSesion">
    <mat-icon fontSet="material-symbols-outlined" class="pr-6 mr-0">exit_to_app</mat-icon>
    <label class="pointer">{{ 'LOWER_MENU.LOG_OUT' | translate }}</label>
  </button>
</div>
