import { Injectable } from '@angular/core';
import { CargoDTO } from 'src/app/ModelDTO/DTO/cargo.DTO';
import { CargoBasicoDTO } from 'src/app/ModelDTO/DTO/cargoBasico.DTO';
import { CargoConCategoriasDTO } from 'src/app/ModelDTO/DTO/cargoConCategorias.DTO';
import { CargoServiceBackend } from 'src/app/ServiceBackend/cargo.ServiceBackend';

@Injectable({
  providedIn: 'root',
})
export class CargosService {
  private areaAEditar: CargoDTO;

  constructor(private personalCargoServiceBackend: CargoServiceBackend) {}

  public async obtenerCargos(): Promise<Array<CargoDTO>> {
    return this.personalCargoServiceBackend.getCargos();
  }

  public async obtenerCargosBasicos(): Promise<Array<CargoBasicoDTO>> {
    const res = await this.personalCargoServiceBackend.getCargos();
    const cargosBasicos = new Array<CargoBasicoDTO>();
    for (const c of res) {
      const cBasico = new CargoBasicoDTO();
      cBasico.id = c.id;
      cBasico.nombre = c.nombre;
      cBasico.descripcion = c.descripcion;
      cargosBasicos.push(cBasico);
    }
    return cargosBasicos;
  }

  public async agregarCargo(cargo: CargoConCategoriasDTO): Promise<CargoConCategoriasDTO> {
    const res = await this.personalCargoServiceBackend.insertCargos(cargo);
    return res;
  }

  public async editarCargo(cargo: CargoConCategoriasDTO): Promise<CargoConCategoriasDTO> {
    const res = await this.personalCargoServiceBackend.updateCargosId(cargo.id, cargo);
    return res;
  }

  public async eliminarCargo(cargo: CargoConCategoriasDTO): Promise<void> {
    await this.personalCargoServiceBackend.deleteCargosId(cargo.id);
  }

  get CargoAEditar(): CargoDTO {
    return this.areaAEditar;
  }

  set CargoAEditar(t: CargoDTO) {
    this.areaAEditar = t;
  }
}
