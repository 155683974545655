import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { Subscription } from 'rxjs';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { NotificationHandlerService } from 'src/app/shared/services/notification-handler.service';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { featureVistaFichaje } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { Router, NavigationEnd } from '@angular/router';

export enum SelectVistaModalResult {
  Ayuda,
  Backoffice,
  CambiarAFichaje,
  CambiarCuenta,
  CambiarVista,
  CerrarSesion,
  MiCuenta,
  Notifiaciones,
  Perks,
  Referral,
  SinAccion = 0,
}

@Component({
  selector: 'app-select-vista-modal',
  templateUrl: './select-vista-modal.component.html',
  styleUrls: ['./select-vista-modal.component.scss'],
})
export class SelectVistaModalComponent implements OnInit, OnDestroy {
  public isAdmin = this.headerService.isAdmin();
  public version: string = environment.appVersion;
  public vistaEmpresa$ = this.headerService.vistaEmpresa$;
  public resultEnum = SelectVistaModalResult;

  public puedeIrFichaje = false;
  public tieneOtrasCuentas = false;
  public esCordova = false;
  public isClockInOutQr = false;
  public isClockInOutFacial = false;
  public isClockInOutTerminal = false;

  private subscriptions = new Subscription();

  constructor(
    public headerService: HeaderAppService,
    public notificationService: NotificationHandlerService,
    private featureFlagService: FeatureFlagService,
    private cordovaService: CordovaService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.esCordova = this.cordovaService.isCordovaApp;
    this.tieneOtrasCuentas = this.headerService.userLogged.hasMoreAccounts;
    this.updateClockInOutStatus(this.router.url);
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.updateClockInOutStatus(event.urlAfterRedirects);
        }
      }),
    );
    this.subscriptions.add(
      this.featureFlagService.flags$.subscribe(({ flags }) => {
        this.puedeIrFichaje = FeatureFlagHelper.featureOn(featureVistaFichaje, flags);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private updateClockInOutStatus(url: string): void {
    this.isClockInOutQr = url.startsWith('/clock-in-out-terminal-qr');
    this.isClockInOutFacial = url.startsWith('/clock-in-out-terminal-facial');
    this.isClockInOutTerminal = this.isClockInOutFacial || this.isClockInOutQr;
  }
}
