import { Injectable, Inject } from '@angular/core';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';
import { NamedBlobDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/namedBlob.DTO';
import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map, Observable, Subject } from 'rxjs';
import { AuditLogEntryDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/auditLogEntry.DTO';
import { BankAccountTypeDTO } from 'src/app/ModelDTO/DTO/bankAccountType.DTO';

@Injectable({
   providedIn: 'root'
})
export class BankAccountTypeServiceBackend {

   constructor(protected http: HttpClient,) { }

   public async getBankAccountTypeIdAuditory(id: number): Promise<Array<AuditLogEntryDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/bankAccountType/' + id + '/Auditory'));
         const resJson = res.data;
         const resDTO = new Array<AuditLogEntryDTO>();
         for (const item of resJson) {
            const itemDTO = new AuditLogEntryDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async getBankAccountTypeIdPDF(id: number, namedBlob: NamedBlobDTO = null): Promise<Blob> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/bankAccountType/' + id + '/PDF' + '?BLOB=true', { observe: 'response', responseType: 'blob' }));
                  const resDTO = new Blob([res.body], { type: 'application/pdf'});
         if (namedBlob) {
            namedBlob.setBlobNameFromHttpResponse(res);
            namedBlob.blob = res.body;
         }
         return resDTO;
   }

   public async getBankAccountType(): Promise<Array<BankAccountTypeDTO>> {

         const res = await lastValueFrom(this.http.get<DataDTO>(Constants.apiURL + '/bankAccountType/'));
         const resJson = res.data;
         const resDTO = new Array<BankAccountTypeDTO>();
         for (const item of resJson) {
            const itemDTO = new BankAccountTypeDTO();
            itemDTO.PrepareDTO(item);
            resDTO.push(itemDTO);
         }
         return resDTO;

   }

   public async insertBankAccountType(bankAccountTypeDTO: BankAccountTypeDTO): Promise<BankAccountTypeDTO> {

         const res = await lastValueFrom(this.http.post(Constants.apiURL + '/bankAccountType/', bankAccountTypeDTO));
         const resDTO = new BankAccountTypeDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public insertBankAccountTypeObs(bankAccountTypeDTO: BankAccountTypeDTO): Observable<BankAccountTypeDTO> {
      return this.http.post(Constants.apiURL + '/bankAccountType/', bankAccountTypeDTO).pipe(
        map((res)=>{
          const resDTO = new BankAccountTypeDTO();
          resDTO.PrepareDTO(res);
          return resDTO;
        })
      );
   }

   public async updateBankAccountTypeId(id: number, bankAccountTypeDTO: BankAccountTypeDTO): Promise<BankAccountTypeDTO> {

         const res = await lastValueFrom(this.http.put(Constants.apiURL + '/bankAccountType/' + id, bankAccountTypeDTO));
         const resDTO = new BankAccountTypeDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

   public updateBankAccountTypeIdObs(id: number, bankAccountTypeDTO: BankAccountTypeDTO): Observable<BankAccountTypeDTO> {
      return this.http.put(Constants.apiURL + '/bankAccountType/' + id, bankAccountTypeDTO).pipe(
         map((res)=>{
           const resDTO = new BankAccountTypeDTO();
           resDTO.PrepareDTO(res);
           return resDTO;
         })
       );
   }

   public async deleteBankAccountTypeId(id: number, deleteFromDb: boolean = false): Promise<void> {

         await lastValueFrom(this.http.delete(Constants.apiURL + '/bankAccountType/' + id + '?deleteFromDb=' + deleteFromDb));

   }

   public async getBankAccountTypeId(id: number): Promise<BankAccountTypeDTO> {

         const res = await lastValueFrom(this.http.get(Constants.apiURL + '/bankAccountType/' + id));
         if (!res) { return null; }
         const resDTO = new BankAccountTypeDTO();
         resDTO.PrepareDTO(res);
         return resDTO;

   }

}
