/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { convertJsonDates } from 'src/app/shared/shared-functions';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs'
import { Constants } from 'src/app/shared/constants';
import { DataDTO } from 'src/app/api/interfaces/data.interface';

import { AuditLogEntryDTO } from 'src/app/api/interfaces/audit-log-entry.interface';
import { ImageEntityDTO } from 'src/app/api/interfaces/image-entity.interface';
import { ImageFileDTO } from 'src/app/api/interfaces/image-file.interface';
import { PersonalBasicoContratacionDTO } from 'src/app/api/interfaces/personal-basico-contratacion.interface';
import { PersonalBasicoDTO } from 'src/app/api/interfaces/personal-basico.interface';
import { PersonalDTO } from 'src/app/api/interfaces/personal.interface';
import { PersonalPuestoFlatDTO } from 'src/app/api/interfaces/personal-puesto-flat.interface';

@Injectable({
   providedIn: 'root'
})
export class PersonalBackendService {

   constructor(protected http: HttpClient) { }


   public getPersonalIdAuditory(id: number): Observable<AuditLogEntryDTO[]> {
      return this.http.get<DataDTO<AuditLogEntryDTO>>(Constants.apiURL + '/personal/' + id + '/Auditory').pipe(map((res) => convertJsonDates(res.data) as AuditLogEntryDTO[]));
   }

   public insertPersonal(personalDTO: PersonalDTO): Observable<PersonalDTO> {
      return this.http.post<PersonalDTO>(Constants.apiURL + '/personal/', personalDTO).pipe(map((res) => convertJsonDates(res) as PersonalDTO ));
   }

   public updatePersonalId(id: number, personalDTO: PersonalDTO): Observable<PersonalDTO> {
      return this.http.put<PersonalDTO>(Constants.apiURL + '/personal/' + id, personalDTO).pipe(map((res) => convertJsonDates(res) as PersonalDTO ));
   }

   public deletePersonalId(id: number, deleteFromDb: boolean = false): Observable<void> {
      return this.http.delete<void>(Constants.apiURL + '/personal/' + id + '?deleteFromDb=' + deleteFromDb);
   }
   public getPersonalId(id: number): Observable<PersonalDTO> {
      return this.http.get<PersonalDTO>(Constants.apiURL + '/personal/' + id).pipe(map((res) => convertJsonDates(res) as PersonalDTO ));
   }

   public getPersonal(params: { workDayIds?: string, areaId?: number, sectorId?: number, officeId?: number, issuingCountryId?: number }, ): Observable<PersonalBasicoContratacionDTO[]> {
      const { workDayIds = '', areaId = 0, sectorId = 0, officeId = 0, issuingCountryId = 0 } = params;
      return this.http.get<DataDTO<PersonalBasicoContratacionDTO>>(Constants.apiURL + '/personal/' + '?workDayIds=' + workDayIds +
            '&areaId=' + areaId +
            '&sectorId=' + sectorId +
            '&officeId=' + officeId +
            '&issuingCountryId=' + issuingCountryId).pipe(map((res) => convertJsonDates(res.data) as PersonalBasicoContratacionDTO[]));
   }

   public getPersonalBusquedaPorAfipdocumento(documento: string, issuingCountryId: number = 0): Observable<PersonalDTO> {
      return this.http.get<PersonalDTO>(Constants.apiURL + '/personal/BusquedaPorAfip/' + documento + '?issuingCountryId=' + issuingCountryId).pipe(map((res) => convertJsonDates(res) as PersonalDTO ));
   }

   public getPersonalAutoComplete(contiene: string, pageSize: number): Observable<PersonalBasicoDTO[]> {
      return this.http.get<DataDTO<PersonalBasicoDTO>>(Constants.apiURL + '/personal/autoComplete' + '?contiene=' + contiene +
            '&pageSize=' + pageSize).pipe(map((res) => convertJsonDates(res.data) as PersonalBasicoDTO[]));
   }

   public insertPersonalIdImage(id: number, imageEntityDTO: ImageEntityDTO): Observable<ImageEntityDTO> {
      return this.http.post<ImageEntityDTO>(Constants.apiURL + '/personal/' + id + '/Image', imageEntityDTO).pipe(map((res) => convertJsonDates(res) as ImageEntityDTO ));
   }

   public insertPersonalIdReenviarNuevoMail(id: number): Observable<void> {
      return this.http.post<void>(Constants.apiURL + '/personal/' + id + '/reenviarNuevoMail', null);
   }
   public updatePersonalPerfil(personalDTO: PersonalDTO): Observable<PersonalDTO> {
      return this.http.put<PersonalDTO>(Constants.apiURL + '/personal/perfil', personalDTO).pipe(map((res) => convertJsonDates(res) as PersonalDTO ));
   }

   public insertPersonalIdSignatureImage(id: number, imageFileDTO: ImageFileDTO): Observable<ImageEntityDTO> {
      return this.http.post<ImageEntityDTO>(Constants.apiURL + '/personal/' + id + '/signatureImage', imageFileDTO).pipe(map((res) => convertJsonDates(res) as ImageEntityDTO ));
   }

   public getPersonalOrganization(params: { area?: number, sector?: number, office?: number, onlyTeam?: boolean, businessName?: number }, ): Observable<PersonalPuestoFlatDTO[]> {
      const { area = 0, sector = 0, office = 0, onlyTeam = false, businessName = 0 } = params;
      return this.http.get<DataDTO<PersonalPuestoFlatDTO>>(Constants.apiURL + '/personal/organization' + '?area=' + area +
            '&sector=' + sector +
            '&office=' + office +
            '&onlyTeam=' + onlyTeam +
            '&businessName=' + businessName).pipe(map((res) => convertJsonDates(res.data) as PersonalPuestoFlatDTO[]));
   }
}
