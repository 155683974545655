import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { PersonalModelDTO } from 'src/app/ModelDTO/personal.ModelDTO';
import { CambioDeEstadoComponent } from 'src/app/app-common/perfil-empleado-shared/detalle-empleado/cambio-de-estado/cambio-de-estado.component';
import { AccountUserComponent } from './account-user/account-user.component';

@Component({
  selector: 'app-account-detail',
  standalone: true,
  imports: [MatTabGroup, MatTab, TranslateModule, AccountUserComponent, CambioDeEstadoComponent],
  templateUrl: './account-detail.component.html',
  styleUrl: './account-detail.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountDetailComponent {
  @Input() public editMode = false;
  @Input() public employeeModel: PersonalModelDTO;
}
