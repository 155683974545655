<div class="d-flex flex-column" mat-dialog-title>
  <div class="d-flex flex-row align-items-center justify-content-between">
    <h1>
      {{ (data.mode === TenantModalMode.ACCOUNT ? 'ACCOUNT.ACCOUNTS' : 'GENERAL.ROLES') | translate }} ({{ originalDataSource.length }})
    </h1>
    <span class="dialog-close-icon material-symbols-outlined" (click)="close()"> close </span>
  </div>
  <mat-form-field class="w-100" subscriptSizing="dynamic" appearance="outline" *ngIf="originalDataSource.length > 6">
    <mat-label> {{ 'GENERAL.FILTER_BY_NAME' | translate }}...</mat-label>
    <input matInput type="text" [ngModel]="searchText" (ngModelChange)="onSearchTextChange($event)" />
  </mat-form-field>
</div>
<div mat-dialog-content>
  <ng-container *ngFor="let tenant of filteredDataSource">
    <div
      *ngIf="tenant.id !== ($company | async)?.tenantId"
      [command]="tenantCmd"
      [commandValue]="tenant"
      class="mb-2 gray-hover pointer d-flex flex flex-row align-items-center"
    >
      <app-circle-image [width]="50" [imageSource]="getImageUrl(tenant)"> </app-circle-image>
      &nbsp; {{ tenant.name }}
    </div>
  </ng-container>
</div>
