import { IEntityDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/entity.DTO';
import { CompetenceTypeDTO } from 'src/app/ModelDTO/DTO/competenceType.DTO';
import { CompetenceResultDTO } from 'src/app/ModelDTO/DTO/competenceResult.DTO';


export class CompetenceCategoryDTO implements IEntityDTO {

   name = '';
   description = '';
   types: Array<CompetenceTypeDTO>;
   result: CompetenceResultDTO;
   id = 0;
   cacheStamp = 0;

   constructor() {
      this.types = new Array<CompetenceTypeDTO>();
   }

   public PrepareDTO(jsonObj): void {
      if (jsonObj === null) { return; }
      if (jsonObj.name != null) { this.name = jsonObj.name; }
      if (jsonObj.description != null) { this.description = jsonObj.description; }
      if (jsonObj.result != null) {
        this.result = new CompetenceResultDTO();
        this.result.PrepareDTO(jsonObj.result);
      }
      if (jsonObj.types != null) { for (const item of jsonObj.types) { const itemDTO = new CompetenceTypeDTO(); itemDTO.PrepareDTO(item); this.types.push(itemDTO); } }
      if (jsonObj.id != null) { this.id = jsonObj.id; }
      if (jsonObj.cacheStamp != null) { this.cacheStamp = jsonObj.cacheStamp; }
   }

   public isNewEntity(): boolean {
      return this.id === 0;
   }
}
