import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CambioDTO } from 'src/app/ModelDTO/DTO/cambio.DTO';
import { Command, ICommand } from 'src/app/shared/lib/ngx-neo-directives-mat/ngx-command/command.directive';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal-edicion-historial',
  templateUrl: './modal-edicion-historial.component.html',
})
export class ModalEdicionHistorialComponent {
  public cambioAModificar: CambioDTO;

  public editarCmd: ICommand = new Command(() => this.editarCambioHistorial(), new BehaviorSubject(true), false);

  constructor(
    private dialogRef: MatDialogRef<ModalEdicionHistorialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CambioDTO,
  ) {
    this.cambioAModificar = new CambioDTO();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public editarCambioHistorial(): void {
    this.dialogRef.close(this.data);
  }
}
