import { DestroyRef, inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ActionType } from '@api/enums/action-type.enum';
import { EstadoCapacitacion } from '@api/enums/estado-capacitacion.enum';
import { NotificationPriority } from '@api/enums/notification-priority.enum';
import { NotificationState } from '@api/enums/notification-state.enum';
import { TipoCategoriaDocumento } from '@api/enums/tipo-categoria-documento.enum';
import { TipoNotificacion } from '@api/enums/tipo-notificacion.enum';
import { NotificationAttachmentsDTO } from '@api/interfaces/notification-attachments.interface';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, filter, Subject, switchMap, take, tap } from 'rxjs';
import { EventosService } from 'src/app/app-common/calendario-eventos/eventos.service';
import { DetalleNotificacionComponent } from 'src/app/app-common/detalle-notificacion/detalle-notificacion.component';
import { DocumentTabEnum } from 'src/app/app-common/documentacion-shared/document-tab.enum';
import { ModalGoalChangedComponent, ModalGoalChangedData } from 'src/app/app-common/modal-goal-changed/modal-goal-changed.component';
import { SectionsEmployee } from 'src/app/app-common/perfil-empleado-shared/perfil-empleado-enums';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { AdminTrainingTabs } from 'src/app/layout/administrador/administrador-capacitaciones/admin-training-tabs.enum';
import { CuestionariosService } from 'src/app/layout/administrador/administrador-cuestionarios/cuestionarios.service';
import { DeliverySectionEnum } from 'src/app/layout/administrador/administrador-entregas/delivery-section.enum';
import { DUED_RETURN } from 'src/app/layout/administrador/administrador-entregas/listado-de-entregas/listado-de-entregas.component';
import { PerformanceOptionsQueryParams } from 'src/app/layout/user/performance/performance.component';
import { UserPerformanceTab } from 'src/app/layout/user/performance/user-performance-tabs.enum';
import { NotificationResponse, ServiceChangeResponse } from 'src/app/app-common/chat/interfaces/chat-backend-response.interface';
import { FeatureFlagService } from 'src/app/shared/feature-flags/feature-flags.service';
import { FeatureFlagHelper } from 'src/app/shared/feature-flags/feature-flag.helper';
import { companyFeatureWebsocket } from 'src/app/shared/feature-flags/feature-flag-provider.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { classToJson } from 'src/app/shared/shared-functions';
import { NotificationDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/notification.DTO';
import { NotificationServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/backend/notification.ServiceBackend';
import { PushService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/push/push.service';
import { ServiceChangeDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/serviceChange.DTO';
import { ChatService } from 'src/app/app-common/chat/chat.service';
import { WebSocketService } from './web-socket.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationHandlerService {
  public notificationsNotSeen = 0;
  public pageNumber = 0;
  public notifications: NotificationDTO[] = [];
  public notificationMaxPriority: NotificationPriority = NotificationPriority.Low;
  private getNotificationsSubject = new Subject<NotificationDTO[]>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getNotifications$ = this.getNotificationsSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public isLoading$ = this.isLoadingSubject.asObservable();

  private getMoreNotificationsSubject = new Subject<NotificationDTO[]>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public getMoreNotifications$ = this.getMoreNotificationsSubject.asObservable();

  private newNotificationSubject = new Subject<any>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public newNotification$ = this.newNotificationSubject.asObservable();

  private destroyRef = inject(DestroyRef);

  constructor(
    private notificationServiceBackend: NotificationServiceBackend,
    private pushService: PushService,
    private webSocketService: WebSocketService,
    private headerService: HeaderAppService,
    private dialog: MatDialog,
    private router: Router,
    private neoModalService: NgxNeoModalMatService,
    private translateService: TranslateService,
    private eventService: EventosService,
    private cuestionariosService: CuestionariosService,
    private featureFlagService: FeatureFlagService,
    private chatService: ChatService,
  ) {
    // TODO: This should be deleted when removing push service
    this.pushService.registerPushFrom<NotificationDTO>('NewNotification', (notification) => {
      const notificationDTO = this.getOrPrepareNotificationDTO(notification);
      this.addNewNotificationIfSameTenant(notificationDTO);
    });

    // TODO: This should be deleted when removing push service
    this.pushService.registerPushFrom<ServiceChangeDTO>('ServiceChange', (serviceChange) => {
      if (serviceChange.action === ActionType.Delete) {
        this.notifications = this.notifications.filter((x) => x.id !== serviceChange.id1);
      } else if (serviceChange.serviceNotify.startsWith('/user/notifications/clearNumberInBell')) {
        this.notificationsNotSeen = 0;
      }
    });

    // TODO: This should be deleted when removing push service
    this.pushService.onConectedToServer$.subscribe((data) => {
      if (data && !this.headerService.IsTerminal && this.pushService.shouldExcecuteReconnect && this.headerService.$user()?.user) {
        this.getAllNotification();
      }
    });

    this.featureFlagService.flags$
      .pipe(
        filter((flagsData) => flagsData?.flags?.size > 0 && FeatureFlagHelper.featureOn(companyFeatureWebsocket, flagsData.flags)),
        tap(() => {
          this.subscribeToChannels();
        }),
        switchMap(() =>
          this.webSocketService.connected$.pipe(
            filter((connected) => connected === true),
            take(1),
          ),
        ),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        if (!this.headerService.IsTerminal && this.webSocketService.shouldExecuteReconnect && this.headerService.$user()?.user) {
          this.getAllNotification();
        }
      });
  }

  public async understoodNotification(notificationDTO: NotificationDTO): Promise<void> {
    const readedNotification = await this.notificationServiceBackend.updateUserNotificationsIdUnderstood(
      notificationDTO.id,
      notificationDTO,
    );
    notificationDTO.understoodDateTime = readedNotification.understoodDateTime;
    notificationDTO.state = NotificationState.Read;
    notificationDTO.notUnderstood = false;

    this.notificationPriorityAnalyzer();
  }

  public async archivedNotification(notificationDTO: NotificationDTO): Promise<void> {
    await this.notificationServiceBackend.deleteUserNotificationsId(notificationDTO.id);
    notificationDTO.state = NotificationState.Archived;

    this.notificationPriorityAnalyzer();
  }

  public async unArchivedNotification(notificationDTO: NotificationDTO): Promise<void> {
    await this.notificationServiceBackend.updateUserNotificationsId(notificationDTO.id, notificationDTO, true);
    notificationDTO.state = NotificationState.Read;

    this.notificationPriorityAnalyzer();
  }

  public async ClearBell(): Promise<void> {
    await this.notificationServiceBackend.insertUserNotificationsClearNumberInBell();
    this.notificationsNotSeen = 0;
  }

  public getOrPrepareNotificationDTO(notification: any): NotificationDTO {
    let notificationDTO = this.notifications.find((x) => x.id === notification.id);
    if (!notificationDTO) {
      notificationDTO = new NotificationDTO();
    }
    notificationDTO.PrepareDTO(notification);
    return notificationDTO;
  }

  public addNewNotificationIfSameTenant(notificationDTO: NotificationDTO): void {
    if (this.headerService.tenant && this.headerService.tenant !== notificationDTO.tenant) {
      return;
    }

    const isNewNotification = notificationDTO.id === 0;
    if (isNewNotification) {
      this.notifications.unshift(notificationDTO);
      this.notificationsNotSeen = +1;
      this.notificationPriorityAnalyzer();
      this.newNotificationSubject.next(notificationDTO);
    }
  }

  public async getAllNotification(): Promise<void> {
    this.isLoadingSubject.next(true);
    try {
      const notificationsData = await this.notificationServiceBackend.getUserNotifications(false, 0, 20, -1, true);
      this.notificationsNotSeen = notificationsData.notificationsNotSeen;
      this.notifications = notificationsData.data;

      this.notificationPriorityAnalyzer();

      this.getNotificationsSubject.next(this.notifications);
    } finally {
      this.isLoadingSubject.next(false);
    }
  }

  public handlePushNotification(notification: any): void {
    const notificationDto = this.getOrPrepareNotificationDTO(notification.additionalData?.customData);
    // We don't want to add chat notifications, as they are not shown in inbox
    if (!this.isChatNotification(notificationDto)) {
      this.addNewNotificationIfSameTenant(notificationDto);
    }
    // Don't handle notification if app is in foreground
    if (notificationDto && !notification.additionalData.foreground) {
      this.handleNotification(notificationDto, this.headerService.modoEmpresa(), this.headerService.userLogged.userTypeId);
    }
  }

  public isChatNotification(notification: NotificationDTO): boolean {
    return notification.notificationType === TipoNotificacion.ChatNewMessage;
  }

  public handleNotification(notification: NotificationDTO, isAdminView: boolean, teammateFileId: number): void {
    // if there is no id, it is a only test push
    if (notification.id) {
      if (notification.notificationType === TipoNotificacion.No_definido && this.headerService.isUserTypesUser) {
        this.openedNotification(notification);
      } else {
        this.readNotification(notification);
      }

      if (notification.notificationType === TipoNotificacion.No_definido) {
        this.dialog.open(DetalleNotificacionComponent, {
          data: notification,
          disableClose: true,
          hasBackdrop: true,
          panelClass: 'full-size-sm-900-lg-modal',
        });
      } else {
        this.redirectNotification(notification, isAdminView, teammateFileId);
      }
    }
  }

  public async redirectNotification(notificacion: NotificationDTO, vistaEmpresa: boolean, personalLegajoId: number): Promise<void> {
    if (!this.headerService.IsLogged()) {
      return;
    }

    if (!notificacion.openDateTime) {
      // eslint-disable-next-line no-param-reassign
      notificacion.openDateTime = new Date();
      if (this.notificationsNotSeen !== 0) {
        this.ClearBell();
      }
    }
    const prefijo = vistaEmpresa ? '/admin' : '/user';
    switch (notificacion.notificationType) {
      case TipoNotificacion.NuevoEvento:
      case TipoNotificacion.EventoModificado:
      case TipoNotificacion.NuevoComentarioEvento:
        this.eventService.openEventById(notificacion.serviceId);
        break;
      case TipoNotificacion.NuevaAusencia:
        this.router.navigate([`${prefijo}/ausencias`]);
        break;
      case TipoNotificacion.NuevaSolicitudAusencia:
        this.router.navigate(['admin/ausencias'], {
          queryParams: {
            tab: 1,
            request: notificacion.serviceId,
          },
        });
        break;
      case TipoNotificacion.CambioEstadoSolicitudAusencia:
        this.router.navigate(['user/ausencias', notificacion.serviceId]);
        break;
      case TipoNotificacion.NuevaEntrega:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/entregas`], {
            queryParams: { tab: DeliverySectionEnum.DeliveriesList },
          });
        } else {
          this.router.navigate([`${prefijo}/entregas`]);
        }
        break;
      case TipoNotificacion.NuevasEntregasPendientes:
        this.router.navigate([`${prefijo}/entregas`]);
        break;
      case TipoNotificacion.NuevaSolicitudEntrega: {
        const tab = vistaEmpresa ? DeliverySectionEnum.Requests : DeliverySectionEnum.Pending;
        const selectedRequestId = notificacion.serviceId;
        this.router.navigate([`${prefijo}/entregas`], {
          queryParams: { tab, selectedRequestId },
        });
        break;
      }
      case TipoNotificacion.CambioEstadoSolicitudEntrega: {
        const tab = vistaEmpresa ? DeliverySectionEnum.Requests : DeliverySectionEnum.Pending;
        this.router.navigate([`${prefijo}/entregas`], {
          queryParams: { tab },
        });
        break;
      }
      case TipoNotificacion.CambioEstadoSolicitudCapacitacion:
        this.router.navigate([`${prefijo}/capacitaciones`]);
        // Una solicitud aprobada es una capacitacion pendiente
        break;
      case TipoNotificacion.NuevaCapacitacion:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/capacitaciones`], {
            queryParams: { tab: AdminTrainingTabs.Trainings },
          });
        } else {
          this.router.navigate([`${prefijo}/capacitaciones`]);
        }
        break;
      case TipoNotificacion.NuevaSolicitudCapacitacion:
        this.router.navigate([`${prefijo}/capacitaciones`], {
          queryParams: { tab: AdminTrainingTabs.Requests, selectedRequestId: notificacion.serviceId },
        });
        break;
      case TipoNotificacion.NuevasCapacitacionesPendientes:
        this.router.navigate([`${prefijo}/capacitaciones`], {
          queryParams: {
            tab: AdminTrainingTabs.Trainings,
            status: EstadoCapacitacion.Pendiente,
          },
        });
        break;
      case TipoNotificacion.DevolucionesVencidas: {
        const queryParams = {
          tab: DeliverySectionEnum.DeliveriesList,
          status: DUED_RETURN,
        };
        this.router.navigate([`${prefijo}/entregas`], {
          queryParams,
        });
        break;
      }
      case TipoNotificacion.EmpleadoDevolucionVencida:
        this.router.navigate([`${prefijo}/entregas`]);
        break;
      case TipoNotificacion.NuevaSancion:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/sanciones/detalle`, notificacion.serviceId]);
        } else {
          this.router.navigate([`${prefijo}/sanciones/detalle`, notificacion.serviceId]);
        }
        break;
      case TipoNotificacion.NuevoComentarioSancion:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/sanciones/detalle`, notificacion.serviceId]);
        } else {
          this.router.navigate([`${prefijo}/sanciones/detalle`, notificacion.serviceId]);
        }
        break;
      case TipoNotificacion.NuevoFeedback:
        this.router.navigate([`${prefijo}/feedback/detalle`, notificacion.serviceId]);
        break;
      case TipoNotificacion.NuevoFeedbackMuroDeLaFama:
        this.router.navigate([`${prefijo}/feedback`], {
          queryParams: { tab: vistaEmpresa ? 1 : 2 },
        });
        break;
      case TipoNotificacion.NuevoComentarioFeedback:
        this.router.navigate([`${prefijo}/feedback/detalle`, notificacion.serviceId]);
        break;
      case TipoNotificacion.NuevoDocumento:
        this.router.navigate([`${prefijo}/documentacion/documento/`, notificacion.serviceId], {
          queryParams: {
            type: TipoCategoriaDocumento.Colaborador,
          },
        });
        break;
      case TipoNotificacion.NuevoDocumentoEmpresa:
        this.router.navigate([`${prefijo}/documentacion/documento/`, notificacion.serviceId], {
          queryParams: {
            type: TipoCategoriaDocumento.Empresa,
          },
        });
        break;
      case TipoNotificacion.NuevoDocumentoPublico:
        this.router.navigate([`${prefijo}/documentacion/documento/`, notificacion.serviceId], {
          queryParams: {
            type: TipoCategoriaDocumento.P1ublico,
          },
        });
        break;
      case TipoNotificacion.NuevoCuestionario:
        this.router.navigate([`${prefijo}/cuestionarios/formulario`, notificacion.serviceId]);
        break;
      case TipoNotificacion.CuestionarioDesaprobado:
        if (vistaEmpresa) {
          this.cuestionariosService.notificationServiceId = notificacion.serviceId;
          this.router.navigate([`${prefijo}/cuestionarios`]);
        } else {
          this.neoModalService.warning('INBOX.MODE_CONTROL');
        }
        break;
      case TipoNotificacion.FirmaSolicitada:
        if (notificacion.serviceId > 0) {
          this.router.navigate([`${prefijo}/documentacion/previsualizacion/${notificacion.serviceId}`], {
            queryParams: { owner: personalLegajoId },
          });
        } else {
          this.router.navigate([`${prefijo}/documentacion/signatures`], {
            queryParams: { type: DocumentTabEnum.Signatures },
          });
        }
        break;
      case TipoNotificacion.FirmaRealizada:
        // Usamos serviceId2 porque el backend manda el id de la persona que firmo
        this.router.navigate([`${prefijo}/documentacion/previsualizacion/${notificacion.serviceId}`], {
          queryParams: { owner: notificacion.serviceId2 },
        });
        break;
      case TipoNotificacion.FirmaPendiente:
        this.router.navigate([`${prefijo}/documentacion/previsualizacion/${notificacion.serviceId}`], {
          queryParams: { owner: personalLegajoId },
        });
        break;
      case TipoNotificacion.NuevoComentarioPerfil:
        this.router.navigate(['/user/perfil-usuario'], {
          queryParams: { userMenu: SectionsEmployee.Notas },
        });
        break;
      case TipoNotificacion.DomicilioRequerido:
        this.router.navigate(['/user/perfil-usuario'], {
          queryParams: { userMenu: SectionsEmployee.DatosPersonales, tab: 2 },
        });
        break;
      case TipoNotificacion.NuevoComentarioComunicado:
        {
          const userNotification = await this.notificationServiceBackend.getUserNotificationsNotificationId(notificacion.serviceId);
          if (userNotification) {
            const notificacionInterface = classToJson<NotificationDTO, NotificationAttachmentsDTO>(userNotification);
            this.dialog.open(DetalleNotificacionComponent, {
              data: notificacionInterface,
              disableClose: true,
              panelClass: 'full-size-sm-900-lg-modal',
            });
          } else {
            const message = this.translateService.instant('INBOX.COMMUNICATION_NOT_FOUND');
            await this.neoModalService.warning(message);
          }
        }
        break;
      case TipoNotificacion.NuevoComentarioAusencia:
        if (this.headerService.isUserTypesUser) {
          this.router.navigate([`user/ausencias`], {
            queryParams: {
              tab: 2,
              absence: notificacion.serviceId,
            },
          });
        } else {
          this.router.navigate([`admin/ausencias/${notificacion.serviceId}`]);
        }
        break;
      case TipoNotificacion.NuevoComentarioACandidato:
        this.router.navigate([`${prefijo}/reclutamiento/detalle-candidato/`, notificacion.serviceId]);
        break;
      case TipoNotificacion.NotificationNeedHelp:
      case TipoNotificacion.No_definido:
        {
          const notificacionInterface = classToJson<NotificationDTO, NotificationAttachmentsDTO>(notificacion);
          this.dialog.open(DetalleNotificacionComponent, {
            data: notificacionInterface,
            disableClose: true,
            panelClass: 'full-size-sm-900-lg-modal',
          });
        }
        break;
      case TipoNotificacion.VencimientoContrato:
        this.router.navigate(['/admin/organigrama'], {
          queryParams: { modal: true, userModal: notificacion.serviceId, userMenu: SectionsEmployee.DatosLaborales },
        });
        break;
      case TipoNotificacion.DueTasks:
        this.router.navigate(['/user/tasks/']);
        break;
      case TipoNotificacion.NewTasks:
        {
          let url = '';
          if (notificacion.serviceId > 0) {
            url = `/user/tasks/${notificacion.serviceId}`;
          } else {
            url = '/user/tasks';
          }
          this.router.navigate([url]);
        }
        break;
      case TipoNotificacion.MergeAccount:
        this.router.navigate(['/mi-cuenta']);
        break;
      case TipoNotificacion.NuevoComentarioTarea:
        if (vistaEmpresa) {
          this.router.navigate([`${prefijo}/tasks/detail/`, notificacion.serviceId]);
        } else {
          this.router.navigate([`${prefijo}/tasks/`, notificacion.serviceId]);
        }
        break;
      case TipoNotificacion.ComunicadosSinLeer:
        this.router.navigate(['/user/comunicados']);
        break;
      case TipoNotificacion.NuevoCuestionarioCompetencias:
        this.router.navigate(['/user/performance'], {
          queryParams: { tab: UserPerformanceTab.Evaluations },
        });
        break;
      case TipoNotificacion.NewPostulatedCandidate:
        this.router.navigate([`/admin/reclutamiento/oferta/${notificacion.serviceId}/candidato/${notificacion.serviceId2}`]);
        break;
      case TipoNotificacion.GoalEdition: {
        const data: ModalGoalChangedData = {
          notification: classToJson(notificacion),
          titleKey: 'PERFORMANCE.GOALS.GOAL_MODIFICATION',
          acceptAction: () => {
            this.router.navigate(['/user/tasks/', notificacion.serviceId]);
          },
        };
        this.dialog.open(ModalGoalChangedComponent, {
          data,
          disableClose: true,
        });
        break;
      }
      case TipoNotificacion.SubordinatedGoalEdition: {
        const data: ModalGoalChangedData = {
          notification: classToJson(notificacion),
          titleKey: 'PERFORMANCE.GOALS.EMPLOYEE_GOAL_MODIFICATION',
          acceptAction: () => {
            this.router.navigate(['/admin/tasks/detail/', notificacion.serviceId]);
          },
        };
        this.dialog.open(ModalGoalChangedComponent, {
          data,
          disableClose: true,
        });
        break;
      }
      case TipoNotificacion.NewProfileEditions:
        this.router.navigate(['/admin/organigrama/historial-cambios']);
        break;
      case TipoNotificacion.ReevaluateGoal: {
        const queryParams: PerformanceOptionsQueryParams = {
          tab: UserPerformanceTab.Evaluations,
          option: 'goals',
          goal: notificacion.serviceId,
        };

        const data: ModalGoalChangedData = {
          notification: classToJson(notificacion),
          titleKey: 'PERFORMANCE.GOALS.REEVALUATE_GOAL',
          acceptAction: () => {
            this.router.navigate(['/user/performance'], { queryParams });
          },
        };

        this.dialog.open(ModalGoalChangedComponent, {
          data,
          disableClose: true,
        });
        break;
      }
      case TipoNotificacion.MentionInSocialPost: {
        this.router.navigate(['/user/feed'], {
          queryParams: { post: notificacion.serviceId },
        });
        break;
      }
      case TipoNotificacion.NewCommentSocialPost: {
        this.router.navigate(['/user/feed'], {
          queryParams: { post: notificacion.serviceId },
        });
        break;
      }
      case TipoNotificacion.DeleteSocialPost: {
        this.router.navigate(['/user/feed'], {
          queryParams: { post: notificacion.serviceId },
        });
        break;
      }
      case TipoNotificacion.ChatNewMessage: {
        // service is the conversationId for this notification type
        this.chatService.openConversation(notificacion.service);
        break;
      }
      default:
        break;
    }
  }

  private subscribeToChannels(): void {
    this.webSocketService
      .subscribeToChannel('NewNotification')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((notificationResponse: NotificationResponse) => {
        const notificationDTO = this.getOrPrepareNotificationDTO(notificationResponse.content);
        this.addNewNotificationIfSameTenant(notificationDTO);
      });

    this.webSocketService
      .subscribeToChannel('ServiceChange')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((serviceChangeResponse: ServiceChangeResponse) => {
        const serviceChange = new ServiceChangeDTO();
        serviceChange.PrepareDTO(serviceChangeResponse.content);

        if (serviceChange.action === ActionType.Delete) {
          this.notifications = this.notifications.filter((x) => x.id !== serviceChange.id1);
        } else if (serviceChange.serviceNotify.startsWith('/user/notifications/clearNumberInBell')) {
          this.notificationsNotSeen = 0;
        }
      });
  }
  private notificationPriorityAnalyzer(): void {
    this.notificationMaxPriority = NotificationPriority.Low;
    this.notifications.forEach((notification) => {
      if (notification.state !== NotificationState.NotRead) return;
      if (notification.priority === NotificationPriority.High) {
        this.notificationMaxPriority = NotificationPriority.High;
      } else if (notification.priority === NotificationPriority.Medium) {
        this.notificationMaxPriority = NotificationPriority.Medium;
      }
    });
  }

  private readNotification(notification: NotificationDTO): void {
    notification.readDateTime = DateTime.now().toJSDate();
    notification.state = NotificationState.Read;
    this.notificationPriorityAnalyzer();
    this.notificationServiceBackend.updateUserNotificationsId(notification.id, notification).catch(() => {
      notification.readDateTime = null;
      notification.state = NotificationState.NotRead;
    });
  }

  private openedNotification(notification: NotificationDTO): void {
    notification.openDateTime = DateTime.now().toJSDate();
    this.notificationPriorityAnalyzer();
    this.notificationServiceBackend.updateUserNotificationsIdOpened(notification.id, notification).catch(() => {
      notification.openDateTime = null;
    });
  }
}
