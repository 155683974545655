import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HeaderAppService } from 'src/app/core/header/header-app.service';
import { NgxNeoModalMatService } from 'src/app/shared/lib/ngx-neo-modal-mat/ngx-neo-modal-mat.service';
import { AuthenticationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/helpers/auth/authentication.service';
import { AuthServiceBackend } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/backend/auth.ServiceBackend';
import { CordovaService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/cordova.service';
import { NativePushNotificationService } from 'src/app/shared/lib/ngx-neo-frontend-mat/services/cordova/native-push-notification.service';
import { AuthTenantsResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authTenantsResponse.DTO';
import { TenantResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/tenantResponse.DTO';
import { AuthRequestDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authRequest.DTO';
import { UserTypes } from '@api/enums/user-types.enum';
import { AuthResponseDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/authResponse.DTO';
import { SelectAccountModalComponent, TenantModalInfo, TenantModalMode } from './select-account-modal/select-account-modal.component';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public searching = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private neoModalService: NgxNeoModalMatService,
    private authenticationService: AuthenticationService,
    private authServiceBackend: AuthServiceBackend,
    private headerService: HeaderAppService,
    private cordoaveService: CordovaService,
    private nativePushNotificationService: NativePushNotificationService,
  ) {}

  public async showAllTenantAccount(res: AuthTenantsResponseDTO, mode = TenantModalMode.ACCOUNT): Promise<TenantResponseDTO> {
    const modalData: TenantModalInfo = { tenants: res.tenants, mode };
    const modalResponse = await this.dialog
      .open(SelectAccountModalComponent, {
        data: modalData,
        disableClose: false,
        panelClass: 'full-size-sm-600-lg-modal',
      })
      .afterClosed()
      .toPromise();

    let tenant: TenantResponseDTO = null;
    if (modalResponse && modalResponse.tenant) {
      tenant = modalResponse.tenant as TenantResponseDTO;
    }
    return tenant;
  }

  public async login(login: AuthRequestDTO, redirectUrl: string = undefined): Promise<void> {
    try {
      this.authenticationService.removeInfoLogin();

      if (this.cordoaveService.isCordovaApp) {
        this.nativePushNotificationService.init();
      }

      this.searching = true;

      const res = await this.authServiceBackend.insertAuth(login);
      this.searching = false;
      this.authenticationService.login(res);
      if (this.headerService.userLogged.userType === UserTypes.Administrator) {
        const lastUrl = localStorage.getItem('lastUrl');
        if (lastUrl && lastUrl.includes('/user')) {
          this.headerService.setModoEmpleado();
        } else {
          this.headerService.setModoEmpresa();
        }
      } else {
        this.headerService.setModoEmpleado();
      }

      if (this.cordoaveService.isCordovaApp) {
        this.router.navigateByUrl('/refreshComponent', { skipLocationChange: true }).finally(() => {
          this.router.navigate([redirectUrl ?? '/'], { replaceUrl: true });
        });
      } else {
        this.router.navigate([redirectUrl ?? '/'], { replaceUrl: true });
      }
    } catch (ex) {
      this.searching = false;
      this.authenticationService.removeInfoLogin();

      if (ex.status === 401) {
        await this.neoModalService.warning('LOGIN.EXPIRED_TOKEN');
      } else if (ex.status === 405) {
        await this.neoModalService.warning('LOGIN.DISABLED_USER');
      }
    }
  }

  public async loginDemo(login: AuthResponseDTO): Promise<void> {
    try {
      this.authenticationService.authResponseDTO = login;
      this.headerService.userLogged.PrepareDTO(login);
      this.headerService.userLogged.userType = 1;
      this.headerService.setModoEmpresa();
      await this.router.navigate(['/layout'], { replaceUrl: true });
    } catch (ex) {
      this.authenticationService.removeInfoLogin();
      if (ex.status === 401) {
        await this.neoModalService.warning('LOGIN.EXPIRED_TOKEN');
      } else if (ex.status === 405) {
        await this.neoModalService.warning('LOGIN.DISABLED_USER');
      }
    }
  }
}
