<div class="d-flex flex-column flex-grow-1 mt-2">
  <app-lista-historial-cambios
    [titulo]="'EMPLOYEES.CHARGES' | translate"
    [listado]="historial?.Cargos"
    [personalModel]="personalModel"
    [disabled]="!modoEdicion || !modoEmpresa"
  >
  </app-lista-historial-cambios>
  <app-lista-doble-historial-cambios
    [tituloGeneral]="'GENERAL.AREAS_AND_SECTORS' | translate"
    [tituloListado]="'GENERAL.AREAS' | translate"
    [tituloSublistado]="'GENERAL.SECTORS' | translate"
    [listado]="historial?.Area"
    [sublistado]="historial?.Sector"
    [personalModel]="personalModel"
    [disabled]="!modoEdicion || !modoEmpresa"
  >
  </app-lista-doble-historial-cambios>
  <app-lista-doble-historial-cambios
    [tituloGeneral]="'GENERAL.CATEGORIES_AND_SUBCATEGORIES' | translate"
    [tituloListado]="'GENERAL.CATEGORIES' | translate"
    [tituloSublistado]="'GENERAL.SUBCATEGORIES' | translate"
    [listado]="historial?.Categoria"
    [sublistado]="historial?.Subcategoria"
    [personalModel]="personalModel"
    [disabled]="!modoEdicion || !modoEmpresa"
  >
  </app-lista-doble-historial-cambios>
  <app-lista-historial-cambios-con-porcentaje
    [titulo]="'GENERAL.SALARIES' | translate"
    [listado]="historial?.Sueldos"
    [personalModel]="personalModel"
    [disabled]="!modoEdicion || !modoEmpresa"
  >
  </app-lista-historial-cambios-con-porcentaje>
</div>
