<div class="d-flex flex-column flex-grow-1">
  <div class="d-flex flex-column filters-container">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label> {{ 'GENERAL.FILTER_BY_NAME' | translate }} </mat-label>
      <input
        matInput
        #busqueda
        id="busqueda"
        name="busqueda"
        type="text"
        matInput
        [(ngModel)]="textFilter"
        [changeCommand]="filterChangeCmd"
        [neoAutofocus]="true"
      />
    </mat-form-field>
    <mat-slide-toggle color="primary" (change)="onMostarSoloSeleccionadosChanged()" [(ngModel)]="mostrarSoloSeleccionados">
      {{ 'GENERAL.SHOW_ONLY_SELECTED' | translate }}
    </mat-slide-toggle>
  </div>

  <div class="vs-container font-small">
    <cdk-virtual-scroll-viewport *ngIf="dataFiltered.length > 0; else noResult" class="vs-viewport" itemSize="48">
      <!-- Table Header -->
      <div class="d-flex flex-row flex-fill font-small box-table-encabezado align-items-center box-bottom-table">
        <div class="d-flex flex-fill ml-3">{{ 'GENERAL.EMPLOYEES' | translate }}</div>
        <div class="d-flex flex-row align-items-center" *ngIf="permitirSeleccionarTodo">
          <mat-checkbox color="primary" class="mr-2" (change)="onSelectAllChanged($event)"></mat-checkbox>
        </div>
      </div>
      <!-- Table data -->
      <mat-selection-list>
        <mat-list-option
          color="primary"
          *ngFor="let data of dataFiltered; let first = first"
          (click)="onRowClick(data)"
          [ngClass]="{ 'box-top-table': !first }"
          [matTooltip]="disabledTooltip | translate: { teammate: data.personalLegajo.nombreCompleto }"
          [matTooltipDisabled]="!data.disabled"
          [value]="data"
          [selected]="data.selected"
        >
          <div class="d-flex flex-row align-items-center">
            <app-circle-image [width]="40" [personalLegajo]="data.personalLegajo"> </app-circle-image>
            <div class="ml-2">{{ data.personalLegajo.nombreCompleto }}</div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </cdk-virtual-scroll-viewport>
    <ng-template #noResult>
      <div class="d-flex flex-row px-3 align-items-center" style="height: 50px">
        {{ mostrarSoloSeleccionados ? ('GENERAL.NO_TEAMMATES_SELECTED' | translate) : ('GENERAL.NO_TEAMMATES_FOUND' | translate) }}
      </div>
    </ng-template>
  </div>
</div>
