import { Constants } from '../shared/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { SalesCrmDTO } from 'src/app/ModelDTO/DTO/salesCrm.DTO';
import { DataDTO } from 'src/app/shared/lib/ngx-neo-frontend-mat/models/DTO/data.DTO';

@Injectable({
   providedIn: 'root'
})
export class ReferralsServiceBackend {

   constructor(protected http: HttpClient) { }

   public getReferrals(): Observable<SalesCrmDTO[]> {
    return this.http.get<DataDTO>(Constants.apiURL + '/integrations/sales-crm').pipe(
      map((res) => {
        const resDTO: SalesCrmDTO[] = [];

        res.data.forEach((item) => {
          const itemDTO = new SalesCrmDTO();
          itemDTO.PrepareDTO(item);
          resDTO.push(itemDTO);
        });

        return resDTO;
    }));
   }
}
